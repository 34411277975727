import {
  Box,
  Heading,
  List,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import React from 'react';
import { karla, ovo } from '../../../Theme/theme';
import PropTypes from 'prop-types';

const dataTerms = [
  {
    title: '',
    description:
      'Hai #KlambyFamily! Kami menyarankan Anda untuk membaca syarat dan ketentuan ini sebelum membeli produk kami di situs web. Dengan melakukan pembelian atau aktivitas lainnya melalui website Klamby, berarti Anda setuju dengan syarat dan ketentuan yang kami buat. Klamby dapat mengubah syarat dan ketentuan setiap saat menjadi lebih baik. Jika Anda tidak setuju dengan syarat dan ketentuan yang kami buat, kami sarankan untuk tidak menggunakan situs web kami :) ',
  },
  {
    title: '1. PRIVASI',
    description:
      'Kami menjaga privasi informasi Anda yang Anda berikan dengan menggunakan situs ini. Kami akan menggunakan informasi sehubungan dengan tujuan pengiriman dan dengan menggunakan situs web kami, Anda menyetujui kebijakan privasi kami.',
  },
  {
    title: '2. SYARAT DAN KETENTUAN UMUM',
    description:
      'Dengan menggunakan situs web, Anda setuju untuk menggunakan produk dan layanan untuk tujuan yang sah, nonkomersial, dan sesuai dengan hukum. Anda dilarang melakukan segala bentuk tindakan yang dapat merugikan KLAMBY dan situs ini.',
  },
  {
    title: '3. #KLAMBY AKUN',
    description:
      'Kami akan melindungi informasi yang Anda berikan kepada kami, kami akan menjaga kerahasiaan informasi Anda. Pastikan Anda memberi kami informasi yang akurat untuk pembaruan pembaruan terbaru kami. ',
  },
  {
    title: '4. KEBIJAKAN PENGEMBALIAN & PENUKARAN',
    description: [
      {
        subtitle: 'Batas waktu untuk mengembalikan atau menukar produk :',
        description: [
          'Batas pengembalian produk maksimal 15 hari setelah anda menerima produk. Jika lebih dari batas waktu yang sudah ditentukan produk tersebut tidak bisa di tukar/ di kembalikan.',
        ],
      },
      {
        subtitle:
          'Syarat dan ketentuan untuk mengembalikan atau menukar produk :',
        description: [
          'Mengembalikan atau menukar produk hanya dapat diterima jika kesalahan dilakukan oleh pihak team Klamby',
          'Lengkapi form retur yang diberikan oleh team Klamby.',
          'Sertakan bukti nomor order ID.',
          'Sertakan bukti foto/ video pada bagian baju yang defect (minor).',
          'Produk belum pernah dicuci dan dengan kondisi yang sama seperti saat paket diterima.',
          'Produk belum pernah digunakan.',
          'Tidak bisa menukar produk dengan model dan size yang berbeda.',
          '(Retur hanya berlaku untuk produk yang sama. Jika ingin tukar dengan produk dan size yang berbeda, kami akan melakukan pengembalian dana sesuai dengan invoice, dan anda dapat melakukan pemesanan ulang melalui website).',
          'Ketersedian produk pengganti sesuai dengan stock yang tersedia',
          'Produk yang dikembalikan harus dalam kondisi yang sesuai dengan keadaan awal (label hang tag masih terpasang, belum digunakan).',
          'Biaya pengiriman akan di sesuaikan dengan keadaan.',
        ],
      },
      {
        subtitle: 'Jika produk pengganti tidak tersedia (sold out)',
        description: [
          'Jika produk pengganti tidak tersedia, kami akan melakukan pengembalian dana sesuai dengan jumlah di invoice. ',
        ],
      },
      {
        subtitle: 'Kami tidak menerima pengembalian jika:',
        description: [
          'Produk telah digunakan',
          'Data yang diberikan tidak jelas',
          'Lebih dari 15 hari setelah barang diterima',
          'Masalah yang terjadi oleh pihak lain',
          'Tidak ada video unboxing',
        ],
      },
    ],
  },
  {
    title: '5. PESANAN',
    description:
      'Semua pembelian yang sudah dibayar tidak dapat dibatalkan/dikembalikan. Kami akan memproses pesanan Anda setelah pembayaran diverifikasi, kami tidak dapat memproses pesanan Anda jika stok kami habis dan kami akan mengkonfirmasi kepada Anda. ',
  },
  {
    title: '6. PENGGUNAAN KOMERSIAL',
    description:
      'Anda dilarang untuk menyalin, mereproduksi, dan mempublikasikan konten ini untuk penggunaan komersial apa pun untuk situs Anda sendiri.',
  },
  {
    title: '7. ISI',
    description:
      'Semua foto yang ditampilkan di website kami adalah foto asli. Kami mencoba yang terbaik untuk menunjukkan tampilan dan warna yang paling akurat, tetapi perbedaan dapat terjadi karena lampu atau tampilan monitor yang berbeda. ',
  },
  {
    title: '8. PERJANJIAN',
    description:
      'Anda setuju untuk bertanggung jawab atas aktivitas Anda di situs web ini. Kami tidak bertanggung jawab atas segala kerugian atau kerusakan atau force majeure yang disebabkan oleh pihak lain. ',
  },
  {
    title: '9. VARIASI KETENTUAN',
    description:
      'Klamby diizinkan untuk merevisi Ketentuan ini kapan saja sesuai keinginan Klamby.',
  },
];

const TermConditionsComponent = ({ label }) => {
  return (
    <>
      <Stack gap={50} mt={10} p={10}>
        <Heading textAlign={'center'} textTransform={'capitalize'} style={ovo}>
          {label.split('-').join(' & ')}
        </Heading>
        <Box mx={20}>
          <Stack mx={20}>
            {dataTerms.map((x, i) => (
              <Stack key={i} gap={3} pt={2}>
                <Text
                  style={karla}
                  fontSize={'2xl'}
                  as={'bold'}
                  fontWeight={'bold'}
                >
                  {x.title}
                </Text>
                {x.title != '4. KEBIJAKAN PENGEMBALIAN & PENUKARAN' ? (
                  <Text style={karla} fontSize={'lg'} pb={4}>
                    {x.description}
                  </Text>
                ) : (
                  x?.description.map((y, i) => (
                    <List key={i} gap={3} ps={2}>
                      <ListItem
                        style={karla}
                        fontSize={'lg'}
                        pb={4}
                        fontWeight={'bold'}
                      >
                        {y?.subtitle}
                      </ListItem>
                      {y.description.map((z, i) => (
                        <UnorderedList key={i} ps={5}>
                          <ListItem style={karla} fontSize={'lg'} pb={4}>
                            {z}
                          </ListItem>
                        </UnorderedList>
                      ))}
                    </List>
                  ))
                )}
              </Stack>
            ))}
          </Stack>
        </Box>
      </Stack>
    </>
  );
};

TermConditionsComponent.propTypes = {
  label: PropTypes.string,
};

export default TermConditionsComponent;
