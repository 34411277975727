/* eslint-disable no-console */
/* eslint-disable react/no-children-prop */
import {
  Button,
  Card,
  HStack,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import {
  sendEmailVerification,
  signOut,
  updatePassword,
  updateProfile,
} from 'firebase/auth';
import React, { useState } from 'react';
import { auth } from '../../Config/firebase';
import { useNavigate } from 'react-router-dom';
import { updateDocumentFirebase } from '../../Apis/firebaseApi';
import { karla, ovo, themeColor } from '../../Theme/theme';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { addPointsCustomer } from '../../Apis/pointApi';
import { addLogoutPixel } from '../../Utils/pixelUtils';
import { UseAuthStore } from '../../Hooks/Zustand/store';

function EmailVerification() {
  const [emailSent, setEmailSent] = useState(false);
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);

  const toast = useToast();
  const navigate = useNavigate();
  const globalState = UseAuthStore();

  const verify = () => {
    if (!auth.currentUser) {
      // Jika pengguna tidak terotentikasi, maka alihkan mereka ke halaman login atau lakukan tindakan lain sesuai kebutuhan.
      // Misalnya:
      toast({
        title: 'Error',
        description: 'User is not authenticated',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
      return;
    }

    if (!name) {
      showErrorToast('Name cannot be blank, please input your name');
    } else if (!password) {
      showErrorToast('Password cannot be blank, please input your password');
    } else if (!confirmPassword) {
      showErrorToast('Confirm password cannot be blank');
    } else if (password !== confirmPassword) {
      showErrorToast('Password and confirm password cannot be different');
    } else {
      setIsVerifying(true);
      updateProfile(auth?.currentUser, {
        displayName: name,
        photoURL: '',
      })
        .then(() => {
          sendEmailVerification(auth.currentUser).then(() => {
            setEmailSent(true);
            updateDocumentFirebase('users', auth?.currentUser?.uid, {
              name: name,
              email: auth?.currentUser?.email,
            }).then(() => {
              updatePassword(auth.currentUser, password)
                .then(async () => {
                  await updatePointRegister();
                  handleLogout();
                })
                .catch((error) => {
                  showErrorToast(error.message);
                })
                .finally(() => {
                  setIsVerifying(false);
                });
            });
          });
        })
        .catch((error) => {
          showErrorToast(error.message);
        });
    }
  };
  const showErrorToast = (message) => {
    toast({
      title: 'Oops!',
      description: message,
      status: 'error',
      duration: 5000,
      isClosable: true,
      position: 'top',
    });
  };

  const updatePointRegister = async () => {
    try {
      const update = await addPointsCustomer(
        auth?.currentUser?.email,
        'ecommerce klamby',
        'inbound',
        750000
      );
      if (update.status) {
        toast({
          title: 'Success',
          description: 'Points added successfully',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top',
        });
      }
    } catch (error) {
      throw new Error(error.message, 'Failed to send error message');
    }
  };

  const handleLogout = async () => {
    try {
      addLogoutPixel(globalState);
      signOut(auth)
        .then(() => {
          toast({
            status: 'success',
            description: 'Logged out successfully',
            duration: 2000,
          });
          navigate('/login');
          localStorage.removeItem('user');
          localStorage.removeItem('addCart');
          localStorage.removeItem('addToCart');
          localStorage.removeItem('tokenFirebase');
          localStorage.removeItem('isLoggedIn');
          globalState?.setIsLoading(false);

          window.location.reload();
        })
        .catch((error) => {
          toast({
            title: 'Log out failed',
            description: error.message,
            status: 'error',
            position: 'top-right',
            isClosable: true,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  const height = window.innerHeight;

  return (
    <Stack
      spacing={5}
      pt={10}
      minH={height / 1.5}
      bgSize='cover'
      alignItems='center'
      justifyContent='center'
    >
      <Card rounded={0}>
        <Stack
          justifyContent='center'
          alignItems='center'
          spacing={5}
          bgColor='white'
          p={10}
          borderRadius='xl'
        >
          <Stack
            w='full'
            px={[1, 1, 5]}
            style={karla}
            spacing={1}
            alignItems='flex-start'
            justifyContent='flex-start'
            rounded={0}
          >
            <Text fontWeight={500}>Please verify your account</Text>
            <Text fontSize={35} fontWeight='bold' style={ovo}>
              Verify Your Email
            </Text>
          </Stack>

          <Stack spacing={4} p='3' borderRadius='xl' style={ovo}>
            <Stack alignItems='center' justifyContent='center'>
              <InputGroup
                w={{
                  base: '92%',
                  md: '92%',
                }}
                alignItems='center'
              >
                <Text alignItems='flex-start' w='250px'>
                  Your email :
                </Text>
                <Input
                  w={{
                    base: '100%',
                    md: '100%',
                  }}
                  fontSize='md'
                  shadow={3}
                  color='black'
                  rounded={0}
                  value={auth?.currentUser?.email}
                  isDisabled={true}
                />
              </InputGroup>
            </Stack>

            <Stack alignItems='center' justifyContent='center'>
              <InputGroup
                w={{
                  base: '92%',
                  md: '92%',
                }}
                alignItems='center'
              >
                <Text alignItems='flex-start' w='250px'>
                  Your display name :
                </Text>
                <Input
                  w={{
                    base: '100%',
                    md: '100%',
                  }}
                  placeholder='Enter your name'
                  fontSize='md'
                  shadow={3}
                  rounded={0}
                  bgColor='white'
                  color='black'
                  onChange={(e) => setName(e.target.value)}
                />
              </InputGroup>
            </Stack>

            <Stack alignItems='center'>
              <InputGroup
                rounded={0}
                w={{
                  base: '92%',
                  md: '92%',
                }}
              >
                <Input
                  w={{
                    base: '100%',
                    md: '100%',
                  }}
                  rounded={0}
                  placeholder='password'
                  fontSize='md'
                  shadow='md'
                  type={showPassword ? 'text' : 'password'}
                  bgColor='white'
                  color='black'
                  onChange={(e) => setPassword(e.target.value)}
                />
                <InputRightElement>
                  {showPassword ? (
                    <MdVisibilityOff
                      size={18}
                      color='black'
                      onClick={() =>
                        setShowPassword((showPassword) => !showPassword)
                      }
                      style={{ cursor: 'pointer' }}
                    />
                  ) : (
                    <MdVisibility
                      size={18}
                      // color="black"
                      onClick={() =>
                        setShowPassword(
                          (showConfirmPassword) => !showConfirmPassword
                        )
                      }
                      style={{ cursor: 'pointer' }}
                    />
                  )}
                </InputRightElement>
              </InputGroup>
            </Stack>

            <Stack alignItems='center'>
              <InputGroup
                w={{
                  base: '92%',
                  md: '92%',
                }}
              >
                <Input
                  w={{
                    base: '100%',
                    md: '100%',
                  }}
                  rounded={0}
                  placeholder='Confirm password'
                  fontSize='md'
                  shadow='md'
                  type={showConfirmPassword ? 'text' : 'password'}
                  bgColor='white'
                  color='black'
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <InputRightElement>
                  {showConfirmPassword ? (
                    <MdVisibilityOff
                      size={20}
                      color='black'
                      onClick={() =>
                        setShowConfirmPassword(
                          (showConfirmPassword) => !showConfirmPassword
                        )
                      }
                      style={{ cursor: 'pointer' }}
                    />
                  ) : (
                    <MdVisibility
                      size={20}
                      color='black'
                      onClick={() =>
                        setShowConfirmPassword(
                          (showConfirmPassword) => !showConfirmPassword
                        )
                      }
                      style={{ cursor: 'pointer' }}
                    />
                  )}
                </InputRightElement>
              </InputGroup>
            </Stack>

            <HStack
              spacing={3}
              w='full'
              pt={2}
              alignItems='center'
              justifyContent='center'
            >
              <Button onClick={handleLogout} size='md' w='fit-content'>
                Back to login
              </Button>

              <Button
                onClick={() => verify()}
                isLoading={isVerifying}
                loadingText='Verifying email, please wait...'
                size='md'
                color={'white'}
                bgColor={themeColor}
              >
                Click to Verify Email
              </Button>
            </HStack>

            {emailSent ? (
              <Heading size='sm'>
                Email sent, please check your email at{' '}
                {auth?.currentUser?.email}
              </Heading>
            ) : (
              <></>
            )}
          </Stack>
        </Stack>
      </Card>
    </Stack>
  );
}

export default EmailVerification;
