import {
  Box,
  Button,
  Divider,
  HStack,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../../Config/firebase';
import { UseAuthStore } from '../../Hooks/Zustand/store';
import { karla, ovo } from '../../Theme/theme';
import {
  getCollectionFirebase,
  getSingleDocumentFirebase,
} from '../../Apis/firebaseApi';
import HistoryOrder from './HistoryOrder';
import EmailVerification from '../Authentication/EmailVerification';
import { addLogoutPixel } from '../../Utils/pixelUtils';

const SettingPage = () => {
  const navigate = useNavigate();

  const toast = useToast();
  const params = useParams();

  const globalState = UseAuthStore();
  const [historyOrder, setHistoryOrder] = useState([]);
  const [userData, setUserData] = useState({});
  const [defaultAddress, setDefaultAddress] = useState([]);
  const [addresses, setAddresses] = useState([]);

  const getUser = async () => {
    try {
      const getDataUser = await getSingleDocumentFirebase('users', params.user);
      const getAddressUser = await getCollectionFirebase(
        `users/${params.user}/address`
      );

      if (getDataUser && getAddressUser) {
        const getDefault = getAddressUser?.find((x) => x.isDefault === true);
        setUserData(getDataUser);
        setDefaultAddress(getDefault);
        setAddresses(getAddressUser);
      }
    } catch (error) {
      // eslint-disable-next-line
      console.log('Error get user data:', error.message);
    }
  };

  const getHistoryUser = async () => {
    try {
      const conditions = [
        {
          field: 'companyId',
          operator: '==',
          value: globalState?.currentCompany,
        },
        {
          field: 'contactId',
          operator: '==',
          value: globalState?.contactId,
        },
        {
          field: 'projectId',
          operator: '==',
          value: globalState?.currentProject,
        },
      ];
      const sortBy = {
        field: 'createdAt',
        direction: 'desc',
      };
      const history = await getCollectionFirebase(
        'crm_orders',
        conditions,
        sortBy
      );
      if (history) {
        setHistoryOrder(history);
      }
    } catch (error) {
      // eslint-disable-next-line
      console.log(error.message);
    }
  };

  const handleLogout = () => {

    //analytics
    addLogoutPixel(globalState);

    signOut(auth)
      .then(() => {
        // Sign-out successful.
        toast({
          status: 'success',
          description: 'Logged out success',
          duration: 2000,
        });
        navigate('/');
        localStorage.clear();
        globalState.setIsLoading(false);
        window.location.reload();
      })

      .catch((error) => {
        // eslint-disable-next-line
        console.log(error, 'ini error');
      });
  };

  useEffect(() => {
    getUser();
    getHistoryUser();
  }, [globalState]);

  return (
    <>
      {globalState?.isLoggedIn ? (
        <>
          {auth?.currentUser?.emailVerified ? (
            <Stack textAlign={'left'} gap={5} my={10}>
              <Heading textAlign={'center'} style={ovo} fontSize={'3xl'}>
                My account
              </Heading>
              <Button
                variant={'link'}
                color={'black'}
                style={karla}
                textDecor={'underline'}
                onClick={() => handleLogout()}
              >
                Log out
              </Button>

              <SimpleGrid
                columns={[1, 1, 1, 2]}
                templateColumns={['100%', '100%', '100%', '70% 30%']}
                gap={10}
              >
                {' '}
                <Box gap={5}>
                  <Stack py={5}>
                    <Heading style={ovo} fontSize={'3xl'}>
                      Personal Details
                    </Heading>
                    <Stack style={karla}>
                      <Text textTransform={'capitalize'}>
                        Name: {userData?.name}
                      </Text>
                      <Text>Email: {userData?.email}</Text>
                    </Stack>
                  </Stack>
                  <Divider />
                  {/* <Stack py={5}>
                      <Heading style={ovo} fontSize={'3xl'}>
                      Points
                      </Heading>
                      <Stack style={karla}>
                        <Text>Total points: {pointUser} point </Text>
                      </Stack>
                    </Stack> */}
                  <Divider />
                  <Stack py={5}>
                    <Heading style={ovo} fontSize={'3xl'}>
                      Order History
                    </Heading>
                    <Stack style={karla} height={'50vh'}>
                      {historyOrder?.length > 0 ? (
                        <HistoryOrder history={historyOrder} />
                      ) : (
                        <Text style={karla}>
                          {'You haven\'t placed any orders yet.'}
                        </Text>
                      )}
                    </Stack>
                  </Stack>
                </Box>
                <Stack
                  gap={5}
                  align={'left'}
                  justify={'left'}
                  textAlign={'left'}
                  mt={[10, 10, 10, 0]}
                >
                  <Heading style={ovo} fontSize={'3xl'}>
                    Account Details
                  </Heading>
                  <Box>
                    <Text style={karla}>
                      {defaultAddress?.address?.company_name}
                    </Text>
                    <Text style={karla} textTransform={'capitalize'}>
                      {defaultAddress?.address?.street}
                    </Text>
                    <Text style={karla}>
                      {' '}
                      {defaultAddress?.address?.subdistrict_name}
                    </Text>
                    <Text style={karla}>{defaultAddress?.address?.city}</Text>
                    <HStack>
                      <Text style={karla}>{defaultAddress?.address?.state}</Text>
                      <Text style={karla}>
                        {defaultAddress?.address?.postal_code}
                      </Text>
                    </HStack>
                    <Text style={karla}>{defaultAddress?.address?.country}</Text>
                  </Box>
                  <Button
                    alignItems={'left'}
                    justifyContent={'left'}
                    variant={'link'}
                    color={'black'}
                    style={karla}
                    textAlign={'left'}
                    onClick={() => navigate(`/account/${params.user}/address`)}
                    textDecor={'underline'}
                  >
                    View addresses ({addresses?.length})
                  </Button>
                </Stack>
              </SimpleGrid>
            </Stack>
          ) : (
            <EmailVerification />
          )}
        </>
      ) : (
        <></>
      )
      }
    </>
  );
};

export default SettingPage;
