/* eslint-disable no-undef */
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider, extendTheme, ColorModeScript } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import ScrollToTop from './Components/scrollToTop';
import TagManager from 'react-gtm-module';

const colors = {
  brand: {
    10: 'gray.50',
    900: '#1a365d',
    800: '#153e75',
    700: '#2a69ac',
  },
};

const theme = extendTheme({
  colors,
  config: {
    initialColorMode: 'light',
    useSystemColorMode: true,
  },
});

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
  dataLayer: {
    event: 'pageview',
    path: window.location.pathname,
    companyId: 'ffBBNgi1CODzR9xxU2Ro',
    projectId: '2h3DLK7iKAXRNNZS7PLR',
  },
};

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/firebase-messaging-sw.js',)
      .then(function (registration,) {
        // Successful registration
        console.log(
          'Hooray. Registration successful, scope is:',
          registration.scope,
        );
      },)
      .catch(function (error,) {
        // Failed registration, service worker won’t be installed
        console.log(
          'Whoops. Service worker registration failed, error:',
          error,
        );
      },);
  },);
}


TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <ScrollToTop />
      <App />
    </ChakraProvider>
  </BrowserRouter>
);

reportWebVitals();
