import { getCollectionFirebase } from '../Apis/firebaseApi';

export const checkProductIncluded = (data, id) => {
  const isIncluded = data.some((x) => {
    if (x?.id === id) {
      return true;
    } else {
      return false;
    }
  });
  return isIncluded;
};

export const checkStockProduct = async (productId) => {
  try {
    const res = await getCollectionFirebase('crm_product_variants', [
      { field: 'productId', operator: '==', value: productId },
    ]);

    if (res?.length > 0) {
      const sumStock = res.reduce((sum, { stock_int }) => sum + stock_int, 0);
      
      // Ensure sumStock is not negative or NaN
      const validSumStock = isNaN(sumStock) || sumStock < 0 ? 0 : sumStock;
      return validSumStock;
    } else {
      return 0;
    }
  } catch (error) {
    console.log(error);
    return 0; // Ensure a zero value is returned in case of an error
  }
};

export const valueVoucher = (value, type) => {
  if (type === 'percentage') {
    return value + '%';
  } else {
    return 'Potongan sebesar Rp. ' + value;
  }
}

