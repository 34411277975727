import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Checkbox,
  CheckboxGroup,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  Flex,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  Spacer,
  Stack,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { karla, ovo } from '../../../Theme/theme';
import { PriceTag } from '../../../Components/PriceTag/PriceTag';
import { UseAuthStore } from '../../../Hooks/Zustand/store';
import PropTypes from 'prop-types';


const FilterProduct = ({ price, stock, size }) => {
  // console.log(stock, "stock")
  const globalState = UseAuthStore();
  const [priceRange, setPriceRange] = useState([]);
  // const [showMinTooltip, setShowMinTooltip] = useState(false);
  // const [showMaxTooltip, setShowMaxTooltip] = useState(false);
  const sizeMaster = [
    'XS',
    'XS/S',
    'S',
    'M',
    'M/L',
    'L',
    'XL',
    'XXL',
    'XXXL',
    '1/2',
    '3/4',
    '5/6',
    '7/8',
    '9/10',
    '11/12',
    'All Size',
    'Allize',
    'Allsize',
    'L-XL-XXL',
    'M-L',
    'XL-XXL',
    'XL/XXL',
    'XS-S',
    'XS-S-M',
  ];

  const handlePrice = (value) => {
    setPriceRange(value);
    globalState?.setFilterPrice(value);
    return price(value);
  };

  const handleStocks = (value) => {
    globalState.setFilterStock(value);
    return stock(value);
  };

  const handleSizes = (value) => {
    globalState.setFilterSize(value);
    return size(value);
  };

  return (
    <DrawerContent>
      <DrawerCloseButton />
      <DrawerHeader style={ovo} fontSize={24} my={4}>
        Filter
      </DrawerHeader>
      <DrawerBody>
        <Accordion allowMultiple style={karla}>
          <AccordionItem>
            <AccordionButton>
              <Box as='span' flex='1' textAlign='left' fontSize={20} my={4}>
                Availability
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <Box display={'flex'} flexDir={'column'} fontSize={20}>
                <CheckboxGroup
                  onChange={(e) => handleStocks(e)}
                  value={globalState.filterStock}
                >
                  <Checkbox size={'lg'} value={'in_stock'}>
                    In stock
                  </Checkbox>
                  <Checkbox size={'lg'} value={'out_of_stock'}>
                    Out of stock
                  </Checkbox>
                </CheckboxGroup>
              </Box>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton>
              <Box as='span' flex='1' textAlign='left' fontSize={20} my={4}>
                Price
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <Box>
                <Flex>
                  <PriceTag
                    price={globalState?.filterPrice[0] || 0}
                    text={karla}
                    currency={'IDR'}
                  />
                  <Spacer />
                  <PriceTag
                    price={globalState?.filterPrice[1] || 950000}
                    text={karla}
                    currency={'IDR'}
                  />
                </Flex>
                <RangeSlider
                  aria-label={['min', 'max']}
                  // defaultValue={[0, 950000]}
                  value={[
                    globalState?.filterPrice[0] ? globalState?.filterPrice[0] : 0,
                    globalState?.filterPrice[1]
                      ? globalState?.filterPrice[1]
                      : 950000,
                  ]}
                  min={0}
                  max={950000}
                  step={1000}
                  colorScheme='teal'
                  onChange={(x) => handlePrice(x)}
                  // onMouseEnter={() => {
                  //   setShowMinTooltip(true);
                  //   setShowMaxTooltip(true);
                  // }}
                  // onMouseLeave={() => {
                  //   setShowMinTooltip(false);
                  //   setShowMaxTooltip(false);
                  // }}
                >
                  <RangeSliderTrack>
                    <RangeSliderFilledTrack bg={'black'} />
                  </RangeSliderTrack>
                  {/* <Tooltip
                    hasArrow
                    bg="white.500"
                    color="Black"
                    placement="top"
                    isOpen={showMinTooltip}
                    label={`IDR ${priceRange[0]},00`}
                  > */}
                  <RangeSliderThumb index={0} />
                  {/* </Tooltip>
                  <Tooltip
                    hasArrow
                    bg="white.500"
                    color="Black"
                    placement="top"
                    isOpen={showMaxTooltip}
                    label={`IDR ${priceRange[1]},00`}
                  > */}
                  <RangeSliderThumb index={1} />
                  {/* </Tooltip> */}
                </RangeSlider>
              </Box>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton>
              <Box as='span' flex='1' textAlign='left' my={4} fontSize={20}>
                Size
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <Stack>
                <CheckboxGroup
                  onChange={(e) => handleSizes(e)}
                  value={globalState?.filterSize}
                >
                  {sizeMaster.map((x, i) => (
                    <Checkbox value={x.toLowerCase()} key={i} size={'lg'}>
                      {x}
                    </Checkbox>
                  ))}
                </CheckboxGroup>
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </DrawerBody>
    </DrawerContent>
  );
};

FilterProduct.propTypes = {
  price: PropTypes.object,
  stock: PropTypes.func,
  size: PropTypes.func,
  sizeMaster: PropTypes.array,
  globalState: PropTypes.object,
  setGlobalState: PropTypes.func,
};

export default FilterProduct;
