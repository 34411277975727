import React from 'react';
import LoginPage from '../Pages/Authentication/LoginPage';
import SignUpPage from '../Pages/Authentication/SignUpPage';
import EmailVerification from '../Pages/Authentication/EmailVerification';
import ForgotPassword from '../Pages/Authentication/ForgotPassword';
import EmailVerificationData from '../Pages/Authentication/EmailVerificationData';

const AuthenticationRouter = [
  {
    path: '/',
    element: <LoginPage />,
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/signup',
    element: <SignUpPage />,
  },
  {
    path: '/email-verifications',
    element: <EmailVerification />,
  },
  {
    path: '/data-email-verifications',
    element: <EmailVerificationData />,
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
  },
];

export default AuthenticationRouter;
