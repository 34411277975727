import { Box, HStack, IconButton, Input, InputGroup, Spacer, Stack, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { ovo } from '../../../Theme/theme';
import { FaKey } from 'react-icons/fa';
import PropTypes from 'prop-types';


const InputPasswordComponent = ({data, password}) => {
  const [input, setInput] = useState('');

  const handleCheckPassword = () => {
    console.log(data, input);
    if (data === input) {
      password(true);
    } else {
      password(false);
    }
  };
  return (
    <>
      <Stack
        minH={window.innerHeight / 1.2}
        bgColor='white'
        bgSize='cover'
        alignItems={'center'}
        justifyContent='center'
      >
        <Stack>
          <Stack
            justifyContent='center'
            shadow={'md'}
            alignItems='center'
            spacing={5}
            bgColor='white'
            p={10}
            borderRadius='xl'
          >
            <Box>
              <Text fontSize={'3xl'} style={ovo}>
                Welcome to our exclusive page
              </Text>
            </Box>
            <Spacer />
            <HStack>
              <Stack>
                <InputGroup w={'350px'}>
                  <Input
                    w={{ base: '100%', md: '100%' }}
                    placeholder='enter secret password'
                    borderRadius={'md'}
                    fontSize={'md'}
                    // type={showPassword ? 'text' : 'password'}
                    bgColor={'white'}
                    color={'black'}
                    id='password'
                    onChange={(e) => setInput(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleCheckPassword();
                      }
                    }}
                  />
                </InputGroup>
              </Stack>
              <IconButton
                borderRadius={'md'}
                style={ovo}
                // isLoading={loading}
                alignItems={'center'}
                justifyContent='center'
                bgColor={'#8e6d46'}
                color={'white'}
                onClick={() => handleCheckPassword()}
                icon={<FaKey />}
              ></IconButton>
            </HStack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

InputPasswordComponent.propTypes = {
  data: PropTypes.string,
  password: PropTypes.func,
};

export default InputPasswordComponent;
