import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Center,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { ovo, themeColor } from '../../Theme/theme';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { setDocumentFirebase } from '../../Apis/firebaseApi';
import { auth } from '../../Config/firebase';
import { UseAuthStore } from '../../Hooks/Zustand/store';

function SignUpPage() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const toast = useToast();
  const navigate = useNavigate();
  const globalState = UseAuthStore();

  const handleSignUp = () => {
    const displayName = data?.firstName + ' ' + data?.lastName;
    if (
      (data?.email === undefined &&
        data?.password === undefined &&
        data?.firstName === undefined &&
        data?.lastName === undefined &&
        data?.dateofbirth === undefined) ||
      data?.password === undefined
    ) {
      return toast({
        title: 'Something Wrong',
        description: 'check your name, birth date, email, and password',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'bottom',
      });
    }

    if (
      data?.email !== '' &&
      data?.password !== '' &&
      data?.firstName !== '' &&
      data?.phoneNumber !== '' &&
      data?.lastName !== ''
    ) {
      try {
        setLoading(true);

        createUserWithEmailAndPassword(auth, data?.email, data?.password)
          .then(async (userCredential) => {
            await updateProfile(auth.currentUser, {
              displayName,
            });
            // Signed in
            const user = userCredential.user;
            const dataUser = {
              firstName: data?.firstName || '',
              lastName: data?.lastName || '',
              name: displayName,
              email: user.email || '',
              gender: data?.gender || '',
              dateofbirth: data?.dateofbirth || '',
              phoneNumber: data?.phoneNumber || '',
              companyId: globalState?.currentCompany,
              projectId: globalState?.currentProject,
              tokenId: []
            };

            if (user) {
              // await setDoc(doc(db, 'users', user?.uid), 
              //   dataUser,
              // );
              await setDocumentFirebase('users', user?.uid, dataUser)

              await setDocumentFirebase(
                'crm_contacts',
                `${globalState?.currentProject}-${dataUser?.phoneNumber}`,
                {
                  aov: 0,
                  country: 'indonesia',
                  ltv: 0,
                  trx: 0,
                  type: 'contacts',
                  externalPoints: 750000
                  // contactsId: user?.uid,
                }
              );

              // const allCreate = await Promise.all([contacts, users]);
              if (!user.emailVerified) {
                toast({
                  title: 'Success Create',
                  description: `Success Create account ${user.displayName}, please check your email`,
                  status: 'success',
                  duration: 10000,
                  isClosable: true,
                  position: 'bottom',
                });
                navigate('/email-verifications');
              } else {
                // setLoading(false);
                navigate('/', { replace: true });
              }
            }
            setLoading(false);
          })
          .catch((error) => {
            toast({
              title: 'Something Wrong',
              description: `${error.message}`,
              status: 'error',
              duration: 5000,
              isClosable: true,
              position: 'bottom',
            });
            setLoading(false);
          });
      } catch (error) {
        toast({
          title: 'Something Wrong',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'bottom',
        });
        setLoading(false);
      }
    } else {
      toast({
        title: 'Something Wrong',
        description: 'check your data',
        status: 'error',
        duration: 10000,
        isClosable: true,
        position: 'bottom',
      });
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const height = window.innerHeight;

  return (
    <Stack
      minH={height / 1.2}
      bgColor='white'
      bgSize='cover'
      alignItems={'center'}
      justifyContent='center'
    >
      <Card w={'50%'} p={5} boxShadow={'base'} rounded={5}>
        <CardHeader>
          <Center>
            <Text fontSize={'3xl'} style={ovo}>
              Create Account
            </Text>
          </Center>
        </CardHeader>
        <CardBody>
          <VStack gap={'15px'} w={'full'}>
            <HStack spacing={'30px'} w={'full'}>
              <Box w={'100%'}>
                <FormControl isRequired>
                  <FormLabel textTransform={'uppercase'} style={ovo}>
                    First name
                  </FormLabel>
                  <Input
                    w={'100%'}
                    focusBorderColor={themeColor}
                    borderRadius={'sm'}
                    fontSize={'md'}
                    shadow={3}
                    bgColor={'white'}
                    color={'black'}
                    onChange={(e) =>
                      setData({ ...data, firstName: e.target.value })
                    }
                  />
                </FormControl>
              </Box>
              <Box w={'100%'}>
                <FormControl isRequired>
                  <FormLabel textTransform={'uppercase'} style={ovo}>
                    Last name
                  </FormLabel>
                  <Input
                    w={'100%'}
                    isRequired
                    focusBorderColor={themeColor}
                    borderRadius={'sm'}
                    fontSize={'md'}
                    shadow={3}
                    bgColor={'white'}
                    color={'black'}
                    onChange={(e) =>
                      setData({ ...data, lastName: e.target.value })
                    }
                  />
                </FormControl>
              </Box>
            </HStack>
            <Stack w={'full'}>
              <FormControl isRequired>
                <FormLabel textTransform={'uppercase'} style={ovo}>
                  Birth date
                </FormLabel>
                <Input
                  w={'full'}
                  focusBorderColor={themeColor}
                  type='date'
                  borderRadius={'sm'}
                  fontSize={'md'}
                  shadow={3}
                  bgColor={'white'}
                  color={'black'}
                  onChange={(e) =>
                    setData({ ...data, dateofbirth: new Date(e.target.value) })
                  }
                />
              </FormControl>
            </Stack>
            <Stack w={'full'}>
              <FormControl isRequired>
                <FormLabel textTransform={'uppercase'} style={ovo}>
                  Gender
                </FormLabel>
                <RadioGroup
                  style={ovo}
                  onChange={(e) => setData({ ...data, gender: e })}
                  value={data?.gender}
                >
                  <Stack direction={'row'}>
                    <Radio value='male'>Male</Radio>
                    <Radio value='female'>Female</Radio>
                  </Stack>
                </RadioGroup>
              </FormControl>
            </Stack>
            <Stack w={'full'}>
              <FormControl isRequired>
                <FormLabel textTransform={'uppercase'} style={ovo}>
                  Phone number
                </FormLabel>
                <InputGroup>
                  <Input
                    w={'full'}
                    focusBorderColor={themeColor}
                    placeholder='62XXXXXXXX'
                    borderRadius={'sm'}
                    fontSize={'md'}
                    shadow={3}
                    bgColor={'white'}
                    color={'black'}
                    onChange={(e) =>
                      setData({
                        ...data,
                        phoneNumber: e.target.value,
                      })
                    }
                  />
                </InputGroup>
              </FormControl>
            </Stack>
            <Stack w={'full'}>
              <FormControl isRequired>
                <FormLabel textTransform={'uppercase'} style={ovo}>
                  Email
                </FormLabel>
                <InputGroup w={'full'}>
                  <Input
                    placeholder='example@email.com'
                    focusBorderColor={themeColor}
                    borderRadius={'sm'}
                    fontSize={'md'}
                    shadow={3}
                    bgColor={'white'}
                    color={'black'}
                    onChange={(e) =>
                      setData({ ...data, email: e.target.value })
                    }
                  />
                </InputGroup>
              </FormControl>
            </Stack>
            <Stack w={'full'}>
              <FormControl isRequired>
                <FormLabel textTransform={'uppercase'} style={ovo}>
                  Password
                </FormLabel>
                <InputGroup w={'full'}>
                  <Input
                    focusBorderColor={themeColor}
                    borderRadius={'sm'}
                    fontSize={'md'}
                    shadow={3}
                    bgColor={'white'}
                    color={'black'}
                    type={showPassword ? 'text' : 'password'}
                    onChange={(e) =>
                      setData({ ...data, password: e.target.value })
                    }
                  />
                  <InputRightElement width='4.5rem'>
                    {showPassword ? (
                      <MdVisibilityOff
                        size={20}
                        color='black'
                        onClick={togglePasswordVisibility}
                        style={{ cursor: 'pointer' }}
                      />
                    ) : (
                      <MdVisibility
                        size={20}
                        color='black'
                        onClick={togglePasswordVisibility}
                        style={{ cursor: 'pointer' }}
                      />
                    )}
                  </InputRightElement>
                </InputGroup>
              </FormControl>
            </Stack>
          </VStack>
        </CardBody>
        <CardFooter>
          <Button
            w={'full'}
            onClick={handleSignUp}
            isLoading={loading}
            style={ovo}
            color={'white'}
            bgColor={themeColor}
          >
            Create New Account
          </Button>
        </CardFooter>
      </Card>
    </Stack>
  );
}

export default SignUpPage;
