/* eslint-disable no-console */
/* eslint-disable react/no-children-prop */

import {
  Button,
  Card,
  HStack,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import {
  sendEmailVerification,
  signOut,
  updatePassword,
  updateProfile,
} from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { auth } from '../../Config/firebase';
import { useNavigate } from 'react-router-dom';
import {
  getSingleDocumentFirebase,
  updateDocumentFirebase,
} from '../../Apis/firebaseApi';
import { karla, ovo, themeColor } from '../../Theme/theme';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { addPointsCustomer } from '../../Apis/pointApi';
import { addLogoutPixel } from '../../Utils/pixelUtils';
import { UseAuthStore } from '../../Hooks/Zustand/store';

const EmailVerificationData = () => {
  const [emailSent, setEmailSent] = useState(false);
  const [data, setData] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);

  const toast = useToast();
  const navigate = useNavigate();
  const globalState = UseAuthStore();

  const getUser = async () => {
    const response = await getSingleDocumentFirebase('users', auth?.currentUser?.uid);
    if (response) {
      setData({...data, name: response?.name, dateofbirth: response?.dateofbirth, gender: response?.gender, phoneNumber: response?.phoneNumber});
    } 
  };

  const verify = () => {
    if (!auth.currentUser) {
      // Jika pengguna tidak terotentikasi, maka alihkan mereka ke halaman login atau lakukan tindakan lain sesuai kebutuhan.
      // Misalnya:
      toast({
        title: 'Error',
        description: 'User is not authenticated',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
      return;
    }

    if (!data?.name) {
      showErrorToast('Name cannot be blank, please input your name');
    } else if (!data?.dateofbirth) {
      showErrorToast(
        'Date of birth cannot be blank, please input your password'
      );
    } else if (!data?.gender) {
      showErrorToast('Gender cannot be blank, please input your password');
    } else if (!data?.phoneNumber) {
      showErrorToast(
        'Phone number cannot be blank, please input your password'
      );
    } else if (!data?.password) {
      showErrorToast('Password cannot be blank, please input your password');
    } else if (!data?.confirmPassword) {
      showErrorToast('Confirm password cannot be blank');
    } else if (data?.password !== data?.confirmPassword) {
      showErrorToast('Password and confirm password cannot be different');
    } else {
      setIsVerifying(true);
      updateProfile(auth?.currentUser, {
        displayName: data?.name,
        photoURL: '',
      })
      // return console.log(auth?.currentUser?.uid, data)
        .then(() => {
          sendEmailVerification(auth.currentUser).then(() => {
            setEmailSent(true);
            const newData = {
              name: data?.name,
              dateofbirth: data?.dateofbirth,
              gender: data?.gender,
              phoneNumber: data?.phoneNumber}
            updateDocumentFirebase('users', auth?.currentUser?.uid, newData).then(
              () => {
                updatePassword(auth.currentUser, data?.password)
                  .then(async () => {
                    // await updatePointRegister();
                    handleLogout();
                  })
                  .catch((error) => {
                    showErrorToast(error.message);
                  })
                  .finally(() => {
                    setIsVerifying(false);
                  });
              }
            );
          });
        })
        .catch((error) => {
          showErrorToast(error.message);
        });
    }
  };
  const showErrorToast = (message) => {
    toast({
      title: 'Oops!',
      description: message,
      status: 'error',
      duration: 5000,
      isClosable: true,
      position: 'top',
    });
  };

  const updatePointRegister = async () => {
    try {
      const update = await addPointsCustomer(
        auth?.currentUser?.email,
        'ecommerce klamby',
        'inbound',
        750000
      );
      if (update.status) {
        toast({
          title: 'Success',
          description: 'Points added successfully',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top',
        });
      }
    } catch (error) {
      throw new Error(error.message, 'Failed to send error message');
    }
  };

  const handleLogout = async () => {
    try {
      addLogoutPixel(globalState);
      signOut(auth)
        .then(() => {
          toast({
            status: 'success',
            description: 'Logged out successfully',
            duration: 2000,
          });
          navigate('/login');
          localStorage.removeItem('user');
          localStorage.removeItem('addCart');
          localStorage.removeItem('addToCart');
          localStorage.removeItem('tokenFirebase');
          localStorage.removeItem('isLoggedIn');
          globalState?.setIsLoading(false);

          window.location.reload();
        })
        .catch((error) => {
          toast({
            title: 'Log out failed',
            description: error.message,
            status: 'error',
            position: 'top-right',
            isClosable: true,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  const height = window.innerHeight;
  return (
    <Stack
      spacing={5}
      pt={10}
      minH={height / 1.5}
      bgSize='cover'
      alignItems='center'
      justifyContent='center'
    >
      <Card rounded={0}>
        <Stack
          justifyContent='center'
          alignItems='center'
          spacing={5}
          bgColor='white'
          p={10}
          borderRadius='xl'
        >
          <Stack
            w='full'
            px={[1, 1, 5]}
            style={karla}
            spacing={1}
            alignItems='flex-start'
            justifyContent='flex-start'
            rounded={0}
          >
            <Text fontWeight={500}>
              Please complete and verify your account
            </Text>
            <Text fontSize={35} fontWeight='bold' style={ovo}>
              Verify Your Email
            </Text>
          </Stack>

          <Stack spacing={4} p='3' borderRadius='xl' style={ovo}>
            <Stack alignItems='center' justifyContent='center'>
              <InputGroup
                w={{
                  base: '92%',
                  md: '92%',
                }}
                alignItems='center'
              >
                <Text alignItems='flex-start' w='250px'>
                  Your email :
                </Text>
                <Input
                  w={{
                    base: '100%',
                    md: '100%',
                  }}
                  fontSize='md'
                  shadow={3}
                  color='black'
                  rounded={0}
                  value={auth?.currentUser?.email}
                  isDisabled={true}
                />
              </InputGroup>
            </Stack>

            <Stack alignItems='center' justifyContent='center'>
              <InputGroup
                w={{
                  base: '92%',
                  md: '92%',
                }}
                alignItems='center'
              >
                <Text alignItems='flex-start' w='250px'>
                  Your display name :
                </Text>
                <Input
                  w={{
                    base: '100%',
                    md: '100%',
                  }}
                  placeholder='Enter your name'
                  fontSize='md'
                  shadow={3}
                  rounded={0}
                  bgColor='white'
                  color='black'
                  value={data?.name}
                  onChange={(e) =>
                    setData({ ...data, name: e.target.value })
                  }
                />
              </InputGroup>
            </Stack>

            <Stack alignItems='center' justifyContent='center'>
              <InputGroup
                w={{
                  base: '92%',
                  md: '92%',
                }}
                alignItems='center'
              >
                <Text alignItems='flex-start' w='250px'>
                  Birth date :
                </Text>
                <Input
                  w={'full'}
                  focusBorderColor={themeColor}
                  type='date'
                  borderRadius={'sm'}
                  fontSize={'md'}
                  shadow={3}
                  bgColor={'white'}
                  color={'black'}
                  onChange={(e) =>
                    setData({ ...data, dateofbirth: new Date(e.target.value) })
                  }
                />
              </InputGroup>
            </Stack>

            <Stack alignItems='center' justifyContent='center'>
              <InputGroup
                w={{
                  base: '92%',
                  md: '92%',
                }}
                alignItems='center'
              >
                <Text alignItems='flex-start' w='250px'>
                  Gender :
                </Text>
                <RadioGroup
                  style={ovo}
                  onChange={(e) => setData({ ...data, gender: e })}
                  value={data?.gender}
                >
                  <Stack direction={'row'}>
                    <Radio value='male'>Male</Radio>
                    <Radio value='female'>Female</Radio>
                  </Stack>
                </RadioGroup>
              </InputGroup>
            </Stack>

            <Stack alignItems='center' justifyContent='center'>
              <InputGroup
                w={{
                  base: '92%',
                  md: '92%',
                }}
                alignItems='center'
              >
                <Text alignItems='flex-start' w='250px'>
                  Phone number :
                </Text>
                <Input
                  w={'full'}
                  focusBorderColor={themeColor}
                  placeholder='62XXXXXXXX'
                  borderRadius={'sm'}
                  fontSize={'md'}
                  shadow={3}
                  type='number'
                  bgColor={'white'}
                  color={'black'}
                  onChange={(e) =>
                    setData({
                      ...data,
                      phoneNumber: e.target.value,
                    })
                  }
                />
              </InputGroup>
            </Stack>

            <Stack alignItems='center'>
              <InputGroup
                rounded={0}
                w={{
                  base: '92%',
                  md: '92%',
                }}
              >
                <Input
                  w={{
                    base: '100%',
                    md: '100%',
                  }}
                  rounded={0}
                  placeholder='Password'
                  fontSize='md'
                  shadow='md'
                  type={showPassword ? 'text' : 'password'}
                  bgColor='white'
                  color='black'
                  onChange={(e) =>
                    setData({ ...data, password: e.target.value })
                  }
                />
                <InputRightElement>
                  {showPassword ? (
                    <MdVisibilityOff
                      size={18}
                      color='black'
                      onClick={() =>
                        setShowPassword((showPassword) => !showPassword)
                      }
                      style={{ cursor: 'pointer' }}
                    />
                  ) : (
                    <MdVisibility
                      size={18}
                      // color="black"
                      onClick={() =>
                        setShowPassword(
                          (showConfirmPassword) => !showConfirmPassword
                        )
                      }
                      style={{ cursor: 'pointer' }}
                    />
                  )}
                </InputRightElement>
              </InputGroup>
            </Stack>

            <Stack alignItems='center'>
              <InputGroup
                w={{
                  base: '92%',
                  md: '92%',
                }}
              >
                <Input
                  w={{
                    base: '100%',
                    md: '100%',
                  }}
                  rounded={0}
                  placeholder='Confirm password'
                  fontSize='md'
                  shadow='md'
                  type={showConfirmPassword ? 'text' : 'password'}
                  bgColor='white'
                  color='black'
                  onChange={(e) =>
                    setData({ ...data, confirmPassword: e.target.value })
                  }
                />
                <InputRightElement>
                  {showConfirmPassword ? (
                    <MdVisibilityOff
                      size={20}
                      color='black'
                      onClick={() =>
                        setShowConfirmPassword(
                          (showConfirmPassword) => !showConfirmPassword
                        )
                      }
                      style={{ cursor: 'pointer' }}
                    />
                  ) : (
                    <MdVisibility
                      size={20}
                      color='black'
                      onClick={() =>
                        setShowConfirmPassword(
                          (showConfirmPassword) => !showConfirmPassword
                        )
                      }
                      style={{ cursor: 'pointer' }}
                    />
                  )}
                </InputRightElement>
              </InputGroup>
            </Stack>

            <HStack
              spacing={3}
              w='full'
              pt={2}
              alignItems='center'
              justifyContent='center'
            >
              <Button onClick={handleLogout} size='md' w='fit-content'>
                Back to login
              </Button>

              <Button
                onClick={() => verify()}
                isLoading={isVerifying}
                loadingText='Verifying email, please wait...'
                size='md'
                color={'white'}
                bgColor={themeColor}
              >
                Click to Verify Email
              </Button>
            </HStack>

            {emailSent ? (
              <Heading size='sm'>
                Email sent, please check your email at{' '}
                {auth?.currentUser?.email}
              </Heading>
            ) : (
              <></>
            )}
          </Stack>
        </Stack>
      </Card>
    </Stack>
  );
};

export default EmailVerificationData;
