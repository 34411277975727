import logo_bni from '../Assets/Image/Bank/BNI Bank Negara Indonesia.png';
import logo_bca from '../Assets/Image/Bank/BCA.png';
import logo_permata from '../Assets/Image/Bank/Permata.png';
import logo_mandiri from '../Assets/Image/Bank/Mandiri.png';
import logo_bri from '../Assets/Image/Bank/Logo_BRI.png';
import logo_gopay from '../Assets/Image/Bank/Gopay.png';
//import logo_dana from "../Assets/Image/Bank/Logo DANA -  (Koleksilogo.com).png";
import logo_shopay from '../Assets/Image/Bank/ShopeePay Logo -  (Koleksilogo.com).png';
//import logo_gopay_later from "../Assets/Image/Bank/Logo GoPayLater laluahmadcom.png";
import logo_qris from '../Assets/Image/Bank/Quick Response Code Indonesia Standard (QRIS) Logo Vector.png';
import logo_indomaret from '../Assets/Image/Bank/Indomaret.png';
import logo_alfamart from '../Assets/Image/Bank/Alfamart.png';
import logo_card from '../Assets/Image/Bank/credit-debit.png';
import logo_visa from '../Assets/Image/Bank/visa.png';
import logo_mastercard from '../Assets/Image/Bank/mastercard.png';
import logo_jcb from '../Assets/Image/Bank/jcb.png';

export const listPayment = [
  {
    name: 'Virtual Account',
    type: 'bank_transfer',
    bank: [
      {
        name: 'bri',
        key: 'bri_va',
        logo: [logo_bri],
        title: 'IDR 4,000',
        value: 4000,
        additional_value: 0,
        formula: {
          key: 'number',
        },
      },
      {
        name: 'bni',
        key: 'bni_va',
        logo: [logo_bni],
        title: 'IDR 4,000',
        value: 4000,
        additional_value: 0,
        formula: {
          key: 'number',
        },
      },
      {
        name: 'bca',
        key: 'bca_va',
        logo: [logo_bca],
        title: 'IDR 4,000',
        value: 4000,
        additional_value: 0,
        formula: {
          key: 'number',
        },
      },
      {
        name: 'permata',
        key: 'permata_va',
        logo: [logo_permata],
        title: 'IDR 4,000',
        value: 4000,
        additional_value: 0,
        formula: {
          key: 'number',
        },
      },
      // {
      //   name: 'mandiri',
      //   key: 'mandiri',
      //   logo: [logo_mandiri],
      // },
    ],
  },
  {
    name: 'E-Wallets',
    type: 'E-Wallets',
    bank: [
      {
        name: 'gopay',
        key: 'gopay',
        logo: [logo_gopay],
        title: '2,9% + IDR 2,000',
        value: 2.9,
        additional_value: 2000,
        formula: {
          key: 'percentage',
        },
      },
      // {
      //   name: "gopay later",
      //   key: "gopay",
      //   logo: logo_gopay_later,
      // },
      {
        name: 'shopee pay',
        key: 'shopeepay',
        logo: [logo_shopay],
        title: '2%',
        value: 2,
        additional_value: 5000,
        formula: {
          key: 'percentage',
        },
      },
      // {
      //   name: "dana",
      //   key: "gopay",
      //   logo: logo_dana,
      // },
      // {
      //   name: "qris",
      //   key: "qris",
      //   logo: logo_qris,
      // },
    ],
  },
  {
    name: 'Over the Counter',
    type: 'Over The Counter',
    bank: [
      {
        name: 'Alfamart',
        key: 'alfamart',
        logo: [logo_alfamart],
        title: '2,9% + IDR 2,000',
        value: 2.9,
        additional_value: 2000,
        formula: {
          key: 'percentage',
        },
      },
      {
        name: 'Indomaret',
        key: 'indomaret',
        logo: [logo_indomaret],
        title: '2,9% + IDR 2,000',
        value: 0,
        additional_value: 2000,
        formula: {
          key: 'percentage',
        },
      },
    ],
  },
  {
    name: 'Credit Card',
    type: 'credit_card',
    bank: [
      {
        name: 'Credit card',
        key: 'credit_card',
        logo: [logo_visa, logo_mastercard, logo_jcb],
        title: '2,9% + IDR 2,000',
        value: 2.9,
        additional_value: 2000,
        formula: {
          key: 'percentage',
        },    
      },
    ],
  },
];
