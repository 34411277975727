import {
  Box,
  HStack,
  Heading,
  Image,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ovo } from '../../../Theme/theme';
// import { lookbook } from '../../../Data/Collections';
import PropTypes from 'prop-types';

const videoKlambyBlog =
  'https://cdn.shopify.com/videos/c/o/v/7a88827ef51643cc9a001db1ff8e141d.mp4';

const BlogComponent = ({ blog, lookbook }) => {
  const karla = {
    fontFamily: 'Karla',
    fontWeight: 400,
  };

  const navigate = useNavigate();
  return (
    <Stack gap={10}>
      <Stack pos={'relative'}>
        <video autoPlay muted loop>
          <source src={videoKlambyBlog} type='video/mp4' />
        </video>
        <Stack
          bgColor={'rgba(1,1,1,0.4)'}
          w={'100%'}
          h='100%'
          mt={[5, 5, 5, 5, 10, 10]}
        >
          <Stack
            bottom={0}
            align={'center'}
            pos={'absolute'}
            w={'100%'}
            h={[100, 100, 200, 200]}
          >
            <Heading
              textTransform='capitalize'
              style={ovo}
              color={'white'}
              fontSize={['4xl', '4xl', '7xl', '7xl']}
            >
              community gathering
            </Heading>
            <Text
              textTransform={'uppercase'}
              style={karla}
              color={'white'}
              letterSpacing={'0.2em'}
            >
              Bandung, 2023
            </Text>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        px={[5, 10, 50, 79]}
        gap={[10, 10, 10, 10, 10, 20]}
        mt={[5, 5, 5, 5, 10, 10]}
      >
        <Box position={'relative'}>
          <Heading
            style={ovo}
            fontSize={'3xl'}
            textAlign={'center'}
            mb={[5, 5, 5, 5, 10, 10]}
          >
            Blog posts
          </Heading>

          {blog?.length > 3 && (
            <Text
              style={karla}
              position={'absolute'}
              right={0}
              top={1}
              onClick={() => navigate('/blogs/all')}
              cursor={'pointer'}
            >
              View All
            </Text>
          )}
        </Box>
        <SimpleGrid
          columns={[
            1,
            1,
            blog.length % 2 === 0 ? 2 : 3,
            blog.length % 2 === 0 ? 2 : 3,
            blog.length % 2 === 0 ? 2 : 3,
          ]}
          gap={5}
        >
          {blog.slice(0, 2).map((x, i) => (
            <Stack
              key={i}
              cursor={'pointer'}
              onClick={() =>
                navigate(
                  `/journal/${encodeURIComponent(x?.title)?.replace(/%20/g, '-')}/${x.id}`
                )
              }
            >
              <Image src={x.thumbnail_image} />
              <HStack wrap={'wrap'}>
                {x.tags.map((y, id) => (
                  <HStack
                    textAlign={'center'}
                    justify={'center'}
                    wrap={'wrap'}
                    key={id}
                  >
                    <Text
                      textTransform={'uppercase'}
                      fontSize={'sm'}
                      style={karla}
                      key={id}
                    >
                      {y}
                    </Text>
                    <Text mt={'-2'}>.</Text>
                  </HStack>
                ))}
              </HStack>
              <Heading style={karla} fontSize={'xl'}>
                {x.title}
              </Heading>
            </Stack>
          ))}
        </SimpleGrid>
        <Heading
          style={ovo}
          fontSize={'3xl'}
          textAlign={'center'}
          mb={[5, 5, 5, 5, 10, 10]}
        >
          Lookbook
        </Heading>
        <SimpleGrid
          columns={[
            1,
            1,
            lookbook.length % 2 === 0 ? 2 : 3,
            lookbook.length % 2 === 0 ? 2 : 3,
            lookbook.length % 2 === 0 ? 2 : 3,
          ]}
          gap={[0, 0, 10, 10]}
        >
          {lookbook.map((x, i) => (
            <Image src={x.thumbnail_image} key={i} w={'full'} cursor={'pointer'} onClick={() => navigate(`/lookbook/${encodeURIComponent(x?.title)?.replace(/%20/g, '-')}/${x?.id}`)} />
          ))}
        </SimpleGrid>
      </Stack>
    </Stack>
  );
};

BlogComponent.propTypes = {
  blog: PropTypes.array,
  lookbook: PropTypes.array,
};

export default BlogComponent;
