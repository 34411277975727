/* eslint-disable no-console */
import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  SimpleGrid,
  Stack,
  StackDivider,
  Text,
  Textarea,
  useDisclosure,
  useNumberInput,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PriceTag } from '../../Components/PriceTag/PriceTag';
import { TbRulerMeasure } from 'react-icons/tb';
import { karla, ovo } from '../../Theme/theme';
import { UseAuthStore } from '../../Hooks/Zustand/store';
import {
  getCollectionFirebase,
  getSingleDocumentFirebase,
  setDocumentFirebase,
} from '../../Apis/firebaseApi';
// import { clientTypessense } from '../../Apis/Typesense';
import ProductSlider from './Component/ProductSlider';
import { addATCPixel } from '../../Utils/pixelUtils';
import HtmlRenderer from './Component/HtmlRendererComponent';
import StockOffline from './Component/StockOfflineComponent';
import Countdown from '../../Components/Timer/Countdown';
import moment from 'moment';

const themeColor = '#8e6d46';
const transparent = { bgColor: 'rgba(142,109,70,0.8)' };

const ProductPage = () => {
  const toast = useToast();
  const globalState = UseAuthStore();
  const params = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: 1,
      defaultValue: 1,
      min: 1,
      max: 10,
    });
  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();
  const [sizeGuide, setSizeGuide] = useState({});
  const [variants, setVariants] = useState([]);
  const [attribute, setAttribute] = useState([]);
  const [cart, setCart] = useState({});
  const [openReview, setOpenReview] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [modal, setModal] = useState({
    label: '',
    mode: 'size chart',
    item: null,
  });
  const [productData, setProductData] = useState(null);

  const fetchDataVariants = async (productId, arrExternalVariant) => {
    try {
      const conditions = [
        {
          field: 'companyId',
          operator: '==',
          value: globalState?.currentCompany,
        },
        {
          field: 'projectId',
          operator: '==',
          value: globalState?.currentProject,
        },
      ];

      if (arrExternalVariant?.length > 0) {
        const promises = arrExternalVariant?.map(async (x) => {
          const conditions = [
            {
              field: 'external_variant_id',
              operator: '==',
              value: x,
            }
          ];
          const get = await getCollectionFirebase('crm_product_variants', conditions);
          return get?.length > 0 ? get[0] : null;
        });
        const results = await Promise.all(promises);
        const resArr = results.filter((result) => result !== null);
        setVariants(resArr);
        return resArr;
      } else {
        conditions.push({
          field: 'productId',
          operator: '==',
          value: productId,
        });
        
        const response = await getCollectionFirebase(
          'crm_product_variants',
          conditions
        );
        setVariants(response);
        return response;
      }
      //kalo diganti product id gausah ambil index
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleAttribute = async (variants) => {
    try {
      const data = await Promise.all(
        variants?.map((x) => {
          if (x?.status) {
            return {
              attributes: x?.attribute?.join('-') || '',
              stock: x?.stock_int || x?.stock || 0,
              price: x?.price_int || parseInt(x?.price) || 0,
              variantId: x?.id || '',
              external_variant_id: x?.external_variant_id || '',
              productId: x?.productId || '',
            };
          }
        })
      );
      setAttribute(data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const fetchDataProduct = async () => {
    try {
      const getDetail = await getSingleDocumentFirebase(
        'crm_product',
        `${params.product}`
      );
      if (getDetail) {
        setProductData(getDetail);
        const getVariants = await fetchDataVariants(getDetail?.id, getDetail?.external_variant_id);
        await handleAttribute(getVariants);
        setSelectedProduct(getDetail);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleSelectedVariant = async (item) => {
    setCart({
      ...item,
      variantId: item?.id || '',
      productId: item?.productId || productData.id,
      external_variant_id: item?.external_variant_id || '',
      stocks: item?.stock || 0,
      price: item?.price || 0,
    });
  };

  const handleDrawerCart = async () => {
    // console.log(cart, 'ini cart')
    if(Object.keys(cart).length === 0){
      toast({
        title: 'Error',
        description: 'Please select the variant first',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return 
      
    }
    globalState.setIsLoading(true)

    try {
      const inputData = {
        ...cart,
        qty: parseInt(input.value),
      };

      let arrCart = [];
      const getLocal = await JSON.parse(localStorage.getItem('addToCart'));

      if (getLocal) {
        arrCart = getLocal;
        const filter = getLocal.find((x) => x.id === inputData.id);
        if (!filter) {
          arrCart.push(inputData);
        } else {
      

          const index = getLocal.findIndex((x) => x?.id === inputData.id);
          arrCart[index].qty = inputData.qty;
        }
      }
      localStorage.setItem('addToCart', JSON.stringify(arrCart));
      addATCPixel(globalState, arrCart);

      if (globalState?.contactId) return handleSetUserCart(getLocal);
      globalState?.setDrawer(true)
      globalState.setIsLoading(false)
    } catch (error) {
      globalState.setIsLoading(false)
      console.log(error.message);
    }
  }

  const handleSetUserCart = async (data) => {
    // console.log('mang sempent masuk kesini')
    globalState.setIsLoading(true)
    try {
      const totalQty = [];
      const totalValue = [];
      const docId = globalState?.contactId
      data?.map((x) => {
        totalQty.push(x.qty || 0)
        totalValue.push((x?.sale_price_int === 0 || x.sale_price_int === undefined ? x?.regular_price_int : x?.sale_price_int)  * x?.qty || 0)
      })

      const sumQty = totalQty.reduce((a, b) => a + b, 0);
      const sumValue = totalValue.reduce((a, b) => a + b, 0);
      const input = {
        cart: data || [],
        createdAt: new Date(),
        lastUpdated: new Date(),
        companyId: globalState?.currentCompany,
        projectId: globalState?.currentProject,
        totalValue: sumValue || 0,
        totalQty: sumQty || 0,
        totalItem: data?.length || 0,
        contactId: docId
      };
      await setDocumentFirebase('crm_cart', docId, input);
      globalState.setDrawer(true)
    } catch (error) {
      console.log(error.message);
    }finally{
      globalState.setIsLoading(false)
    }
  };


  const handleOpenSizeChart = () => {
    setModal({
      label: 'size guide',
      mode: 'size guide',
      item: selectedProduct?.guide,
    });
    onOpen();
  };

  const handleStockOffline = () => {
    setModal({
      label: 'stock offline',
      mode: 'stock offline',
      item: params.product,
    });
    onOpen();
  };


  useEffect(() => {
    fetchDataProduct();
    return () => {
      setOpenReview(false);
    };
  }, [params.product]);

  return (
    <Stack textAlign={'left'} gap={10} mb={10}>
      <SimpleGrid
        columns={[1, 2]}
        justifyContent={'left'}
        alignItems={'left'}
        textAlign={'left'}
        templateColumns={['100%', '60% 40%']}
      >
        <SimpleGrid columns={2} gap={2} marginEnd={[0, 20]} mb={[10, 0]}>
          {selectedProduct?.images?.map((item) => {
            // if (item !== sizeGuide) {
            return <Image key={item} src={item} objectFit={'cover'} w='full' />;
            // }
          })}
        </SimpleGrid>

        <Stack align={'left'} justify={'left'} textAlign={'left'} gap={5}>
          <Stack>
            {productData?.launchingAt && (
              <Countdown
                startDate={moment(new Date()).unix()}
                endDate={moment(productData?.launchingAt?.seconds * 1000)}
              />
            )}
          </Stack>
          <Heading textAlign={'left'} textTransform={'capitalize'} style={ovo}>
            {selectedProduct?.name} 
          </Heading>
          {selectedProduct?.regular_price_int &&
          selectedProduct?.sale_price_int || selectedProduct?.sale_price_int > 0 ? (
              <HStack>
                <Text as={'s'}>
                  <PriceTag
                    price={selectedProduct?.regular_price_int}
                    currency={'IDR'}
                    text={{ ...karla, fontSize: '20px', color: 'red' }}
                  />
                </Text>
                <Badge colorScheme='red'>
                  <PriceTag
                    price={selectedProduct?.sale_price_int}
                    currency={'IDR'}
                    text={{ ...karla, fontSize: '20px' }}
                  />
                </Badge>
              </HStack>
            ) : (
              <PriceTag
                price={selectedProduct?.regular_price_int}
                currency={'IDR'}
                text={{ ...karla, fontSize: '20px' }}
              />
            )}
          <Divider />
          <Box>
            <Flex justify={'space-between'}>
              <Text
                textTransform={'uppercase'}
                style={ovo}
                letterSpacing={'0.2em'}
                fontSize={'sm'}
              >
                {attribute && attribute?.length ? 'variants' : ''}
                {/* {dummyVarianst && dummyVarianst?.length ? 'variants' : ''} */}
              </Text>

              {attribute && (
                <Button
                  leftIcon={<TbRulerMeasure />}
                  style={karla}
                  // variant={'link'}
                  colorScheme='white'
                  color='black'
                  onClick={handleOpenSizeChart}
                >
                  Size Guide
                </Button>
              )}
            </Flex>
            {variants ? (
              variants?.map((x, i) => (
                <Button
                  key={i}
                  variant={'outline'}
                  rounded={0}
                  colorScheme='none'
                  style={karla}
                  w={'fit-content'}
                  textTransform={'uppercase'}
                  mr={2}
                  isDisabled={!x?.stock || x?.stock && x?.stock <= 0 || x?.stock_int <= 0 }
                  borderColor={cart?.variantId === x?.id ? 'black' : '#ededec'}
                  onClick={() => {
                    handleSelectedVariant(x);
                  }}
                >
                  {x?.attribute}
                </Button>
              ))
            ) : (
              <Button
                variant={'outline'}
                rounded={0}
                colorScheme='none'
                style={karla}
                w={'fit-content'}
              >
                All Size
              </Button>
            )}
          </Box>
          <Box>
            <Text style={ovo} letterSpacing={'0.2em'} fontSize={'sm'}>
              QUANTITY
            </Text>
            <HStack
              gap={0}
              m={0}
              p={0}
              border={'1px solid #ededec'}
              w={'fit-content'}
            >
              <Button rounded={0} {...dec} size={'sm'} variant={'ghost'}>
                -
              </Button>
              <Input
                variant={'unstyled'}
                textAlign={'center'}
                w={50}
                rounded={0}
                {...input}
                size={'sm'}
              />
              <Button rounded={0} {...inc} size={'sm'} variant={'ghost'}>
                +
              </Button>
            </HStack>
          </Box>
          {moment(new Date()).valueOf() < moment(productData?.launchingAt?.seconds * 1000) ? (
            <Stack>
              <Button
                rounded={0}
                color={'white'}
                colorScheme='none'
                style={ovo}
                bgColor={themeColor}
              >
                Coming soon ..
              </Button>
            </Stack>
          ) : (
            <Stack>
              <Button
                rounded={0}
                color={'white'}
                colorScheme='none'
                style={ovo}
                isLoading={globalState.isLoading}
                bgColor={themeColor}
                onClick={() => handleDrawerCart()}
              >
                Add to cart
              </Button>

              <Button
                rounded={0}
                color={'white'}
                colorScheme='none'
                style={ovo}
                bgColor={themeColor}
                onClick={() => handleStockOffline()}
              >
                Check stocks
              </Button>
            </Stack>
          )}

          <Text style={{ ...karla, fontWeight: 300 }} fontSize={'lg'}>
            <div className='description'>
              <HtmlRenderer html={selectedProduct?.description || ''} />
            </div>
          </Text>
        </Stack>
      </SimpleGrid>

      <Stack h='500px' align={'left'} justify={'left'}>
        <Heading style={ovo} fontSize={'2xl'} textAlign={'center'}>
          You may also like
        </Heading>
        <ProductSlider
          categories={
            selectedProduct?.categoryId?.length > 0
              ? selectedProduct?.categoryId
              : ['se0xGGWqSdUwk6pLaapk']
          }
        />
      </Stack>

      <Stack justify={'center'} align={'center'} gap={10}>
        <Heading style={ovo} fontSize={'2xl'} textAlign={'center'}>
          Review Customer
        </Heading>
        <HStack gap={5} justify={'center'} divider={<StackDivider />}>
          <Box>
            <Box></Box>
            <Text style={karla}>Belum ada ulasan</Text>
          </Box>

          <Button
            rounded={0}
            color={'white'}
            _hover={transparent}
            colorScheme='none'
            style={{ ...karla, fontWeight: 'bold' }}
            bgColor={themeColor}
            onClick={() => setOpenReview(!openReview)}
            w={'3xs'}
          >
            {openReview ? 'Cancel review' : 'Write review'}
          </Button>
        </HStack>
        {openReview && (
          <>
            <Divider />
            <Stack
              textAlign={'center'}
              gap={10}
              align={'center'}
              justify={'center'}
            >
              <Heading
                style={{ ...karla, fontWeight: 'bold' }}
                textAlign={'center'}
                fontSize={'2xl'}
              >
                Write review
              </Heading>
              <Text style={ovo} letterSpacing={'0.2em'} fontSize={'xs'}>
                RATING
              </Text>
              <FormControl>
                <FormLabel
                  style={ovo}
                  letterSpacing={'0.2em'}
                  fontSize={'sm'}
                  textAlign={'center'}
                >
                  TITLE (100)
                </FormLabel>
                <Input
                  style={karla}
                  placeholder='Input your title of the review'
                  rounded={0}
                />
              </FormControl>
              <FormControl>
                <FormLabel
                  style={ovo}
                  letterSpacing={'0.2em'}
                  fontSize={'sm'}
                  textAlign={'center'}
                >
                  DESCRIPTION
                </FormLabel>
                <Textarea
                  style={karla}
                  placeholder='Write your review here'
                  rounded={0}
                  h={'150px'}
                />
              </FormControl>
              <FormControl>
                <FormLabel
                  style={ovo}
                  letterSpacing={'0.2em'}
                  fontSize={'sm'}
                  textAlign={'center'}
                >
                  PICTURE / VIDEO (OPTIONAL)
                </FormLabel>
                <Input style={karla} placeholder='YouTube URL' rounded={0} />
              </FormControl>
              <FormControl>
                <FormLabel
                  style={ovo}
                  letterSpacing={'0.2em'}
                  fontSize={'sm'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  textAlign={'center'}
                  textTransform={'uppercase'}
                  display={['block', 'block', 'block', 'flex']}
                >
                  name (your name will be shown in public like
                  <Flex align={'center'} justify={'center'}>
                    <Select
                      style={karla}
                      ms={1}
                      color={themeColor}
                      variant={'unstyled'}
                      w={'fit-content'}
                    >
                      <option>Jhon Smith</option>
                      <option>Jhon S.</option>
                      <option>J.S.</option>
                      <option>Anonim</option>
                    </Select>
                    )
                  </Flex>
                </FormLabel>
                <Input
                  style={karla}
                  placeholder='Input your name (public)'
                  rounded={0}
                />
              </FormControl>
              <FormControl>
                <FormLabel
                  style={ovo}
                  letterSpacing={'0.2em'}
                  fontSize={'sm'}
                  textAlign={'center'}
                >
                  E-MAIL
                </FormLabel>
                <Input
                  style={karla}
                  placeholder='Input your email (private)'
                  rounded={0}
                />
              </FormControl>
              <HStack>
                <Button
                  color={themeColor}
                  style={{ ...karla, fontWeight: 'bold' }}
                  size={'md'}
                  variant={'unstyled'}
                  border={`1px solid ${themeColor}`}
                  _hover={{ color: 'rgba(142, 109, 70, 0.7)' }}
                  rounded={0}
                  px={2}
                >
                  Cancel Review
                </Button>
                <Button
                  bgColor={themeColor}
                  style={{ ...karla, fontWeight: 'bold' }}
                  color={'white'}
                  rounded={0}
                  size={'md'}
                  colorScheme='none'
                  _hover={transparent}
                >
                  Submit Review
                </Button>
              </HStack>
            </Stack>
          </>
        )}
      </Stack>

      <Modal isOpen={isOpen} onClose={onClose} size={'lg'} isCentered>
        <ModalOverlay bg={'#ffffff9c'} />
        <ModalContent p={5}>
          <ModalCloseButton />
          <ModalHeader>
            <Text style={ovo} textTransform={'uppercase'}>
              {modal.label}
            </Text>
            <Divider />
          </ModalHeader>
          <ModalBody>
            {modal.mode === 'size guide' && (
              <HtmlRenderer html={modal?.item || null} />
            )}
            {modal.mode === 'stock offline' && (
              // <StockOffline productId={params.product} />
              <StockOffline productId={params.product} />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default ProductPage;
