import {
  Badge,
  Box,
  Container,
  Divider,
  Flex,
  HStack,
  IconButton,
  Stack,
  TableContainer,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { formatFrice } from '../../../Utils/numberUtil';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import PropTypes from 'prop-types';
import {
  convertMilisecond,
  convertTimestampToDate,
  timeFirebase,
} from '../../../Utils/timeUtil';
import { HiShoppingCart } from 'react-icons/hi2';
import { FaMoneyBillWave } from 'react-icons/fa';

const RewardActivity = ({ section, setSection, logPoint, config }) => {
  const sortData = () => {
    return logPoint.sort(
      (a, b) => timeFirebase(a?.createdAt) - timeFirebase(b?.createdAt)
    );
  };

  const sortedLogPoint = sortData();

  return (
    <Box height='100%' maxHeight='100%' overflow='hidden'>
      <HStack pt={4} pl={2}>
        <IconButton
          cursor={'pointer'}
          onClick={() => setSection('')}
          size={'sm'}
          variant={'none'}
          color={'gray.400'}
          icon={<MdKeyboardArrowLeft size={25} />}
        />
        <Text textTransform={'capitalize'} fontWeight={500}>
          {section}
        </Text>
      </HStack>
      {section === 'activity' ? (
        <Box px={4} py={2} overflowY={'auto'} maxH={'100%'}>
          <Box p={3} bg={'gray.200'} borderRadius={'md'}>
            <Text fontSize={'sm'}>
              Your points balance may not reflect your latest activity
            </Text>
          </Box>
          <Stack spacing={0}>
            <Container overflowY={'auto'} overflowWrap={'anywhere'}>
              {sortedLogPoint?.map((x, i) => (
                <Stack key={i} borderRadius={'md'}>
                  <Flex justify={'space-between'} fontSize={'xs'} p={3}>
                    <Stack spacing={1}>
                      <Text>{x?.orderId}</Text>
                      <Text>{formatFrice(x?.point / config.earn)} Points</Text>
                    </Stack>
                    <Stack spacing={1}>
                      <Text>{convertMilisecond(x?.createdAt?.seconds)}</Text>
                      <Badge
                        w={'fit-content'}
                        colorScheme={x?.type === 'redeem' ? 'blue' : 'green'}
                      >
                        {x?.type ? x?.type : 'earn'}
                      </Badge>
                    </Stack>
                  </Flex>
                  <Divider />
                </Stack>
              ))}
            </Container>
          </Stack>
        </Box>
      ) : section === 'earn' ? (
        <Box>
          <Box
            m={4}
            p={5}
            fontSize={'sm'}
            border={'1px'}
            borderRadius={'md'}
            borderColor={'gray.100'}
            boxShadow={'sm'}
          >
            <HStack>
              <IconButton
                size={'lg'}
                variant={'none'}
                color={'black'}
                icon={<HiShoppingCart size={25} />}
              />
              <Stack>
                <Text fontSize={'sm'}>Place an order</Text>
                <Text color={'gray.500'} fontSize={'sm'}>
                  1 point for every Rp100 spent
                </Text>
              </Stack>
            </HStack>
          </Box>
        </Box>
      ) : (
        <Box>
          <Box
            m={4}
            p={5}
            fontSize={'sm'}
            border={'1px'}
            borderRadius={'md'}
            borderColor={'gray.100'}
            boxShadow={'sm'}
          >
            <HStack>
              <IconButton
                size={'lg'}
                variant={'none'}
                color={'black'}
                icon={<FaMoneyBillWave size={25} />}
              />
              <Stack>
                <Text fontSize={'sm'}>Use point to pay order</Text>
                <Text color={'gray.500'} fontSize={'sm'}>
                  1 point is equal to Rp100
                </Text>
              </Stack>
            </HStack>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default RewardActivity;

RewardActivity.propTypes = {
  section: PropTypes.string,
  setSection: PropTypes.func,
  logPoint: PropTypes.array,
  config: PropTypes.object,
};
