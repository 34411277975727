const Typesense = require('typesense');
const API = process.env.REACT_APP_TYPESENSE_API_KEY;

export const clientTypessense = new Typesense.Client({
  nodes: [
    {
      host: process.env.REACT_APP_TYPESENSE_HOST, // For Typesense Cloud use xxx.a1.typesense.net
      port: '443', // For Typesense Cloud use 443
      protocol: 'https', // For Typesense Cloud use https
    },
  ],
  apiKey: API,
  connectionTimeoutSeconds: 2,
});