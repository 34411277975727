import { useEffect, useState } from 'react';
import { UseAuthStore } from '../../Hooks/Zustand/store';
import { useLocation, useParams } from 'react-router-dom';
import { Box, Center, Flex, Heading, Image, Stack, useToast } from '@chakra-ui/react';
import { getCollectionFirebase, getSingleDocumentFirebase } from '../../Apis/firebaseApi';
import { addFacebookPixel, addGoogleTagManager, analytics } from '../../Utils/pixelUtils';
import MicrositeDisplayView from './Components/MicrositeDisplayPage';

function MicrositePage() {
  const [data, setData] = useState(null);
  const globalState = UseAuthStore();
  const param = useParams();
  const location = useLocation()
  const toast = useToast();

  const dataSlug = location?.pathname?.split('/m/')[1]

  const getData = async () => {
    globalState?.setIsLoading(true);
    const condition = [
      {field: 'projectId', operator: '==', value: 'bKEB41GUP7kHzHmpMXjn' },
      {field: 'slug', operator: '==', value: dataSlug },
    ]
    try {
      const res = await getCollectionFirebase(
        'crm_web_microsite',
        condition
      );

      console.log(res[0])

      if (res[0]?.pixels?.facebook) {
        addFacebookPixel(res?.pixels?.facebook);
      }
      if (res[0]?.pixels?.['tag-manager']) {
        addGoogleTagManager(res[0]?.pixels['tag-manager']);
      }

      if (res[0]?.projectId) {
        // console.log('run analytics')
        setData(res[0])
        analytics.page({
          companyId: globalState?.webConfig?.companyId,
          projectId: globalState?.webConfig?.projectId,
          micrositeId: res[0]?.id || '',
        });
      }
      globalState?.setIsLoading(false);
    } catch (error) {
      console.log(error.message);
      globalState?.setIsLoading(false);
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 3000,
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    getData();

  }, [dataSlug]);

  return (
    <>
      {data?.projectId ? (
        <Flex
          h="100vh"
          w="100vw"
          borderColor="white"
          boxShadow="xl"
          overflow="auto"
          position="relative"
          sx={{
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
          }}
        >
          {/* <Heading>Anjirn</Heading> */}
          <MicrositeDisplayView data={data}/>
        </Flex>
      ) : (
        <Box position={'relative'} height={'full'} bg={'red'} zIndex={50}>
          <Stack h={'full'}>
            <Image
              position={'absolute'}
              zIndex={'10'}
              boxSize="full"
              objectFit="cover"
              src={
                'https://images.unsplash.com/photo-1545972154-9bb223aac798?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3050&q=80&exp=8&con=-15&sat=-75'
              }
              alt="gambar"
            />{' '}
            <Center zIndex={20} py={50}>
              <Heading>Mikrositus tidak ditemukan</Heading>
            </Center>
          </Stack>{' '}
        </Box>
      )}
    </>
  );
}

export default MicrositePage;
