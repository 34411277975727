/* eslint-disable no-console */
import {
  Box,
  Flex,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  getCollectionFirebase,
  getSingleDocumentFirebase,
} from '../../../Apis/firebaseApi';
import CloseSubMenuOverlay from './NestedMenu/CloseSubMenuOverlay';
import MenuItemSubMenu from './NestedMenu/MenuItemSubMenu';
import InnerMenu from './NestedMenu/InnerMenu';
import PropTypes from 'prop-types';
import { auth } from '../../../Config/firebase';
import { Timestamp } from 'firebase/firestore';

const MenuNavbar = ({globalState }) => {
  const navigate = useNavigate();

  const [configNavbar, setConfigNavbar] = useState([]);
  const [openMenu, setOpenMenu] = useState(false);
  const [innerMenusActive, setInnerMenusActive] = useState(true);
  const [collectionMenu, setCollectionMenu] = useState([]);

  const getConfigFirebase = async () => {
    try {
      const getData = await getSingleDocumentFirebase(
        'crm_web_config',
        globalState?.currentProject
      );

      if (getData) {
        const navbar = getData?.navigation;
        // setNested(getData[0]?.menu[7]);
        const menuVip = {
          name: 'VIP Member',
          type: 'external',
          subCategories: [],
          target: 'vip-page',
        };

        if (globalState?.isLoggedIn && globalState?.isVIP) {
          setConfigNavbar([...navbar, menuVip]);
        } else {
          setConfigNavbar(navbar);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const getCollectionProduct = async () => {
    const collectionTags = [
      'scarf',
      'tops',
      'bottoms',
      'others',
      'signature scarf',
      'plain scarf',
      'instant hijab',
    ];
    let arrayNav = [];

    try {
      const promises = collectionTags.map(async (nav) => {
        const conditions = [
          { field: 'tags', operator: 'array-contains', value: nav },
        ];

        const res = await getCollectionFirebase(
          'crm_product_categories',
          conditions
        );
        return res;
      });

      const results = await Promise.all(promises);
      results.forEach((res) => {
        arrayNav = [...arrayNav, ...res];
      });

      setCollectionMenu(arrayNav);
    } catch (error) {
      throw new Error(error);
    }
  };

  const handleMenuNavbar = (item) => {
    try {
      navigate(`${item.target.replace(' ', '-')}`);
    } catch (error) {
      console.log(error.message);
    }
  };

  const getMembership = async() => {
    // let dataMember = { email: '', isVIPPassword: false, phone: '', tags:[]}

    try {
      const todayDate = new Date();
      const conditions = [
        { field: 'projectId', operator: '==',value: globalState?.currentProject},
        { field: 'isPublish', operator: '==',value: true},
        { field: 'startedAt', operator: '<=',value: Timestamp.fromDate(todayDate)}
      ]
      const res = await getCollectionFirebase('crm_web_membership', conditions);
      const filteredRes = res.filter((item) => item.finishedAt >= Timestamp.fromDate(todayDate));

      if(filteredRes?.length>0){
        // const segmentRes = await getSingleDocumentFirebase('crm_contacts_segmentations', res[0]?.segments[0])
        // segmentVip = segmentRes

        const conditionsUser = [
          {field: 'projectId',operator: '==',value: globalState?.currentProject,},
          {field: 'membershipTags',operator: 'array-contains',value: res[0]?.slug,},
          {field: 'email',operator: '==',value: auth?.currentUser?.email,},
        ]
        const userRes = await getCollectionFirebase('crm_contacts', conditionsUser)
        if (userRes?.length > 0) {
          globalState?.setIsVIP(true)
        }
      }

      // const query = buildQuery(segmentVip)

      // const [resJitsu, resCount] = await Promise.all([
      //   apiJitsuBackendSegment(globalState, globalState?.currentProject, { ...query, select: 'distinct(user_id)', limit: 25 }),
      //   apiJitsuBackendSegment(globalState, globalState?.currentProject, { ...query, select: 'count(distinct(user_id))' }),
      // ]);
      // const resFilter = resJitsu?.filter((x) => x?.user_id !== '' && x?.user_id !== null && x?.user_id !== undefined)
      // const uidContact = resFilter?.map((x) => x?.user_id);

      // const firebasePromises = uidContact?.map((uid) => getSingleDocumentFirebase('crm_contacts', uid));
      // const firebaseResults = await Promise.all(firebasePromises);

      // const mergedData = resFilter.map((item, index) => ({
      //   ...item,
      //   ...firebaseResults[index]
      // }));

      // const findVipByUid = mergedData.find((x) => x?.uid === globalState?.uid)
      // const findVipById = mergedData.find((x) => x?.id === globalState?.uid)

      // if(findVipByUid || findVipById){
      //   globalState?.setIsVIP(true)
      // }

    } catch (error) {
      console.log(error)
    }
  }

  // const handleMouseEnter = (index) => {
  //   setOpenMenu(index);
  // };

  // const handleMouseLeave = () => {
  //   setOpenMenu(null);
  // };

  const closeSubMenus = () => {
    setInnerMenusActive(false);
  };

  useEffect(() => {
    if(globalState?.isLoggedIn){
      getMembership()
    }
  }, [globalState?.isLoggedIn])
  

  useEffect(() => {
    getConfigFirebase();
    getCollectionProduct();
    // getMembership()
  }, [globalState.isVIP]);

  return (
    <>
      <HStack
        justifyContent={'center'}
        alignItems='center'
        w={'100%'}
        fontSize='sm'
        wrap={'wrap'}
        textTransform={'uppercase'}
        color={'#8e6d46'}
      >
        {configNavbar?.map((item, index) => (
          <Stack
            key={index}
            px={2}
            // onClick={globalState?.isTrial ? null : () => navigate('/')}
            // onClick={() => navigate('/')}
            cursor={'pointer'}
            color={'#8e6d46'}
          >
            {(item?.isExpanding || item?.subCategories?.length) > 0 ? (
              <>
                {item.name !== 'collections' && !item.isFullWidth ? (
                  <Menu
                    isOpen={openMenu === index}
                    onClose={() => setOpenMenu(null)}
                    onOpen={(index) => setOpenMenu(index)}
                  >
                    <Box>
                      <Menu autoSelect={false} closeOnSelect={false}>
                        <CloseSubMenuOverlay
                          isActive={innerMenusActive}
                          closeSubMenu={closeSubMenus}
                        />
                        <MenuButton textTransform={'uppercase'} color={'#8e6d46'}>
                          {item.name}
                        </MenuButton>
                        <MenuList
                          transition='all 0.1s'
                          zIndex={999}
                          color={'#8e6d46'}
                        >
                          {item.subnavigation?.map((x, i) => {
                            if (x?.parent && x?.parent != '') {
                              const parent = x?.parent?.map((y) => {
                                return {
                                  categoriesId: y.categoriesId,
                                  name: y.label,
                                  link: item.link,
                                  navbar: item.name,
                                };
                              });
                              return (
                                <MenuItemSubMenu key={i}>
                                  <InnerMenu
                                    title={x.name}
                                    childrenItems={parent}
                                    handleItemClick={(x) => handleMenuNavbar(x)}
                                  />
                                </MenuItemSubMenu>
                              );
                            } else {
                              return (
                                <MenuItem
                                  textTransform={'uppercase'}
                                  key={i}
                                  closeSubMenu={closeSubMenus}
                                  onClick={() =>
                                    handleMenuNavbar({
                                      ...x,
                                      navbar: item.name,
                                    })
                                  }
                                >
                                  {x.name}
                                </MenuItem>
                              );
                            }
                          })}
                        </MenuList>
                      </Menu>
                    </Box>
                  </Menu>
                ) : item.name === 'collections' ? (
                  <Popover isLazy trigger='hover'>
                    <PopoverTrigger>
                      <Text textTransform={'uppercase'} bg={'transparent'} color={'#8e6d46'}>
                        {item?.name}
                      </Text>
                    </PopoverTrigger>
                    <PopoverContent w={'100vw'} p={10}>
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverBody>
                        <Flex
                          flexDir={'row'}
                          justify={'center'}
                          gap={20}
                          color={'#000'}
                        >
                          <SimpleGrid columns={4} spacing={16}>
                            <Box>
                              <Text fontWeight={'semibold'}>Scarf</Text>
                              <Stack spacing={4} fontSize={12} mt={5}>
                                {collectionMenu
                                  .filter((x) => x.tags[0] === 'scarf')
                                  .map((y, i) => {
                                    return (
                                      <Text
                                        transition={'margin 0.2s ease-in-out'}
                                        _hover={{
                                          ml: 1,
                                          transition: '0.2s ease-in-out',
                                        }}
                                        key={i}
                                        onClick={() =>
                                          navigate(
                                            `/collections/${y?.name?.toLowerCase().replace(/\s+/g, '-')}`
                                          )
                                        }
                                      >
                                        {y.name}
                                      </Text>
                                    );
                                  })}
                              </Stack>
                            </Box>
                            <Box>
                              <Text
                                fontWeight={'semibold'}
                                onClick={() =>
                                  navigate('/collections/tops')
                                }
                                _hover={{
                                  transform: '1.02',
                                  transition: '0.75s ease-in-out',
                                }}
                              >
                                Tops
                              </Text>
                              <Stack spacing={4} fontSize={12} mt={5}>
                                {collectionMenu
                                  .filter((x) => x.tags[0] === 'tops')
                                  .map((y, i) => {
                                    return (
                                      <Text
                                        transition={'margin 0.2s ease-in-out'}
                                        _hover={{
                                          ml: 1,
                                          transition: '0.2s ease-in-out',
                                        }}
                                        key={i}
                                        onClick={() =>
                                          navigate(
                                            `/collections/${y?.name?.toLowerCase().replace(/\s+/g, '-')}`
                                          )
                                        }
                                      >
                                        {y.name}
                                      </Text>
                                    );
                                  })}
                              </Stack>
                            </Box>
                            <Box>
                              <Text
                                fontWeight={'semibold'}
                                onClick={() => navigate('collections/bottoms')}
                              >
                                Bottoms
                              </Text>
                              <Stack spacing={4} fontSize={12} mt={5}>
                                {collectionMenu
                                  .filter((x) => x.tags[0] === 'bottoms')
                                  .map((y, i) => {
                                    return (
                                      <Text
                                        transition={'margin 0.2s ease-in-out'}
                                        _hover={{
                                          ml: 1,
                                          transition: '0.2s ease-in-out',
                                        }}
                                        key={i}
                                        onClick={() =>
                                          navigate(
                                            `/collections/${y?.name?.toLowerCase().replace(/\s+/g, '-')}`
                                          )
                                        }
                                      >
                                        {y.name}
                                      </Text>
                                    );
                                  })}
                              </Stack>
                            </Box>
                            <Box>
                              <Text
                                fontWeight={'semibold'}
                                onClick={() => navigate('collections/all')}
                              >
                                Others
                              </Text>
                              <Stack spacing={4} fontSize={12} mt={5}>
                                {collectionMenu
                                  .filter((x) => x.tags[0] === 'others')
                                  .map((y, i) => {
                                    return (
                                      <Text
                                        transition={'margin 0.2s ease-in-out'}
                                        _hover={{
                                          ml: 1,
                                          transition: '0.2s ease-in-out',
                                        }}
                                        key={i}
                                        onClick={() =>
                                          navigate(
                                            `/collections/${y?.name?.toLowerCase().replace(/\s+/g, '-')}`
                                          )
                                        }
                                      >
                                        {y.name}
                                      </Text>
                                    );
                                  })}
                              </Stack>
                            </Box>
                          </SimpleGrid>
                          <HStack spacing={10}>
                            <Box
                              onClick={() =>
                                navigate('collections/tops')
                              }
                            >
                              <VStack>
                                <Image
                                  w={200}
                                  src='https://klamby.id/cdn/shop/files/tops.png?v=1641875715&width=540'
                                />
                                <Text fontWeight={'semibold'}>Tops</Text>
                              </VStack>
                            </Box>
                            <Box onClick={() => navigate('collections/pants')}>
                              <VStack>
                                <Image
                                  w={200}
                                  src='https://klamby.id/cdn/shop/files/pants_20c1ccd8-0f94-4df9-8fbb-29a17d33bd42.png?v=1641875837&width=540'
                                />
                                <Text fontWeight={'semibold'}>Pants</Text>
                              </VStack>
                            </Box>
                          </HStack>
                        </Flex>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                // ) : item.name === 'scarves' ? (
                ) : item.isFullWidth ? (
                  <Popover isLazy trigger='hover'>
                    <PopoverTrigger>
                      <Text textTransform={'uppercase'} bg={'transparent'} color={'#8e6d46'}>
                        {item?.name}
                      </Text>
                    </PopoverTrigger>
                    <PopoverContent w={'100vw'} p={10}>
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverBody>
                        <Flex
                          flexDir={'row'}
                          justify={'center'}
                          gap={20}
                          color={'#000'}
                        >
                          <SimpleGrid columns={item?.subnavigation?.length || 3} spacing={16}>
                            {item?.subnavigation?.map((y, i) => (
                              <Box key={i}>
                                <Text
                                  fontWeight={'semibold'}
                                  onClick={() =>
                                    navigate(`collections/${y?.name?.replace(/ /g, '-')}`)
                                  }
                                  _hover={{
                                    transform: '1.02',
                                    transition: '0.75s ease-in-out',
                                  }}
                                >
                                  {y?.name}
                                </Text>
                                <Stack spacing={4} fontSize={12} mt={5}>
                                  {y?.linkedCategories?.length > 0 ? (
                                    <>
                                      {y?.linkedCategories?.map((linked, i) => (
                                        <Text
                                          textTransform={'uppercase'}
                                          key={i}
                                          transition={'margin 0.2s ease-in-out'}
                                          _hover={{
                                            ml: 1,
                                            transition: '0.2s ease-in-out',
                                          }}
                                          onClick={() =>
                                            navigate(
                                              `/collections/${linked?.toLowerCase().replace(/ /g, '-')}`
                                            )
                                          }
                                        >
                                          {linked}
                                        </Text>
                                      ))}
                                    </>
                                  ):(
                                    <></>
                                  )}
                                  {collectionMenu
                                    .filter(
                                      (x) => x.tags[0] === 'signature scarf'
                                    )
                                    .map((y, i) => {
                                      return (
                                        <Text
                                          transition={'margin 0.2s ease-in-out'}
                                          _hover={{
                                            ml: 1,
                                            transition: '0.2s ease-in-out',
                                          }}
                                          key={i}
                                          onClick={() =>
                                            navigate(
                                              `/collections/${y?.name?.toLowerCase().replace(/\s+/g, '-')}`
                                            )
                                          }
                                        >
                                          {y.name}
                                        </Text>
                                      );
                                    })}
                                </Stack>
                              </Box>
                            ))}
                          </SimpleGrid>
                        </Flex>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                ): null}
              </>
            ) : (
              <Text textTransform={'uppercase'} onClick={() => handleMenuNavbar(item)}>
                {item.name}
              </Text>
            )}
          </Stack>
        ))}
      </HStack>
    </>
  );
};

MenuNavbar.propTypes = {
  onToggle: PropTypes.func,
  globalState: PropTypes.object,
};

export default MenuNavbar;
