import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Center,
  Divider,
  Flex,
  HStack,
  Heading,
  Icon,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import logo_monogram from '../../Assets/Image/Klamby/Monogram - white.png';
import { textMuted, themeColor, transparent } from '../../Theme/theme';
import { PriceTag } from '../../Components/PriceTag/PriceTag';
import { getDocWithSnapshotFirebase } from '../../Apis/firebaseApi';
import { useNavigate, useParams } from 'react-router-dom';
import { BsQuestionCircle } from 'react-icons/bs';
import { FiArrowLeft, FiCheckCircle, FiXCircle } from 'react-icons/fi';
import { listPayment } from '../../Data/Payment';
import CopyToClipboardUtil from '../../Utils/CopyToClipboardUtil';
import CountdownTimerV2 from '../../Components/Timer/CountDownTimerV2';
import PaymentMethod from './PaymentMethod';
import PropTypes from 'prop-types';
import { UseAuthStore } from '../../Hooks/Zustand/store';

const PaymentPage = () => {
  const globalState = UseAuthStore();
  const navigate = useNavigate();
  const params = useParams();
  const [currentTransaction, setCurrentTransaction] = useState({});
  const [bankPayment, setBankPayment] = useState({});
  const [isExpired, setIsExpired] = useState(false);
  const [toggelModal, setToggleModal] = useState(false);
  const [address, setAddress] = useState('');
  let fieldAddress = {};
  let mergedFields = '';

  const getCurrentTransaction = async () => {
    let findLogo = ''
    
    try {
      globalState?.setIsLoading(true);
      const transactions = await getDocWithSnapshotFirebase(
        'crm_orders',
        params?.orderId
      );

      if (transactions?.paymentStatus === 'PAID') {
        navigate('/home');
      }

      if (transactions) {
        const typePayment =
          transactions?.payment_information?.payment_type === 'gopay'
            ? 'gopay'
            : 'va';

        setCurrentTransaction(transactions);

        const findType = listPayment.find(
          (x) => x.type === (typePayment === 'gopay' ? 'bank' : typePayment)
        );


        findLogo = findType.bank.find(
          (x) =>
            x.name === transactions?.payment_information?.va_numbers[0]?.bank
        );
        

        // transactions?.payment_information?.va_numbers?.length > 0
        //   ? transactions?.payment_information?.va_numbers[0]?.bank
        //   : 'permata'
      
        const paymentInformation = {
          paymentInformation: transactions?.payment_information,
          via: {
            type: findLogo?.name === 'mandiri' ? 'echannel' : typePayment,
            bank: findLogo?.name,
            va_number: transactions?.payment_information?.permata_va_number
              ? transactions?.payment_information?.permata_va_number
              : transactions?.payment_information?.biller_code
                ? transactions?.payment_information?.biller_code +
                  transactions?.payment_information?.bill_key
                : transactions?.payment_information?.va_numbers[0]?.va_number,
            logo: findLogo?.logo,
            // qr: transactions?.payment_information?.ac,
          },
        };

        

        fieldAddress = transactions?.contact_information?.address;
        const fieldsToMerge = [
          'street',
          'subdistrict_name',
          'city',
          'state',
          'postal_code',
          'country',
        ];
        fieldsToMerge.forEach((key, index) => {
          mergedFields += `${fieldAddress[key]}`;
          if (index < fieldsToMerge.length - 1) {
            mergedFields += ',';
          }
        });
        setAddress(mergedFields);
        setBankPayment(paymentInformation);
        globalState?.setIsLoading(false);
      }

    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error.message);
    }
  };

  const handleCreateNewPayment = () => {
    setToggleModal(!toggelModal);
  };

  useEffect(() => {
    getCurrentTransaction();
  }, []);

  return (
    <>
      <Card
        p={5}
        border={`1px solid ${themeColor}`}
        roundedTop={5}
        bgColor={themeColor}
        rounded={0}
      >
        <Image boxSize='40px' src={logo_monogram} alt='Monogram White' />
      </Card>
      <Card
        rounded={0}
        p={3}
        //   border={"1px solid rgba(0,0,0,.045)"}
        textAlign={'center'}
        bgColor={'rgba(0,0,0,.045)'}
        shadow={0}
      >
        <CardHeader p={2}>
          <HStack display={'flex'} justifyContent={'space-between'}>
            <Heading fontSize={'2xl'} fontWeight={'medium'} color={transparent}>
              Total
            </Heading>
            <CountdownTimerV2
              expiry_date={currentTransaction?.payment_information?.expiry_time}
              isExpired={(item) => setIsExpired(item)}
              paymentStatus={currentTransaction?.paymentStatus}
            />
          </HStack>
          <Heading>
            <PriceTag price={currentTransaction.amount} currency={'IDR'} />
          </Heading>
          <HStack display={'flex'} justifyContent={'space-between'}>
            <Text color={textMuted}>{`Order ID ${params.orderId}`}</Text>
            <Text
              color={textMuted}
            >{`Transaction time ${currentTransaction?.payment_information?.transaction_time}`}</Text>
          </HStack>
        </CardHeader>
      </Card>
      <Card roundedBottom={5} rounded={0} shadow={0} border={themeColor}>
        <CardBody>
          <VStack align={'start'}>
            {currentTransaction?.paymentStatus === 'PENDING' &&
            isExpired === false ? (
                <>
                  {currentTransaction?.payment_information?.payment_type ===
                  'echannel' ||
                currentTransaction?.payment_information?.payment_type ===
                  'bank_transfer' ? (
                      <>
                        <HStack
                          w={'full'}
                          display={'flex'}
                          justifyContent={'space-between'}
                        >
                          <Heading
                            as={'h6'}
                            size={'md'}
                            textTransform={'uppercase'}
                          >
                        Bank {bankPayment?.via?.bank}
                          </Heading>
                          <Box
                            w={'80px'}
                            h={'40px'}
                            display={'flex'}
                            alignItems={'center'}
                          >
                            <Image src={bankPayment?.via?.logo} />
                          </Box>
                        </HStack>
                        <Text>
                      Lakukan pembayaran dari rekening Bank{' '}
                          {(bankPayment?.via?.bank || 'undefined')?.toUpperCase()}{' '}
                          {bankPayment?.bank === 'mandiri'
                            ? 'ke nomor bill e-channel berikut'
                            : 'ke nomor virtual account berikut:'}
                        </Text>
                        <VStack w={'full'} align={'start'}>
                          <Heading as={'h6'} size={'sm'}>
                            {bankPayment?.bank === 'mandiri'
                              ? 'Nomor bill e-channel'
                              : 'Nomor vitual account'}
                          </Heading>
                          <HStack w={'full'} justifyContent={'space-between'}>
                            <Text>{bankPayment?.via?.va_number}</Text>
                            <CopyToClipboardUtil
                              text={bankPayment?.via?.va_number}
                            />
                          </HStack>
                          <Accordion w={'full'} pt={2} allowToggle>
                            <AccordionItem>
                              <AccordionButton
                                display={'flex'}
                                justifyContent={'space-between'}
                                px={0}
                              >
                                <HStack ps={0}>
                                  <BsQuestionCircle />
                                  <Text>Cara bayar</Text>
                                </HStack>
                                <AccordionIcon />
                              </AccordionButton>
                              <AccordionPanel></AccordionPanel>
                            </AccordionItem>
                          </Accordion>
                        </VStack>
                      </>
                    ) : (
                      <>
                        <HStack
                          w={'full'}
                          display={'flex'}
                          justifyContent={'space-between'}
                        >
                          <Heading
                            as={'h6'}
                            size={'md'}
                            textTransform={'uppercase'}
                          >
                        gopay/e-wallet
                          </Heading>
                          <Box
                            w={'80px'}
                            h={'40px'}
                            display={'flex'}
                            alignItems={'center'}
                          >
                            <Image src={bankPayment?.via?.logo} />
                          </Box>
                        </HStack>
                        <Text>Pilih pembayaran melalui:</Text>
                        <VStack w={'full'} align={'start'}>
                          <HStack w={'full'}>
                            {/* <Center
                              w={'50%'}
                              display={'flex'}
                              flexDir={'column'}
                              h={'full'}
                            >
                              <Text
                                fontWeight={'semibold'}
                                textTransform={'uppercase'}
                                textAlign={'center'}
                                minHeight={'10%'}
                              >
                                gopay
                              </Text>
                              <Box
                                m={0}
                                w={'full'}
                                boxSize={'sm'}
                                display={'flex'}
                                justifyContent={'center'}
                                alignItems={'center'}
                              >
                                <Link
                                  isExternal
                                  href={
                                    currentTransaction?.payment_information
                                      ?.actions[1]?.url
                                  }
                                  color={themeColor}
                                  p={3}
                                  size={'lg'}
                                >
                                  Redirect to gopay
                                  <FiArrowRightCircle />
                                </Link>
                              </Box>
                            </Center> */}
                            <Center
                              w={'50%'}
                              display={'flex'}
                              flexDir={'column'}
                              h={'full'}
                            >
                              <Text
                                fontWeight={'semibold'}
                                textTransform={'uppercase'}
                                textAlign={'center'}
                              >
                            qris
                              </Text>
                              <Box
                                boxSize={'sm'}
                                display={'flex'}
                                alignItems={'center'}
                              >
                                <Image
                                  src={
                                    currentTransaction?.payment_information
                                      ?.actions[0]?.url
                                  }
                                />
                              </Box>
                            </Center>
                          </HStack>
                          <Accordion w={'full'} pt={2} allowToggle>
                            <AccordionItem>
                              <AccordionButton
                                display={'flex'}
                                justifyContent={'space-between'}
                                px={0}
                              >
                                <HStack ps={0}>
                                  <BsQuestionCircle />
                                  <Text>Cara bayar</Text>
                                </HStack>
                                <AccordionIcon />
                              </AccordionButton>
                              <AccordionPanel></AccordionPanel>
                            </AccordionItem>
                          </Accordion>
                        </VStack>
                      </>
                    )}
                </>
              ) : currentTransaction?.paymentStatus === 'PAID' ? (
                <VStack alignItems={'center'} w={'full'} p={3}>
                  <Icon as={FiCheckCircle} color={themeColor} boxSize={20} />
                  <Text color={themeColor}>
                  Your payment has been successfully processed. Thank you,
                  please wait for your order!
                  </Text>
                </VStack>
              ) : currentTransaction?.paymentStatus === 'PENDING' &&
              isExpired === true ? (
                  <VStack alignItems={'center'} w={'full'} p={3}>
                    <Icon as={FiXCircle} color={'red'} boxSize={20} />
                    <Text color={themeColor}>This payment has expired!</Text>
                    <Link color={themeColor} onClick={() => setToggleModal(true)}>
                  click here to make new payment
                    </Link>
                  </VStack>
                ) : (
                  <></>
                )}

            <Accordion w={'full'} py={4} defaultIndex={[0]} allowToggle>
              <AccordionItem>
                <AccordionButton
                  display={'flex'}
                  justifyContent={'space-between'}
                  px={0}
                >
                  <HStack ps={0}>
                    <Text fontSize={'lg'}>Detail Product</Text>
                  </HStack>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel>
                  <Box>
                    <Flex justifyContent={'space-between'} p={2}>
                      <Text>Invoice</Text>
                      <Text color={textMuted}>{params.orderId}</Text>
                    </Flex>
                    <Flex justifyContent={'space-between'} p={2}>
                      <Text>Total</Text>
                      <PriceTag
                        price={currentTransaction?.amount}
                        currency={'IDR'}
                      />
                    </Flex>
                  </Box>
                  <Divider
                    colorScheme={'gray'}
                    borderStyle={'solid'}
                    borderWidth={'1px'}
                    borderRadius={10}
                  />
                  <Box p={3}>
                    <Text fontSize={'lg'}>Detail Pelanggan</Text>
                    <Box p={2}>
                      <Flex justifyContent={'space-between'}>
                        <Text>Reciever</Text>
                        <Text color={textMuted} textTransform={'capitalize'}>
                          {currentTransaction?.contact_information?.name}
                        </Text>
                      </Flex>

                      <Flex justifyContent={'space-between'}>
                        <Text>Email</Text>
                        <Text color={textMuted}>
                          {currentTransaction?.contact_information?.email}
                        </Text>
                      </Flex>

                      <Flex justifyContent={'space-between'}>
                        <Text>Phone number</Text>
                        <Text color={textMuted}>
                          {
                            currentTransaction?.contact_information
                              ?.phone_number
                          }
                        </Text>
                      </Flex>

                      <Flex justifyContent={'space-between'}>
                        <Text>Address</Text>
                        <Text
                          color={textMuted}
                          textTransform={'capitalize'}
                          w={'40%'}
                          textAlign={'end'}
                        >
                          {
                            currentTransaction?.contact_information?.address
                              ?.fullAddress
                          }
                        </Text>
                      </Flex>
                    </Box>
                  </Box>
                  <Divider
                    colorScheme={'gray'}
                    borderStyle={'solid'}
                    borderWidth={'1px'}
                    borderRadius={10}
                  />
                  <Box p={3}>
                    <Text fontSize={'lg'}>Detail Order</Text>
                    {currentTransaction?.items?.map((x, i) => (
                      <Box p={2} key={i}>
                        <Flex justifyContent={'space-between'} p={2}>
                          <Box w={'80px'} h={'40px'} p={2}>
                            <Image src={x?.image} alt={x.name} />
                          </Box>
                          <Box textAlign={'end'}>
                            <Text color={textMuted}>{x?.name}</Text>
                            <Text color={textMuted}>
                              quantity: {x?.quantity}
                            </Text>
                            <Text color={textMuted}>Note: {x?.message}</Text>
                          </Box>
                        </Flex>
                      </Box>
                    ))}
                  </Box>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </VStack>
          <Button
            w={'full'}
            bgColor={themeColor}
            size={'lg'}
            color={'white'}
            onClick={() => navigate('/')}
          >
            <FiArrowLeft />
            Back to Merchant
          </Button>
        </CardBody>
      </Card>

      <Modal isOpen={toggelModal} onClose={() => setToggleModal(!toggelModal)}>
        <ModalOverlay />
        <ModalContent minW={'60%'}>
          <ModalBody>
            <PaymentMethod
              cancel={() => setToggleModal(!toggelModal)}
              total={currentTransaction.amount}
              submitPayment={(item) => handleCreateNewPayment(item)}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

PaymentPage.propTypes = {
  total: PropTypes.object,
};

export default PaymentPage;
