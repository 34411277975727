import {
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const FooterCheckoutComponent = () => {
  const navigate = useNavigate();
  const [toggleModal, setToggleModal] = useState(false);
  const [modal, setModal] = useState({ label: '', mode: '', item: null });

  const handelRefundPolicy = () => {
    // setModal({
    //   label: "refund policy",
    //   mode: "refund policy",
    //   item: null,
    // });
    // setToggleModal(true);
  };
  const handlePrivacyPolicy = () => {
    setModal({
      label: 'privacy policy',
      mode: 'privacy policy',
      item: null,
    });
    setToggleModal(true);
  };
  const handleTermsOfService = () => {
    navigate('/terms-of-service');
    // setModal({
    //   label: "terms of service",
    //   mode: "terms of service",
    //   item: null,
    // });
    // setToggleModal(true);
  };
  const handleContactInformation = () => {
    setModal({
      label: 'contact information',
      mode: 'contact information',
      item: null,
    });
    setToggleModal(true);
  };

  return (
    <>
      <HStack
        w='100%'
        justify={'center'}
        align={'center'}
        h='full'
        fontSize={'sm'}
        gap={3}
        wrap={'wrap'}
      >
        <Text
          color={'#8E6D46'}
          textDecor={'underline'}
          onClick={handelRefundPolicy}
        >
          Refund policy{' '}
        </Text>
        <Text
          color={'#8E6D46'}
          textDecor={'underline'}
          onClick={handlePrivacyPolicy}
        >
          Privacy policy
        </Text>
        <Text
          color={'#8E6D46'}
          textDecor={'underline'}
          onClick={handleTermsOfService}
        >
          Terms of service
        </Text>
        <Text
          color={'#8E6D46'}
          textDecor={'underline'}
          onClick={handleContactInformation}
        >
          Contact information{' '}
        </Text>
      </HStack>

      <Modal isOpen={toggleModal} onClose={() => setToggleModal(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ModalCloseButton />
            <Text textTransform={'capitalize'}>{modal.label}</Text>
          </ModalHeader>
          <ModalBody></ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
export default FooterCheckoutComponent;
