import {
  Box,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Center,
  Divider,
  Flex,
  HStack,
  Heading,
  IconButton,
  Image,
  Radio,
  RadioGroup,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { karla, textMuted, themeColor } from '../../Theme/theme';
import logo_monogram from '../../Assets/Image/Klamby/Monogram - white.png';
import { FiArrowLeft, FiChevronRight } from 'react-icons/fi';
import { listPayment } from '../../Data/Payment';
import PropTypes from 'prop-types';


const PaymentMethod = ({ submitPayment }) => {
  const [type, setType] = useState({});
  const [toggle, setToggle] = useState(false);

  const handleSelectType = (index) => {
    const dataType = listPayment[index];
    setType(dataType);
    setToggle(true);
  };

  const handleSelectPayment = (item) => {
    const dataPayment = {
      selectedType: type?.name,
      payment_type: item,
    };
    return submitPayment(dataPayment);
  };

  return (
    <>
      <Box border={'1px solid rgba(0,0,0,.045)'} py={4}>
        <Card
          p={5}
          border={`1px solid ${themeColor}`}
          roundedTop={5}
          bgColor={themeColor}
          rounded={0}
        >
          <Image boxSize='40px' src={logo_monogram} alt='Monogram White' />
        </Card>
        <Card
          rounded={0}
          p={3}
          //   border={"1px solid rgba(0,0,0,.045)"}
          textAlign={'center'}
          bgColor={'rgba(0,0,0,.045)'}
          shadow={0}
        >
          {/* <CardHeader p={2}>
            <HStack display={'flex'} justifyContent={'space-between'}>
              <Heading
                fontSize={'2xl'}
                fontWeight={'medium'}
                color={transparent}
              >
                Total
              </Heading>
            </HStack>
            <Heading>
              <PriceTag price={total} currency={'IDR'} />
            </Heading>
          </CardHeader> */}
        </Card>
        {!toggle ? (
          <Card roundedTop={0} shadow={0} style={karla}>
            <CardHeader>
              <Heading fontSize={'xl'} fontWeight={'medium'} color={textMuted} style={karla}>
                All payment methods
              </Heading>
            </CardHeader>

            <CardBody pt={0}>
              {listPayment?.map((x, i) => (
                <Flex
                  key={i}
                  justifyContent={'space-between'}
                  _hover={{
                    cursor: 'pointer',
                    bgColor: 'RGBA(0, 0, 0, 0.02)',
                    rounded: 5,
                  }}
                  onClick={() => handleSelectType(i)}
                >
                  <Box p={2} id={x.type}>
                    <Heading size='xs' textTransform='uppercase'>
                      {x.name}
                    </Heading>
                    <HStack>
                      {x.bank.map((y, i) => (
                        <VStack pt={3} key={i}>
                          <Box
                            w={'80px'}
                            h={'40px'}
                            display={'flex'}
                            alignItems={'center'}
                            px={3}
                          >
                            {y?.logo?.map((z, i) => (
                              <Image src={z} me={2} key={i} />
                            ))}
                          </Box>
                        </VStack>
                      ))}
                    </HStack>
                    <Divider py={2} />
                  </Box>
                  <Center>
                    <IconButton
                      size='lg'
                      variant='ghost'
                      color='#8e6d46'
                      aria-label='open menu'
                      icon={<FiChevronRight size={20} />}
                      alignItems={'center'}
                      h={'full'}
                    />
                  </Center>
                </Flex>
              ))}
            </CardBody>
          </Card>
        ) : (
          <Card roundedTop={0} shadow={0}>
            <CardHeader py={0}>
              <Flex cursor={'pointer'} onClick={() => setToggle(false)}>
                <IconButton
                  size='lg'
                  variant='ghost'
                  color='#8e6d46'
                  aria-label='open menu'
                  icon={<FiArrowLeft size={20} />}
                  alignItems={'center'}
                  _hover={'none'}
                  // h={"full"}
                />
                <Text textAlign={['left', 'center']} py={3} color={textMuted} style={karla}>
                  Back to payment type
                </Text>
              </Flex>
            </CardHeader>
            <CardBody pt={0}>
              <RadioGroup colorScheme={'orange'}>
                {type.bank.map((y, i) => (
                  <VStack
                    key={i}
                    // divider={<StackDivider />}
                    alignItems={'start'}
                    p={3}
                    _hover={{
                      cursor: 'pointer',
                      bgColor: 'RGBA(0, 0, 0, 0.02)',
                      rounded: 5,
                    }}
                    onClick={() => handleSelectPayment(y)}
                  >
                    {/* <Divider /> */}
                    <Radio key={i} value={y.value}>
                      <HStack>
                        {y?.logo?.map((z, i) => (
                          <Box
                            key={i}
                            w={'110px'}
                            h={'60px'}
                            display={'flex'}
                            alignItems={'center'}
                            px={3}
                          >
                            <Image src={z} me={2} />
                          </Box>
                        ))}
                        <Text style={karla}>
                          {y?.title}
                        </Text>
                      </HStack>
                    </Radio>
                    <Divider />
                  </VStack>
                ))}
              </RadioGroup>
            </CardBody>
            <CardFooter></CardFooter>
          </Card>
        )}
      </Box>
    </>
  );
};

PaymentMethod.propTypes = {
  total: PropTypes.number,
  listPayment: PropTypes.array,
  submitPayment: PropTypes.func,
};

export default PaymentMethod;
