/* eslint-disable no-console */
import {
  Box,
  Center,
  Divider,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { UseAuthStore } from '../../Hooks/Zustand/store';
import { karla, ovo } from '../../Theme/theme';
import { PriceTag } from '../../Components/PriceTag/PriceTag';
import { useNavigate, useParams } from 'react-router-dom';
import { clientTypessense } from '../../Apis/Typesense';
import { getSingleDocumentFirebase } from '../../Apis/firebaseApi';
import { addSearchPixel } from '../../Utils/pixelUtils';
import { checkStockProduct } from '../../Utils/productUtils';

const SearchPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const globalState = UseAuthStore();
  const [collection, setCollection] = useState('');
  const [listProduct, setListProduct] = useState([]);
  const [inputSearch, setInputSearch] = useState([]);
  const [inStore, setInStore] = useState('');
  const [productStocks, setProductStocks] = useState({});

  const handleRoute = () => {
    const route = params?.store;
    if (route) {
      setInStore('store');
      setCollection('product_stores');
    } else {
      setInStore('search');
      setCollection('crm_product');
    }
  };

  const handleTypesenseSearch = async () => {
    try {
      const key = params.key;
      const searchParameters = {
        q: key,
        query_by: 'name',
        // filter_by: filter ? filter : '',
        // sort_by: "_text_match:desc",
        // per_page: 50,
      };

      if (key) {
        try {
          const searchResult = await clientTypessense
            .collections(collection)
            .documents()
            .search(searchParameters);

          const newData = searchResult.hits.map((hit) => ({ ...hit.document }));
          const arrProductId = newData.map((product) => product.id);

          setInputSearch(key);

          const resSearch = await fetchAllProduct(arrProductId);

          addSearchPixel(globalState, key, resSearch);
        } catch (error) {
          console.error(error, 'errorrr');
        }
      } else {
        setInputSearch(key);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const fetchAllProduct = async (productIds) => {
    try {
      const productPromises = productIds.map((id) =>
        getSingleDocumentFirebase('crm_product', id)
      );
      const products = await Promise.all(productPromises);
      setListProduct(products);
      return products;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const handleHeading = () => {
    const heading = params?.store;
    if (heading) {
      return 'Available in Store';
    } else {
      return 'Search';
    }
  };

  const handleDiscount = (sale_price, regular_price) => {
    const percentage = ((regular_price - sale_price) / regular_price) * 100;
    return parseInt(percentage);
  };

  useEffect(() => {
    handleRoute();
  }, [params]);

  useEffect(() => {
    handleTypesenseSearch();
  }, [collection, params?.key, params?.store]);

  useEffect(() => {
    fetchAllProduct();
  }, []);

  useEffect(() => {
    const fetchStocks = async () => {
      const stocks = {};
      for (const item of listProduct) {
        const stock = await checkStockProduct(item.id);
        stocks[item.id] = stock;
      }
      setProductStocks(stocks);
    };

    fetchStocks();
  }, [listProduct]);

  return (
    <Stack gap={10} mb={10}>
      <Heading textAlign={'center'} textTransform={'capitalize'} style={ovo}>
        {handleHeading()}
      </Heading>

      <Divider size={'lg'} />

      <Box style={ovo} display={'flex'} justifyContent={'space-between'}>
        <Text fontSize={22}>
          {listProduct?.length} results for &quot;{inputSearch}&quot;
        </Text>
      </Box>
      {listProduct?.length > 0 ? (
        <SimpleGrid columns={[2, 2, 3, 3, 3, 4]} gap={[2, 2, 2, 10]}>
          {listProduct?.slice(0, 100).map((item, index) => {
            // return console.log(item, "ini itemdi collection")
            return (
              <Stack
                key={index}
                pos={'relative'}
                minW={['100%', '100%', 'unset', 'unset', 'unset', 'unset']}
                onClick={() => {
                  navigate(`/collections/all/product/${item.id}`);
                  globalState.setProduct(item.id);
                }}
                cursor={'pointer'}
              >
                {productStocks[item?.id] > 0 &&
                item?.discountApplied &&
                item?.sale_price_int > 0 ? (
                    <Flex pos={'absolute'} right={0} bgColor={'#b73737'} top={0}>
                      <Text
                        px={2}
                        py={1}
                        fontSize={'sm'}
                        color={'white'}
                        letterSpacing={'0.2em'}
                        style={karla}
                      >
                        {item?.saleType === 'percentage'
                          ? 'SAVE ' + item?.saleValue + '%'
                          : 'SALE Rp.' + item?.saleValue}
                      </Text>
                    </Flex>
                  ) : productStocks[item?.id] > 0 &&
                    item?.sale_price_int === 0 ? (
                      <Flex
                        pos={'absolute'}
                        right={0}
                        bgColor={'#8e6d46'}
                        top={0}
                      ></Flex>
                    ) : productStocks[item?.id] === 0 ? (
                      <Flex pos={'absolute'} right={0} bgColor={'#b73737'} top={0}>
                        <Text
                          px={2}
                          py={1}
                          fontSize={'sm'}
                          color={'white'}
                          letterSpacing={'0.2em'}
                          style={karla}
                        >
                          SOLD OUT
                        </Text>
                      </Flex>
                    ) : (
                      <></>
                    )}
                <Image
                  objectFit={'cover'}
                  minW={['70%', '70%', 'unset', 'unset', 'unset', 'unset']}
                  aspectRatio={2 / 3}
                  src={item.images ? item.images[0] : null}
                />
                <Box>
                  <Text
                    style={karla}
                    fontSize={['md', 'md', 'lg', 'lg', 'lg', 'xl']}
                  >
                    {item?.name}
                  </Text>

                  {item?.sale_price_int && item.sale_price_int > 0 ? (
                    <Flex gap={2}>
                      <Text
                        as={'del'}
                        style={karla}
                        fontSize={['xs', 'xs', 'md', 'md']}
                      >
                        {
                          <PriceTag
                            currency={'IDR'}
                            price={item.regular_price_int}
                          />
                        }
                      </Text>
                      <Text fontSize={['xs', 'xs', 'md', 'md']}>
                        <PriceTag
                          currency={'IDR'}
                          price={item.sale_price_int}
                          color='red'
                        />
                      </Text>
                    </Flex>
                  ) : (
                    <Text style={karla} fontSize={['xs', 'xs', 'md', 'md']}>
                      {
                        <PriceTag
                          currency={'IDR'}
                          price={item.regular_price_int || 0}
                        />
                      }
                    </Text>
                  )}
                </Box>
              </Stack>
            );
          })}
        </SimpleGrid>
      ) : (
        <Center style={ovo}>
          <Text fontSize={22}>
            There is not product with keyword &quot;{params.key}&quot;
          </Text>
        </Center>
      )}
    </Stack>
  );
};

export default SearchPage;
