import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getCollectionFirebase, getSingleDocumentFirebase } from '../../../Apis/firebaseApi'
import { Box, Button, Container, HStack, Heading, Image, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import moment from 'moment'
import { FaLongArrowAltLeft } from 'react-icons/fa'
import { formatFrice } from '../../../Utils/numberUtil'

const VipSinglePage = () => {
  const param = useParams()
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const [products, setProducts] = useState([])

  //fonts
  const ovo = {
    fontFamily: 'Ovo',
    fontWeight: 400,
  };
  const karla = {
    fontFamily: 'Karla',
    fontWeight: 400,
  };

  const fetchMembershipContent = async() => {
    try {
      const res = await getSingleDocumentFirebase('crm_web_membership', param?.id)
      
      if(res) {
        setData(res)

        //list product array
        const productPromises = res?.productId?.map((x) => getSingleDocumentFirebase('crm_product', x))

        // Wait for all promises to resolve
        const arrayProduct = await Promise.all(productPromises)

        setProducts(arrayProduct)

        //list product that has integrated collection id
        if(res?.collectionId || res?.collectionId?.length > 0){
          const productCollection = res?.collectionId?.map((x) => getCollectionFirebase('crm_product', [{field: 'collectionId', operator: 'array-contains', value: x}])) 
          const arrayProductCollection = await Promise.all(productCollection)
          const mergedProduct = [...arrayProduct, ...arrayProductCollection.flat()]

          setProducts(mergedProduct)
        }

        

      }
    } catch (error) {
      throw new Error(error)
    }
  }

  console.log(products)

  useEffect(() => {
    fetchMembershipContent()
  
    return () => {
      setData([])
    }
  }, [])
  

  return (
    <Container maxW={'container.lg'} mb={10}>
      <Stack gap={10}>
        <Stack>
          <HStack textAlign={'center'} justify={'center'}>
            {data?.tags?.map((y, id) => (
              <HStack
                textAlign={'center'}
                justify={'center'}
                wrap={'wrap'}
                key={id}
              >
                <Text
                  textTransform={'uppercase'}
                  fontSize={'sm'}
                  style={karla}
                  key={id}
                >
                  {y}
                </Text>
                <Text mt={'-2'}>.</Text>
              </HStack>
            ))}
            <Text textTransform={'uppercase'} fontSize={'sm'} style={karla}>
              {moment(data?.createdAt).format('llll')}
            </Text>
          </HStack>
          <Heading textAlign={'center'} style={ovo}>
            {data?.title}
          </Heading>
        </Stack>
        <Image src={data?.img} w={'100%'} objectFit={'cover'} />
        <Stack style={karla} textAlign={'justify'} align={'center'}>
          {/* {data && data?.post && parse(data?.post)} */}
          <div dangerouslySetInnerHTML={{ __html: `<p style="text-align: center">${data?.html}</p>` }} />
        </Stack>
        
        {products?.length > 0 ? (
          <SimpleGrid columns={[1, 2, 4]} spacing={5} justifyContent={'center'}>
            {products?.map((x, i) => (
              <Box key={i} onClick={() => navigate(`/collections/all/product/${x.id}`)} cursor={'pointer'}>
                <Image objectFit={'cover'} w='full' src={x?.images[0] || ''} h={300} />
                <Stack fontSize={['md', 'md', 'lg', 'lg', 'lg']} py={2} spacing={1}>
                  <Text style={karla}>{x?.name}</Text>
                  <Text style={karla}>IDR {formatFrice(x?.regular_price_int)}</Text>
                </Stack>
              </Box>
            ))}
          </SimpleGrid>
        ):(
          <></>
        )}
        
        <Button
          justifyContent={'center'}
          display={'flex'}
          alignItems={'center'}
          style={karla}
          leftIcon={<FaLongArrowAltLeft />}
          color={'black'}
          variant={'link'}
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
      </Stack>
    </Container>
  )
}

export default VipSinglePage