// import { Timestamp } from 'firebase-admin/firestore';
import { Timestamp } from 'firebase/firestore';
import moment from 'moment';

export const convertMilisecond = (data) => {
  return moment.unix(data).format('DD MMM YYYY hh:mm a');
};

export const calculateDaysDifference = (firestoreTimestamp) => {
  // Konversi Firestore timestamp ke JavaScript Date object
  const date = new Date(
    firestoreTimestamp.seconds * 1000 + firestoreTimestamp.nanoseconds / 1000000
  );
  // Parse date menggunakan moment.js dengan zona waktu UTC+7 (Asia/Bangkok)
  const timestamp = moment(date)
    .format('YYYY-MM-DDTHH:mm:ssZ')
    .replace('Z', '+07:00');

  // Waktu sekarang dalam UTC
  const now = moment.utc();

  // Hitung selisih hari
  return now.diff(timestamp, 'days');
};

export const convertUniqueNumberDate = (data) => {
  const date = new Date(data);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Bulan dimulai dari 0
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

export const convertTimestampToDate = (timestamp) => {
  return new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
};

export const checkTimeStatus = (startAt, finishedAt) => {
  const now = new Date().getTime();
  const startTime = convertTimestampToDate(startAt).getTime();
  const finishTime = convertTimestampToDate(finishedAt).getTime();

  if (now < startTime) {
    const timeDiff = startTime - now;
    const countdownTime = new Date(timeDiff).toISOString().substr(11, 8);
    return { status: 'before', countdown: countdownTime };
  } else if (now > finishTime) {
    return { status: 'expired', countdown: '' };
  } else {
    return { status: 'inRange', countdown: '' };
  }
};

export const timeFirebase = (timestamp) => {
  // Konversi Firestore timestamp ke Date object
  const date = new Date(timestamp?.seconds * 1000);
  // Format tanggal menggunakan moment.js
  return moment(date).format('DD-MM-YYYY HH:mm');
  
}

export const timeFirebase2 = (timestamp) => {
  // Konversi Firestore timestamp ke Date object
  const date = new Date(timestamp?.seconds * 1000);
  // Format tanggal menggunakan moment.js
  return date;
  
}

export const getPastFirestoreTimestamp = (daysBack) => {
  // Mendapatkan tanggal saat ini
  const currentDate = new Date();
  
  // Mengurangi jumlah hari dari tanggal saat ini
  currentDate.setDate(currentDate.getDate() - daysBack);
  
  // Mengembalikan timestamp Firestore
  return Timestamp.fromDate(currentDate);
};
