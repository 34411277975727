import React from 'react';
import HomePage from '../Pages/Home/HomePage';
import CollectionsPage from '../Pages/Collections/CollectionsPage';
import ProductPage from '../Pages/Product/ProductPage';
import SettingPage from '../Pages/Setting/SettingPage';
import AddressPage from '../Pages/Setting/AddressPage';
import CheckoutPage from '../Pages/Checkout/CheckoutPage';
import CartPage from '../Pages/Cart/CartPage';
import PagesPage from '../Pages/Pages/PagesPage';
import BlogsPage from '../Pages/Blogs/BlogsPage';
import SingleBlogPage from '../Pages/Blogs/SingleBlogPages/SingleBlogPage';
import PaymentPage from '../Pages/Payments/PaymentPage';
import SearchPage from '../Pages/Search/SearchPage';
import SingleLookbookPage from '../Pages/Blogs/SingleLookbookPages/SingleLookbookPage';
import VipPage from '../Pages/VIP/VipPage';
import VipSinglePage from '../Pages/VIP/VipSinglePage/VipSinglePage';
import ThankyouPages from '../Pages/Payments/ThankyouPages';
import MicrositePage from '../Pages/Microsites/MicrositePage';

const HomeRouter = [
  {
    path: '/',
    element: <HomePage />,
  },
  {
    path: '/collections/',
    element: <CollectionsPage />,
  },
  {
    path: '/collections/:type',
    element: <CollectionsPage />,
  },
  {
    path: '/collections/:type/product/:product',
    element: <ProductPage />,
  },
  {
    path: '/account/:user',
    element: <SettingPage />,
  },
  {
    path: '/account/:user/address',
    element: <AddressPage />,
  },
  {
    path: '/checkout',
    element: <CheckoutPage />,
  },
  {
    path: '/cart',
    element: <CartPage />,
  },
  {
    path: '/pages/:type',
    element: <PagesPage />,
  },
  {
    path: '/blogs/all',
    element: <BlogsPage />,
  },
  {
    path: '/journal/:title/:id',
    element: <SingleBlogPage />,
  },
  {
    path: '/lookbook/:title/:id',
    element: <SingleLookbookPage />,
  },
  {
    path: '/payment/:orderId',
    element: <PaymentPage />,
  },
  {
    path: '/payment/:orderId/thankyou',
    element: <ThankyouPages />,
  },
  {
    path: '/search',
    element: <SearchPage />,
  },
  {
    path: '/search/:key',
    element: <SearchPage />,
  },
  {
    path: '/search/:key/:store',
    element: <SearchPage />,
  },
  {
    path: '/vip-page',
    element: <VipPage />,
  },
  {
    path: '/vip-page/:title/:id',
    element: <VipSinglePage />,
  },
];

export default HomeRouter;
