import { FcEditImage, FcSettings } from 'react-icons/fc';

import { IoIosHelpCircleOutline } from 'react-icons/io';

import { CgProfile } from 'react-icons/cg';

export const data = [
  {
    name: 'shop all',
  },
  {
    name: 'october fest up to 70%',
  },
  {
    name: 'sambas series',
  },
  {
    name: 'scarves',
    icon: IoIosHelpCircleOutline,
    submenu: [
      { name: 'Create', icon: FcEditImage, link: '/create-aimarketer' },
      { name: 'Folder', icon: FcSettings, link: '/folder-aimarketer' },
    ],
  },

  {
    name: 'collection',
    icon: CgProfile,
    submenu: [
      { name: 'Create', icon: FcEditImage, link: '/create-aimarketer' },
      { name: 'Folder', icon: FcSettings, link: '/folder-aimarketer' },
    ],
  },

  {
    name: 'journal',
  },

  {
    name: 'store location',
  },
  {
    name: 'account',
  },
];

export const dataFooter = [
  {
    name: 'about',
    submenu: [
      { name: 'contact us', link: '/' },
      { name: 'careers', link: '/' },
      { name: 'terms & conditions', link: '/' },
      { name: 'offline store', link: '/' },
    ],
  },
  {
    name: 'customer support',
    submenu: [
      { name: 'klamby family', link: '/' },
      { name: 'about points', link: '/' },
      { name: 'FAQ', link: '/' },
    ],
  },
  {
    name: 'social',
    submenu: [
      { name: 'tokopedia', link: '/' },
      { name: 'instagram', link: '/' },
      { name: 'facebok', link: '/' },
      { name: 'Youtube', link: '/' },
      { name: 'tiktok', link: '/' },
    ],
  },
];

export const dataHomePage = [
  {
    image1:
      'https://klamby.id/cdn/shop/files/DSC00376_1_1_360x.jpg?v=1693616117',
    image2: 'https://klamby.id/cdn/shop/files/DSC09387_1_360x.png?v=1693616166',

    bgImage:
      'https://klamby.id/cdn/shop/files/journal02_1944x.jpg?v=1693616359',
    bgJournalImage:
      'https://klamby.id/cdn/shop/files/Blog_banner_1944x.png?v=1647229453',
    imageSambasCollection:
      'https://klamby.id/cdn/shop/files/Sambas_sneak_peek_1728x.jpg?v=1693617201',
  },
];
