/* eslint-disable react/no-children-prop */
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Center,
  FormControl,
  FormLabel,
  Input,
  Spinner,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../Config/firebase';
import { UseAuthStore } from '../../Hooks/Zustand/store';
import { karla, ovo, themeColor } from '../../Theme/theme';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const globalState = UseAuthStore();

  if (globalState?.uid) {
    navigate('/');
  }

  const toast = useToast();

  const handleUpdatePassword = () => {
    setLoading(true);
    if (email === null || email === '') {
      toast({
        title: 'Error',
        description: 'Input email',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } else {
      sendPasswordResetEmail(auth, email)
        .then(() => {
          toast({
            title: 'Success',
            description:
              'Reset password request has been sent, kindly check your email!',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
          setLoading(false);
          navigate('/login');
        })
        .catch((error) => {
          toast({
            title: error.code,
            description: error.message,
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
          setLoading(false);
        });
    }
  };

  // const handleLogout = async () => {
  //   const pathLink = 'login';

  //   await logoutUserWithIp(
  //     window?.location?.hostname,
  //     auth?.currentUser?.email,
  //     pathLink
  //   );

  //   signOut(auth)
  //     .then(() => {
  //       toast({
  //         status: 'success',
  //         description: 'Logged out successfully',
  //         duration: 2000,
  //       });
  //       navigate('/login');
  //       localStorage.clear();
  //       window.location.reload();
  //     })
  //     .catch((error) => {
  //       toast({
  //         title: 'Deoapp.com',
  //         description: error.message,
  //         status: 'error',
  //         position: 'top-right',
  //         isClosable: true,
  //       });
  //     });
  // };

  const height = window.innerHeight;

  return (
    <>
      <Stack
        minH={height / 1.2}
        bgColor='white'
        bgSize='cover'
        alignItems={'center'}
        justifyContent='center'
      >
        <Card w={'50%'} p={5} boxShadow={'base'} rounded={5}>
          <CardHeader>
            <Center>
              <Text fontSize={'3xl'} style={ovo}>
                Reset Password
              </Text>
            </Center>
          </CardHeader>
          <CardBody>
            <Stack alignItems='center' style={karla}>
              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input
                  w={{
                    base: '100%',
                    md: '100%',
                  }}
                  placeholder='example@email.com'
                  fontSize={'md'}
                  shadow={3}
                  rounded={0}
                  bgColor={'white'}
                  color={'black'}
                  onChange={
                    (e) => setEmail(e.target.value)
                    //   setDataLogin({ ...dataLogin, email: e.target.value })
                  }
                />
              </FormControl>
            </Stack>

            <Stack pt={5} style={karla}>
              {loading ? (
                <Spinner size={'sm'} />
              ) : (
                <Button
                  alignItems={'center'}
                  justifyContent='center'
                  size={'md'}
                  bgColor={themeColor}
                  onClick={() => handleUpdatePassword()}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleUpdatePassword();
                    }
                  }}
                >
                  <Text color={'white'} style={karla}>
                    Send Reset Password
                  </Text>
                </Button>
              )}
              <Button
                alignItems={'center'}
                justifyContent='center'
                size={'md'}
                onClick={() => navigate('/login')}
              >
                <Text color={'black'} style={karla}>
                  Back to Login
                </Text>
              </Button>
            </Stack>
          </CardBody>
        </Card>
      </Stack>
    </>
  );
}

export default ForgotPassword;
