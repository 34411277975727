import React, { useEffect, useState } from 'react';
import { Box, Stack } from '@chakra-ui/react';
import { dataCollections } from '../../Data/Collections';
import { getSingleDocumentFirebase } from '../../Apis/firebaseApi';
import Hero1 from '../../Components/Templates/Hero/Hero1';
import Hero2 from '../../Components/Templates/Hero/Hero2';
import Hero3 from '../../Components/Templates/Hero/Hero3';
import Hero4 from '../../Components/Templates/Hero/Hero4';
import Hero5 from '../../Components/Templates/Hero/Hero5';
import Hero6 from '../../Components/Templates/Hero/Hero6';
import Hero7 from '../../Components/Templates/Hero/Hero7';
import LogoFeatures1 from '../../Components/Templates/Features/LogoFeatures1';
import ProductHighlight1 from '../../Components/Templates/Product/ProductHighlight1';
import HeroSlider from '../../Components/Templates/Hero/HeroSlider';
import Countdown2 from '../../Components/Templates/Hero/Countdown2';
import { UseAuthStore } from '../../Hooks/Zustand/store';
import { ProductCountdown } from '../../Components/Templates/Hero/ProductCountdown';

function HomePage() {
  const globalState = UseAuthStore()
  const [configHome, setConfigHome] = useState([]);
  const [webConfig, setWebConfig] = useState({});
  dataCollections.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  const handlePageview = async () => {
    const getConfig = await getSingleDocumentFirebase(
      'websites',
      's0zZPXekaJatxg8uCKK2'
    );
    if (getConfig) {
      setConfigHome(getConfig.pageHome);
    }
  };

  const handleWebConfig = async () => {
    try {
      const res = await getSingleDocumentFirebase('crm_web_config', globalState?.currentProject);
      if(res) return setWebConfig(res?.countdown) || console.log(res)
    } catch (error) {
      console.log(error.message)
    }
  };

  useEffect(() => {
    handlePageview();
    handleWebConfig()
  }, []);

  return (
    <Stack gap={0} mb={20}>
      {/* {isMobile ? (
        <CarouselComponent images={imgCarouselMobile} aspectRatio={2 / 3} />
      ) : (
        <CarouselComponent images={imgCarouselHead} aspectRatio={16 / 9} />
      )} */}
      {/* <DynamicHtml htmlString={configHome?.html} cssString={configHome?.css}/> */}
      
      {configHome?.map((x, i) => (
        <Box key={i}>
          {x?.id === 'Hero1' ? (
            <Hero1 header={x?.header} content={x?.content} data={x?.data} />
          ) : x?.id === 'Hero2' ? (
            <Hero2
              header={x?.header}
              content={x?.content}
              data={x?.data}
              image={x?.image}
            />
          ) : x?.id === 'Hero3' ? (
            <Hero3
              header={x?.header}
              content={x?.content}
              data={x?.data}
              image={x?.image}
            />
          ) : x?.id === 'Hero4' ? (
            <Hero4
              header={x?.header}
              content={x?.content}
              data={x?.data}
              image={x?.image}
            />
          ) : x?.id === 'Hero5' ? (
            <Hero5
              header={x?.header}
              content={x?.content}
              data={x?.data}
              image={x?.image}
            />
          ) : x?.id === 'Hero6' ? (
            <Hero6
              header={x?.header}
              content={x?.content}
              data={x?.data}
              image={x?.image}
            />
          ) : x?.id === 'Hero7' ? (
            <Hero7
              header={x?.header}
              content={x?.content}
              data={x?.data}
              image={x?.image}
            />
          ) : x?.id === 'LogoFeatures1' ? (
            <LogoFeatures1
              header={x?.header}
              content={x?.content}
              data={x?.data}
              image={x?.image}
            />
          ) : x?.id === 'ProductHighlights2' ? (
            <ProductHighlight1
              header={x?.header}
              content={x?.content}
              data={x?.data}
              image={x?.image}
            />
          ) : x?.id === 'FullImage2' ? (
            <Hero6
              header={x?.header}
              content={x?.content}
              data={x?.data}
              image={x?.image}
            />
          ) : x?.id === 'BannerSlider1' ? (
            <HeroSlider
              header={x?.header}
              content={x?.content}
              data={x?.data}
              image={x?.image}
            />
          ) : null}
        </Box>
      ))}
      
    </Stack>
  );
}

export default HomePage;
