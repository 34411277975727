import {
  Box,
  HStack,
  Heading,
  IconButton,
  Image,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { BsGeoAltFill, BsInstagram, BsWhatsapp } from 'react-icons/bs';
import { karla, ovo } from '../../../Theme/theme';
import PropTypes from 'prop-types';


export const imgaeStoreLocation =
  'https://klamby.id/cdn/shop/files/A7S01354_1944x.jpg?v=1649036641';

const LocationComponent = ({ data }) => {
  return (
    <>
      <Stack gap={10}>
        <Stack pos={'relative'}>
          <Box>
            <Image
              w={'100vw'}
              h={1000}
              objectFit={'cover'}
              src={imgaeStoreLocation}
            />
          </Box>
          <Stack
            align={'center'}
            justify={'center'}
            bgColor={'rgba(1,1,1,0.4)'}
            w={'100%'}
            pos={'absolute'}
            h='100%'
          >
            <Heading
              textTransform='capitalize'
              style={ovo}
              color={'white'}
              fontSize={['xl', '3xl', '5xl', '7xl']}
            >
              Store Locations
            </Heading>
            <Text
              textTransform={'uppercase'}
              style={karla}
              color={'white'}
              letterSpacing={'0.2em'}
            >
              get klamby near you
            </Text>
          </Stack>
        </Stack>
        <Stack px={[5, 10, 50, 79]} gap={[10, 10, 20, 20]} mt={[5, 5, 10, 10]}>
          <Heading style={ovo} fontSize={'3xl'} textAlign={'center'}>
            Offline Store
          </Heading>

          <SimpleGrid
            columns={[
              data.length % 2 === 0 ? 1 : 2,
              data.length % 2 === 0 ? 1 : 2,
              data.length % 2 === 0 ? 2 : 3,
              data.length % 2 === 0 ? 2 : 3,
              data.length % 2 === 0 ? 2 : 3,
            ]}
            gap={5}
          >
            {data?.map((x, i) => (
              <Stack key={i} align={'center'} cursor={'pointer'}>
                <Image src={x?.thumbnail} />
                <Heading
                  textTransform={'capitalize'}
                  fontSize={'2xl'}
                  style={ovo}
                >
                  {x?.state}
                </Heading>
                <Text style={{ ...karla, fontWeight: 'bold' }}>
                  {x.building !== ''
                    ? x.building
                    : x.address?.street.split(',')[0]}
                </Text>
                <Text style={karla} align={'center'}>
                  {x?.address?.street +
                    ', ' +
                    x?.address?.city +
                    ', ' +
                    x?.address?.province +
                    ', ' +
                    (x?.address?.postal_code !== null
                      ? x?.address?.postal_code
                      : '')}
                </Text>
                <HStack>
                  <Text style={karla}>{x.operation_day}:</Text>
                  <Text textTransform={'uppercase'} style={karla}>
                    {x.operation_hour}
                  </Text>
                </HStack>
                {x.phone_number && <Text style={karla}>{x?.phone_number}</Text>}
                <HStack>
                  {x?.social_media?.map((y, id) => (
                    <a
                      href={y.link}
                      target='_blank'
                      rel='noreferrer'
                      key={id}
                      style={karla}
                    >
                      <IconButton
                        size='lg'
                        color='#8e6d46'
                        variant='ghost'
                        aria-label='open menu'
                        icon={
                          y?.platform === 'instagram' ? (
                            <BsInstagram size={20} />
                          ) : y?.platform === 'whatsapp' ? (
                            <BsWhatsapp size={20} />
                          ) : y?.platform !== 'instagram' &&
                            y?.platform !== 'whatsapp' ? (
                              <BsGeoAltFill size={20} />
                            ) : (
                              <></>
                            )
                        }
                      />
                      {/* {x.link ? 
                  "Get directions" : null} */}
                    </a>
                  ))}
                </HStack>
              </Stack>
            ))}
          </SimpleGrid>
          {/* <Heading style={ovo} fontSize={"3xl"} textAlign={"center"}>
              Metro
            </Heading>
            <LocationComponent data={metroStore} /> */}
        </Stack>
      </Stack>
    </>
  );
};


LocationComponent.propTypes = {
  data: PropTypes.array,
}
export default LocationComponent;
