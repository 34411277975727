/* eslint-disable no-console */
import {
  Box,
  Button,
  Divider,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Flex,
  HStack,
  Image,
  Input,
  Spacer,
  Stack,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { PriceTag } from '../../PriceTag/PriceTag';
import { karla, ovo, themeColor } from '../../../Theme/theme';
import { useNavigate } from 'react-router-dom';
import { formatFrice } from '../../../Utils/numberUtil';
import { BsTrash3 } from 'react-icons/bs';
import { UseAuthStore } from '../../../Hooks/Zustand/store';
import {
  getSingleDocumentFirebase,
  setDocumentFirebase,
} from '../../../Apis/firebaseApi';
import PropTypes from 'prop-types';
import VoucherApplyComponent from '../../../Pages/Checkout/Components/VoucherApplyComponent';

const CartSidebar = ({ globalState }) => {
  const toast = useToast();
  const totalPrice = useRef(0);
  const [allCart, setAllCart] = useState([]);
  const [inputValues, setInputValues] = useState([]);
  const [voucher, setVoucherPrice] = useState(0);
  const [price, setPrice] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [voucherId, setVoucherId] = useState('');
  const [voucherUsed, setVoucherUsed] = useState({});
  const [totalAll, setTotalAll] = useState(0);

  let total = 0;
  const navigate = useNavigate();

  const handleIncrement = async (index, max) => {
    if (inputValues[index] < max) {
      setInputValues((prevValues) => {
        const newValues = [...prevValues];
        newValues[index] += 1;
        if (newValues !== undefined) {
          handleLocal(newValues);
        } else console.log('new values is undefined');
        return newValues; // return newValues to setInputValues
      });
    }
  };

  const handleDecrement = (index) => {
    if (inputValues[index] > 1) {
      setInputValues((prevValues) => {
        const newValues = [...prevValues];
        newValues[index] -= 1;
        if (newValues !== undefined) {
          handleLocal(newValues);
        } else console.log('new values is undefined');
        return newValues;
      });
    }
  };

  const handleDelete = (item, index) => {
    console.log(item, 'ini item');
    try {
      const getLocal = JSON.parse(localStorage.getItem('addToCart'));
      if (getLocal || item?.variantData) {
        const removeIndex = index;
        getLocal?.splice(removeIndex, 1);
        handleUpdateUserCart(getLocal);
        handleSetLocalCart();
        localStorage.setItem('addToCart', JSON.stringify(getLocal));
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleLocal = (newValues) => {
    const arrCarts = [];

    if (allCart) {
      allCart.map((x, i) => {
        arrCarts.push({
          ...x.variantData,
          qty: newValues[i],
        });
      });
    }

    if (globalState?.uid) {
      handleUpdateUserCart(arrCarts);
    }
  };

  const handleUpdateUserCart = async (cart) => {
    try {
      const docId = globalState.contactId;
      await setDocumentFirebase('crm_cart', docId, {
        cart: cart,
      });
    } catch (error) {
      console.log(error.message, 'ini handle error');
    }
  };

  const handleSetLocalCart = async () => {
    try {
      let cartData;

      if (globalState.email) {
        const docId = globalState.contactId;
        const getFirebase = await getSingleDocumentFirebase('crm_cart', docId);
        cartData = getFirebase?.cart || [];
      } else {
        const localCart = localStorage.getItem('addToCart');
        cartData = localCart ? JSON.parse(localCart) : [];
      }

      const filterCart = cartData.filter(
        (x) => x?.id !== null || x?.id !== undefined
      );
      const mapProduct = await Promise.all(
        filterCart.map(async (x) => {
          const res = await getSingleDocumentFirebase(
            'crm_product',
            x.productId
          );
          const dataAll = {
            variantData: x,
            variantId: x.variantId,
            productData: res,
            productId: res.id,
            quantity: x.qty,
          };
          return dataAll;
        })
      );
      setInputValues(mapProduct.map((x) => x.quantity));

      setAllCart(mapProduct || []);

      setTotalAll(total);
    } catch (error) {
      console.log(
        error.message,
        'failed set local from firebase or localStorage'
      );
    }
  };

  const handleCheckout = () => {
    if (globalState?.isTrial) {
      if (allCart?.length > 0) {
        navigate('/checkout');
      } else {
        toast({
          title: 'Pleas choose the product first',
          description: 'Failed',
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      }
    } else {
      toast({
        title: 'This website is under maintenance',
        description: 'Users restricted',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleNote = (note) => {
    const getNote = localStorage.getItem('note');
    if (getNote) {
      localStorage.removeItem('note');
    }
    localStorage.setItem('note', note);
  };

  useEffect(() => {
    handleSetLocalCart();

    return () => {};
  }, []);

  useEffect(() => {
    setQuantity(inputValues.reduce((a, b) => a + b, 0));
    totalPrice.current = total - (voucher ? voucher : 0);
    setTotalAll(totalPrice.current);
  }, [inputValues, total, voucher]);

  return (
    <DrawerContent isScrolled>
      <DrawerCloseButton />
      <DrawerHeader borderBottomWidth='1px'>
        <Text style={ovo}>Cart</Text>
      </DrawerHeader>
      <DrawerBody>
        <Stack gap={5} my={4}>
          {allCart?.map((x, i) => {
            total += x?.productData?.sale_price_int
              ? x?.productData?.sale_price_int * inputValues[i]
              : inputValues[i] * x?.variantData?.price_int ||
                inputValues[i] * parseInt(x?.variantData?.price);
            return (
              <Flex key={i} gap={5}>
                <Image
                  src={x?.variantData?.image}
                  aspectRatio={2 / 3}
                  w='90px'
                  objectFit={'cover'}
                />
                <Stack justify={'space-between'} width={'100%'}>
                  <HStack justify={'space-between'}>
                    <Stack>
                      <Text style={karla}>
                        {x?.productData?.name?.split('- ')[1] ||
                          x?.productData?.name?.split('| ')[1] ||
                          x?.productData?.name}
                      </Text>
                      <Text
                        style={karla}
                        fontSize={'smaller'}
                        textTransform={'uppercase'}
                      >
                        {x.variantData?.name?.split(' - ')[2] ||
                          x.variantData?.name?.split(' - ')[1] ||
                          x.variantData?.name}
                      </Text>
                    </Stack>
                    <Button
                      m={0}
                      p={0}
                      size={'sm'}
                      variant={'ghost'}
                      onClick={() => handleDelete(x, i)}
                    >
                      <BsTrash3 />
                    </Button>
                  </HStack>
                  <Flex justify={'space-between'}>
                    <HStack
                      gap={0}
                      m={0}
                      p={0}
                      border={'1px solid #ededec'}
                      w={'fit-content'}
                    >
                      <Button
                        rounded={0}
                        size='sm'
                        variant='ghost'
                        onClick={() => handleDecrement(i)}
                      >
                        -
                      </Button>
                      <Input
                        variant='unstyled'
                        textAlign='center'
                        w={50}
                        rounded={0}
                        value={inputValues[i]}
                        onChange={(e) => {
                          const value = parseInt(e.target.value, 10);
                          if (!isNaN(value) && value >= 1 && value <= 6) {
                            setInputValues((prevValues) => {
                              const newValues = [...prevValues];
                              newValues[i] = value;
                              return newValues;
                            });
                          }
                        }}
                        size='sm'
                      />
                      <Button
                        rounded={0}
                        size='sm'
                        variant='ghost'
                        onClick={() => handleIncrement(i, 6)}
                      >
                        +
                      </Button>
                    </HStack>

                    <Box>
                      {x?.productData?.sale_price_int ? (
                        <Text as={'s'} color={'gray.500'} style={karla}>
                          <PriceTag
                            price={
                              x?.variantData?.price_int * inputValues[i] ||
                              parseInt(x?.variantData?.price) * inputValues[i]
                            }
                            currency={'IDR'}
                            text={karla}
                          />
                        </Text>
                      ) : (
                        <></>
                      )}
                      <Text
                        color={x?.productData?.sale_price_int ? 'red' : 'black'}
                      >
                        <PriceTag
                          price={
                            x?.productData?.sale_price_int
                              ? x?.productData?.sale_price_int * inputValues[i]
                              : x?.variantData?.price_int * inputValues[i] ||
                                parseInt(x?.variantData?.price) * inputValues[i]
                          }
                          currency={'IDR'}
                          text={karla}
                        />
                      </Text>
                    </Box>
                  </Flex>
                </Stack>
              </Flex>
            );
          })}

          <Text
            textTransform={'uppercase'}
            style={ovo}
            fontSize={'sm'}
            letterSpacing={'0.2em'}
          >
            order note
          </Text>
          <Textarea
            defaultValue={localStorage.getItem('note')}
            rounded={0}
            onChange={(e) => handleNote(e.target.value)}
          />
        </Stack>
      </DrawerBody>
      <Divider />

      <DrawerFooter outline={0.5}>
        <Stack w={'full'} spacing={5}>
          <VoucherApplyComponent
            dataCart={allCart}
            voucherId={(e) => setVoucherId(e)}
            voucherUsed={(voucher) => setVoucherUsed(voucher)}
            quantity={quantity}
            price={total}
            setPrice={(item) => setPrice(item)}
            setVoucher={(e) => setVoucherPrice(e)}
          />
          {voucher ? (
            <>
              <Stack>
                <Flex justify={'space-between'} fontSize={'sm'}>
                  <Text>Subtotal</Text>
                  <PriceTag price={total} currency={'IDR'} text={karla} />
                </Flex>
                <Flex justify={'space-between'} fontSize={'sm'}>
                  <Text>Discount voucher</Text>
                  <Text color={'red'}>
                    <PriceTag
                      price={voucher ? voucher : 0}
                      currency={'IDR'}
                      text={karla}
                    />
                  </Text>
                </Flex>
              </Stack>
            </>
          ) : (
            <></>
          )}
          <HStack>
            <Text
              textTransform={'uppercase'}
              style={ovo}
              letterSpacing={'0.2em'}
            >
              total
            </Text>
            <Spacer />
            {voucher ? (
              <HStack>
                <Text fontWeight={500} style={karla} textDecor={'line-through'}>
                  <PriceTag price={total} currency={'IDR'} text={karla} />
                </Text>
                <Text fontWeight={500} style={karla} color={'red'}>
                  <PriceTag price={totalAll} currency={'IDR'} text={karla} />
                </Text>
              </HStack>
            ) : (
              <Text fontWeight={500} style={karla}>
                <PriceTag price={total} currency={'IDR'} text={karla} />
              </Text>
            )}
          </HStack>
          <Text
            style={karla}
            textAlign='center'
            fontSize={'xs'}
            color='gray.500'
          >
            Shipping, taxes, and discount codes calculated at checkout.
          </Text>
          <Button
            rounded={0}
            bgColor={themeColor}
            color='white'
            size={'lg'}
            style={ovo}
            colorScheme='none'
            _hover={{ bgColor: 'rgba(0, 0, 0, 0.8)' }}
            onClick={() => {
              globalState.setDrawer(!globalState.drawerToggle);
              handleCheckout();
              // navigate(globalZustan?.isLoggedIn === true ? "/cart" : "/checkout");
            }}
          >
            Checkout
          </Button>
        </Stack>
      </DrawerFooter>
    </DrawerContent>
  );
};

CartSidebar.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  globalState: PropTypes.object,
};

export default CartSidebar;
