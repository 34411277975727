/* eslint-disable no-console */
import {
  Box,
  Button,
  Center,
  Divider,
  HStack,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { UseAuthStore } from '../../Hooks/Zustand/store';
import { getCollectionFirebase } from '../../Apis/firebaseApi';
import { karla, themeColor } from '../../Theme/theme';
import { convertUniqueNumberDate } from '../../Utils/timeUtil';
import moment from 'moment';
import PropTypes from 'prop-types';

const PopupDiscount = ({close}) => {
  const globalState = UseAuthStore();
  const [discounts, setDiscounts] = useState([]);
  const [savedVoucher, setSavedVoucher] = useState('');
  const [applied, setApplied] = useState(false);
  console.log('alien')

  const getDiscount = async () => {
    try {
      const conditions = [
        { field: 'projectId',operator: '==',value: globalState?.currentProject},
        { field: 'companyId',operator: '==',value: globalState?.currentCompany},
        { field: 'isActive',operator: '==',value: true},
        { field: 'discountType',operator: '==',value: 'voucher'},
        { field: 'isVisible',operator: '==',value: true},
      ];
      const getDiscount = await getCollectionFirebase(
        'crm_discount',
        conditions
      );
      if (getDiscount) {
        setDiscounts(getDiscount);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAppliedVoucher = () => {
    const appliedVoucher = localStorage.getItem('appliedVoucher');
    // const appliedVoucher = globalState?.appliedVoucher;
    if (appliedVoucher) {
      setSavedVoucher(JSON.parse(appliedVoucher));
    }
  };

  const appliedVoucher = (voucherCode) => {
    localStorage.removeItem('appliedVoucher');
    localStorage.setItem('appliedVoucher', JSON.stringify(voucherCode));
    globalState?.setAppliedVoucher(voucherCode);
    setApplied(voucherCode);
  };

  const handleValue = (type, value) => {
    if (type === 'percentage') {
      return value + '%';
    } else {
      return 'Potongan sebesar Rp. ' + value;
    }
  };

  useEffect(() => {
    getDiscount();
  }, []);

  useEffect(() => {
    getAppliedVoucher();
  }, [savedVoucher]);

  return (
    <>
      <ModalHeader
        bgColor={'#8e6d46'}
        minH={'50px'}
        color={'white'}
        roundedTop={'lg'}
      >
        <ModalCloseButton />
        <Center>
          <Text>Available Discounts</Text>
        </Center>
      </ModalHeader>
      <ModalBody m={0} p={0} roundedBottom={'lg'}>
        <Box roundedBottom={'lg'} style={karla}>
          <Box p={3}>
            <Center style={karla}>
              <Text>
                Check out our active promotions! Spend more to save more!
              </Text>
            </Center>
          </Box>
          <Box
            bgColor={'gray.100'}
            p={5}
            roundedBottom={'lg'}
            style={karla}
            overflowY={'auto'}
            h={'65vh'}
            sx={{
              '&::-webkit-scrollbar': {
                w: '2',
                h: '2',
              },
              '&::-webkit-scrollbar-track': {
                w: '6',
                h: '5',
              },
              '&::-webkit-scrollbar-thumb': {
                borderRadius: '10',
                bg: '#8E6D46',
              },
            }}
          >
            <Stack>
              {discounts?.map((discount) => (
                <Box
                  key={discount?.id}
                  border={'1px'}
                  p={5}
                  bgColor={'white'}
                  m={2}
                >
                  <HStack>
                    <VStack
                      minW={'60%'}
                      align={'flex-start'}
                      fontSize={'sm'}
                      style={karla}
                    >
                      <Text as={'b'} fontSize={'lg'}>
                        {discount?.discountCode}
                      </Text>
                      <Text>{discount?.description}</Text>
                      <Text>
                        {handleValue(discount?.type, discount?.value)}
                      </Text>
                    </VStack>
                    <Divider orientation='vertical' />
                    <VStack minW={'40%'}>
                      <Box
                        color={'white'}
                        bgColor={'black'}
                        p={3}
                        style={karla}
                        minW={'80%'}
                      >
                        <Text fontSize={'xs'}> Coupon Code</Text>
                        <Text as={'b'} fontSize={'md'}>
                          {discount?.discountCode}
                        </Text>
                      </Box>
                      <Button
                        bgColor={
                          savedVoucher === discount?.id ? 'white' : themeColor
                        }
                        size={'sm'}
                        color={
                          savedVoucher === discount?.id ? themeColor : 'white'
                        }
                        style={karla}
                        onClick={() => appliedVoucher(discount?.discountCode)}
                        isDisabled={
                          applied === discount?.discountCode ? true : false
                        }
                      >
                        {applied === discount?.discountCode
                          ? 'voucher applied'
                          : 'Apply'}
                      </Button>
                      <Stack>
                        <Text fontSize={'sm'}>
                          {' '}
                          Expired at :{' '}
                          {moment(
                            new Date(discount?.expiredAt?.seconds * 1000)
                          ).format('DD/MM/YYYY')}
                        </Text>
                      </Stack>
                    </VStack>
                  </HStack>
                </Box>
              ))}
            </Stack>
          </Box>
        </Box>
      </ModalBody>
      <ModalFooter bgColor={'gray.100'} roundedBottom={'lg'}>
        <Button
          bgColor={themeColor}
          size={'md'}
          color={'white'}
          style={karla}
          onClick={close}
        >
          Continue shopping
        </Button>
      </ModalFooter>
    </>
  );
};

PopupDiscount.propTypes = {
  close: PropTypes.func.isRequired,
};

export default PopupDiscount;
