import { Box, HStack } from '@chakra-ui/react';
import React from 'react';
import AppSearchInput from '../../Search/AppSearchInput';
import { AiOutlineClose } from 'react-icons/ai';
import PropTypes from 'prop-types';


const SearchBar = ({ toClose }) => {
  return (
    <Box>
      <HStack
        shadow={'lg'}
        zIndex={100}
        h={'80px'}
        spacing={5}
        w='full'
        bgColor={'white'}
        alignItems='center'
        justifyContent={'center'}
      >
        <AppSearchInput />
        <AiOutlineClose size={20} cursor={'pointer'} onClick={toClose} />
      </HStack>
      {/* <Box
        position="absolute"
        bgColor={"blackAlpha.700"}
        w="full"
        h={"100vh"}
      /> */}
    </Box>
  );
};

SearchBar.propTypes = {
  toClose: PropTypes.func
}

export default SearchBar;
