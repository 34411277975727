import React, { useState, useEffect } from 'react';
import { Box, Text, Badge } from '@chakra-ui/react';
import { themeColor } from '../../Theme/theme';
import PropTypes from 'prop-types';


const CountdownTimerV2 = ({ expiry_date, isExpired, paymentStatus }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(expiry_date) - +new Date();
    // const difference = +new Date("2024-03-21 14:35:34") - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
      isExpired(false);
    } else {
      isExpired(true);
    }

    return timeLeft;
  };

  // console.log(paymentStatus)

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <Box key={interval} textAlign='center' mx={2}>
        <Text fontSize='md' fontWeight='bold' color={themeColor}>
          {timeLeft[interval]}
        </Text>
        <Text fontSize='sm' color='gray.500'>
          {interval.toUpperCase()}
        </Text>
      </Box>
    );
  });

  return (
    <Box display='flex' justifyContent='center' alignItems='center' mt={4}>
      {timerComponents.length && paymentStatus === 'PENDING' ? (
        timerComponents
      ) : timerComponents.length && paymentStatus === 'PAID' ? (
        <Badge fontSize='md' fontWeight='bold' colorScheme='green'>
          payment done
        </Badge>
      ) : !timerComponents.length && paymentStatus === 'PAID' ? (
        <Badge fontSize='md' fontWeight='bold' colorScheme='green'>
          payment done
        </Badge>
      ) : !timerComponents.length && paymentStatus === 'PENDING' ? (
        <Badge fontSize='md' fontWeight='bold' colorScheme='red'>
          payment expired
        </Badge>
      ) : (
        <></>
      )}
    </Box>
  );
};

CountdownTimerV2.propTypes = {
  expiry_date: PropTypes.string,
  isExpired: PropTypes.func,
  paymentStatus: PropTypes.string,
};

export default CountdownTimerV2;
