import {
  Button,
  Container,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Image,
  Input,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
// import { blog } from '../../../Data/Blog';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import {
  FaFacebook,
  FaLongArrowAltLeft,
  FaPinterest,
  FaTwitter,
} from 'react-icons/fa';
// import parse from 'html-react-parser';
import { getSingleDocumentFirebase } from '../../../Apis/firebaseApi';
const themeColor = '#8e6d46';

const SingleBlogPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [dataBlog, setDataBlog] = useState({});

  const fetchSingleDataBlog = async () => {
    try {
      const res = await getSingleDocumentFirebase('crm_web_blog', params.id)
      
      const modify = res?.html?.replace(/<img\s/g, '<img class="display: flex, justify-content: center"')
      setDataBlog({...res, html: modify})
    } catch (error) {
      throw new Error(error)
    }
  };
  const karla = {
    fontFamily: 'Karla',
    fontWeight: 400,
  };
  const ovo = {
    fontFamily: 'Ovo',
    fontWeight: 400,
  };

  console.log('ppopopopop')

  useEffect(() => {
    fetchSingleDataBlog();
  }, []);

  return (
    <Container maxW={'container.lg'} mb={10}>
      <Stack gap={10}>
        <Stack>
          <HStack textAlign={'center'} justify={'center'}>
            {dataBlog?.tags?.map((y, id) => (
              <HStack
                textAlign={'center'}
                justify={'center'}
                wrap={'wrap'}
                key={id}
              >
                <Text
                  textTransform={'uppercase'}
                  fontSize={'sm'}
                  style={karla}
                  key={id}
                >
                  {y}
                </Text>
                <Text mt={'-2'}>.</Text>
              </HStack>
            ))}
            <Text textTransform={'uppercase'} fontSize={'sm'} style={karla}>
              {moment(dataBlog?.createdAt).format('llll')}
            </Text>
          </HStack>
          <Heading textAlign={'center'} style={ovo}>
            {dataBlog?.title}
          </Heading>
        </Stack>
        <Image src={dataBlog?.img} w={'100%'} objectFit={'cover'} />
        <Stack style={karla} textAlign={'justify'} align={'center'}>
          {/* {dataBlog && dataBlog?.post && parse(dataBlog?.post)} */}
          <div dangerouslySetInnerHTML={{ __html: `<p style="text-align: center">${dataBlog?.html}</p>` }} />
        </Stack>
        <HStack gap={10}>
          <Button
            leftIcon={<FaFacebook />}
            style={karla}
            variant={'link'}
            color={'black'}
          >
            Share
          </Button>
          <Button
            leftIcon={<FaTwitter />}
            style={karla}
            variant={'link'}
            color={'black'}
          >
            Tweet
          </Button>
          <Button
            leftIcon={<FaPinterest />}
            style={karla}
            variant={'link'}
            color={'black'}
          >
            Pin it
          </Button>
        </HStack>
        <Divider />
        <Stack gap={5}>
          <Heading fontSize={'3xl'} style={ovo}>
            Leave a comment
          </Heading>
          <HStack style={karla}>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input rounded={0} />
            </FormControl>
            <FormControl>
              <FormLabel>Email</FormLabel>
              <Input rounded={0} />
            </FormControl>
          </HStack>
          <FormControl>
            <FormLabel style={karla}>Message</FormLabel>
            <Textarea rounded={0} />
          </FormControl>
          <Text style={karla}>
            Please note, comments must be approved before they are published
          </Text>
          <Button
            rounded={0}
            bgColor={themeColor}
            color='white'
            w={'fit-content'}
            style={ovo}
            colorScheme='none'
            _hover={{ bgColor: 'rgba(0, 0, 0, 0.8)' }}
          >
            Post a comment
          </Button>
          <HStack wrap={'wrap'} style={karla}>
            <a>
              This site is protected by reCAPTCHA and the Google{' '}
              <a>Privacy Policy </a>
              <a>and </a>
              <a>Terms of Service </a>
              <a>apply</a>
            </a>
          </HStack>
        </Stack>
        <Divider />
        <Button
          justifyContent={'center'}
          display={'flex'}
          alignItems={'center'}
          style={karla}
          leftIcon={<FaLongArrowAltLeft />}
          color={'black'}
          variant={'link'}
          onClick={() => navigate(-1)}
        >
          Back to Journal
        </Button>
      </Stack>
    </Container>
  );
};

export default SingleBlogPage;
