/* eslint-disable no-console */
import { useEffect, useState } from 'react';
import { UseAuthStore } from '../../../Hooks/Zustand/store';
import {
  getCollectionFirebase,
  getSingleDocumentFirebase,
} from '../../../Apis/firebaseApi';
import { Box, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { ovo } from '../../../Theme/theme';
import PropTypes from 'prop-types';


const StockOffline = (productId) => {
  const [dataStore, setDataStore] = useState([]);
  const globalState = UseAuthStore();

  const handleByCondition = async () => {
    try {
      const conditions = [
        {
          field: 'companyId',
          operator: '==',
          value: globalState?.currentCompany,
        },
        {
          field: 'projectId',
          operator: '==',
          value: globalState?.currentProject,
        },
      ];

      const sortBy = { field: 'createdAt', direction: 'desc' };
      const limitValue = 100;
      const startAfterData = null;

      const findProductStore = await getCollectionFirebase(
        'product_stores',
        conditions,
        sortBy,
        limitValue,
        startAfterData
      );

      if (findProductStore) {
        const variants = await Promise.all(
          findProductStore
            // .filter((x) => x?.productId === "qXd2YhKwLWz9FgFzURe6")
            .filter((x) => x?.productId === productId.productId)
            .map(async (x) => {
              const findStore = await getSingleDocumentFirebase(
                'store',
                x?.storeId
              );
              return {
                variantName: x.name,
                stocks: x.stocks,
                store: findStore.name,
              };
            })
        );
        setDataStore(variants);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // console.log(dataStore,'dataStore')

  useEffect(() => {
    // handleTypesenseSearch(productId.productId);
    handleByCondition();
  }, []);

  return (
    <>
      <Box display={'flex'} justifyContent={'space-between'}>
        {dataStore?.length > 0 ? (
          <Table>
            <Thead>
              <Tr>
                <Th>Variant</Th>
                <Th>Stocks</Th>
                <Th>Available at</Th>
              </Tr>
            </Thead>
            <Tbody>
              {dataStore?.map((x, i) => {
                return (
                  <Tr key={i}>
                    <Td>{x.variantName}</Td>
                    <Td>{x.stocks}</Td>
                    <Td>{x.store}</Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        ) : (
          <Text style={ovo}>
            There is no any available stock in offline store
          </Text>
        )}
      </Box>
    </>
  );
};

StockOffline.propTypes = {
  productId: PropTypes.object,
};

export default StockOffline;
