import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Image,
  Input,
  Stack,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { karla, ovo, themeColor } from '../../../Theme/theme';

export const imgaeStoreLocation =
  'https://klamby.id/cdn/shop/files/yahaha_1728x.png?v=1642561895';

const ContactUsComponent = () => {
  return (
    <>
      <Stack gap={10}>
        <Stack pos={'relative'}>
          <Box>
            <Image
              w={'100vw'}
              h={600}
              objectFit={'cover'}
              src={imgaeStoreLocation}
            />
          </Box>
          <Stack
            align={'center'}
            justify={'center'}
            bgColor={'rgba(1,1,1,0.4)'}
            w={'100%'}
            pos={'absolute'}
            h='100%'
          >
            <Heading
              textTransform='capitalize'
              style={ovo}
              color={'white'}
              fontSize={['xl', '3xl', '5xl', '7xl']}
            >
              Contact Us
            </Heading>
            <Text
              textTransform={'uppercase'}
              style={karla}
              color={'white'}
              letterSpacing={'0.2em'}
            >
              we wanna hear from you
            </Text>
          </Stack>
        </Stack>
      </Stack>
      <Center
        bgColor={'#faf2e2'}
        m={0}
        h={'30vh'}
        p={10}
        display={'flex'}
        flexDir={'column'}
      >
        <VStack>
          <Text as={'p'} style={karla} fontSize={'2xl'}>
            Please feel free to email teamwearingklamby@gmail.com or just speak
            your mind below.
          </Text>
        </VStack>
        <VStack pt={5}>
          <Heading as={'h6'} style={karla}>
            Klamby Studio
          </Heading>
          <Text>
            Islamic Village, Jl. Islamic II No.5, Klp. Dua, Kec. Klp. Dua,
            Kabupaten Tangerang, Banten 15810
          </Text>
          <Text>
            Question / Return / Complaint: 082125197645 Order: 082125197645
          </Text>
        </VStack>
      </Center>

      <VStack m={0} h={'50vh'} p={10}>
        <Text as={'p'} style={ovo} fontSize={'2xl'} pb={5}>
          Get In Touch
        </Text>
        <HStack w={'80%'} gap={5}>
          <FormControl>
            <FormLabel style={ovo}>Name</FormLabel>
            <Input
              focusBorderColor={themeColor}
              placeholder='First name'
              rounded={0}
              // value={address.firstName}
              // onChange={(e) =>
              //   setAddress({
              //     ...address,
              //     firstName: e.target.value,
              //   })
              // }
            />
          </FormControl>
          <FormControl>
            <FormLabel style={ovo}>Email</FormLabel>
            <Input
              rounded={0}
              focusBorderColor={themeColor}
              placeholder='Last name'
              // value={address.lastName}
              // onChange={(e) =>
              //   setAddress({
              //     ...address,
              //     lastName: e.target.value,
              //   })
              // }
            />
          </FormControl>
        </HStack>
        <FormControl w={'80%'} gap={5} py={2}>
          <FormLabel style={ovo}>Message</FormLabel>
          <Textarea h={'20vh'} rounded={0} />
        </FormControl>
        <Button
          w={'80%'}
          gap={5}
          bgColor={themeColor}
          color={'white'}
          rounded={0}
          size={'xl'}
          style={ovo}
        >
          Send
        </Button>
        <Text size={''}>
          This site is protected by reCAPTCHA and the Google Privacy Policy and
          Terms of Service apply.
        </Text>
      </VStack>
    </>
  );
};

export default ContactUsComponent;
