import { Stack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import LocationComponent from './Components/LocationComponent';
import BlogComponent from './Components/BlogComponent';
import { getCollectionFirebase } from '../../Apis/firebaseApi';
import { useLocation } from 'react-router-dom';
import TermConditionsComponent from './Components/TermConditionsComponent';
import ContactUsComponent from './Components/ContactUsComponent';
import { UseAuthStore } from '../../Hooks/Zustand/store';

const PagesPage = () => {
  const location = useLocation();
  const [listStore, setListStore] = useState([]);
  const globalState = UseAuthStore()
  const [blogData, setBlogData] = useState([])
  const [lookbookData, setLookbookData] = useState([])

  const params = location.pathname.replace('/pages/', '');

  const fetchListBlog = async() => {
    const conditions = [
      {field: 'companyId', operator: '==', value: globalState.currentCompany},
      {field: 'projectId', operator: '==', value: globalState.currentProject},
      {field: 'isPublish', operator: '==', value: true},
    ]

    try {
      const res = await getCollectionFirebase('crm_web_blog', conditions)

      if(res.length > 0){
        //pecah berdasarkan tags
        const blog = res.filter((data) => data?.tags?.includes('blogpost'))
        setBlogData(blog)

        const lookbook = res.filter((data) => data?.tags?.includes('lookbook'))
        setLookbookData(lookbook)
      }

    } catch (error) {
      throw new Error(error)
    }
  }

  const fetchListStore = async () => {
    try {
      const conditions = [
        { field: 'companyId', operator: '==', value: 'ffBBNgi1CODzR9xxU2Ro' },
        { field: 'building', operator: '!=', value: 'online' },
      ];
      const sortBy = null;
      const limitValue = null;
      const startAfterData = null;
      const getList = await getCollectionFirebase(
        'store',
        conditions,
        sortBy,
        limitValue,
        startAfterData
      );
      if (getList) {
        setListStore(getList);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchListStore();
    fetchListBlog()
  }, []);

  return (
    <Stack mb={20}>
      {params.includes('blog') ? <BlogComponent blog={blogData} lookbook={lookbookData} /> : <></>}
      {params.includes('locations') ? (
        <LocationComponent data={listStore} />
      ) : (
        <></>
      )}
      {params.includes('conditions') ? (
        <TermConditionsComponent label={params} />
      ) : (
        <></>
      )}
      {params.includes('contacts') ? (
        <ContactUsComponent label={params} />
      ) : (
        <></>
      )}
    </Stack>
  );
};

export default PagesPage;
