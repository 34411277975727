import React, { useEffect, useState } from 'react';
import {
  getCollectionFirebase,
} from '../../Apis/firebaseApi';
import {
  Stack,
} from '@chakra-ui/react';
import { UseAuthStore } from '../../Hooks/Zustand/store';
import { checkTimeStatus } from '../../Utils/timeUtil';
import ContentVIPComponent from './Component/ContentVIPComponent';
import InputPasswordComponent from './Component/InputPasswordComponent';
import { auth } from '../../Config/firebase';

const VipPage = () => {
  const globalState = UseAuthStore();
  const [data, setData] = useState([]);
  const [products, setProducts] = useState([]);
  const [isPassword, setIsPassword] = useState(false);
  const [isUser, setIsUser] = useState(false);

  const fetchDataProducts = async (categoryId) => {
    if (!categoryId) return [];

    const responses = await Promise.allSettled(
      categoryId.map(async (id) => {
        const conditions = [
          { field: 'categoryId', operator: 'array-contains', value: id },
          { field: 'projectId', operator: '==',value: globalState.currentProject}
        ]

        try {
          const res = await getCollectionFirebase('crm_product', conditions);
          return res.length > 0 ? res : null;
        } catch (error) {
          throw new Error(`Error fetching category ${id}:`, error)
        }
      })
    );

    const filteredResponses = responses
      .filter(({ status, value }) => status === 'fulfilled' && value)
      .flatMap(({ value }) => value);

    setProducts(filteredResponses);
  };

  const fetchMembershipContent = async () => {
    // console.log('ppopijiujikn')
    try {
      const conditions = [
        { field: 'projectId', operator: '==',value: globalState?.currentProject},
        { field: 'isPublish', operator: '==',value: true}
      ]
      const res = await getCollectionFirebase(
        'crm_web_membership', conditions
      )
      console.log(res, 'res')
      
      if (res.length > 0) {
        setData(res[0]);
        checkMembershipUser(res[0]);
        checkStatusTime(res[0]); // Memanggil fungsi checkStatusTime tanpa menggunakan hasilnya

        await fetchDataProducts(res[0]?.categoryId);
      }
    } catch (error) {
      throw new Error('Error fetching membership content:', error)
    }
  };

  const checkStatusTime = (item) => {
    if (item?.startedAt && item?.finishedAt) {
      const statusTime = checkTimeStatus(item?.startedAt, item?.finishedAt);
      if (statusTime?.status === 'inRange') {
        return (
          <Stack gap={10}>
            {data?.isPassword ? (
              isPassword ? (
                <ContentVIPComponent data={data} products={products} />
              ) : (
                <InputPasswordComponent
                  data={data?.secretPassword}
                  password={(e) => {
                    setIsPassword(e)
                  }}
                />
              )
            ) : (
              <ContentVIPComponent data={data} products={products} />
            )}
          </Stack>
        );
      } else if (statusTime?.status === 'expired') {
        return null;
      } else {
        return (
          <Stack gap={10}>

          </Stack>
        )
      }
    }
  };

  const checkMembershipUser = async(data) => {
    try {
      const conditions = [
        {field: 'projectId',operator: '==',value: globalState?.currentProject,},
        {field: 'membershipTags',operator: 'array-contains',value: data?.slug,},
        {field: 'email',operator: '==',value: auth?.currentUser?.email,},
      ]
      const res = await getCollectionFirebase('crm_contacts', conditions)
      if (res?.length > 0) {
        setIsUser(true)
      }
    } catch (error) {
      throw new Error(error)
    }
  };

  useEffect(() => {
    fetchMembershipContent();
  }, []);

  return (
    <div>
      {isUser ? checkStatusTime(data) : null}
    </div>
  );
};

export default VipPage;
