import { Box, Heading, Radio, RadioGroup, Stack } from '@chakra-ui/react';
import React from 'react';
import { themeColor } from '../../../Theme/theme';
import FormAddressComponent from './FormAddressComponent';
import PropTypes from 'prop-types';


const BillingAddressComponent = ({billingAddress, setBillingAddress, handleBillingAddress}) => {
  return (
    <>
      <Heading fontSize={'2xl'} fontWeight={'medium'}>
        Billing address
      </Heading>
      <RadioGroup
        colorScheme='orange'
        value={billingAddress}
        onChange={(value) => setBillingAddress(value)}
      >
        <Box>
          <Box
            cursor={'pointer'}
            onClick={() => setBillingAddress('same')}
            p={5}
            bgColor={billingAddress === 'same' ? '#FCF5F0' : 'none'}
            border={
              billingAddress === 'same'
                ? `1px solid ${themeColor}`
                : '1px solid rgba(0,0,0,.045)'
            }
            roundedTop={5}
          >
            <Radio isChecked={billingAddress === 'same'} value={'same'}>
              Same as shipping address
            </Radio>
          </Box>
          <Box
            cursor={'pointer'}
            onClick={() => setBillingAddress('different')}
            p={5}
            bgColor={billingAddress === 'different' ? '#FCF5F0' : 'none'}
            border={
              billingAddress === 'different'
                ? `1px solid ${themeColor}`
                : '1px solid rgba(0,0,0,.045)'
            }
            roundedBottom={billingAddress === 'same' && 5}
          >
            <Radio value='different'> Use a different billing address</Radio>
          </Box>
        </Box>
        {billingAddress === 'different' && (
          <Stack
            bgColor={'rgba(0,0,0,.045)'}
            border={'1px solid rgba(0,0,0,.045)'}
            p={5}
            mt={0}
            roundedBottom={5}
          >
            <FormAddressComponent formAddress={handleBillingAddress} />
          </Stack>
        )}
      </RadioGroup>
    </>
  );
};

BillingAddressComponent.propTypes = {
  billingAddress: PropTypes.string,
  setBillingAddress: PropTypes.func,
  handleBillingAddress: PropTypes.func,
};

export default BillingAddressComponent;
