/* eslint-disable no-undef */
/* eslint-disable no-console */
import {
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Stack,
  Tooltip,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { themeColor } from '../../../Theme/theme';
import { country, province } from '../../../Data/Country';
import { BsQuestionCircle } from 'react-icons/bs';
import PropTypes from 'prop-types';
import { shippingSubDistrict } from '../../../Apis/checkoutAPI';


const FormAddressComponent = ({ formAddress }) => {
  const [district, setDistrict] = useState([]);
  const [address, setAddress] = useState({});

  const handleDistrict = async (district) => {
    const districtKey = {
      keyword: district.toLowerCase(),
    };

    try {

      const getSubDistrict = await shippingSubDistrict(districtKey)
      if (getSubDistrict.data.status === true) {
        setDistrict(getSubDistrict.data.data.data);
      } else {
        console.log(getSubDistrict.data.message, 'failed');
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const createNewAddress = () => {
    if (address?.subdistrict_id) {
      const fullAddress = {
        subdistrict_id: address?.subdistrict_id,
        receiver: address?.firstName + address?.lastName,
        email: address?.email,
        phone_number: address?.phone_number,
        address: address
        // {
        //   subdistrict_name: address?.subdistrict_name,
        //   country: address?.country,
        //   postal_code: address?.postal_code,
        //   state: address?.state,
        //   street: address?.street,
        //   subdistrict_id: address?.subdistrict_id,
        //   lastName: address?.lastName,
        //   phone_number: address?.phone_number,
        //   firstName: address?.firstName,
        //   city: address?.city,
        // }
      };
      return formAddress(fullAddress);
    }
  };

  useEffect(() => {
    createNewAddress();
  }, [address]);

  return (
    <Stack>
      <FormControl>
        <FormLabel>Country</FormLabel>
        <Select
          bg={'white'}
          focusBorderColor={themeColor}
          value={address?.country}
          defaultValue={'Indonesia'}
          onChange={(e) => {
            setAddress({
              ...address,
              country: e.target.value || '',
            });
            console.log(e.target.value);
          }}
        >
          {country.map((x, i) => (
            <option key={i} value={x.name}>
              {x.name}
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl isRequired>
        <FormLabel>Email</FormLabel>
        <Input
          bg={'white'}
          focusBorderColor={themeColor}
          placeholder="example@email.com"
          value={address?.email}
          onChange={(e) =>
            setAddress({
              ...address,
              email: e.target.value || '',
            })
          }
        />
      </FormControl>
      <HStack>
        <FormControl>
          <FormLabel>First name</FormLabel>
          <Input
            bg={'white'}
            focusBorderColor={themeColor}
            placeholder="First name"
            value={address?.firstName}
            onChange={(e) =>
              setAddress({
                ...address,
                firstName: e.target.value || '',
              })
            }
          />
        </FormControl>
        <FormControl>
          <FormLabel>Last name</FormLabel>
          <Input
            bg={'white'}
            focusBorderColor={themeColor}
            placeholder="Last name"
            value={address?.lastName}
            onChange={(e) =>
              setAddress({
                ...address,
                lastName: e.target.value || '',
              })
            }
          />
        </FormControl>
      </HStack>
      <FormControl isRequired>
        <FormLabel>Address</FormLabel>
        <Input
          bg={'white'}
          focusBorderColor={themeColor}
          placeholder="Address"
          value={address?.street}
          onChange={(e) =>
            setAddress({
              ...address,
              street: e.target.value.toUpperCase() || '',
            })
          }
        />
      </FormControl>
      <FormControl isRequired>
        <FormLabel>Province</FormLabel>
        <Select
          bg={'white'}
          focusBorderColor={themeColor}
          placeholder="select province"
          value={address?.state}
          onChange={(e) =>
            setAddress({
              ...address,
              state: e.target.value || '',
            })
          }
        >
          {province?.map((x, i) => (
            <option value={x.name} key={i}>
              {x.name}
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl isRequired>
        <FormLabel>City/District</FormLabel>
        <Input
          bg={'white'}
          focusBorderColor={themeColor}
          placeholder="City/district"
          value={address?.city}
          onChange={(e) => {
            handleDistrict(e.target.value);
            setAddress({
              ...address,
              city: e.target.value || '',
            });
          }}
        />
      </FormControl>
      <HStack>
        <FormControl
          isRequired
          isDisabled={district?.length > 0 ? false : true}
        >
          <FormLabel>Subdistrict </FormLabel>
          <Select
            bg={'white'}
            focusBorderColor={themeColor}
            placeholder="select district"
            value={address?.subdistrict_id}
            onChange={(e) => {
              setAddress({
                ...address,
                subdistrict_id: parseInt(e?.target?.value) || 0,
                subdistrict_name:
                  e.target.options[e.target.selectedIndex].textContent || 0,
              });
            }}
          >
            {district.map((x) => (
              <option value={x.subdistrict_id} key={x.subdistrict_id}>
                {x.city}
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>Postal code</FormLabel>
          <Input
            bg={'white'}
            focusBorderColor={themeColor}
            placeholder="Postal code"
            value={address.postal_code}
            onChange={(e) =>
              setAddress({
                ...address,
                postal_code: e.target.value || '',
              })
            }
          />
        </FormControl>
      </HStack>
      <FormControl isRequired>
        <FormLabel>Phone</FormLabel>
        <InputGroup>
          <Input
            type='number'
            bg={'white'}
            focusBorderColor={themeColor}
            placeholder="Phone number"
            defaultValue={address?.phone_number}
            onChange={(e) =>
              setAddress({
                ...address,
                phone_number: e.target.value,
              })
            }
          />
          <Tooltip
            label="In case we need to contact you about your order"
            aria-label="More information"
            w={200}
            textAlign={'center'}
          >
            <InputRightElement>
              <BsQuestionCircle />
            </InputRightElement>
          </Tooltip>
        </InputGroup>
      </FormControl>
    </Stack>
  );
};

FormAddressComponent.propTypes = {
  formAddress: PropTypes.func.isRequired,
};

export default FormAddressComponent;
