import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  Box,
  Button,
  Flex,
  HStack,
  Input,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { UseAuthStore } from '../../../Hooks/Zustand/store';
import {
  getCollectionFirebase,
  getSingleDocumentFirebase,
} from '../../../Apis/firebaseApi';
import { karla, ovo, textMuted, themeColor } from '../../../Theme/theme';
import { timeFirebase2 } from '../../../Utils/timeUtil';
import { auth } from '../../../Config/firebase';
import PropTypes from 'prop-types';
import { FiXCircle } from 'react-icons/fi';
import { set } from 'firebase/database';
import { valueVoucher } from '../../../Utils/productUtils';

const VoucherApplyComponent = ({
  dataCart,
  voucherId,
  voucherUsed,
  quantity,
  price,
  setPrice,
  setVoucher,
}) => {
  const [commonProducts, setCommonProducts] = useState([]);
  const globalState = UseAuthStore();
  const [key, setKey] = useState('');
  const [label, setLabel] = useState('');
  const [activeVouchers, setActiveVouchers] = useState([]);
  const toast = useToast();
  const getAppliedVoucher = async () => {
    const get = localStorage.getItem('appliedVoucher');
    const data = JSON.parse(get);
    if (data) {
      setKey(data);
    }
  };

  const deleteVoucher = () => {
    setLabel('');
    setKey('');
    voucherUsed({})
    setVoucher(0)
    localStorage.removeItem('appliedVoucher');
  };

  const getDiscountLog = async (voucherId, contactId) => {
    try {
      const conditions = [
        {
          field: 'projectId',
          operator: '==',
          value: globalState?.currentProject,
        },
        {
          field: 'companyId',
          operator: '==',
          value: globalState?.currentCompany,
        },
        { field: 'discountType', operator: '==', value: 'voucher' },
        { field: 'discountId', operator: '==', value: voucherId },
      ];

      if (contactId) {
        conditions.push({
          field: 'contactId',
          operator: '==',
          value: contactId,
        });
        const getUserLog = await getCollectionFirebase(
          'crm_discount_log',
          conditions
        );
        return getUserLog;
      }

      const getDiscount = await getCollectionFirebase(
        'crm_discount_log',
        conditions
      );
      return getDiscount;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const keyVoucher = async () => {
    try {
      const conditions = [
        {
          field: 'projectId',
          operator: '==',
          value: globalState?.currentProject,
        },
        {
          field: 'companyId',
          operator: '==',
          value: globalState?.currentCompany,
        },
        { field: 'isActive', operator: '==', value: true },
        { field: 'isVisible', operator: '==', value: true },
        { field: 'discountCode', operator: '==', value: key },
      ];
      const getVoucher = await getCollectionFirebase(
        'crm_discount',
        conditions
      );

      if (getVoucher?.length > 0) {
        return checkConditionVoucher(getVoucher[0]);
      } else {
        setVoucher(0);
      }
    } catch (error) {
      console.log(error.message, 'Failed to send  error message');
      return toast({
        title: 'Code voucher not found',
        description: 'Your voucher code is not valid',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'bottom',
      });
    }
  };

  const getProductVoucher = async (voucherId) => {
    try {
      const conditions = [
        {
          field: 'projectId',
          operator: '==',
          value: globalState?.currentProject,
        },
        {
          field: 'activeVouchers',
          operator: 'array-contains',
          value: voucherId,
        },
      ];
      const getProducts = await getCollectionFirebase(
        'crm_product',
        conditions
      );
      if (getProducts?.length > 0) {
        return getProducts;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const handleCheckProduct = async (products) => {
    const commonProducts = products.filter((x) =>
      dataCart.some((y) => x.id === y.productId)
    );
    return commonProducts;
  };

  const handleCheckUsers = async (slug) => {
    try {
      const conditions = [
        {
          field: 'projectId',
          operator: '==',
          value: globalState?.currentProject,
        },
        { field: 'voucherTags', operator: 'array-contains', value: slug },
      ];
      const checkOnUsers = await getCollectionFirebase(
        'crm_contacts',
        conditions
      );
      if (checkOnUsers?.length > 0) {
        if (globalState?.isLoggedIn) {
          const commonUser = checkOnUsers.find(
            (x) => x.email === auth?.currentUser?.email
          );
          if (commonUser?.email) {
            return { status: true };
          } else {
            return {
              status: false,
              message: 'You are not allowed to use this voucher',
            };
          }
        } else {
          return { status: false, message: 'You have to login first' };
        }
      } else {
        return { status: true };
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const checkConditionVoucher = async (voucher) => {
    const isExpired = { value: false, label: '' };
    const isMinQuantity = { value: false, label: '' };
    const isMinPurchase = { value: false, label: '' };
    const isStocks = { value: false, label: '' };
    const isReusable = { value: false, label: '' };
    const isUserVoucher = { value: false, label: '' };
    const isProductVoucher = { value: false, label: '' };

    const startedAt = timeFirebase2(voucher?.startedAt);
    const expiredAt = timeFirebase2(voucher?.expiredAt);
    const getProducts = await getProductVoucher(voucher?.id);

    //check expired
    if (startedAt && expiredAt) {
      if (
        new Date() > new Date(startedAt) &&
        new Date() < new Date(expiredAt)
      ) {
        isExpired.value = true;
      } else if (new Date() > new Date(expiredAt)) {
        isExpired.value = false;
        isExpired.label = 'Voucher has expired';
        return toast({
          title: isExpired.label,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom',
        });
      } else {
        isExpired.value = false;
        isExpired.label = 'Voucher is not active yet';
        return toast({
          title: isExpired.label,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom',
        });
      }
    }

    //check min quantity
    if (voucher?.minQty > 0) {
      if (quantity >= voucher?.minQty) {
        isMinQuantity.value = true;
      } else {
        isMinQuantity.value = false;
        isMinQuantity.label = 'This voucher must be used with minimum quantity';
      }
    } else {
      isMinQuantity.value = true;
    }

    //check min purchase
    if (voucher?.minShop > 0) {
      if (price >= voucher?.minShop) {
        isMinPurchase.value = true;
      } else {
        isMinPurchase.value = false;
        isMinPurchase.label = 'This voucher must be used with minimum purchase';
      }
    } else {
      isMinPurchase.value = true;
    }

    //check max use per user
    if (voucher?.maxUsePerUser > 0) {
      const checkLogDiscount = await getDiscountLog(voucher?.id);
      if (voucher?.maxUsePerUser > checkLogDiscount?.length) {
        isReusable.value = true;
      } else {
        isReusable.value = false;
        isReusable.label = 'You have used this voucher';
        toast({
          title: isReusable.label,
          description: 'you only can use this voucher once',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom',
        });
      }
    } else {
      isReusable.value = true;
    }

    //check product
    if (getProducts?.length > 0) {
      const checkProduct = await handleCheckProduct(getProducts);
      if (checkProduct?.length > 0) {
        isProductVoucher.value = true;
        setCommonProducts(checkProduct);
      } else {
        isProductVoucher.value = false;
        isProductVoucher.label = 'This voucher must be used with product';
      }
    } else {
      isProductVoucher.value = true;
    }

    //check user
    if (voucher?.slug) {
      const checkUsers = await handleCheckUsers(voucher?.slug);
      if (checkUsers.status) {
        isUserVoucher.value = checkUsers.status;
      } else {
        isUserVoucher.value = checkUsers.status;
        isUserVoucher.label = checkUsers.message;
      }
    } else {
      isUserVoucher.value = true;
    }

    //check stock
    if (voucher?.maxUse > 0) {
      const checkLogDiscount = await getDiscountLog(voucher?.id);
      const stock = voucher?.maxUse - checkLogDiscount?.length;
      if (stock >= 0) {
        isStocks.value = true;
        return handleVoucher(
          isMinPurchase,
          isStocks,
          isReusable,
          isExpired,
          isMinQuantity,
          isProductVoucher,
          isUserVoucher,
          voucher
        );
      } else {
        return toast({
          title: 'Voucher is no longer available',
          description: 'out of stock',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom',
        });
      }
    } else {
      isStocks.value = true;
      return handleVoucher(
        isMinPurchase,
        isStocks,
        isReusable,
        isExpired,
        isMinQuantity,
        isProductVoucher,
        isUserVoucher,
        voucher
      );
    }
  };

  const handleVoucher = (
    isMinPurchase,
    isStocks,
    isReusable,
    isExpired,
    isMinQuantity,
    isProductVoucher,
    isUserVoucher,
    voucher
  ) => {
    if (
      !isStocks.value ||
      !isMinPurchase.value ||
      !isReusable.value ||
      !isExpired.value ||
      !isMinQuantity.value ||
      !isProductVoucher.value ||
      !isUserVoucher.value
    ) {
      const label = [
        isMinPurchase?.label,
        isReusable?.label,
        isExpired?.label,
        isMinQuantity?.label,
        isProductVoucher?.label,
        isUserVoucher?.label,
      ]
        .filter((x) => x !== '')
        .join(', ');

      setLabel(label);
    } else {
      //check value vocuher
      let value = voucher?.value;
      const maxValue = voucher?.max;
      if (voucher?.type === 'percentage') {
        const checkValue = value / 100;
        const checkValueTotal = checkValue * price;
        //check max
        if (maxValue > 0) {
          if (checkValueTotal >= maxValue) {
            value = maxValue;
          } else {
            value = checkValueTotal;
          }
        }
      }

      setVoucher(value);
      setLabel(`${voucher?.discountCode} voucher applied`);
      setPrice(price - value);

      voucherId(voucher?.id);

      return voucherUsed({
        detailVoucher: voucher,
        products: commonProducts,
        amountValue: value,
      });
    }
  };

  const handleAutoGenerateVoucher = async () => {
    try {
      const conditions = [
        { field: 'isActive', operator: '==', value: true },
        { field: 'isVisible', operator: '==', value: true },
        { field: 'autoGenerate', operator: '==', value: true },
      ];
      const resData = await getCollectionFirebase('crm_discount', conditions);
      const voucherAuto = resData?.map((x) => x?.id);
  
      // Check active vouchers in dataCart
      const checkActiveVouchers = dataCart.flatMap((x) =>
        x?.productData?.activeVouchers?.filter((y) => voucherAuto?.includes(y)) || []
      );
      const distinctVouchers = Array.from(new Set(checkActiveVouchers));
      const getDetailVoucher = await Promise.all(
        distinctVouchers.map((x) => getSingleDocumentFirebase('crm_discount', x))
      );
  
      // Set the active vouchers state
      setActiveVouchers(getDetailVoucher);
    } catch (error) {
      console.log('Error in handleAutoGenerateVoucher:', error);
    }
  };


  useEffect(() => {
    if (key) {
      keyVoucher();
    }
  }, [key]);

  useEffect(() => {
    if (dataCart?.length > 0) {
      handleAutoGenerateVoucher();
    }
  }, [dataCart]);

  useEffect(() => {
    getAppliedVoucher();
  }, []);

  return (
    <Stack>
      {activeVouchers?.length > 0 ? (
        <Accordion allowToggle p={0} defaultIndex={[0]}>
          <AccordionItem>
            <AccordionButton>
              <Box as='i' flex='1' textAlign='left'>
                {`${activeVouchers?.length} vouchers available`}
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel p={0} style={karla}>
              {activeVouchers?.length > 0 &&
                activeVouchers?.map((x, i) => (
                  <Box
                    key={i}
                    border={`1px solid ${themeColor}`}
                    p={4}
                    roundedTop={i === 0 ? 5 : 0}
                    roundedBottom={activeVouchers?.length > 0 ? 5 : 0}
                    bgColor={'#FCF5F0'}
                    onClick={() => setKey(x?.discountCode)}
                  >
                    <Flex justifyContent={'space-between'}>
                      <Stack>
                        <Text>{x?.discountCode}</Text>
                        <Text color={textMuted}>
                          {valueVoucher(x?.value, x?.type, x?.productPrice)}
                        </Text>
                      </Stack>
                      <Button
                        rounded={0}
                        bgColor={themeColor}
                        color={'white'}
                        isDisabled={key ? true : false}
                      >
                        {key === x?.discountCode ? 'Applied' : 'Apply'}
                      </Button>
                    </Flex>
                  </Box>
                ))}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      ) : (
        <></>
      )}
      <HStack>
        <Input
          placeholder='Discount code or gift card'
          bgColor={'white'}
          rounded={0}
          value={key}
          onChange={(e) => setKey(e.target.value)}
          isDisabled={label.includes('voucher applied') ? true : false}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              keyVoucher();
            }
          }}
        />
        <Button
          bgColor={themeColor}
          border={'1px solid rgb(224,210,177)'}
          rounded={0}
          color={'white'}
          style={ovo}
          onClick={keyVoucher}
          isDisabled={label.includes('voucher applied') ? true : false}
        >
          Apply
        </Button>

        {key !== '' && (
          <Button
            bgColor={themeColor}
            border={'1px solid rgb(224,210,177)'}
            rounded={0}
            color={'white'}
            style={ovo}
            onClick={deleteVoucher}
          >
            Delete
          </Button>
        )}
      </HStack>
      {label.includes('voucher applied') ? (
        <Alert
          status='error'
          border={'1px solid rgb(224,210,177)'}
          rounded={'lg'}
        >
          <HStack display={'flex'} justifyContent={'space-between'}>
            <Text
              as={'i'}
              color={label.includes('voucher applied') ? 'green' : 'red'}
              fontSize={'sm'}
            >
              {label}
            </Text>
            <FiXCircle
              color={themeColor}
              onClick={() => {
                setLabel(''), setKey(''), setVoucher(0), voucherUsed({});
              }}
            />
          </HStack>
        </Alert>
      ) : (
        <Text
          as={'i'}
          color={label.includes('voucher applied') ? 'green' : 'red'}
          fontSize={'sm'}
        >
          {label}
        </Text>
      )}
    </Stack>
  );
};

VoucherApplyComponent.propTypes = {
  price: PropTypes.number,
  setPrice: PropTypes.func,
  setVoucher: PropTypes.func,
  voucherId: PropTypes.func,
  voucherUsed: PropTypes.func,
  dataCart: PropTypes.array,
  quantity: PropTypes.number,
};

export default VoucherApplyComponent;
