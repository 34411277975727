import {
  Box,
  chakra,
  Container,
  Link,
  SimpleGrid,
  Stack,
  Text,
  VisuallyHidden,
  Input,
  useColorModeValue,
  Divider,
  InputGroup,
  Button,
  InputLeftElement,
  Accordion,
  AccordionItem,
  AccordionButton,
  HStack,
  AccordionPanel,
  Spacer,
  AccordionIcon,
} from '@chakra-ui/react';
import { useState } from 'react';
import { MdOutlineEmail } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { dataUser } from '../../Data/DataUser';
import { dataFooter } from '../Sidebar/DataMenu';
import PropTypes from 'prop-types';


const ovo = {
  fontFamily: 'Ovo',
  fontWeight: 400,
};
const karla = {
  fontFamily: 'Karla',
  fontWeight: 400,
};

const SocialButton = ({ children, label, href }) => {
  return (
    <chakra.button
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={8}
      h={8}
      as={'a'}
      cursor={'pointer'}
      href={href}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

const ListHeader = ({ children }) => {
  return (
    <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
      {children}
    </Text>
  );
};

export default function AppFooter() {
  const [email, setEmail] = useState(dataUser.email);
  const navigate = useNavigate();

  return (
    <Box
      zIndex={'0'}
      position='absolute'
      w='full'
      bg={useColorModeValue('#8e6d46', '#8e6d46')}
      color={useColorModeValue('gray.200', 'white')}
      py={[5, 10, 20]}
    >
      <Stack w={'full'} h='auto' alignItems={'center'} justifyContent='center'>
        <Container as={Stack} maxW={'6xl'} py={5}>
          <SimpleGrid
            display={{ base: 'none', md: 'grid' }}
            templateColumns={{ sm: '1fr 1fr', md: '1fr 1fr 1fr 2fr' }}
            // columns={4}
            spacing={8}
          >
            <Stack align={'flex-start'} fontSize='sm'>
              <Text
                textTransform={'uppercase'}
                style={ovo}
                letterSpacing={'0.2em'}
              >
                About
              </Text>

              <Text
                style={karla}
                cursor={'pointer'}
                onClick={() => navigate('/pages/contacts')}
              >
                Contact us
              </Text>
              <Text
                style={karla}
                cursor={'pointer'}
                onClick={() => navigate('/pages/careers')}
              >
                Careers
              </Text>
              <Text
                style={karla}
                cursor={'pointer'}
                onClick={() => navigate('/pages/terms-conditions')}
              >
                Terms & Conditions
              </Text>
              <Text
                style={karla}
                cursor={'pointer'}
                onClick={() => navigate('/pages/store-locations')}
              >
                Offline Store
              </Text>

              <Stack spacing={6}>
                <Box>
                  {/* <Image onClick={() => navigate("/")} cursor="pointer" src={logoimportir} /> */}
                </Box>
                {/* <Text fontSize={"sm"} style={karla} textAlign={"center"}>
                  © 2023 Wearing Klamby
                </Text> */}
                {/* <Stack direction={"row"} spacing={6}>
                  <SocialButton label={"Twitter"} href={"https://www.facebook.com/importir.org/?locale=id_ID/"}>
                    <FaFacebook />
                  </SocialButton>
                  <SocialButton label={"YouTube"} href={"https://www.youtube.com/channel/UCt7EqweONcwImFGCjq33fNg"}>
                    <FaYoutube />
                  </SocialButton>
                  <SocialButton label={"Instagram"} href={"https://www.instagram.com/importirorg/"}>
                    <FaInstagram />
                  </SocialButton>
                </Stack> */}
              </Stack>
            </Stack>

            <Stack align={'flex-start'} fontSize='sm'>
              <Text
                textTransform={'uppercase'}
                letterSpacing={'0.2em'}
                style={ovo}
                fontSize={'xs'}
              >
                Customer Support
              </Text>

              <Text style={karla}>Klamby Family</Text>
              <Text style={karla}>About Points</Text>
              <Text style={karla}>FAQ</Text>
            </Stack>

            <Stack align={'flex-start'} fontSize='sm'>
              <Text
                textTransform={'uppercase'}
                style={ovo}
                letterSpacing={'0.2em'}
                fontSize={'xs'}
              >
                Social
              </Text>

              <Text style={karla}>Tokopedia</Text>
              <Text style={karla}>Instagram</Text>
              <Text style={karla}>Facebook</Text>
              <Text style={karla}>Youtube</Text>
              <Text style={karla}>Tiktok</Text>
            </Stack>

            <Stack align={'flex-start'} fontSize='sm' spacing={5}>
              <Stack>
                <Text
                  textTransform={'uppercase'}
                  style={ovo}
                  letterSpacing={'0.2em'}
                  fontSize={'xs'}
                >
                  Sign up dan save
                </Text>

                <Text style={karla}>
                  Subscribe to get special offers, free giveaways, and
                  once-in-a-lifetime deals.
                </Text>
              </Stack>
              <InputGroup color={'white'} style={karla}>
                <InputLeftElement>
                  <MdOutlineEmail size={22} />
                </InputLeftElement>
                <Input
                  rounded={0}
                  style={karla}
                  fontSize='sm'
                  borderWidth={0.5}
                  color='white'
                  borderColor='gray.300'
                  defaultValue={'Enter your email'}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Button
                  style={ovo}
                  rounded={0}
                  bgColor={'#c8996ed1'}
                  color={'white'}
                  fontSize={'sm'}
                  size='xs'
                  p={5}
                >
                  Subscribe
                </Button>
              </InputGroup>
            </Stack>
          </SimpleGrid>

          <Stack w='full' display={{ base: 'block', md: 'none' }}>
            <Accordion
              allowToggle
              w={'full'}
              borderColor={'rgba(142,109,70,0.1)'}
            >
              {dataFooter.map((x, i) => (
                <AccordionItem
                  key={i}
                  isDisabled={x.name === 'Social Media' ? true : false}
                >
                  <h2>
                    <AccordionButton
                      paddingInlineStart={0}
                      paddingInline={0}
                      style={{ WebkitPaddingStart: 0, WebkitPaddingEnd: 0 }}
                    >
                      <HStack spacing={5} w='full' my={3}>
                        {/* <Icon as={x.icon} boxSize={isDesktop ? 5 : 7} /> */}

                        <Text
                          textTransform={'uppercase'}
                          fontWeight={500}
                          fontSize='sm'
                          noOfLines={1}
                          letterSpacing={'0.2em'}
                          style={ovo}
                        >
                          {x.name}
                        </Text>

                        <Spacer />
                        {x.submenu && <AccordionIcon />}
                      </HStack>
                    </AccordionButton>
                    <Divider />
                  </h2>
                  {x.submenu ? (
                    <>
                      <AccordionPanel
                        paddingInlineStart={0}
                        paddingInline={0}
                        style={{ WebkitPaddingStart: 0, WebkitPaddingEnd: 0 }}
                      >
                        <Stack>
                          {x.submenu?.map((subitem, i) => (
                            <Link to={subitem.link} key={i}>
                              <HStack spacing='3'>
                                <>
                                  <Text
                                    fontWeight={500}
                                    noOfLines={1}
                                    style={karla}
                                    textTransform={'capitalize'}
                                  >
                                    {subitem.name}
                                  </Text>
                                </>
                              </HStack>
                            </Link>
                          ))}
                        </Stack>
                      </AccordionPanel>
                    </>
                  ) : (
                    <>{null}</>
                  )}
                </AccordionItem>
              ))}
            </Accordion>
            <Stack align={'flex-start'} fontSize='sm' py={5}>
              <Text
                textTransform={'uppercase'}
                style={ovo}
                letterSpacing={'0.2em'}
                fontSize={'sm'}
              >
                Sign up dan save
              </Text>

              <Text style={karla}>
                Subscribe to get special offers, free giveaways, and
                once-in-a-lifetime deals.
              </Text>
              <InputGroup>
                <InputLeftElement>
                  <MdOutlineEmail />
                </InputLeftElement>
                <Input
                  rounded={0}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Button
                  rounded={0}
                  style={ovo}
                  bgColor={'#9b6c3fd1'}
                  color={'white'}
                  fontSize={'sm'}
                  p={5}
                >
                  Subscribe
                </Button>
              </InputGroup>
            </Stack>
          </Stack>
          <Text fontSize={'xs'} style={karla} textAlign={'center'} mt={10}>
            © 2023 Wearing Klamby
          </Text>
        </Container>
      </Stack>
    </Box>
  );
}

SocialButton.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  href: PropTypes.string,
};


ListHeader.propTypes = {
  children: PropTypes.node,
}