/* eslint-disable no-console */
import { useEffect, useRef } from 'react';
import { Stack } from '@chakra-ui/react';
import Layout from './Layouts';
import MainRouter from './Router/MainRouter';
import AuthRouter from './Router/AuthRouter';

import {
  arrayUnionFirebase,
  getCollectionFirebase,
  setDocumentFirebase,
} from './Apis/firebaseApi';
import { UseAuthStore } from './Hooks/Zustand/store';
// import Preloader from './Components/Loading/Preloader';
import { useLocation } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, fetchToken } from './Config/firebase';
import { initializeAnalytics } from './Utils/pixelUtils';
import LoadingOverlay from './Components/Loading/LoadingOverlay';
import MicrositePage from './Pages/Microsites/MicrositePage';

function App() {
  const location = useLocation();
  const globalState = UseAuthStore();
  const company = 'ffBBNgi1CODzR9xxU2Ro';
  const project = 'bKEB41GUP7kHzHmpMXjn';

  const contactRef=  useRef('')

  const updateContact = async (user) => {
    const conditions = [
      { field: 'projectId', operator: '==', value: project },
      { field: 'email', operator: '==', value: user?.email },
    ];
    const sortBy = { field: 'createdAt', direction: 'asc' };
    const limitValue = 1;

    try {
      const res = await getCollectionFirebase(
        'crm_contacts',
        conditions,
        sortBy,
        limitValue
      );

      globalState.setContactId(res[0].id || res[0].contactsId)
      contactRef.current = res[0].id || res[0].contactsId

      if (res?.length === 0) {

        const dataContact = {
          email: user?.email,
          name: user?.displayName,
          phoneNumber: user?.phoneNumber,
          createdAt: new Date(),
          lastUpdated: new Date(),
          lastLogin: new Date(),
          ltv: 0,
          aov: 0,
          trx: 0,
          contactId: res?.contactId,
          companyId: company,
          projectId: project,
        };

        await setDocumentFirebase('crm_contacts', res?.contactId, dataContact);
      } else {
        await setDocumentFirebase(
          'crm_contacts',
          res[0]?.id || res[0]?.contactId,
          { ...res[0], lastLogin: new Date() }
        );
      }
     
    } catch (error) {
      throw new Error(error.message);
    }
  };


  const uploadTokenToFirebase = async (token, user) => {
    if (token !== '') {
      const collectionName = 'users';
      const docName = user?.uid;
      const field = 'tokenId';
      const values = [token];

      try {
        await arrayUnionFirebase(collectionName, docName, field, values);
      } catch (error) {
        console.log(error, 'Failed to send  error message');
      }
    }
  };


  


  useEffect(() => {


    onAuthStateChanged(auth, async(user) => {
      if(user){

        const tokenFirebase = await user.getIdToken(true);
        globalState.setAuthFirebaseToken(tokenFirebase);
        
        globalState.setIsLoggedIn(true);
        globalState.setUser(user);
        globalState.setUid(user.uid);
        globalState.setName(user.displayName);
        globalState.setEmail(user.email);
        

        localStorage.setItem('tokenFirebase', JSON.stringify(tokenFirebase));
        localStorage.setItem('user', JSON.stringify(user.uid));
        localStorage.setItem('isLoggedIn', JSON.stringify(true));

        await updateContact(user);


        const token = await fetchToken();

        if (token) {
          await uploadTokenToFirebase(token, user);
        }


       
      } else {
        globalState.setIsLoggedIn(false);
      }
      
    })
  
    return () => {}
  }, [])

  console.log(location.pathname)
  

  useEffect(() => {
    const analytics = initializeAnalytics();

    analytics.page({
      name: globalState?.name || '',
      email: globalState?.email || '',
      projectId: globalState?.currentProject || '',
      companyId: globalState?.companyId || '',
    });
  }, [location.pathname]);

  return (
    <>
      {location.pathname.includes('/m/') ? (
        <>
          <MicrositePage />
        </>
      ):(
        <>
          <Stack>
            <LoadingOverlay />
            {location.pathname && (
              <Layout>
                <MainRouter />
                <AuthRouter />
              </Layout>
            )}
          </Stack>
        </>
      )}
    </>

  );
}

export default App;
