import {
  Box,
  Button,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Spacer,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { checkLoginData } from '../../Hooks/Middleware/UserMiddleWare';

import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { auth, db } from '../../Config/firebase';
import { ovo } from '../../Theme/theme';
import { UseAuthStore } from '../../Hooks/Zustand/store';
import {
  getCollectionFirebase,
  getSingleDocumentFirebase,
  setDocumentFirebase,
} from '../../Apis/firebaseApi';
import { addLoginPixel } from '../../Utils/pixelUtils';
import { doc, setDoc } from 'firebase/firestore';

function LoginPage() {
  // const project = '2h3DLK7iKAXRNNZS7PLR';
  const globalState = UseAuthStore();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [dataAccount, setDataAccount] = useState({
    email: '',
    password: '',
  });

  const toast = useToast();
  const navigate = useNavigate();

  const handleAddData = (e) => {
    const { id, value } = e.target;
    setDataAccount({ ...dataAccount, [id]: value });
  };

  const handleCheckContacts  = async () => {
    try {
      const conditions = [
        { field: 'projectId', operator: '==', value: globalState?.currentProject },
        { field: 'email', operator: '==', value: dataAccount?.email }
      ];

      const checkOnContact = await getCollectionFirebase(
        'crm_contacts',
        conditions
      );

      return checkOnContact;

    } catch (error) {
      throw new Error(error)
    }
  };

  const handleCheckUsers = async (uid) => {
    try {
      const checkOnUsers = await getSingleDocumentFirebase('users', uid);

      if (
        checkOnUsers?.phoneNumber &&
        checkOnUsers?.gender &&
        checkOnUsers?.dateofbirth
      ) {
        return { status: true, data: checkOnUsers };
      } else {
        return { status: false, data: null };
      }

    } catch (error) {
      throw new Error(error)
    }
  };

  const createLoginContacts = async (data, error) => {
    // console.log(data)
    try {
      setLoading(true);

      if(error.message === 'Firebase: Error (auth/user-not-found).'){
        if(data?.email){
          createUserWithEmailAndPassword(auth, data?.email, dataAccount?.password)
            .then(async (userCredential) => {
              const user = userCredential.user;
              const dataUser = {
                email: user.email || '',
                name: data?.name || '',
                gender: '',
                dateofbirth: '',
                phoneNumber: '',
                companyId: globalState?.currentCompany,
                projectId: globalState?.currentProject,
                tokenId: [],
              };

              if (user) {
                await setDoc(
                  doc(db, 'users', user?.uid),
                  dataUser
                );

                globalState?.setEmail(user?.email)
                globalState?.setPhone(user?.phoneNumber || '')

                navigate('/data-email-verifications');
              }
              setLoading(false);
            })
            .catch((error) => {
              toast({
                title: 'Something Wrong',
                description: `${error.message}`,
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'bottom',
              });
              setLoading(false);
            });
        } else {
          // if(error.message !== 'Firebase: Error (auth/user-not-found).'){
          navigate('/signup')
          // }
        }
      }
    } catch (error) {
      toast({
        title: 'Something Wrong',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom',
      });
      setLoading(false);
      setLoading(false);
    }
  };

  const handleLogin = async () => {
    //penjagaan jika input kosong
    let contactData

    //penjagaan jika input kosong
    const validationResult = checkLoginData(dataAccount);
    if (!validationResult.success) {
      toast(validationResult.error);
      return;
    }
    setLoading(true);
    try {
      setLoading(true);

      //check input email di contact
      const checkContacts = await handleCheckContacts()
      // console.log(checkContacts)

      if(checkContacts?.length > 0){
        globalState.setContactId(checkContacts[0]?.id)
        contactData = checkContacts[0]
      }

      //login user
      const userCredential = await signInWithEmailAndPassword(
        auth,
        dataAccount?.email,
        dataAccount?.password,
      );


      const user = userCredential.user;

      if (user) {
        globalState.setAuthFirebaseToken(user.accessToken);
        globalState.setIsLoggedIn(true);
        globalState.setUid(user.uid);
        globalState.setName(user?.displayName);
        globalState.setEmail(user?.email)
        globalState.setPhone(user?.phoneNumber)

        // if (
        //   user?.uid == 'RHJZeANnfcNSok4S4hMi5MXdz2k1' ||
        //   user?.uid == 'inTx5f8SG7Rzry8oXSMqfqEZ1mD2' ||
        //   user?.uid == 'vExYhr3l6xavl0AXvr1WNUncZCp1'
        // ) {
        //   globalState.setIsTrial(true);
        // }

        if(checkContacts?.length === 0){
          const dataContact = {
            name: user?.displayName,
            email: user.email || '',
            phoneNumber: user?.phoneNumber || '',
            companyId: globalState?.currentCompany,
            projectId: globalState?.currentProject,
          };

          //bikin contact baru untuk project klamby
          await setDocumentFirebase('crm_contacts', `${globalState?.currentProject}-${user?.phoneNumber || user?.uid}`, dataContact)
        }

        addLoginPixel({...globalState, name:user.displayName, email: user?.email, phone:user?.phoneNumber, uid: user.uid, contactId: contactData?.id });

        globalState.setName(user?.displayName);
        globalState.setEmail(user?.email)
        globalState.setPhone(user?.phoneNumber)

        // if (
        //   user?.uid == 'RHJZeANnfcNSok4S4hMi5MXdz2k1' ||
        //   user?.uid == 'inTx5f8SG7Rzry8oXSMqfqEZ1mD2' ||
        //   user?.uid == 'vExYhr3l6xavl0AXvr1WNUncZCp1'
        // ) {
        //   globalState.setIsTrial(true);
        // }

        if(checkContacts?.length === 0){
          const dataContact = {
            name: user?.displayName,
            email: user.email || '',
            phoneNumber: user?.phoneNumber || '',
            companyId: globalState?.currentCompany,
            projectId: globalState?.currentProject,
          };

          //bikin contact baru untuk project klamby
          await setDocumentFirebase('crm_contacts', `${globalState?.currentProject}-${user?.phoneNumber || user?.uid}`, dataContact)
          // console.log(createResult, 'ini result')
        }

        //analytics
        // addLoginPixel({...globalState, name:user.displayName, email: user?.email, phone:user?.phoneNumber, uid: user.uid, contactId: contactData?.id });

        toast({
          title: 'Login Successful',
          description: `You have successfully logged in as ${userCredential.user.email} `,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }

      await handleCheckUsers(user?.uid);

      // if (!checkUser?.status) {
      //   return navigate('/data-email-verifications');
      // }

      // if (!checkUser?.status) {
      //   return navigate('/data-email-verifications');
      // }

      // console.log(auth?.currentUser?.emailVerified)

      // check if user email is verified
      if (auth?.currentUser?.emailVerified) {
        navigate('/');
        localStorage.clear();
      } else {
        if(checkContacts?.length > 0){
          navigate('/data-email-verifications');
        } else {
          navigate('/email-verifications');
        }
      }


      // window.location.reload()
    } catch (error) {
      await createLoginContacts(contactData, error)
      
      if(!contactData?.email){
        toast({
          title: 'Error',
          description:
              error.message === 'Firebase: Error (auth/user-not-found).'
                ? 'Wrong email or password. Please try again.'
                : 'You do not have an account, please sign up.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } else {
        if(error.message === 'Firebase: Error (auth/wrong-password).'){
          toast({
            title: 'Error',
            description: 'Wrong email or password. Please try again.',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
      }
      // }
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const height = window.innerHeight;

  return (
    <>
      <Stack
        minH={height / 1.2}
        bgColor='white'
        bgSize='cover'
        alignItems={'center'}
        justifyContent='center'
      >
        <Stack>
          <Stack
            justifyContent='center'
            shadow={'md'}
            alignItems='center'
            spacing={5}
            bgColor='white'
            p={10}
            borderRadius='xl'
          >
            <Box>
              <Text fontSize={'3xl'} style={ovo}>
                Login
              </Text>
            </Box>
            <Spacer />
            <Stack spacing={5} alignItems={'center'} justifyContent='center'>
              <Stack>
                <Text textTransform={'uppercase'} style={ovo}>
                  Email
                </Text>
                <InputGroup w={'350px'}>
                  <Input
                    // placeholder="email"
                    borderRadius={'sm'}
                    fontSize={'md'}
                    bgColor={'white'}
                    color={'black'}
                    id='email'
                    onChange={(e) => handleAddData(e)}
                  />
                </InputGroup>
              </Stack>

              <Stack>
                <HStack>
                  <Text
                    textTransform={'uppercase'}
                    textAlign='start'
                    style={ovo}
                  >
                    Password
                  </Text>
                  <Spacer />
                  <Text
                    textTransform={'capitalize'}
                    textAlign='start'
                    fontSize='sm'
                    cursor={'pointer'}
                    onClick={() => navigate('/forgot-password')}
                  >
                    Forgot Password?
                  </Text>
                </HStack>

                <InputGroup w={'350px'}>
                  <Input
                    w={{
                      base: '100%',
                      md: '100%',
                    }}
                    // placeholder="password"
                    borderRadius={'sm'}
                    fontSize={'md'}
                    type={showPassword ? 'text' : 'password'}
                    bgColor={'white'}
                    color={'black'}
                    id='password'
                    onChange={(e) => handleAddData(e)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleLogin();
                      }
                    }}
                  />
                  <InputRightElement>
                    {showPassword ? (
                      <MdVisibilityOff
                        size={20}
                        color='black'
                        onClick={togglePasswordVisibility}
                        style={{ cursor: 'pointer' }}
                      />
                    ) : (
                      <MdVisibility
                        size={20}
                        color='black'
                        onClick={togglePasswordVisibility}
                        style={{ cursor: 'pointer' }}
                      />
                    )}
                  </InputRightElement>
                </InputGroup>
              </Stack>
            </Stack>

            <Stack pt={5} w='full'>
              <Button
                borderRadius={'sm'}
                style={ovo}
                isLoading={loading}
                alignItems={'center'}
                justifyContent='center'
                bgColor={'#8e6d46'}
                onClick={() => handleLogin()}
              >
                <Text color={'white'}>Sign in</Text>
              </Button>
            </Stack>

            <Spacer />

            <HStack
              space={1}
              w='full'
              alignItems='flex-start'
              justifyContent={'flex-start'}
            >
              <Text
                color={'gray.600'}
                fontSize={['xs', null, 'sm']}
                cursor='pointer'
                onClick={() => navigate('/signup')}
              >
                Create Account
              </Text>
            </HStack>
          </Stack>
          <Spacer />
        </Stack>
      </Stack>
    </>
  );
}

export default LoginPage;
