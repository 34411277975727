import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Checkbox,
  Flex,
  HStack,
  Heading,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { UseAuthStore } from '../../../Hooks/Zustand/store';
import FormAddressComponent from './FormAddressComponent';
import { BsPlusLg } from 'react-icons/bs';
import PropTypes from 'prop-types';
import { themeColor } from '../../../Theme/theme';

const DeliveryAddressComponent = ({
  handleToggle,
  defaultAddress,
  setDefaultAddress,
  addresses,
  setToggleModal,
  isChecked,
  setIsChecked,
  selectedAddress,
  
}) => {
  const globalState = UseAuthStore();

  const handleNewAddress = (item) => {
    if (globalState?.isLoggedIn && defaultAddress) {
      setToggleModal(true);
    } else {
      selectedAddress(item);
    }
  };
  return (
    <Stack id='address'>
      {globalState?.isLoggedIn && defaultAddress ? (
        <Stack id='ship_to'>
          <Accordion
            border={'transparent'}
            allowToggle
            w={'100%'}
            defaultIndex={[0]}
          >
            <AccordionItem>
              <AccordionButton
                display={'flex'}
                justifyContent={'space-between'}
                px={0}
              >
                <Stack textAlign={'start'} px={2}>
                  <Text fontSize={'20'} color={themeColor}>
                    Ship to
                  </Text>
                  <HStack>
                    <Text textTransform={'capitalize'}>
                      {defaultAddress?.receiver?.toUpperCase()},{' '}
                      {defaultAddress?.address?.street},{' '}
                      {defaultAddress?.address?.subdistrict_name},{' '}
                      {defaultAddress?.address?.city}
                    </Text>
                    <Text></Text>
                  </HStack>
                </Stack>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel px={0} w={'100%'}>
                <RadioGroup
                  colorScheme='orange'
                  w={'100%'}
                  value={defaultAddress?.id}
                  onChange={(value) => {
                    setDefaultAddress({ ...defaultAddress, id: value });
                  }}
                >
                  {addresses?.map((x) => (
                    <Flex
                      key={x.id}
                      justify={'space-between'}
                      align={'center'}
                      py={3}
                      onClick={() => setDefaultAddress({ ...x, id: x.id })}
                      cursor={'pointer'}
                    >
                      <Radio
                        value={x.id}
                        size={'lg'}
                        minW={'100%'}
                        isChecked={defaultAddress?.id === x.id}
                      >
                        <Flex justifyContent={'space-between'} minW={'100%'}>
                          <Box w={'auto'}>
                            <Text fontSize={16} as={'b'}>
                              {x.receiver.toUpperCase()}
                            </Text>
                            <Text fontSize={16} textTransform={'capitalize'}>
                              {x.address.street}
                            </Text>
                            <Text fontSize={16}>
                              {x.address.subdistrict_name}
                              {', '}
                              {x.address.postal_code}
                            </Text>
                            <Text fontSize={16} textTransform={'capitalize'}>
                              {x.address.city}
                              {', '}
                              {x.address.state}
                              {', '}
                              {x.address.country}
                            </Text>
                          </Box>
                        </Flex>
                      </Radio>
                    </Flex>
                  ))}
                </RadioGroup>

                {/* {addNewAddress ? (
                  <FormAddressComponent formAddress={handleNewAddress} />
                ) : ( */}
                <HStack
                  pt={2}
                  cursor={'pointer'}
                  onClick={() =>
                    handleToggle('Add address', null, 'add address')
                  }
                >
                  <BsPlusLg color={themeColor} fontWeight={'md'} />
                  <Text color={themeColor}>Add new address</Text>
                </HStack>
                {/* )} */}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Stack>
      ) : (
        <Stack id='delivery'>
          <Heading fontSize={'2xl'} fontWeight={'medium'}>
            Delivery
          </Heading>
          <Box pt={3}>
            <FormAddressComponent formAddress={handleNewAddress} />
          </Box>
          <Checkbox
            py={2}
            colorScheme='orange'
            isChecked={isChecked.saveInfo}
            onChange={(e) => {
              setIsChecked({ ...isChecked, saveInfo: e.target.checked });
              // setNewAddress({ ...newAddress, isChecked: e.target.value });
            }}
          >
            Save this information for next time
          </Checkbox>
        </Stack>
      )}
    </Stack>
  );
};

DeliveryAddressComponent.propTypes = {
  handleToggle: PropTypes.func,
  defaultAddress: PropTypes.object,
  setDefaultAddress: PropTypes.func,
  addresses: PropTypes.array,
  toggleModal: PropTypes.func,
  setToggleModal: PropTypes.func,
  isChecked: PropTypes.object,
  setIsChecked: PropTypes.func,
  selectedAddress: PropTypes.func,
};

export default DeliveryAddressComponent;
