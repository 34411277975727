/* eslint-disable no-console */
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Image,
  Input,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  Textarea,
  useMediaQuery,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PriceTag } from '../../Components/PriceTag/PriceTag';
import { karla, ovo } from '../../Theme/theme';
import { UseAuthStore } from '../../Hooks/Zustand/store';
import { BsTrash3 } from 'react-icons/bs';
import {
  getSingleDocumentFirebase,
  setDocumentFirebase,
} from '../../Apis/firebaseApi';
import PropTypes from 'prop-types';
import VoucherApplyComponent from '../Checkout/Components/VoucherApplyComponent';
import { formatFrice } from '../../Utils/numberUtil';

// import InputQuantityComponent from './Component/InputQuantityComponent';

const themeColor = '#8e6d46';

const CartPage = () => {
  const globalState = UseAuthStore();
  const navigate = useNavigate();
  const [allCart, setAllCart] = useState([]);
  let total = 0;
  const [isDesktop] = useMediaQuery('(min-width: 1280px)');
  const [inputValues, setInputValues] = useState([]);
  const [voucher, setVoucherPrice] = useState(0);
  const [price, setPrice] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const totalPrice = useRef(0);
  const [voucherId, setVoucherId] = useState('');
  const [voucherUsed, setVoucherUsed] = useState({});
  const [totalAll, setTotalAll] = useState(0);

  const handleIncrement = async (index, max) => {
    if (inputValues[index] < max) {
      setInputValues((prevValues) => {
        const newValues = [...prevValues];
        newValues[index] += 1;
        if (newValues !== undefined) {
          handleLocal(newValues);
        } else console.log('new values is undefined');
        return newValues; // return newValues to setInputValues
      });
    }
  };

  const handleDecrement = (index) => {
    if (inputValues[index] > 1) {
      setInputValues((prevValues) => {
        const newValues = [...prevValues];
        newValues[index] -= 1;
        if (newValues !== undefined) {
          handleLocal(newValues);
        } else console.log('new values is undefined');
        return newValues;
      });
    }
  };

  const handleDelete = (item, index) => {
    try {
      const getLocal = JSON.parse(localStorage.getItem('addToCart'));
      if (getLocal || item?.variantData) {
        const removeIndex = index;
        getLocal?.splice(removeIndex, 1);
        handleUpdateUserCart(getLocal);
        handleSetLocalCart();
        localStorage.setItem('addToCart', JSON.stringify(getLocal));
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleLocal = (newValues) => {
    const arrCarts = [];

    if (allCart) {
      allCart.map((x, i) => {
        arrCarts.push({
          ...x.variantData,
          qty: newValues[i],
        });
      });
    }

    if (globalState?.uid) {
      handleUpdateUserCart(arrCarts);
    }
  };

  const handleUpdateUserCart = async (cart) => {
    try {
      const docId = globalState.contactId;
      await setDocumentFirebase('crm_cart', docId, {
        cart: cart,
      });
    } catch (error) {
      console.log(error.message, 'ini handle error');
    }
  };

  const handleNote = (note) => {
    const getNote = localStorage.getItem('note');
    if (getNote) {
      localStorage.removeItem('note');
    }
    localStorage.setItem('note', note);
  };

  const handleSetLocalCart = async () => {
    try {
      let cartData;

      if (globalState?.email) {
        const docId = globalState?.contactId;
        const getFirebase = await getSingleDocumentFirebase('crm_cart', docId);
        cartData = getFirebase?.cart || [];
      } else {
        const localCart = localStorage.getItem('addToCart');
        cartData = localCart ? JSON.parse(localCart) : [];
      }

      const filterCart = cartData.filter(
        (x) => x?.id !== null || x?.id !== undefined
      );
      const mapProduct = await Promise.all(
        filterCart.map(async (x) => {
          const res = await getSingleDocumentFirebase(
            'crm_product',
            x.productId
          );
          const dataAll = {
            variantData: x,
            variantId: x.variantId,
            productData: res,
            productId: res.id,
            quantity: x.qty,
          };
          return dataAll;
        })
      );
      setInputValues(mapProduct.map((x) => x.quantity));

      setAllCart(mapProduct || []);

      // setTotalAll(total);
    } catch (error) {
      console.log(
        error.message,
        'failed set local from firebase or localStorage'
      );
    }
  };

  // const handleDiscount = (type, value, price) => {
  //   if (type === 'percentage') {
  //     const total = price - (price * value) / 100;
  //     return total;
  //   } else if (type === 'value') {
  //     const total = price - value;
  //     return total;
  //   }
  // };

  useEffect(() => {
    handleSetLocalCart();

    return () => {};
  }, []);

  useEffect(() => {
    setQuantity(inputValues.reduce((a, b) => a + b, 0));
    totalPrice.current = total - (voucher ? voucher : 0);
    setTotalAll(totalPrice.current);
  }, [inputValues, total, voucher]);

  const InputQuantity = ({ i }) => {
    return (
      <HStack gap={0}>
        <Button
          rounded={0}
          size='md'
          variant='ghost'
          onClick={() => handleDecrement(i)}
        >
          -
        </Button>
        <Input
          variant='unstyled'
          textAlign='center'
          w={[10, 50, 50, 50]}
          rounded={0}
          value={inputValues[i]}
          onChange={(e) => {
            const value = parseInt(e.target.value, 10);
            if (!isNaN(value) && value >= 1 && value <= 6) {
              setInputValues((prevValues) => {
                const newValues = [...prevValues];
                newValues[i] = value;
                return newValues;
              });
            }
          }}
          size='md'
        />
        <Button
          rounded={0}
          size='md'
          variant='ghost'
          onClick={() => handleIncrement(i, 6)}
        >
          +
        </Button>
      </HStack>
    );
  };

  InputQuantity.propTypes = {
    i: PropTypes.number,
  };

  return (
    <Flex justify={'center'} align={'center'} mb={10}>
      <Stack
        gap={isDesktop ? 10 : 5}
        mt={isDesktop ? 5 : 0}
        w={isDesktop ? '70%' : '100%'}
      >
        <Box textAlign={'center'}>
          <Heading textTransform={'capitalize'} size='lg' style={ovo}>
            Cart
          </Heading>
          <Text
            style={karla}
            textDecor={'underline'}
            cursor={'pointer'}
            onClick={() => navigate('/collections/all')}
          >
            Continue shopping
          </Text>
        </Box>
        <HStack
          textTransform={'uppercase'}
          w='100%'
          gap={'8%'}
          justify={'end'}
          display={isDesktop ? 'flex' : 'none'}
        >
          <Text
            style={{ ...ovo, fontWeight: 500 }}
            fontSize={'md'}
            letterSpacing={'0.2em'}
            textTransform={'uppercase'}
          >
            quantity
          </Text>
          <Text
            style={{ ...ovo, fontWeight: 500 }}
            fontSize={'md'}
            letterSpacing={'0.2em'}
            textTransform={'uppercase'}
          >
            total
          </Text>
        </HStack>
        <Divider />
        {allCart.map((x, i) => {
          total += x?.productData?.sale_price_int
            ? x?.productData?.sale_price_int * inputValues[i]
            : inputValues[i] * x?.variantData?.price_int ||
              inputValues[i] * parseInt(x?.variantData?.price);
          return (
            <HStack w={'100%'} gap={isDesktop ? 10 : 5} key={i}>
              <Image
                aspectRatio={2 / 3}
                w={[90, 150, 150, 150]}
                src={x?.variantData?.image}
                cursor={'pointer'}
                onClick={() =>
                  navigate(`/collections/all/product/${x?.productId}`)
                }
              />

              <Stack w={isDesktop ? '60%' : '100%'} wrap={'wrap'}>
                <Box justifyContent={'start'}>
                  <Text
                    as={'b'}
                    style={karla}
                    flexWrap={'wrap'}
                    fontSize={20}
                    onClick={() =>
                      navigate(`/collections/all/product/${x?.productId}`)
                    }
                  >
                    {x?.productData?.name?.split('- ')[1] ||
                      x?.productData?.name?.split('| ')[1] ||
                      x?.productData?.name}
                  </Text>
                  <Text
                    textTransform={'uppercase'}
                    style={karla}
                    fontSize={18}
                    color={'gray'}
                  >
                    {x.variantData?.name?.split(' - ')[2] ||
                      x.variantData?.name?.split(' - ')[1] ||
                      x.variantData?.name}
                  </Text>
                  <BsTrash3
                    color='#8e6d46'
                    my={5}
                    cursor={'pointer'}
                    onClick={() => handleDelete(x, i)}
                  />
                </Box>
                {!isDesktop && (
                  <HStack justify={'space-between'} w='100%'>
                    <InputQuantity i={i} />
                    {/* <InputQuantityComponent i={i} getAddToCart={() => {getAddToCart()}} handleLocal={(item) => {handleLocal(item)}} /> */}
                    <Box>
                      {x?.productData?.sale_price_int ? (
                        <Text as={'s'} color={'gray.500'} style={karla}>
                          <PriceTag
                            price={
                              x?.variantData?.price_int * inputValues[i] ||
                              parseInt(x?.variantData?.price) * inputValues[i]
                            }
                            currency={'IDR'}
                            text={karla}
                          />
                        </Text>
                      ) : (
                        <></>
                      )}
                      <Text
                        color={x?.productData?.sale_price_int ? 'red' : 'black'}
                      >
                        <PriceTag
                          price={
                            x?.productData?.sale_price_int
                              ? x?.productData?.sale_price_int * inputValues[i]
                              : x?.variantData?.price_int * inputValues[i] ||
                                parseInt(x?.variantData?.price) * inputValues[i]
                          }
                          currency={'IDR'}
                          text={karla}
                        />
                      </Text>
                    </Box>
                  </HStack>
                )}
              </Stack>
              {isDesktop && (
                <>
                  <InputQuantity i={i} />
                  {/* <InputQuantityComponent i={i} getAddToCart={() => {getAddToCart()}} handleLocal={(item) => {handleLocal(item)}} input={inputValues}/> */}
                  <Box>
                    {x?.productData?.sale_price_int ? (
                      <Text as={'s'} color={'gray.500'} style={karla}>
                        <PriceTag
                          price={
                            x?.variantData?.price_int * inputValues[i] ||
                            parseInt(x?.variantData?.price) * inputValues[i]
                          }
                          currency={'IDR'}
                          text={karla}
                        />
                      </Text>
                    ) : (
                      <></>
                    )}
                    <Text
                      color={x?.productData?.sale_price_int ? 'red' : 'black'}
                    >
                      <PriceTag
                        price={
                          x?.productData?.sale_price_int
                            ? x?.productData?.sale_price_int * inputValues[i]
                            : x?.variantData?.price_int * inputValues[i] ||
                              parseInt(x?.variantData?.price) * inputValues[i]
                        }
                        currency={'IDR'}
                        text={karla}
                      />
                    </Text>
                  </Box>
                </>
              )}
            </HStack>
          );
        })}
        <Divider />
        <SimpleGrid columns={isDesktop ? 2 : 1} gap={isDesktop ? 10 : 5}>
          <FormControl>
            <FormLabel
              fontSize={'sm'}
              style={ovo}
              textTransform={'uppercase'}
              letterSpacing={'0.2em'}
            >
              order note
            </FormLabel>
            <Textarea
              minH={isDesktop ? '16vH' : '5vH'}
              style={karla}
              defaultValue={localStorage.getItem('note')}
              onChange={(e) => handleNote(e.target.value)}
            />
          </FormControl>
          <Stack gap={5}>
            <VoucherApplyComponent
              dataCart={allCart}
              voucherId={(e) => setVoucherId(e)}
              voucherUsed={(voucher) => setVoucherUsed(voucher)}
              quantity={quantity}
              price={total}
              setPrice={(item) => setPrice(item)}
              setVoucher={(e) => setVoucherPrice(e)}
            />

            {voucher ? (
              <>
                <Stack>
                  <Flex justify={'space-between'} fontSize={'sm'}>
                    <Text>Subtotal</Text>
                    <PriceTag price={total} currency={'IDR'} text={karla} />
                  </Flex>
                  <Flex justify={'space-between'} fontSize={'sm'}>
                    <Text>Discount voucher</Text>
                    <Text color={'red'}>
                      <PriceTag
                        price={voucher ? voucher : 0}
                        currency={'IDR'}
                        text={karla}
                      />
                    </Text>
                  </Flex>
                </Stack>
              </>
            ) : (
              <></>
            )}
            <HStack>
              <Text
                textTransform={'uppercase'}
                style={ovo}
                letterSpacing={'0.2em'}
              >
                total
              </Text>
              <Spacer />
              {voucher ? (
                <HStack>
                  <Text
                    fontWeight={500}
                    style={karla}
                    textDecor={'line-through'}
                  >
                    <PriceTag price={total} currency={'IDR'} text={karla} />
                  </Text>
                  <Text fontWeight={500} style={karla} color={'red'}>
                    <PriceTag price={totalAll} currency={'IDR'} text={karla} />
                  </Text>
                </HStack>
              ) : (
                <Text fontWeight={500} style={karla}>
                  <PriceTag price={total} currency={'IDR'} text={karla} />
                </Text>
              )}
            </HStack>
            <Text align={'center'} style={karla} fontSize={'sm'}>
              Shipping, taxes, and discount codes calculated at checkout.
            </Text>
            <Button
              rounded={0}
              bgColor={themeColor}
              color='white'
              size={'lg'}
              style={ovo}
              colorScheme='none'
              _hover={{ bgColor: 'rgba(0, 0, 0, 0.8)' }}
              onClick={() => navigate('/checkout')}
            >
              Checkout
            </Button>
          </Stack>
        </SimpleGrid>
      </Stack>
    </Flex>
  );
};

export default CartPage;
