import { HStack, Text, useColorModeValue as mode } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export function formatPrice(value, opts = {}) {
  const { locale = 'en-US', currency = 'USD' } = opts;
  const formatter = new Intl.NumberFormat(locale, {
    currency,
    style: 'currency',
    maximumFractionDigits: 0,
  });
  return formatter.format(value);
}

export const PriceTag = (props) => {
  const {
    price,
    currency,
    salePrice,
    rootProps,
    priceProps,
    salePriceProps,
    color,
    text,
  } = props;
  return (
    <HStack spacing='1' {...rootProps}>
      <Price
        isOnSale={!!salePrice}
        textProps={priceProps}
        color={color}
        text={text}
      >
        {formatPrice(price, {
          currency,
        })}
      </Price>
      {salePrice && (
        <SalePrice {...salePriceProps}>
          {formatPrice(salePrice, {
            currency,
          })}
        </SalePrice>
      )}
    </HStack>
  );
};
const Price = (props) => {
  const { isOnSale, children, textProps, color, text } = props;
  // const defaultColor = mode('gray.700', 'gray.400')
  // const onSaleColor = mode('gray.400', 'gray.700')
  // const color = isOnSale ? onSaleColor : defaultColor
  return (
    <Text
      as='span'
      // fontWeight={500}
      color={color}
      textDecoration={isOnSale ? 'line-through' : 'none'}
      style={text}
      {...textProps}
    >
      {children}
    </Text>
  );
};
const SalePrice = (props) => (
  <Text
    as='span'
    fontWeight='semibold'
    color={mode('gray.800', 'gray.100')}
    {...props}
  />
);

PriceTag.propTypes = {
  price: PropTypes.number,
  currency: PropTypes.string,
  salePrice: PropTypes.number,
  rootProps: PropTypes.object,
  priceProps: PropTypes.object,
  salePriceProps: PropTypes.object,
  color: PropTypes.string,
  text: PropTypes.object,
};

Price.propTypes = {
  isOnSale: PropTypes.bool,
  children: PropTypes.node,
  textProps: PropTypes.object,
  color: PropTypes.string,
  text: PropTypes.object,
};
