import { Box } from '@chakra-ui/layout';
import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';


const CloseSubMenuOverlay = ({ isActive, closeSubMenu }) => {
  const refMenuOverlay = useRef();
  function onMouseMenuOverlayEnterHanler() {
    closeSubMenu();
  }

  useEffect(() => {
    if (refMenuOverlay.current) {
      refMenuOverlay.current.addEventListener(
        'mouseenter',
        onMouseMenuOverlayEnterHanler
      );
    }

    return () => {
      if (refMenuOverlay.current) {
        refMenuOverlay.current.removeEventListener(
          'mouseenter',
          onMouseMenuOverlayEnterHanler
        );
      }
    };
  }, []);

  return (
    <Box
      ref={refMenuOverlay}
      position='fixed'
      pointerEvents={isActive ? 'auto' : 'none'}
      w='100vw'
      h='100vh'
      zIndex={999}
    ></Box>
  );
};

CloseSubMenuOverlay.propTypes = {
  isActive: PropTypes.bool.isRequired,
  closeSubMenu: PropTypes.func.isRequired,
}

export default CloseSubMenuOverlay;
