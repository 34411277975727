/* eslint-disable no-console */
/* eslint-disable no-undef */
import React, { useEffect, useRef, useState } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Card,
  CardHeader,
  Center,
  Flex,
  HStack,
  Heading,
  Icon,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  SimpleGrid,
  Stack,
  StackDivider,
  Text,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { TbDeviceDesktopShare } from 'react-icons/tb';
import HeaderCheckoutComponent from './Components/HeaderCheckoutComponent';
import FooterCheckoutComponent from './Components/FooterCheckoutComponent';
import { karla, textMuted, themeColor } from '../../Theme/theme';
import { UseAuthStore } from '../../Hooks/Zustand/store';
import {
  getCollectionFirebase,
  getSingleDocumentFirebase,
  updateDocumentFirebase,
} from '../../Apis/firebaseApi';
import OrderSummaryComponent from './Components/OrderSummaryComponent.jsx';
import ShippingMethod from './Components/ShippingMethod';
import AddressForm from '../Setting/Components/FormAddress';
import PaymentMethod from '../Payments/PaymentMethod';
import logo_monogram from '../../Assets/Image/Klamby/Monogram - white.png';
import {
  AddPaymentInfoPixel,
  addPaymentInfo,
} from '../../Utils/pixelUtils';
import ListOrderDesktop from './Components/ListOrderDesktopComponent.jsx';
import AccountComponent from './Components/AccountComponent.jsx';
import {
  checkoutMidtrans,
  shippingCostOExpress,
} from '../../Apis/checkoutAPI.js';
import DeliveryAddressComponent from './Components/DeliveryAddressComponent.jsx';
import BillingAddressComponent from './Components/BillingAddressComponent.jsx';

const CheckoutPage = () => {
  const globalState = UseAuthStore();
  const toast = useToast();
  const [isDesktop] = useMediaQuery('(min-width: 1100px)');

  const [isChecked, setIsChecked] = useState({ notify: true, saveInfo: false });
  const [user, setUser] = useState({});
  const [userAddress, setUserAddress] = useState([]);
  const [billingAddress, setBillingAddress] = useState('');
  const [userIsDefault, setUserIsDefault] = useState({});
  const [totalWeightInCart, setTotalWeightInCart] = useState({});
  const [newAddress, setNewAddress] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);
  const [dataShipment, setDataShipment] = useState({});
  const [allCart, setAllCart] = useState([]);
  const [payment, setPayment] = useState(null);
  const [warehouses, setWarehouses] = useState({});
  const [voucherUsed, setVoucherUsed] = useState({});
  const [discount, setDiscount] = useState('');
  const [pointUsed, setPointUsed] = useState({});
  const [shippingCost, setShippingCost] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [sumTotal, setSumTotal] = useState(0)
  const totalAll = useRef(0)

  const [modal, setModal] = useState({
    label: '',
    item: null,
    mode: '',
  });
  const [toggelModal, setToggleModal] = useState(false);
  // let total = 0;

  const fetchDataUser = async () => {
    try {
      const getData = await getCollectionFirebase(
        `users/${globalState?.uid}/address`
      );
      const getUser = await getSingleDocumentFirebase(
        'crm_contacts',
        globalState.contactId
      );

      if (getData && getUser) {
        setUser(getUser);
        setUserAddress(getData);
        const findDefault = getData.find((x) => x.isDefault === true);
        setUserIsDefault(findDefault);
        setNewAddress({
          address: findDefault?.address,
          email: findDefault?.email,
          subdistrict_id: findDefault?.address?.subdistrict_id,
          phone_number: findDefault?.phone_number,
        });
      }
      return getUser
    } catch (error) {
      console.log(error);
    }
  };

  const handleWarehouse = async () => {
    try {
      const getWarehouse = await getSingleDocumentFirebase(
        'crm_warehouse',
        globalState?.currentProject
      );
      return getWarehouse ? setWarehouses(getWarehouse) : null;
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleShippingCost = async () => {
    try {
      const body = {
        origin_id: parseInt(warehouses?.oExpress),
        destination_id:
          userIsDefault?.address?.subdistrict_id ||
          newAddress?.address?.subdistrict_id,
        total_weight: totalWeightInCart, //gram
        force_oexpress: false,
      };
      setIsLoading(true);
      const shippingCost = await shippingCostOExpress(body);

      if (shippingCost?.status) setShippingCost(shippingCost?.data);
      // total = totalPrice + shippingCost?.data?.data?.shippingPrice;
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };


  const handleToggle = (label, item, mode) => {
    setModal({
      label: label,
      item: item,
      mode: mode,
    });
    setToggleModal(true);
  };

  const handleBillingAddress = () => {};

  const handleSelectedAddress = () => {
    try {
      const findId = userAddress?.find((x) => x.id === userIsDefault.id);
      if (findId?.address) {
        setNewAddress({
          address: findId?.address,
          email: findId?.email,
          subdistrict_id: findId?.address?.subdistrict_id,
          phone_number: findId?.phone_number,
        });
        setUserIsDefault(findId);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const getAddToCart = async () => {
    try {
      let cartData;
      
      if (globalState.email) {
        const docId = globalState?.contactId;
        const getFirebase = await getSingleDocumentFirebase('crm_cart', docId);
        cartData = getFirebase?.cart || [];
      } else {
        const localCart = localStorage.getItem('addToCart');
        cartData = localCart ? JSON.parse(localCart) : [];
      }
    
      const mapProduct = await Promise.all(
        cartData?.map(async (x) => {
          const res = await getSingleDocumentFirebase('crm_product', x?.productId);
          const dataAll = {
            variantData: x,
            variantId: x?.variantId,
            productData: res,
            productId: res?.id,
            quantity: x?.qty
          };
          return dataAll;
        })
      );

      const arrTotal = [];
      const weightTotal = [];
      mapProduct?.map((x) => {
        const price = x?.productData?.sale_price_int
          ? x?.productData?.sale_price_int * x?.quantity
          : x?.variantData?.price_int * x?.quantity ||
          parseInt(x?.variantData?.price) * x?.quantity;
        const weights = x?.productData?.weight * x.quantity;
        arrTotal.push(price);
        weightTotal.push(weights);
      });
      const sumPrice = arrTotal.reduce((a, b) => a + b, 0);
      const sumWeight = weightTotal.reduce((a, b) => a + b, 0);

      setAllCart(mapProduct);
      setTotalPrice(sumPrice);
      setTotalWeightInCart(sumWeight);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleUpdateCartAfterCheckout = async () => {
    try {
      const dataCart = await getSingleDocumentFirebase('crm_cart', user.id);
      if (dataCart) {
        const filteredCart = dataCart?.carts?.filter((dataCart) => {
          return !allCart.some(
            (allCart) =>
              dataCart.variantId === allCart.variantId &&
              dataCart.productId === allCart.productId
          );
        });
        await updateDocumentFirebase('crm_cart', user.id, {
          carts: filteredCart,
          cart: dataCart,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };


  const handleCreateOrder = async () => {
    let labelError = '';
    if (!newAddress?.email) {
      labelError = 'Email can not be empty';
    } else if (!newAddress?.phone_number) {
      labelError = 'Phone can not be empty';
    } else if (!newAddress?.subdistrict_id) {
      labelError = 'Subdistrict can not be empty';
    } else if (!payment) {
      labelError = 'Please select payment method first';
    }
    if (
      !newAddress?.email ||
      !newAddress?.phone_number ||
      !newAddress?.subdistrict_id ||
      !payment
    ) {
      toast({
        title: 'Please fulfill your contact informations',
        description: labelError,
        duration: 3000,
        status: 'error',
        position: 'bottom',
      });

      return null;
    }

    const item = allCart.map((x) => {
      // warehouses.find((y) => y.id === x.productData.warehouseId);
      return {
        productId: x.productId,
        variantId: x.variantId,
        quantity: x.quantity,
        message: localStorage.getItem('note') || '',
      };
    });

    const address = {
      country: userIsDefault?.address?.country || newAddress?.country,
      fullAddress: [
        userIsDefault?.address?.street || newAddress?.address?.street,
        userIsDefault?.address?.subdistrict_name || newAddress?.address?.subdistrict_name,
        userIsDefault?.address?.state || newAddress?.address?.state,
        userIsDefault?.address?.country || newAddress?.address?.country,
        userIsDefault?.address?.postal_code || newAddress?.address?.postal_code,
      ]
        .filter(Boolean).join(', '),
    };

    const cleanData = {
      body: {
        discountId: discount || null, // ini berlaku sebagai voucher id
        userType: 'customer',
        userId: globalState?.uid || '',
        companyId: globalState?.currentCompany || '',
        projectId: globalState?.currentProject || '',
        paymentGateway: 'midtrans',

        shipping_provider: {
          provider:
            dataShipment?.provider === 'oexpress'? 'oExpress' : dataShipment?.provider,
          service: dataShipment?.service,
          from: warehouses?.oExpress,
          to: newAddress?.subdistrict_id?.toString(),
        },
        module: 'crm',
        is_production: true,
        items: item,
        contact_information: {
          email: newAddress?.email || userIsDefault?.email,
          name: newAddress?.receiver || userIsDefault?.receiver,
          phone_number: newAddress?.phone_number || userIsDefault?.phone_number,
          address: address || newAddress?.address,
        },
        contactId:
          globalState?.contactId || globalState?.currentProject + '-' + newAddress?.phone_number,
        totalAmount: sumTotal,
        redeemValue: pointUsed?.status ? pointUsed?.value : 0,
        feeId: payment?.payment_type?.key || '',
        getPointUsingVoucher: !voucherUsed?.detailVoucher?.getPointUsingVoucher ? true : voucherUsed?.detailVoucher?.getPointUsingVoucher,
      },
    };

    try {
      globalState?.setIsLoading(true);
      const createOrder = await checkoutMidtrans(cleanData);

      // console.log(createOrder)
      if (createOrder?.data?.status) {
        toast({
          title: 'New order created',
          description: 'please complete the payment',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });

        if (globalState?.isLoggedIn) {
          await handleUpdateCartAfterCheckout();
        }

        // handleUseVoucher(voucherUsed);
        localStorage.removeItem('note');
        localStorage.removeItem('addToCart');
        globalState?.setIsLoading(false);

        //analytics
        addPaymentInfo(cleanData?.body, createOrder?.data?.order_id);

        return window.location.href = createOrder?.data?.payment_url;
        // return navigate(`/payment/${createOrder?.data?.data?.orderId}`);
      } else {
        toast({
          title: 'Failed to create new order',
          description: 'Failed',
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
        globalState?.setIsLoading(false);
      }

      // addPurchasePixel(totalPrice,'IDR', globalState.currentProject)
      
    } catch (error) {
      globalState?.setIsLoading(false);
      console.log(error.message);
    }
  };

  const handleCreatePayment = (item) => {
    try {
      setPayment(item);
      setToggleModal(!toggelModal);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getAddToCart();
    if (globalState?.isLoggedIn) {
      fetchDataUser();
      setIsChecked({ notify: true, saveInfo: false });
    }
  }, []);

  useEffect(() => {
    handleSelectedAddress();
    return () => {}
  }, [userIsDefault])
  

  useEffect(() => {
    handleWarehouse();
    handleShippingCost();
  }, [totalWeightInCart, userIsDefault, newAddress]);

  useEffect(() => {
    if (globalState?.isLoggedIn) {
      totalAll.current = totalPrice + dataShipment?.price - (voucherUsed?.amountValue || 0) - (pointUsed?.status ? pointUsed?.value : 0);
      setSumTotal(totalAll.current < 0 ? 0 : totalAll.current);
    } else {
      totalAll.current = totalPrice + dataShipment?.price - (voucherUsed?.amountValue || 0);
      setSumTotal(totalAll.current < 0 ? 0 : totalAll.current);
    }
  }, [voucherUsed, discount, dataShipment?.price, pointUsed, voucherUsed?.amountValue]);


  return (
    <Box>
      <HeaderCheckoutComponent />
      <SimpleGrid
        columns={isDesktop ? 2 : 1}
        templateColumns={isDesktop ? '60% 40%' : '100%'}
        minH={'100vh'}
        pos={'relative'}
      >
        <Stack
          px={10}
          my={5}
          gap={2}
          divider={<StackDivider borderColor={'1px solid rgb(233,233,233)'} />}
        >
          <Stack px={70} gap={8}>
            <AccountComponent
              setEmail={(email) => setNewAddress({ ...newAddress, email })}
              setIsChecked={() => setIsChecked({ ...isChecked, notify: true })}
              isChecked={isChecked}
            />
            <DeliveryAddressComponent
              handleToggle={(label, item, mode) =>
                handleToggle(label, item, mode)
              }
              defaultAddress={userIsDefault}
              setDefaultAddress={(item) => setUserIsDefault(item)}
              addresses={userAddress}
              setToggleModal={(e) => setToggleModal(e)}
              setIsChecked={() => setIsChecked({ ...isChecked, notify: true })}
              isChecked={isChecked}
              selectedAddress={(e) => setNewAddress(e)}
            />
            

            <Stack id='shipping_method'>
              {globalState.isLoggedIn ? (
                <Stack id='shipping_method_login'>
                  <Accordion
                    border={'transparent'}
                    allowToggle
                    w={'100%'}
                    defaultIndex={[0, 1]}
                  >
                    <AccordionItem>
                      <AccordionButton
                        display={'flex'}
                        justifyContent={'space-between'}
                        px={0}
                      >
                        <Stack textAlign={'start'}>
                          <Text fontSize={'20'} color={themeColor}>
                            Shipping method
                          </Text>
                          {/* <Text>{userIsDefault?.address?.street}</Text> */}
                        </Stack>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel px={0}>
                        <ShippingMethod
                          warehouses={warehouses}
                          newAddress={newAddress}
                          setNewAddress={setNewAddress}
                          dataShipment={(item) => setDataShipment(item)}
                          shippingCost={shippingCost}
                          isLoading={isLoading}
                          setIsLoading={(e) => setIsLoading(e)}
                        />
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </Stack>
              ) : newAddress?.subdistrict_id ? (
                <Stack id='shipping_method_not_login'>
                  <Heading fontSize={'2xl'} fontWeight={'medium'} py={2}>
                    Shipping method
                  </Heading>
                  <ShippingMethod
                    warehouses={warehouses}
                    shippingAddress={newAddress}
                    dataShipment={(item) => setDataShipment(item)}
                    shippingCost={shippingCost}
                    isLoading={isLoading}
                    setIsLoading={(e) => setIsLoading(e)}
                  />
                </Stack>
              ) : (
                <Stack id='shipping_method_not_login'>
                  <Heading fontSize={'2xl'} fontWeight={'medium'} py={2}>
                    Shipping method
                  </Heading>
                  <Box
                    bg={'rgba(0,0,0,.045)'}
                    color={textMuted}
                    py={4}
                    rounded={6}
                  >
                    <Text color={'rgba(0,0,0,0.56)'} px={3}>
                      Enter your shipping address to view available shipping
                      methods.
                    </Text>
                  </Box>
                </Stack>
              )}
            </Stack>

            <Stack id='payment_method'>
              <Stack>
                <Heading fontSize={'2xl'} fontWeight={'medium'}>
                  Payment
                </Heading>
                <Text color={textMuted}>
                  All transactions are secure and encrypted.
                </Text>
              </Stack>
              <Box>
                {payment === null ? (
                  <>
                    <Box
                      p={5}
                      border={`1px solid ${themeColor}`}
                      roundedTop={5}
                      bgColor={'#FCF5F0'}
                    >
                      <Text>Payments via Midtrans</Text>
                    </Box>
                    <Box
                      px={[5, 100, 100, 100]}
                      py={5}
                      border={'1px solid rgba(0,0,0,.045)'}
                      textAlign={'center'}
                      roundedBottom={5}
                      bgColor={'rgba(0,0,0,.045)'}
                      cursor={'pointer'}
                      onClick={() => handleToggle('Payment', null, 'payment')}
                    >
                      <Icon
                        as={TbDeviceDesktopShare}
                        fontSize={'lg'}
                        boxSize={'3.7142857142857144em'}
                      />
                      <Text>
                        {
                          'After clicking “Pay now”, you will be redirected to Payments via Midtrans to complete your purchase securely.'
                        }
                      </Text>
                    </Box>
                  </>
                ) : (
                  <>
                    <Card
                      p={5}
                      border={`1px solid ${themeColor}`}
                      roundedTop={5}
                      bgColor={themeColor}
                      rounded={0}
                    >
                      <Image
                        boxSize='40px'
                        src={logo_monogram}
                        alt='Monogram White'
                      />
                    </Card>
                    <Card roundedTop={0} shadow={0}>
                      <CardHeader>
                        <Flex w={'100%'} justifyContent={'space-between'}>
                          <Stack w={'full'}>
                            <Heading
                              fontSize={'xl'}
                              fontWeight={'medium'}
                              color={textMuted}
                              textTransform={'Uppercase'}
                            >
                              {payment?.selectedType}
                            </Heading>
                            <HStack>
                              {payment?.payment_type?.logo.map((y, i) => (
                                <Box
                                  key={i}
                                  w={'80px'}
                                  h={'40px'}
                                  display={'flex'}
                                  alignItems={'center'}
                                >
                                  <Image src={y} />
                                </Box>
                              ))}
                              <Text color={themeColor} style={karla}>+ admin {payment?.payment_type?.title}</Text>
                            </HStack>
                          </Stack>
                          <Center>
                            <IconButton
                              size='lg'
                              variant='ghost'
                              color='#8e6d46'
                              aria-label='open menu'
                              icon={<BsThreeDotsVertical size={20} />}
                              alignItems={'center'}
                              _hover={'none'}
                              onClick={() =>
                                handleToggle('Payment', null, 'payment')
                              }
                            />
                          </Center>
                        </Flex>
                      </CardHeader>
                    </Card>
                  </>
                )}
              </Box>
            </Stack>

            <Stack id='billing_address'>
              <BillingAddressComponent
                billingAddress={billingAddress}
                setBillingAddress={(item) => setBillingAddress(item)}
                handleBillingAddress={handleBillingAddress}
              />
            </Stack>
            {!isDesktop && (
              <OrderSummaryComponent
                shippingPrice={dataShipment?.price}
                user={user}
                dataCart={allCart}
                voucherUsed={(item) => setVoucherUsed(item)}
                voucherId={(item) => setDiscount(item)}
                pointsRedeem={(point) => setPointUsed(point)}
              />
              
            )}

            <Button
              bgColor={themeColor}
              size={'lg'}
              color={'white'}
              onClick={handleCreateOrder}
            >
              Pay Now
            </Button>
          </Stack>

          <FooterCheckoutComponent />
        </Stack>

        {isDesktop && (
          <ListOrderDesktop
            dataCart={allCart}
            shippingPrice={dataShipment?.price}
            user={user}
            voucherUsed={(item) => {setVoucherUsed(item)}}
            voucherId={(item) => setDiscount(item)}
            pointsRedeem={(point) => setPointUsed(point)}
          />
        )}
      </SimpleGrid>

      <Modal isOpen={toggelModal} onClose={() => setToggleModal(!toggelModal)}>
        <ModalOverlay />
        <ModalContent minW={'60%'}>
          <ModalBody>
            {modal.mode === 'add address' ? (
              <AddressForm
                cancel={() => setToggleModal(!toggelModal)}
                item={modal.item}
                label={modal.label}
                user={globalState?.uid}
                fetchData={() => fetchDataUser()}
              />
            ) : (
              <></>
            )}
            {modal.mode === 'payment' ? (
              <PaymentMethod
                cancel={() => setToggleModal(!toggelModal)}
                total={totalPrice}
                submitPayment={(item) => handleCreatePayment(item)}
              />
            ) : (
              <></>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default CheckoutPage;
