export const dataUserAddress = {
  name: 'John Doe',
  country: 'Indonesia',
  addresses: [
    {
      firstName: 'John',
      lastName: 'Doe',
      company: 'PT. Edrus',
      address1:
        ' Metland Tangerang, Jl. Puri Utama No.7, RT.004/RW.008, Petir, Kec. Cipondoh, Kota Tangerang, Banten 15147',
      city: 'Tangerang',
      country: 'Indonesia',
      province: 'Banten',
      postal: 15147,
      phone: '085775032912',
      default: true,
    },
    {
      firstName: 'Jane',
      lastName: 'Doe',
      company: 'Importir.co',
      address1:
        ' Jl. Puri Utama No.7, RT.004/RW.008, Petir, Kec. Cipondoh, Kota Tangerang, Banten 15147',
      city: 'Tangerang',
      country: 'Indonesia',
      province: 'Banten',
      postal: 15147,
      phone: '08211198721',
      default: false,
    },
  ],
};

export const dataUser = {
  firstName: 'John',
  lastName: 'Doe',
  company: 'PT. Edrus',
  address1:
    ' Metland Tangerang, Jl. Puri Utama No.7, RT.004/RW.008, Petir, Kec. Cipondoh, Kota Tangerang, Banten 15147',
  city: 'Tangerang',
  country: 'Indonesia',
  province: 'Banten',
  postal: 15147,
  phone: '085775032912',
  default: true,
  email: 'johndoe@email.co',
};

export const dataDiscountLog = [
  // {
  //   id: 1,
  //   userId: 'RHJZeANnfcNSok4S4hMi5MXdz2k1',
  //   name: 'nabiilah',
  //   email: 'nabiilah@importir.co',
  //   discountId: 'UUHVobbG8Tg2yCnGBBFb',
  // },
  // {
  //   id: 4,
  //   userId: 'RHJZeANnfcNSok4S4hMi5MXdz2k1',
  //   name: 'nabiilah',
  //   email: 'nabiilah@importir.co',
  //   discountId: 'UUHVobbG8Tg2yCnGBBFb',
  // },
  // {
  //   id: 2,
  //   userId: 'shopii',
  //   name: 'shofifi',
  //   email: 'shofifi@importir.co',
  //   discountId: 'UUHVobbG8Tg2yCnGBBFb',
  // },
  // {
  //   id: 3,
  //   userId: 'fitri',
  //   name: 'fitri',
  //   email: 'fitri@importir.co',
  //   discountId: 'UUHVobbG8Tg2yCnGBBFb',
  // }
]
