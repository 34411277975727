import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useRef, useEffect, useCallback } from 'react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import MenuItemSubMenu from './MenuItemSubMenu';
import PropTypes from 'prop-types';


function addEL(ref, event, handler) {
  if (ref) ref.addEventListener(event, handler);
}
function remEL(ref, event, handler) {
  if (ref) ref.removeEventListener(event, handler);
}

const InnerMenu = ({ title, childrenItems, handleItemClick }) => {
  const refSubMenuButton = useRef();
  const refSubMenuList = useRef();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const subMenuButtonEnterHandle = useCallback(() => {
    onOpen();
    // fix bug with list animation
    refSubMenuList.current.style.pointerEvents = 'auto';
  }, [onOpen]);
  const subMenuButtonLeaveHandle = useCallback(() => {
    onClose();
  }, [onClose]);

  // fix bug with list animation,
  // hide it when menu closed and open only if button menu is hovered
  useEffect(() => {
    if (!isOpen) {
      refSubMenuList.current.style.pointerEvents = 'none';
    }
  }, [isOpen]);

  useEffect(() => {
    addEL(refSubMenuButton.current, 'mouseenter', subMenuButtonEnterHandle);
    addEL(refSubMenuList.current, 'mouseenter', subMenuButtonEnterHandle);
    addEL(refSubMenuButton.current, 'mouseleave', subMenuButtonLeaveHandle);
    addEL(refSubMenuList.current, 'mouseleave', subMenuButtonLeaveHandle);

    return () => {
      remEL(refSubMenuButton.current, 'mouseenter', subMenuButtonEnterHandle);
      remEL(refSubMenuList.current, 'mouseenter', subMenuButtonEnterHandle);
      remEL(refSubMenuButton.current, 'mouseleave', subMenuButtonLeaveHandle);
      remEL(refSubMenuList.current, 'mouseleave', subMenuButtonLeaveHandle);
    };
  }, [subMenuButtonEnterHandle, subMenuButtonLeaveHandle]);

  return (
    <Menu autoSelect={false} offset={[0, 0]} isOpen={isOpen} placement='right'>
      <MenuButton
        textTransform={'uppercase'}
        ref={refSubMenuButton}
        rightIcon={<ChevronDownIcon />}
        as={Button}
        w='full'
        textAlign='left'
        borderRadius='none'
        bg='white'
        zIndex={999}
        _hover={{
          bg: 'blue.50',
        }}
        _focus={{
          boxShadow: 'none',
        }}
        _expanded={{
          bg: 'blue.300',
          color: 'white',
        }}
      >
        {title}
      </MenuButton>
      <MenuList
        textTransform={'capitalize'}
        minW={0}
        ref={refSubMenuList}
        zIndex={999}
        _hover={{ zIndex: 1000 }}
      >
        {childrenItems?.map((item) => {
          if (typeof item.name === 'string') {
            return (
              <MenuItem key={item} onClick={() => handleItemClick(item)}>
                {item.name}
              </MenuItem>
            );
          }
          return <MenuItemSubMenu key={item}>{item.name}</MenuItemSubMenu>;
        })}
      </MenuList>
    </Menu>
  );
};

InnerMenu.propTypes = {
  title: PropTypes.string,
  childrenItems: PropTypes.array,
  handleItemClick: PropTypes.func,
};

export default InnerMenu;
