// /* eslint-disable no-undef */
// docs untuk facebook pixel https://www.facebook.com/business/help/402791146561655?id=1205376682832142

// import { jitsuAnalytics } from "@jitsu/js";
import { jitsuAnalytics } from '@jitsu/js';
// import { jitsuClient } from "@jitsu/sdk-js";

const userData = (globalState, cookie, source) => {
  console.log(globalState)
  return {
    projectId: globalState?.currentProject || '',
    companyId: globalState?.currentCompany || '',
    name: globalState?.name || '',
    email: globalState?.email || '',
    uid: globalState?.uid || '',
    phoneNumber: globalState?.phone || '',
    contactId: globalState?.contactId || '',
    affiliate_id: cookie || '',
    source: source || ''
  };
};

const extractItems = (data) => {
  console.log(data);
  const extracted = [];
  data?.map((item) => {
    const obj = {
      id: item?.variantData?.id || item?.productData?.id || item?.id || '',
      name: item?.variantData?.name || item?.productData?.name || item?.name || '',
      category: item?.productData?.categoryId || item?.categoryId || [],
      price: item?.sale_price_int || item?.regular_price_int || parseInt(item?.price_int) || 0,
      quantity: item?.quantity || 1,
    };
    extracted.push(obj);
  });

  console.log(extracted, 'ppp')

  return JSON.stringify(extracted);
};

export function initializeAnalytics() {

  try {
    const analytics = jitsuAnalytics({
      host: 'https://clv71paoj00003b6rvrpgloiv.d.jitsu.com',
      writeKey: '',
    });
    return analytics; 
  } catch (error) {
    throw new Error(error.message)
  }
  
}

export const analytics = jitsuAnalytics({
  host: 'clv71paoj00003b6rvrpgloiv.d.jitsu.com',
  // Browser Write Key configured on Jitsu Site entity.
  // If no Browser Write Key is added for Site entity, Site ID value can be used a Write Key.
  // On Jitsu.Cloud can be omitted if Site has explicitly mapped domain name that is used in host parameter
  writeKey: '',
});

export function getCookie() {
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    const newC = c.split('=');
    if (newC[0] === 'affiliateID') {
      const dat = { affiliateId: newC[1] };
      return dat;
    }
  }
  return false;
}

const addCookie = async (type, projectId, data) => {
  const cookie = getCookie();

  let d = { type: type, projectId: projectId, aff: cookie.affiliateId };

  if (data) d = { ...d, ...data };

  //   await addSingleDocFirebase('affiliate_logs', d);
};

export function addFacebookPixel(pixelId) {
  // Create a script element
  const script = document.createElement('script');
  script.textContent = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '${pixelId}');
      fbq('track', 'PageView');
    `;
  // Append the script to the body
  document.head.appendChild(script);
}

export function addGoogleTagManager(gtmId) {
  const dataLayer = document.createElement('script');
  dataLayer.textContent = window.dataLayer = window.dataLayer || [];
  document.body.appendChild(dataLayer);

  const gTagScript = document.createElement('script');
  gTagScript.textContent = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${gtmId}');
    `;
  document.head.appendChild(gTagScript);
}

// login
// use globalState as contact
export function addLoginPixel(globalState) {
  console.log(globalState)

  if (window.fbq) window.fbq('track', 'login');
  if (window.dataLayer)
    window.dataLayer.push({
      event: 'login',
    });

  let cookie = '';
  if (getCookie()) {
    addCookie('login', globalState?.currentProject);
    cookie = getCookie()?.affiliateId;
  }

  // console.log('login', userData(globalState, cookie));
  analytics.track('login', userData(globalState, cookie, 'website_klamby'));
  analytics.identify(userData(globalState, cookie, 'website_klamby'));
}

// logout
// use globalState as contact
export function addLogoutPixel(globalState) {
  if (window.fbq) window.fbq('track', 'logout');

  if (window.dataLayer)
    window.dataLayer.push({
      event: 'logout',
    });

  let cookie = '';
  if (getCookie()) {
    addCookie('logout', globalState?.currentProject);
    cookie = getCookie()?.affiliateId;
  }

  // console.log('logout', userData(globalState, cookie));
  analytics.track('logout', userData(globalState, cookie, 'website_klamby'));
  analytics.identify(userData(globalState, cookie, 'website_klamby'));
}

//add to cart pixel
export function addATCPixel(globalState, items) {
  // console.log(items)
  // const itemsValue = items?.reduce((a, b) => a + (b?.regular_price_int || b?.sale_price_int || b?.price_int), 0)
  // console.log(itemsValue)
  if (window.fbq) window.fbq('track', 'AddToCart');

  if (window.dataLayer) window.dataLayer.push({ event: 'AddToCart' });

  let cookie = '';
  if (getCookie()) {
    addCookie('AddToCart', globalState?.currentProject);
    cookie = getCookie()?.affiliateId;
  }

  let data = userData(globalState, cookie, 'website_klamby');

  data = { ...data, items: extractItems([...items]) };

  // console.log('add to cart', data);
  analytics.track('AddToCart', data);
}

//initiate checkout
// useGlobalState as contact
export function addInitiateCheckoutPixel(globalState, dataItem) {
  if (window.fbq) window.fbq('track', 'InitiateCheckout');
  if (window.dataLayer) window.dataLayer.push({ event: 'InitiateCheckout' });

  const items = dataItem?.items
  
  let cookie = '';
  if (getCookie()) {
    addCookie('InitiateCheckout', globalState?.currentProject);
    cookie = getCookie().affiliateId;
  }

  const getData = (data) => {
    const newData = [];
    // data?.map((item) => newData.push(item.productData));
    data?.map((item) => {
      let temp = {};
      temp = item?.productData;
      temp = { ...temp, quantity: item?.quantity };
      newData.push(temp);
    });
    return newData;
  };
  const itemList = getData(items);

  let data = userData(globalState, cookie, 'website_klamby');
  data = {
    ...data,
    items: extractItems(items),
    itemCount: items?.length || 0,
    itemSum: dataItem?.totalQty || 0,
    itemValue: dataItem?.amount || 0,
  };
  console.log('checkout', data);
  analytics.track('InitiateCheckout', data);
}

export function addLeadPixel(user, res) {
  const data = res;

  if (getCookie())
    addCookie('purchase', data?.projectId, {
      value: Math.round(parseInt(data?.totalAmount)) || 0,
      currency: 'IDR',
    });

  const pushJitsu = {
    uid: data?.userId || data?.uid || '',
    userId: `${data?.projectId}-${data?.contact_information?.phone_number}` || '',
    contactId: data?.contactId || '',
    email: user?.contact_information?.email || '',
    phoneNumber: user?.contact_information?.phone_number || '',
    companyId: data?.companyId || '',
    projectId: data?.projectId || '',
    orderId: data?.orderId || '',
    value: Math.round(parseInt(data?.amount)) || 0,
    currency: 'IDR',
    itemList: extractItems(data?.items),
    status: 'PENDING',
    paidAtUnix: 0,
    createdAtUnix: Math.floor(Date.now() / 1000),
  };

  // console.log('lead', pushJitsu);
  analytics.track('purchase', pushJitsu);
}

export function addSearchPixel(globalState, query, result) {
  if (window.fbq) window.fbq('track', 'Search');
  if (window.dataLayer) window.dataLayer.push({ event: 'Search' });

  console.log(result)

  let cookie = '';
  if (getCookie()) {
    addCookie('search', globalState?.currentProject);
    cookie = getCookie().affiliateId;
  }
  console.log();

  let data = userData(globalState, cookie, 'website_klamby');
  data = {
    ...data,
    query: query || '',
    result: extractItems(result),
  };

  // console.log('search', data);
  analytics.track('search', data);
}

export function addSubscribePixel(value, currency, predicted_ltv, projectId) {
  if (window.fbq)
    window.fbq('track', 'Subscribe', {
      value: value,
      currency: currency ? currency : 'IDR',
      predicted_ltv: predicted_ltv,
    });

  if (window.dataLayer)
    window.dataLayer.push({
      event: 'Subscribe',
      value: value,
      currency: currency ? currency : 'IDR',
      predicted_ltv: predicted_ltv,
    });

  if (getCookie())
    addCookie('Subscribe', projectId, {
      value: value,
      currency: currency ? currency : 'IDR',
      predicted_ltv: predicted_ltv,
    });

  analytics.track('Subscribe', {
    projectId: projectId,
    value: value,
    currency: currency ? currency : 'IDR',
    predicted_ltv: predicted_ltv,
  });
}

export function addPurchasePixel(value, currency, projectId) {
  if (window.fbq)
    window.fbq('track', 'Purchase', {
      value: parseFloat(value),
      currency: currency ? currency : 'IDR',
    });

  if (window.dataLayer)
    window.dataLayer.push({
      event: 'Purchase',
      value: parseFloat(value),
      currency: currency ? currency : 'IDR',
    });

  if (getCookie())
    addCookie('purchase', projectId, {
      value: parseFloat(value),
      currency: currency ? currency : 'IDR',
    });

  analytics.track('purchase', {
    value: parseFloat(value),
    currency: currency ? currency : 'IDR',
  });
}

export function AddPaymentInfoPixel(user, orderId) {

  console.log(orderId)

  if (window.fbq) window.fbq('track', 'AddPaymentInfo');

  if (window.dataLayer)
    window.dataLayer.push({
      event: 'AddPaymentInfo',
    });

  console.log('addPaymentInfo', user)

  analytics.track('addPaymentInfo', {
    id: orderId,
    contactId: user?.contactId,
    phoneNumber: user?.contact_information?.phone_number || '',
    email: user?.contact_information?.email || '',
    projectId: user?.projectId,
    companyId: user?.companyId,
  });
}

export function addPaymentInfo(user, orderId) {

  if (window.fbq) window.fbq('track', 'AddPaymentInfo');

  if (window.dataLayer)
    window.dataLayer.push({
      event: 'AddPaymentInfo',
    });

  let cookie = '';

  if (getCookie()) {
    addCookie('addPaymentInfo', user?.projectId, {
      value: user?.totalAmount || 0,
      currency: 'IDR',
    });
    cookie = getCookie().affiliateId;
  }

  const pushJitsu = {
    uid: user?.userId || '',
    userId: user?.userId || '',
    contactId: user?.contactId || '',
    email: user?.contact_information?.email || '',
    phoneNumber: user?.contact_information?.phone_number || '',
    companyId: user?.companyId || '',
    projectId: user?.projectId || '',
    orderId: orderId || '',
    value: user?.totalAmount || 0,
    currency: 'IDR',
    itemList: user.items,
    status: 'PENDING',
    paidAtUnix: 0,
    createdAtUnix: Math.floor(Date.now() / 1000),
    affiliate_id: cookie || '',
  };

  console.log('payment info', pushJitsu)

  analytics.track('addPaymentInfo', pushJitsu);
}

export function addClickPixel(state, url, micrositeId ){
  const cookie = ''

  const data = userData(state, cookie, 'website_klamby');
  const newData = {
    ...data,
    link: url,
    micrositeId : micrositeId,
  };
  analytics.track('click', newData);

}