export const blog = [
  {
    img: 'https://klamby.id/cdn/shop/articles/Frame_1998_540x.jpg?v=1693620152',
    title: 'The Soul of Sambas: Weaving Heritage into Every Thread',
    tags: ['Lifestyle', 'product launch'],
    post: `
          <div class="article__body rte rte--indented-images">
               <p data-mce-fragment="1"><span style="font-weight: 400;" data-mce-fragment="1" data-mce-style="font-weight: 400;">Di dunia fashion, ada lebih dari sekadar yang terlihat. Ini bukan hanya tentang kain, potongan, dan tren - ini tentang emosi, cerita, dan hubungan yang mendalam dengan masa lalu dan masa depan kita. Inilah persis apa yang diwakili oleh Sambas Collection - perjalanan melintasi waktu, tapestri warisan, dan perayaan wanita luar biasa yang menginspirasi koleksi ini.</span></p>
               <p data-mce-fragment="1"><b data-mce-fragment="1">Delving into the Heart of the Collection</b></p>
               <p data-mce-fragment="1"><span style="font-weight: 400;" data-mce-fragment="1" data-mce-style="font-weight: 400;">Bayangkan setiap benang sebagai bisikan dari generasi masa lalu, ditenun menjadi pola yang menceritakan kisah tradisi, kekuatan, dan semangat abadi ibu yang telah membuka jalan. Koleksi Sambas, lahir dari keinginan untuk menghormati narasi ini, mencerminkan harmoni antara kemarin, hari ini, dan esok.</span></p>
               <p data-mce-fragment="1"><b data-mce-fragment="1">Embodying the Stories of Mothers</b></p>
               <p data-mce-fragment="1"><span style="font-weight: 400;" data-mce-fragment="1" data-mce-style="font-weight: 400;">Ibu - mereka adalah pilar keluarga, pembawa kisah, dan pencipta warisan. Setiap potongan dalam Koleksi Sambas beresonansi dengan esensi wanita luar biasa ini. Dari lipatan yang anggun pada sebuah gaun hingga detail rumit pada tunik, setiap elemen mencerminkan kehidupan berbagai sisi yang mereka jalani - kelembutan, kekuatan, dan keanggunan yang mendefinisikan mereka.</span></p>
               <p><b>A Bridge to Our Heritage</b></p>
               <p><span style="font-weight: 400;">Koleksi Sambas bukan sekadar pakaian; ini adalah jembatan yang menghubungkan kita dengan warisan para ibu terdahulu. Ini adalah ode untuk para ibu yang telah menjahit cinta, perhatian, dan budaya ke setiap sudut kehidupan kita. Pola digital, terinspirasi dari tenun tradisional Sambas, membawa cerita kerajinan Indonesia, mencampurkan yang lama dan yang baru dalam tarian seni yang memikat.</span></p>
               <p><b>More than Material, an Emotion</b></p>
               <p><span style="font-weight: 400;">Ketika Anda mengenakan potongan dari Koleksi Sambas, Anda tidak hanya menghiasi diri dengan kain - Anda merangkul perasaan. Anda menyelipkan diri ke dalam cerita mereka yang datang sebelumnya, dan mereka yang akan melanjutkan warisan. Ini bukan hanya tentang terlihat indah; ini tentang merasa terhubung, merasa diberdayakan, dan merasa menjadi bagian dari sesuatu yang lebih besar dari diri kita sendiri.</span></p>
               <p><b>A Call to Embrace</b></p>
               <p><span style="font-weight: 400;">Ketika Koleksi Sambas mengungkapkan dirinya, kami mengundang Anda untuk merasakan jiwanya. Rasakan bisikan tradisi, gema tawa ibu, dan kehangatan ikatan keluarga. Dengan setiap jahitan yang dirajut dengan teliti, dengan setiap pola yang dirancang dengan penuh pemikiran, koleksi ini menjadi perwujudan cinta, ketahanan, dan kekuatan abadi wanita.</span></p>
               <p><b>Conclusion</b></p>
               <p><span style="font-weight: 400;">Koleksi Sambas lebih dari sekadar pakaian; ini adalah pengalaman, penghormatan, dan perayaan wanita yang membentuk dunia kita. Saat Anda merangkul potongan-potongan Sambas, ingatlah bahwa Anda tidak hanya mengenakan mode - Anda mengenakan warisan, Anda mengenakan cerita, dan Anda mengenakan esensi para ibu yang menginspirasi kita setiap hari.</span></p>
               <p><span style="font-weight: 400;">Jadi, masuklah ke dalam Koleksi Sambas, di mana setiap benang adalah saksi masa lalu, penghormatan pada saat ini, dan janji untuk masa depan.</span></p>
               <p data-mce-fragment="1"><span style="font-weight: 400;" data-mce-fragment="1" data-mce-style="font-weight: 400;">&nbsp;</span></p>
          </div>`,
    createdAt: new Date(),
  },
  {
    img: 'https://klamby.id/cdn/shop/articles/ediy_540x.jpg?v=1692947775',
    title: 'Sambas Series: Gabungan Tradisi Bersejarah dengan Seni Digital',
    tags: ['Lifestyle'],
    post: `<div class="column" data-mce-fragment="1">
          <p data-mce-fragment="1"><span data-mce-fragment="1">Ketika kebudayaan Indonesia yang penuh warna dan kaya tradisi bertemu dengan ekspresi modern, lahirlah koleksi terbaru kami, Seri Sambas. Terinspirasi dari tekstil Sambas yang ikonik, koleksi baru Klamby ini berhasil menggabungkan teknik tenun tradisional dengan desain digital yang mutakhir. Yuk, ikuti perjalanan menarik koleksi Seri Sambas kita, di mana masa lalu bertemu masa kini.</span></p>
          <p data-mce-fragment="1">&nbsp;</p>
          <div title="Page 15" class="page">
               <div class="section">
                    <div class="layoutArea">
                         <div class="column">
                              <p><strong>Warisan Nusantara Tenun Sambas </strong></p>
                              <p><span>Lebih dari sekadar kain, tekstil Sambas adalah cerita. Setiap motif dan warna pada tekstil Sambas menggambarkan kehidupan Indonesia dengan segala tradisinya, ritual, dan hubungannya dengan alam.</span></p>
                              <p>&nbsp;</p>
                              <div title="Page 16" class="page">
                                   <div class="section">
                                        <div class="layoutArea">
                                             <div class="column">
                                                  <p><strong>Interpretasi Modern untuk Wanita Kontemporer </strong></p>
                                                  <p><span>Di Klamby, kita menghargai tradisi tetapi juga mengakui semangat dinamis wanita modern. Dengan Seri Sambas, kita menciptakan keseimbangan. Kita memutuskan untuk mengubah pola tenun Sambas yang tradisional menjadi tekstil digital. Hasilnya? Sebuah koleksi yang menghormati esensi Sambas tapi juga sesuai dengan estetika global modern.</span></p>
                                                  <p>&nbsp;</p>
                                                  <p><strong>Koleksi: Elegan yang sesungguhnya </strong></p>
                                                  <p><span>Mulai dari blus, tunik, Outer, Skirt dan gaun yang elegan untuk acara spesial hingga kemeja pria yang matching, Seri Sambas menawarkan berbagai pilihan. Tambahan spesial adalah hijab polos kami yang warnanya cocok sempurna dengan koleksi ini, memastikan tampilan yang utuh.</span></p>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              <p>&nbsp;</p>
                              <div title="Page 17" class="page">
                                   <div class="section">
                                        <div class="layoutArea">
                                             <div class="column">
                                                  <p><strong>Spesial untuk Klamby Family </strong></p>
                                                  <p><span>Sebagai bentuk penghargaan kami, kami menawarkan hadiah menarik untuk pembelian di atas 1 juta. Ini cara kami mengucapkan terima kasih karena telah bersama kami dalam perjalanan menarik ini, dari desa tenun tradisional hingga studio desain digital Klamby.</span></p>
                                                  <p>&nbsp;</p>
                                                  <p><strong>Kesimpulan </strong></p>
                                                  <p><span>Dengan Seri Sambas, kami di Klamby tidak hanya merayakan keindahan tekstil tetapi juga evolusi. Dengan menggabungkan yang lama dan yang baru, kami menciptakan koleksi yang pasti akan disukai oleh pecinta tradisi maupun pendukung mode modern.Yuk, bergabung dengan kami dalam peluncuran seri spesial ini dan jadilah bagian dari revolusi di mana mode tidak hanya terlihat bagus tetapi juga terasa baik, menghubungkan kita dengan akar budaya kita. </span></p>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              <p>&nbsp;</p>
                         </div>
                    </div>
               </div>
          </div>
          <p data-mce-fragment="1">&nbsp;</p>
     </div>`,
    createdAt: new Date(),
  },
  {
    img: 'https://klamby.id/cdn/shop/articles/DSC03182a_1_1_540x.png?v=1691685605',
    title:
      'Peluncuran Archipelago Scarf, Rayakan Hari Kemerdekaan Indonesia dengan Keunikan Budaya',
    tags: ['product launch'],
    post: `<div class="column" data-mce-fragment="1">
          <p data-mce-fragment="1"><span data-mce-fragment="1">[JAKARTA, INDONESIA] - 12 Agustus 2023 - </span><span data-mce-fragment="1">Dalam rangka memperingati Hari Kemerdekaan Indonesia yang ke-78,Klamby dengan bangga mempersembahkan Archipelago Scarf, sebuah karya seni yang menggambarkan kekayaan dan keunikan budaya Indonesia. </span></p>
          <p data-mce-fragment="1"><span data-mce-fragment="1">Archipelago Scarf dirancang dengan pola-pola yang bercerita tentang kepulauan Indonesia, melibatkan elemen kapal kuno dan peta Indonesia, serta simbol-simbol lain yang mewakili rempah-rempah dari tanah air kita. Produk ini mengajak kita semua untuk menghargai dan merayakan warisan budaya yang kaya, sekaligus menghormati perjuangan yang telah dilalui bangsa ini menuju kemerdekaan. </span></p>
          <p data-mce-fragment="1"><span data-mce-fragment="1">Nadine Gaus, Creative Director berkata, "Archipelago Scarf bukan hanya tentang gaya, tetapi juga tentang merayakan identitas kita sebagai bangsa Indonesia. Kami ingin menghubungkan setiap wanita, terutama para ibu yang telah memberikan kontribusi besar bagi negeri ini, dengan sejarah dan budaya kita yang luar biasa".</span></p>
          <p data-mce-fragment="1"><span data-mce-fragment="1">Archipelago Scarf akan tersedia dalam 15 warna dan bisa dibeli mulai 12 Agustus 2023 dengan harga IDR 335,000. Untuk informasi lebih lanjut atau untuk melakukan pemesanan, silakan kunjungi store Klamby atau kunjungi website <a href="www.klamby.id" target="_blank" class="js-no-transition">www.klamby.id</a>.</span></p>
          <p data-mce-fragment="1">&nbsp;</p>
          <p data-mce-fragment="1">&nbsp;</p>
     </div>`,
    createdAt: new Date(),
  },
];
