import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md';

const Hero7 = ({ header, data, content }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imageData, setImageData] = useState([]);

  const dataSlider = imageData;

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? dataSlider?.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === dataSlider?.length - 1;

    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  useEffect(() => {
    const extractedImageData = data.reduce((acc, item) => {
      acc.push(item.image1, item.image2);
      return acc;
    }, []);

    setImageData(extractedImageData);
  }, [data]);

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000);

    return () => clearInterval(interval);
  }, [currentIndex]);

  return (
    <div>
      <div className='bg-amber-50 mx-auto text-center p-16'>
        <p className='mb-5'>{header || 'Join Our Journey'}</p>
        <div className='justify-center gap-5'>
          <p>
            {content ||
              'Join us in this launch and feel the spirit of our inspiring community.'}
          </p>
          <div className='flex justify-center my-3'>
            <input
              className='placeholder:text-sm py-1 px-2'
              placeholder='Enter your email'
            />
            <button className='text-center bg-amber-800 p-2 text-xs text-white'>
              Subscribe
            </button>
          </div>
        </div>
      </div>

      <div className='w-full m-auto h-[1000px] relative group border'>
        <a className='w-full' href='/' target='_blank' rel='noreferrer'>
          <div
            style={{ backgroundImage: `url(${dataSlider[currentIndex]})` }}
            className='w-full h-full bg-center bg-cover duration-500'
          ></div>
        </a>

        <div
          className='text-white absolute hidden group-hover:block bg-slate-400 bg-opacity-50 rounded-full mx-5 top-[50%] cursor-pointer'
          onClick={() => prevSlide()}
        >
          <MdNavigateBefore size={50} />
        </div>
        <div
          className='text-white absolute hidden group-hover:block bg-slate-400 bg-opacity-50  rounded-full right-0 mx-5 top-[50%] cursor-pointer'
          onClick={() => nextSlide()}
        >
          <MdNavigateNext size={50} />
        </div>
      </div>
    </div>
  );
};

Hero7.propTypes = {
  header: PropTypes.string,
  data: PropTypes.array,
  content: PropTypes.string,
};

export default Hero7;
