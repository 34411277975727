/* eslint-disable react/prop-types */

import { Box, Button, Image, Text } from '@chakra-ui/react';
import { UseAuthStore } from '../../../Hooks/Zustand/store';
import MicrositeBackgroundView from './MicrositeBackgroundView';
import { readMore2 } from '../../../Utils/stringUtils';
import { addClickPixel } from '../../../Utils/pixelUtils';


function MicrositeDisplayView({ data }) {
  const globalState = UseAuthStore();

  console.log(data)

  const handleViewLink = (url) => {
    const link = url.startsWith('https://') ? url : 'https://' + url;
    if (link) {
      window.open(link);
    }

    addClickPixel(
      globalState,
      link,
      data?.id,
    );
  };

  return (
    <>
      <Box w={'full'} position={'sticky'} top={0} zIndex={'1'}>
        <MicrositeBackgroundView background={data?.background} />
      </Box>
      <Box
        position={'absolute'}
        w="full"
        display="flex"
        flexDirection="column"
        alignItems={'center'}
        justifyContent={'center'}
        py={'12'}
        px={'6'}
        color={`${data?.background?.textColor}`}
        zIndex="2"
      >
        <Image
          alt='img'
          src={
            data?.image ||
            'https://storage.googleapis.com/deoapp-indonesia.appspot.com/asset/noimage_800x800.jpeg '
          }
          boxSize="32"
          borderRadius="full"
          objectFit="cover"
        />
        <Text fontSize="xl" fontWeight="semibold" mt="1">
          {data?.name}
        </Text>
        <Text fontSize="xs">{data?.bio}</Text>

        {data?.links?.map((item, index) =>
          item.type === 'header' && item.isShown ? (
            <Text key={index} fontSize="sm" fontWeight="semibold" mt="4">
              {item?.title}
            </Text>
          ) : item?.type === 'link' && item?.isShown ? (
            <Button
              key={index}
              display="flex"
              minHeight={{base: '12', md:'16'}}
              h={'full'}
              w={'full'}
              maxWidth="lg"
              alignItems="center"
              justifyContent="center"
              position={'relative'}
              mt="2"
              onMouseEnter={(e) => {
                e.target.style.transform = 'scale(1.03)';
              }}
              onMouseLeave={(e) => {
                e.target.style.transform = 'scale(1)';
              }}
              variant={
                data?.button?.type === 'solid'
                  ? 'solid'
                  : data?.button?.type === 'outline'
                    ? 'outline'
                    : ''
              }
              bgColor={
                data?.button?.type === 'solid' ? data?.button?.buttonColor : ''
              }
              color={`${data?.button?.textColor}`}
              borderColor={`${data?.button?.buttonColor}`}
              shadow={data?.button?.type === 'shadow' ? 'md' : ''}
              borderRadius={
                data?.button?.corner === 'soft'
                  ? 'xl'
                  : data?.button?.corner === 'round'
                    ? 'full'
                    : ''
              }
              _hover={{ transition: 'all 0.2s' }}
              onClick={() => handleViewLink(item?.link)}
              
            >
              {item?.image && <Image
                position={{base:'absolute'}}
                left={'2'}
                alt='img'
                src={
                  item?.image ||
                  'https://storage.googleapis.com/deoapp-indonesia.appspot.com/asset/noimage_800x800.jpeg '
                }
                boxSize={{base: '10', md:'12'}}
                borderRadius="full"
                objectFit="cover"
              />}
              <Text ps={{base: '12', sm: 0}} textOverflow={'hidden'} fontSize={'sm'}>{readMore2(item?.title, 30)}</Text>
            </Button>
          ) : null
        )}
      </Box>
    </>
  );
}

export default MicrositeDisplayView;
