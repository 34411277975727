import {
  Flex,
  FormControl,
  HStack,
  Input,
  Stack,
  Switch,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { karla, ovo } from '../../../Theme/theme';
import { PriceTag } from '../../../Components/PriceTag/PriceTag';
import {
  getCollectionFirebase,
  getSingleDocumentFirebase,
} from '../../../Apis/firebaseApi';
import { UseAuthStore } from '../../../Hooks/Zustand/store';
import { formatFrice } from '../../../Utils/numberUtil';
import {
  calculateDaysDifference,
  getPastFirestoreTimestamp,
} from '../../../Utils/timeUtil';
import { PropTypes } from 'prop-types';

const RedeemPointsComponents = ({ pointsRedeem }) => {
  const toast = useToast();
  const [isSwitch, setIsSwitch] = useState(false);
  const [isChangeInput, setChangeInput] = useState(false);
  const globalState = UseAuthStore();
  const [config, setConfig] = useState({});
  const [earnPoints, setEarnPoints] = useState(0);
  const [redeemPoints, setRedeemPoints] = useState(0);
  const [logPoint, setLogPoint] = useState([]);
  const [order, setOrder] = useState([]);
  const [contact, setContact] = useState({});
  const [totalPoint, setTotalPoint] = useState(0);
  let contactPoints = 0;

  const handleCustomUserPoint = (e) => {
    contactPoints = contact?.externalPoints/config?.earn >= 0 ? contact?.externalPoints/config?.earn : 0;
    const maxPoint = earnPoints - redeemPoints + contactPoints;
    if (e <= maxPoint) {
      setTotalPoint(e);
      pointsRedeem({status: isChangeInput,value: e * config?.redeem});
    } else {
      toast({
        title: 'Max point is ' + formatFrice(maxPoint),
        description: 'you can only redeem ' + formatFrice(maxPoint) + ' points',
        duration: 3000,
        status: 'error',
        isClosable: true,
        position: 'bottom',
      });
    }
  };

  const getHistoryOrder = async (config) => {
    try {
      // Fetch user external points
      const userExternalPoints = await getSingleDocumentFirebase('crm_contacts', globalState?.contactId);
      setContact(userExternalPoints);
      
      const lastTime = getPastFirestoreTimestamp(config?.expiredDays);
      const conditions = [
        { field: 'projectId', operator: '==', value: globalState?.currentProject },
        { field: 'contactId', operator: '==', value: globalState?.contactId },
        { field: 'paymentStatus', operator: '==', value: 'PAID' },
        { field: 'createdAt', operator: '>=', value: lastTime }
      ];
      const orders = await getCollectionFirebase('crm_orders', conditions);

      let totalEarn = orders.reduce((acc, order) => acc + (order?.point || 0), 0);
      contactPoints = userExternalPoints?.externalPoints/config?.earn >= 0 ? userExternalPoints?.externalPoints/config?.earn : 0;
      totalEarn += contactPoints

      setOrder(orders);
      setEarnPoints(totalEarn);
    } catch (error) {
      console.log(error.message);
    }
  };

  const getConfigPoint = async () => {
    try {
      const config = await getSingleDocumentFirebase(
        'crm_points',
        globalState?.currentProject
      );

      if (config && config?.isActive) {
        const clear = {
          earn: parseInt(config?.pointValueEarn),
          expiredDays: parseInt(config?.expiredDays),
          redeem: parseInt(config?.pointValueRedeem),
        };
        setConfig(clear);
        await getHistoryOrder(clear);
        await getLogPoint(clear);
      }
    } catch (error) {
      // eslint-disable-next-line
      console.log(error.message);
    }
  };

  const getLogPoint = async (config) => {
    if (!globalState.contactId) return;
    const lastTime = getPastFirestoreTimestamp(config?.expiredDays);
    try {
      const conditions = [
        { field: 'companyId', operator: '==', value: globalState?.currentCompany },
        { field: 'projectId', operator: '==', value: globalState?.currentProject },
        { field: 'contactId', operator: '==', value: globalState?.contactId },
        { field: 'createdAt', operator: '>=', value: lastTime }
      ];
      
      const log = await getCollectionFirebase('crm_points_log', conditions);
  
      if (log?.length > 0) {
        setLogPoint((prevLogPoint) => [...prevLogPoint, ...log]);
        const totalRedeem = log.reduce((acc, logEntry) => acc + (logEntry?.point || 0), 0);
        setRedeemPoints(totalRedeem);
      }
    } catch (error) {
      console.log(error);
    }
  };
  

  useEffect(() => {
    if (globalState?.isLoggedIn) {
      getConfigPoint();
    }
  }, []);

  useEffect(() => {
    if (globalState?.isLoggedIn) {
      // getContactInfo();
    }
  }, [config]);

  useEffect(() => {
    if (globalState?.isLoggedIn) {
      setTotalPoint(earnPoints - redeemPoints + contactPoints);
      pointsRedeem({
        status: isSwitch,
        value: (earnPoints - redeemPoints + contactPoints) * config?.redeem,
      });
    }
  }, [earnPoints, redeemPoints, isSwitch]);

  return (
    <Stack style={karla}>
      <Stack>
        <Flex
          justify={'space-between'}
          fontSize={'sm'}
          _hover={{ cursor: 'pointer' }}
          onClick={(e) => {
            setIsSwitch(e.target.checked), setChangeInput(false);
          }}
        >
          <Text as={'b'}>Buy using point</Text>
          <Switch id='reddem-point' />
        </Flex>
      </Stack>
      {isSwitch && (
        <Stack px={4} bg={'gray.100'} rounded={5} py={2}>
          <Flex justifyContent={'space-between'}>
            <Text>Total point use</Text>
            <HStack>
              {!isChangeInput ? (
                <>
                  <Text
                    cursor={'pointer'}
                    as={'underline'}
                    color={'blue.500'}
                    onClick={() => setChangeInput(!isChangeInput)}
                  >
                    Change
                  </Text>
                  <Text>{formatFrice(totalPoint)}</Text>
                </>
              ) : (
                <FormControl>
                  <Input
                    rounded={5}
                    size={'sm'}
                    bgColor={'white'}
                    type='number'
                    onChange={(e) => handleCustomUserPoint(e.target.value)}
                    placeholder='Enter point'
                  />
                </FormControl>
              )}
            </HStack>
          </Flex>
          <Flex justifyContent={'space-between'}>
            <Text>Equal to</Text>
            {!isChangeInput ? (
              <PriceTag
                price={(earnPoints - redeemPoints + contactPoints) * config?.redeem}
                currency={'IDR'}
              /> 
            ): 
              <PriceTag
                price={totalPoint * config?.redeem}
                currency={'IDR'}/>
            }
          </Flex>
        </Stack>
      )}
    </Stack>
  );
};

RedeemPointsComponents.propTypes = {
  pointsRedeem: PropTypes.number,
  isPoints: PropTypes.bool,
};

export default RedeemPointsComponents;
