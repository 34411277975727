import { theme as base } from '@chakra-ui/react';

export const fonts = {
  Ovo: `'Ovo', ${base.fonts?.heading}, sans-serif`,
  Karla: `'Karla', ${base.fonts?.body}, serif`,
};

// const theme = extendTheme({ fonts });

export const ovo = {
  fontFamily: 'Ovo',
  fontWeight: 400,
};

export const karla = {
  fontFamily: 'Karla',
  fontWeight: 400,
};

export const themeColor = '#8e6d46';
export const textMuted = 'rgba(0,0,0,0.56)';
export const transparent = { bgColor: 'rgba(142,109,70,0.8)' };
