import React from 'react';
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  HStack,
  Icon,
  useColorModeValue,
  Link,
  Text,
  useDisclosure,
  Stack,
  Accordion,
  Spacer,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  useToast,
} from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import { data } from './DataMenu';
import { UseAuthStore } from '../../Hooks/Zustand/store';


import { FaFacebook, FaInstagram, FaTiktok, FaYoutube } from 'react-icons/fa';
import MenuHeader from './MenuHeader';
import PropTypes from 'prop-types';


const karla = {
  fontFamily: 'Karla',
  fontWeight: 400,
};

export default function AppSideBar({ children }) {
  const { onOpen } = useDisclosure();
  const globalState = UseAuthStore();
  const toast = useToast();
  const location = useLocation();
  // const [desktopShow, setDesktopShow] = useState(true);
  // const isDesktop = useBreakpointValue({ base: false, lg: desktopShow });

  const handleLogout = () => {
    localStorage.clear();
    globalState.setTokens({});
    globalState.setUser({});
    globalState.setIsLoggedIn(false);

    toast({
      title: 'DapatKomisi',
      description: 'Berhasil Logout',
      status: 'success',
      isClosable: true,
      duration: 9000,
    });
  };

  // const backgroundImage = {
  //   backgroundImage: `url("https://www.dropbox.com/scl/fi/nyh2agd6dra4bz6h0axc1/1691684628965-Merah-Putih-Modern-Ilustrasi-Dirgahayu-Republik-Indonesia-Flyer-1.png?rlkey=ekdbr5n2hqr4rs6gubprjpfle&dl=0&raw=1")`,
  // };

  return (
    <Box>
      {/* <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent
            isDesktop
            onClose={onClose}
            logoSide={
              "https://klamby.id/cdn/shop/files/Logo_klamby_baru_banget_140x@2x.png?v=1643345083"
            }
          />
        </DrawerContent>
      </Drawer> */}
      <MenuHeader
        isScrolled
        onOpen={onOpen}
        handleLogout={handleLogout}
        globalState={globalState}
      />
      <Stack
        pt={
          location.pathname === '/' || location.pathname.includes('pages')
            ? 108
            : 150
        }
        w='full'
        position='relative'
      >
        {/* <Stack zIndex={100} pt={150} w='full' position='relative'> */}

        {children}
      </Stack>
    </Box>
  );
}

const SidebarContent = ({ onClose, isDesktop, ...rest }) => {

  return (
    <Box
      transition='2s ease'
      bg={useColorModeValue('white', 'gray.900')}
      borderRight='1px'
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={'full'}
      pos='fixed'
      h='full'
      overflowY='scroll'
      {...rest}
    >
      <Flex h='20' alignItems='center' mx='2' justifyContent='end'>
        {/* <Image onClick={() => navigate('/')} w='200px' cursor='pointer' src={'https://klamby.id/cdn/shop/files/Logo_klamby_baru_banget_140x@2x.png?v=1643345083'} /> */}

        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>

      <Stack w='full' p={5}>
        <Accordion allowToggle w={'full'}>
          {data.map((x, i) => (
            <AccordionItem
              key={i}
              isDisabled={x.name === 'Social Media' ? true : false}
            >
              <h2>
                <AccordionButton
                  paddingInlineStart={0}
                  paddingInline={0}
                  style={{ WebkitPaddingStart: 0, WebkitPaddingEnd: 0 }}
                >
                  <HStack spacing={5} w='full' my={3}>
                    <Icon as={x.icon} boxSize={isDesktop ? 5 : 7} />
                    {isDesktop && (
                      <Text
                        textTransform={'uppercase'}
                        fontWeight={500}
                        fontSize='lg'
                        noOfLines={1}
                        style={karla}
                      >
                        {x.name}
                      </Text>
                    )}
                    <Spacer />
                    {x.submenu && <AccordionIcon />}
                  </HStack>
                </AccordionButton>
              </h2>
              {x.submenu ? (
                <>
                  <AccordionPanel>
                    <Stack>
                      {x.submenu?.map((subitem, i) => (
                        <Link to={subitem.link} key={i}>
                          <HStack spacing='3'>
                            <Icon as={subitem.icon} boxSize={5} />
                            {isDesktop && (
                              <>
                                <Text
                                  pl={3}
                                  fontWeight={500}
                                  fontSize='sm'
                                  noOfLines={1}
                                >
                                  {subitem.name}
                                </Text>
                              </>
                            )}
                          </HStack>
                        </Link>
                      ))}
                    </Stack>
                  </AccordionPanel>
                </>
              ) : (
                <>{null}</>
              )}
            </AccordionItem>
          ))}
        </Accordion>
        <HStack gap={2} align={'left'} justify={'left'}>
          {/* <a ref={'https://www.facebook.com/importir.org/?locale=id_ID/'}> */}
          <IconButton
            size='lg'
            variant='ghost'
            // color='#8e6d46'
            aria-label='open menu'
            icon={<FaFacebook size={25} />}
          />
          {/* </a> */}

          {/* <a href={'https://www.youtube.com/channel/UCt7EqweONcwImFGCjq33fNg'}> */}
          <IconButton
            size='lg'
            variant='ghost'
            // color='#8e6d46'
            aria-label='open menu'
            icon={<FaInstagram size={25} />}
          />
          {/* </a>
                    <a href={'https://www.instagram.com/importirorg/'} > */}

          <IconButton
            size='lg'
            variant='ghost'
            // color='#8e6d46'
            aria-label='open menu'
            icon={<FaTiktok size={25} />}
          />
          <IconButton
            size='lg'
            variant='ghost'
            // color='#8e6d46'
            aria-label='open menu'
            icon={<FaYoutube size={25} />}
          />
          {/* </a> */}
        </HStack>
      </Stack>
    </Box>
  );
};

const NavItem = ({ icon, linklabel, children, navigate, ...rest }) => {
  return (
    <Stack
      onClick={() => navigate(linklabel)}
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}
    >
      <Flex
        align='center'
        p='4'
        borderRadius='lg'
        role='group'
        cursor='pointer'
        _hover={{
          bg: 'transparent',
          color: 'cyan.400',
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr='4'
            fontSize='16'
            _groupHover={{
              color: 'cyan.400',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Stack>
  );
};

AppSideBar.propTypes = {
  onClose: PropTypes.func,
  display: PropTypes.any,
  children: PropTypes.any,
  logoSide: PropTypes.any,
};

SidebarContent.propTypes = {
  onClose: PropTypes.func,
  logoSide: PropTypes.any,
  isDesktop: PropTypes.any,
};

NavItem.propTypes = {
  icon: PropTypes.any,
  linklabel: PropTypes.any,
  navigate: PropTypes.any,
  children: PropTypes.any,
};