import {
  Alert,
  AlertIcon,
  Box,
  Circle,
  Collapse,
  Drawer,
  DrawerOverlay,
  Flex,
  HStack,
  Heading,
  IconButton,
  Image,
  Link,
  Skeleton,
  Stack,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BsBag, BsPerson, BsSearch } from 'react-icons/bs';
import { karla } from '../../Theme/theme';
import CartSidebar from './Navbar/CartSidebar';
import SearchBar from './Navbar/SearchBar';
import MenuNavbar from './Navbar/MenuNavbar';
import CollectionBar from './Navbar/CollectionBar';
import PropTypes from 'prop-types';
import { addLogoutPixel } from '../../Utils/pixelUtils';
import { signOut } from 'firebase/auth';
import { auth } from '../../Config/firebase';

const MenuHeader = ({
  // onOpen,
  isScrolled,
  // handleLogout,
  globalState,
  ...rest
}) => {
  const toast = useToast();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [collapse, setCollapse] = useState({
    label: '',
    mode: '',
    item: null,
  });
  const [drawer, setDrawer] = useState({
    label: '',
    mode: '',
    item: null,
  });

  const handleSearchStore = () => {
    if (globalState?.isTrial) {
      setCollapse({
        label: 'Sarch Product',
        mode: 'search',
        item: null,
      });
      setIsOpen(true);
    } else {
      return navigate('/');
    }
  };

  const handleLogout = async () => {
    try {
      addLogoutPixel(globalState);
      signOut(auth)
        .then(() => {
          toast({
            status: 'error',
            description: 'User restricted only',
            duration: 3000,
            position: 'bottom',
          });
          navigate('/login');
          localStorage.removeItem('user');
          localStorage.removeItem('addCart');
          localStorage.removeItem('addToCart');
          localStorage.removeItem('tokenFirebase');
          localStorage.removeItem('isLoggedIn');
          globalState?.setIsLoading(false);
          globalState.setIsVIP(false)

          window.location.reload();
        })
        .catch((error) => {
          toast({
            title: 'Log out failed',
            description: error.message,
            status: 'error',
            position: 'top-right',
            isClosable: true,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleProfileUser = () => {
    // console.log('ini kena click')
    if (globalState?.isLoggedIn) {
      if (globalState?.isTrial) {
        navigate(`/account/${globalState?.uid}`);
      } else {
        handleLogout()
      }
    } else {
      navigate('/login');
    }
  };

  const handleContainerMouseEnter = () => {
    setIsOpen(true);
  };

  const handleContainerMouseLeave = () => {
    setIsOpen(false);
  };

  const handleCartSidebar = () => {
    setDrawer({
      label: 'Cart',
      mode: 'cart',
      item: null,
    });
  };

  const handleToggleCollections = (item) => {
    setCollapse({
      label: item.name,
      mode: item.name,
      item: item,
    });
    setIsOpen(true);
  };

  useEffect(() => {
    handleCartSidebar();
  }, [globalState?.drawerToggle]);

  return (
    <Stack>
      <Stack
        color='black'
        zIndex={200}
        transition={'all 0.3s ease-in-out'}
        position={'fixed'}
        w={'100%'}
        h='auto'
        spacing={0}
        bgColor={'white'}
        {...rest}
      >
        {/* <Stack
          h={'35px'}
          px={2}
          bgColor='#8e6d46'
          w='100%'
          alignItems={['center', 'center', 'center']}
          justifyContent='center'
        > */}
        {/* <HStack
            color='white'
            spacing={[5, 5, 10]}
            px={1}
            display={{ base: 'center', md: 'center' }}
          >
            <Stack justify={'center'} alignItems={'center'}>
              <Text
                py={2}
                style={{ ...karla, fontWeight: 500 }}
                cursor={'pointer'}
                fontSize='sm'
                align={'center'}
              >
                Batas waktu pembayaran 30 menit setelah order dibuat.
              </Text>
            </Stack>
          </HStack> */}
        <Alert status='warning' w={'full'} size={'sm'} textAlign={'center'} fontSize={'sm'}>
          <AlertIcon />
          <HStack>
            <Text>
              Websites ini dalam tahap pengembangan, silahkan kembali ke
            </Text>
            <Link color={'blue.500'} href='https://klamby.id'>
              klamby.id
            </Link>
          </HStack>
        </Alert>
        {/* </Stack> */}

        <HStack
          bgColor={'white'}
          justifyContent={'space-evenly'}
          px={[0, 0, 20]}
          w={'100%'}
          h='80px'
          display={
            isOpen === true && collapse?.mode === 'collections'
              ? 'flex'
              : isOpen === true && collapse?.mode === 'search'
                ? 'none'
                : 'flex'
          }
          boxShadow={isScrolled ? '0px 0px 10px rgba(0, 0, 0, 0.2)' : 'none'}
        >
          <Flex
            flex={{ base: 1 }}
            justify={{ base: 'start', md: 'start' }}
            gap={5}
          >
            <HStack
              alignItems={'center'}
              justifyContent='center'
              spacing={0}
              px={5}
            >
              <Image
                onClick={() => navigate('/')}
                w={['130px', '100px', '150px']}
                cursor='pointer'
                src={
                  'https://klamby.id/cdn/shop/files/Logo_klamby_baru_banget_140x@2x.png?v=1643345083'
                }
              />
            </HStack>

            <MenuNavbar
              globalState={globalState}
              onToggle={handleToggleCollections}
            />
          </Flex>

          <Stack p={2}>
            <Stack
              flex={{ base: 1, md: 0 }}
              justify={'center'}
              justifyContent='center'
              direction={'row'}
              spacing={5}
            >
              <HStack alignItems={'center'} spacing={0}>
                <Box
                  className='Account'
                  display={{ base: 'none', md: 'block' }}
                >
                  <Tooltip label='Account' fontSize='md'>
                    <Stack>
                      <IconButton
                        size='lg'
                        variant='ghost'
                        color='#8e6d46'
                        aria-label='open menu'
                        icon={<BsPerson size={25} />}
                        onClick={handleProfileUser}
                      />
                      {/* <Badge bgColor='red' color='white' position={'absolute'} borderRadius='full' px={2} fontSize='xs'>{productListWishlist?.data?.length}</Badge> */}
                    </Stack>
                  </Tooltip>
                </Box>

                <div className='search'>
                  <Tooltip label='Search' fontSize='md'>
                    <Stack>
                      <IconButton
                        size='lg'
                        onClick={handleSearchStore}
                        color='#8e6d46'
                        variant='ghost'
                        aria-label='open menu'
                        icon={<BsSearch size={20} />}
                      />
                    </Stack>
                  </Tooltip>
                </div>

                <div className='cart'>
                  <Tooltip label='Cart' fontSize='md'>
                    <Stack pos={'relative'}>
                      <IconButton
                        size='lg'
                        variant='ghost'
                        color='#8e6d46'
                        aria-label='open menu'
                        icon={<BsBag size={20} />}
                        onClick={() => {
                          globalState?.setDrawer(!globalState?.drawerToggle);
                        }}
                      />
                      <Circle
                        bgColor='#8e6d46'
                        size={'2'}
                        bottom={'3'}
                        right={3}
                        pos={'absolute'}
                      />
                    </Stack>
                  </Tooltip>
                </div>
              </HStack>
            </Stack>
          </Stack>
        </HStack>

        <Collapse
          in={isOpen}
          onMouseEnter={handleContainerMouseEnter}
          onMouseLeave={handleContainerMouseLeave}
          transition='ease-in-out 0.3s'
        >
          {collapse?.mode === 'search' ? (
            <SearchBar toClose={() => setIsOpen(!isOpen)} />
          ) : (
            <></>
          )}
          {collapse?.mode === 'collections' ? (
            <CollectionBar toClose={() => setIsOpen(!isOpen)} />
          ) : (
            <></>
          )}
        </Collapse>

        <Drawer
          placement={'right'}
          onClose={() => globalState?.setDrawer(!globalState?.drawerToggle)}
          size='sm'
          isOpen={globalState?.drawerToggle}
        >
          <DrawerOverlay />
          {drawer.mode === 'cart' ? (
            <CartSidebar globalState={globalState} />
          ) : (
            <></>
          )}
        </Drawer>
      </Stack>
    </Stack>
  );
};

MenuHeader.propTypes = {
  isOpen: PropTypes.bool,
  isScrolled: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onToggle: PropTypes.func,
  globalState: PropTypes.object,

  handleContainerMouseEnter: PropTypes.func,
  handleLogout: PropTypes.func,
};

export default MenuHeader;
