import {
  Badge,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { PriceTag } from '../../Components/PriceTag/PriceTag';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';


const HistoryOrder = ({ history }) => {
  const navigate = useNavigate();
  const [historyStatus, setHistoryStatus] = useState([]);

  const paymentStatus = (status, expired) => {
    if (expired != undefined || expired != null || expired != 'Invalid date') {
      const dateNow = new Date();
      const dateExpired = new Date(expired);

      if (status === 'PAID') {
        return <Badge colorScheme='green'>{status}</Badge>;
      } else if (status === 'PENDING' && dateExpired > dateNow) {
        return <Badge>{status}</Badge>;
      } else if (status === 'PENDING' || status === 'expire' && dateExpired < dateNow) {
        return <Badge colorScheme='red'>EXPIRED</Badge>;
      }
    
    } else {
      return <Badge colorScheme='blue'>Invalid date</Badge>;
    }

  };

  const calculateTimeLeft = (item) => {
    const difference = +new Date(item) - +new Date();
    let timeLeft = {};
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
      return { isExpired: false, time: timeLeft };
    } else {
      return { isExpired: true };
    }
  };

  const updateStatus = async () => {
    const update = history.map((x) => {
      const expiry_date = calculateTimeLeft(x?.payment_information?.expiration_date);
      return {
        ...x,
        paymentExpired: expiry_date?.isExpired,
      };
    });
    const updatedHistory = await Promise.all(update);
    setHistoryStatus(updatedHistory);
  };

  const onClickHistory = (id) => {
    window.location.href = `https://app.sandbox.midtrans.com/payment-links/${id}`
  };

  useEffect(() => {
    updateStatus();
  }, []);

  return (
    <>
      <TableContainer overflowX='auto' overflowY={'auto'}>
        <Table variant={'striped'} borderStyle={'solid'} borderColor={'black'}>
          <Thead>
            <Tr>
              <Th>Order</Th>
              <Th>Date</Th>
              <Th>Payment status</Th>
              <Th>Fulfillment status</Th>
              <Th>Total</Th>
            </Tr>
          </Thead>

          <Tbody>
            {historyStatus?.map((x) => (
              <Tr
                key={x.id}
                onClick={() => onClickHistory(x.id)}
                // onClick={() => navigate(`/payment/${x.id}`)}
                cursor={'pointer'}
              >
                <Td>{x?.id}</Td>
                <Td>{moment(x?.createdAt?.seconds * 1000).format('DD MMM YYYY hh:mm')}</Td>
                <Td>{paymentStatus(x?.paymentStatus, x?.payment_information?.expiry_time)}</Td>
                <Td>{x?.resi !== undefined ? 'fulfillment' : 'unfulfillement'}</Td>
                <Td>
                  <PriceTag
                    price={x?.amount}
                    currency={'IDR'}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};

HistoryOrder.propTypes = {
  history: PropTypes.array,
};

export default HistoryOrder;
