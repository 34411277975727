/* eslint-disable no-console */
import React from 'react';
import { UseAuthStore } from '../../../Hooks/Zustand/store';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Checkbox, Flex, FormControl, FormLabel, HStack, Heading, Input, Stack, Text, useToast } from '@chakra-ui/react';
import { themeColor } from '../../../Theme/theme';
import { useNavigate } from 'react-router-dom';
import { addLogoutPixel } from '../../../Utils/pixelUtils';
import { signOut } from 'firebase/auth';
import { auth } from '../../../Config/firebase';
import PropTypes from 'prop-types';

const AccountComponent = ({setEmail, setIsChecked, isChecked}) => {
  const globalState = UseAuthStore();
  const navigate = useNavigate();
  const toast = useToast();

  const handleLogOut = () => {
    addLogoutPixel(globalState);
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        toast({
          status: 'success',
          description: 'Logged out success',
          duration: 2000,
        });
        navigate('/checkout');
        localStorage.removeItem('user');
        localStorage.removeItem('tokenFirebase');
        localStorage.removeItem('isLoggedIn');
        globalState?.setIsLoading(false);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Stack id='account'>
      {globalState?.isLoggedIn ? (
        <Accordion border={'transparent'} allowToggle w={'100%'}>
          <AccordionItem>
            <AccordionButton
              display={'flex'}
              justifyContent={'space-between'}
              px={0}
            >
              <Stack textAlign={'start'}>
                <Text fontSize={'20'} color={themeColor}>Account</Text>
                <Text>{globalState?.user?.email}</Text>
              </Stack>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel px={0}>
              <Text
                color={themeColor}
                as={'u'}
                onClick={handleLogOut}
                cursor={'pointer'}
              >
                Log out
              </Text>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      ) : (
        <>
          <Flex justify={'space-between'}>
            <Heading fontSize={'2xl'} fontWeight={'medium'}>Contact</Heading>
            <HStack align={'center'}>
              <Text color={'rgba(0,0,0,0.56)'}>Have an account?</Text>
              <Text
                cursor={'pointer'}
                color={'#8E6D46'}
                textDecor={'underline'}
                onClick={() => navigate('/login')}
              >
                Login
              </Text>
            </HStack>
          </Flex>
          <Stack>
            <FormControl>
              <FormLabel>Email</FormLabel>
              <Input
                placeholder='Email'
                // value={email}
                onChange={(e) =>
                  setEmail(e.target.value)
                }
              />
            </FormControl>
          </Stack>
        </>
      )}
      <Checkbox
        py={3}
        colorScheme='orange'
        defaultChecked={isChecked.notify}
        onChange={(e) =>
          setIsChecked({ ...isChecked, notify: e.target.checked })
        }
      >
        Email me with news and offers
      </Checkbox>
    </Stack>
  );
};

AccountComponent.propTypes = {
  user: PropTypes.object,
  setEmail: PropTypes.func,
  setIsChecked: PropTypes.func,
  isChecked: PropTypes.object,
};

export default AccountComponent;
