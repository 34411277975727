/* eslint-disable no-undef */
/* eslint-disable no-console */
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  Select,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { UseAuthStore } from '../../../Hooks/Zustand/store';
import { useEffect, useState } from 'react';
import axios from 'axios';
import {
  addDocumentFirebase,
  getCollectionFirebase,
  setDocumentFirebase,
  updateDocumentFirebase,
} from '../../../Apis/firebaseApi';
import { karla, ovo, themeColor, transparent } from '../../../Theme/theme';
import { country, province } from '../../../Data/Country';
import PropTypes from 'prop-types';
import { shippingSubDistrict } from '../../../Apis/checkoutAPI';
import { validPhoneNumber } from '../../../Utils/numberUtil';


const AddressForm = ({ cancel, item, label, user, fetchData }) => {
  const toast = useToast();
  const globalState = UseAuthStore();
  const [address, setAddress] = useState({});
  const [district, setDistrict] = useState([]);
  const [isDefault, setIsDefault] = useState(false);
  const [listAddress, setListAddress] = useState([]);

  const handleDistrict = async (district) => {
    const districtKey = {
      keyword: district?.toLowerCase(),
    };
    try {
      const getSubDistrict = await shippingSubDistrict(districtKey)

      if (getSubDistrict.data.status === true) {
        setDistrict(getSubDistrict.data.data.data);
      } else {
        console.log(getSubDistrict.data.message, 'failed');
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleDefault = async () => {
    const getAddress = await getCollectionFirebase(`users/${user}/address`);
    if (getAddress) {
      setListAddress(getAddress);
    }
  };

  const onSubmitEdit = (address, data) => {
    try {
      const update = updateDocumentFirebase(
        `users/${user}/address`,
        `${item.id}`,
        data
      );
      if (update) {
        toast({
          title: 'Success update address',
          description: 'Address has been updated',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
        return cancel();
      } else {
        toast({
          title: 'Error',
          description: 'Failed updating address',
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const onSubmitAddress = async () => {
    globalState.setIsLoading(true)
    try {
      const inputData = {
        address: address?.address,
        email: globalState?.user?.email,
        label: address?.address?.label,
        phone_number: address?.address?.phone_number,
        receiver:
          address?.address?.firstName + ' ' + address?.address?.lastName,
        isDefault: isDefault,
      };

      if (item != null) {
        if (isDefault) {
          listAddress?.map(async (item) => {
            await setDocumentFirebase(
              `users/${user}/address`,
              `${item.id}`,
              { isDefault: false }
            );
          });
        }

        return onSubmitEdit(address, inputData);
      } else {
        const submit = await addDocumentFirebase(
          `users/${user}/address`,
          inputData,
          globalState?.currentCompany
        );
        if (submit) {
          toast({
            title: 'Address added',
            description: 'New address has been added',
            status: 'success',
            duration: 2000,
            isClosable: true,
          });
          return cancel();
        } else {
          toast({
            title: 'Error',
            description: 'Failed to add new address',
            status: 'error',
            duration: 2000,
            isClosable: true,
          });
        }
      }
    } catch (error) {
      console.log(error.message);
    }finally{
      globalState.setIsLoading(false)
      fetchData()
    }
  };

  useEffect(() => {
    handleDefault();
  }, []);

  useEffect(() => {
    if (item) {
      setAddress(item);
    }
  }, []);

  return (
    <Stack p={2}>
      <Heading as={'h6'} style={ovo} py={3}>
        {label}
      </Heading>
      <Divider />
      <Stack gap={5}>
        <HStack>
          <FormControl isRequired>
            <FormLabel style={ovo} letterSpacing={'0.2em'}>
              First Name
            </FormLabel>
            <Input
              style={karla}
              placeholder='First Name'
              rounded={0}
              defaultValue={item?.address?.firstName}
              value={address?.firstName}
              onChange={(e) =>
                setAddress({
                  ...address,
                  address: {
                    ...address.address,
                    firstName: e.target.value,
                  },
                })
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel style={ovo} letterSpacing={'0.2em'}>
              Last Name
            </FormLabel>
            <Input
              style={karla}
              placeholder='Last Name'
              rounded={0}
              defaultValue={item?.address?.lastName}
              value={address?.lastName}
              onChange={(e) =>
                setAddress({
                  ...address,
                  address: {
                    ...address.address,
                    lastName: e.target.value,
                  },
                })
              }
            />
          </FormControl>
        </HStack>
        <FormControl>
          <FormLabel style={ovo} letterSpacing={'0.2em'}>
            Company
          </FormLabel>
          <Input
            style={karla}
            placeholder='Company'
            rounded={0}
            defaultValue={item?.address?.company_name}
            value={address.company_name}
            onChange={(e) =>
              setAddress({
                ...address,
                address: {
                  ...address.address,
                  company_name: e.target.value,
                },
              })
            }
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel style={ovo} letterSpacing={'0.2em'}>
            Address1
          </FormLabel>
          <Input
            style={karla}
            placeholder='Address1'
            rounded={0}
            defaultValue={item?.address?.street}
            value={address.street}
            onChange={(e) =>
              setAddress({
                ...address,
                address: {
                  ...address.address,
                  street: e.target.value,
                },
              })
            }
          />
        </FormControl>
        <FormControl>
          <FormLabel style={ovo} letterSpacing={'0.2em'}>
            Address2
          </FormLabel>
          <Input
            style={karla}
            placeholder='Address2'
            rounded={0}
            defaultValue={item?.address?.address2}
            value={address.address2}
            onChange={(e) =>
              setAddress({
                ...address,
                address: {
                  ...address.address,
                  address2: e.target.value,
                },
              })
            }
          />
        </FormControl>
        <HStack>
          <FormControl isRequired>
            <FormLabel style={ovo} letterSpacing={'0.2em'}>
              Country
            </FormLabel>
            <Select
              placeholder='select country'
              style={karla}
              rounded={0}
              defaultValue={item?.address?.country}
              value={address?.country}
              onChange={(e) => {
                setAddress({
                  ...address,
                  address: {
                    ...address?.address,
                    country: e.target.value,
                  },
                });
              }}
            >
              {country.map((x, i) => (
                <option key={i} value={x.name}>
                  {x.name}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl isRequired>
            <FormLabel style={ovo} letterSpacing={'0.2em'}>
              Province
            </FormLabel>
            <Select
              placeholder='select province'
              style={karla}
              rounded={0}
              defaultValue={item?.address?.state}
              value={address?.state}
              onChange={(e) =>
                setAddress({
                  ...address,
                  address: {
                    ...address?.address,
                    state: e.target.value,
                  },
                })
              }
            >
              {province.map((x, i) => (
                <option key={i} value={x.name}>
                  {x.name}
                </option>
              ))}
            </Select>
          </FormControl>
        </HStack>
        <HStack>
          <FormControl isRequired>
            <FormLabel style={ovo} letterSpacing={'0.2em'}>
              City / District
            </FormLabel>
            <Input
              style={karla}
              placeholder='City'
              rounded={0}
              defaultValue={item?.address?.city}
              value={address?.city}
              onChange={(e) => {
                handleDistrict(e.target.value);
                setAddress({
                  ...address,
                  address: {
                    ...address?.address,
                    city: e.target.value,
                  },
                });
              }}
            />
          </FormControl>
          <FormControl
            isRequired
            isDisabled={district?.length > 0 ? false : true}
          >
            <FormLabel style={ovo} letterSpacing={'0.2em'}>
              Subdistrict
            </FormLabel>
            <Select
              style={karla}
              rounded={0}
              defaultValue={item?.address?.subdistrict_id}
              value={address.subdistrict_id}
              onChange={(e) => {
                setAddress({
                  ...address,
                  address: {
                    ...address.address,
                    subdistrict_id: parseInt(e.target.value),
                    subdistrict_name:
                      e?.target.options[e.target.selectedIndex]?.textContent ||
                      e?.target.options[0]?.textContent,
                  },
                });
              }}
            >
              {district?.map((x, i) => (
                <option key={i} value={x?.subdistrict_id}>
                  {x?.city}
                </option>
              ))}
            </Select>
          </FormControl>
        </HStack>
        <HStack>
          <FormControl isRequired>
            <FormLabel style={ovo} letterSpacing={'0.2em'}>
              Postal / ZIP Code
            </FormLabel>
            <Input
              style={karla}
              placeholder='Postal / ZIP Code'
              rounded={0}
              defaultValue={item?.address?.postal_code}
              value={address?.postal_code}
              onChange={(e) =>
                setAddress({
                  ...address,
                  address: {
                    ...address?.address,
                    postal_code: e.target.value,
                  },
                })
              }
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel style={ovo} letterSpacing={'0.2em'}>
              Phone
            </FormLabel>
            <Input
              style={karla}
              placeholder='Phone'
              rounded={0}
              defaultValue={item?.address?.phone_number}
              value={address?.phone_number}
              onChange={(e) =>
                setAddress({
                  ...address,
                  address: {
                    ...address.address,
                    phone_number: e.target.value,
                  },
                })
              }
            />
          </FormControl>
        </HStack>
        <FormControl isRequired>
          <FormLabel style={ovo} letterSpacing={'0.2em'}>
            Label
          </FormLabel>
          <Input
            style={karla}
            placeholder='ex: Home'
            rounded={0}
            defaultValue={item?.address?.label}
            value={address?.label}
            onChange={(e) =>
              setAddress({
                ...address,
                address: {
                  ...address?.address,
                  label: e.target.value,
                },
              })
            }
          />
        </FormControl>
        <Checkbox
          style={karla}
          rounded={0}
          colorScheme='orange'
          defaultChecked={false}
          onChange={(e) => {
            setIsDefault(e.target.checked);
            // setAddress({
            //   ...address,
            //   isDefault: e.target.checked,
            // });
            // console.log(e.target.checked);
          }}
        >
          Set as default address
        </Checkbox>
      </Stack>

      <HStack py={4}>
        <Button
          color={themeColor}
          style={{ ...karla, fontWeight: 'bold' }}
          size={'md'}
          variant={'unstyled'}
          border={`1px solid ${themeColor}`}
          _hover={{ color: 'rgba(142, 109, 70, 0.7)' }}
          rounded={0}
          px={2}
          onClick={() => cancel()}
        >
          Cancel
        </Button>
        <Button
          bgColor={themeColor}
          style={{ ...karla, fontWeight: 'bold' }}
          color={'white'}
          rounded={0}
          size={'md'}
          colorScheme='none'
          _hover={transparent}
          onClick={() => onSubmitAddress()}
          // type="submit"
        >
          Save
        </Button>
      </HStack>
    </Stack>
  );
};


AddressForm.propTypes = {
  item: PropTypes.object,
  label: PropTypes.string,
  cancel: PropTypes.func,
  user: PropTypes.string,
  fetchData: PropTypes.func,
};

export default AddressForm;
