import axios from 'axios';

export const checkoutMidtrans = async (data) => {
  const url =
    // 'https://asia-southeast2-deoapp-indonesia.cloudfunctions.net/crm-checkoutMultipleKlamby';
    'https://asia-southeast2-deoapp-indonesia.cloudfunctions.net/crmKlamby-checkoutMultiple'

  const headers = {
    headers: {
      'x-api-key': process.env.REACT_APP_API_KEY_README,
      accept: 'application/json',
      'content-type': 'application/json',
    },
  };
  try {
    const res = await axios.post(url, data?.body, headers);
    return res;
  } catch (error) {
    throw new Error('Failed to send error message: ' + error);
  }
};

export const shippingCostOExpress = async (data) => {
  const url =
    'https://asia-southeast2-deoapp-indonesia.cloudfunctions.net/shipping-oExpressShippingCost';

  const headers = {
    headers: {
      'x-api-key': process.env.REACT_APP_API_KEY_README,
      accept: 'application/json',
      'content-type': 'application/json',
    },
  };
  try {
    const res = await axios.post(url, data, headers);
    return res.data;
  } catch (error) {
    throw new Error('Failed to send error message: ' + error.message);
  }
};

export const shippingSubDistrict = async (data) => {
  const url =
    'https://asia-southeast2-deoapp-indonesia.cloudfunctions.net/shipping-oExpressSubdistrict';

  const headers = {
    headers: {
      'x-api-key': process.env.REACT_APP_API_KEY_README,
    },
  };
  try {
    const res = await axios.post(url, data, headers);
    return res;
  } catch (error) {
    throw new Error('Failed to send error message: ' + error.message);
  }
};
