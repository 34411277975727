import {
  Box,
  Center,
  Image,
  List,
  ListItem,
  SimpleGrid,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { karla } from '../../../Theme/theme';
import { UseAuthStore } from '../../../Hooks/Zustand/store';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';


const CollectionBar = ({ toClose }) => {
  const globalState = UseAuthStore();
  const navigate = useNavigate();
  const category = [
    {
      category: 'scarf',
      subcategory: [],
      id: 'text',
    },
    {
      category: 'tops',
      subcategory: ['blouse', 'tunic', 'shirt', 'outer'],
      id: 'text',
    },
    {
      category: 'bottoms',
      subcategory: ['pants', 'skirt'],
      id: 'text',
    },
    {
      category: 'others',
      subcategory: [
        'parfume',
        'brooch',
        'necklace',
        'dress',
        'pajamas',
        'prayer set',
      ],
      id: 'text',
    },
    {
      category: 'tops',
      subcategory: [],
      id: 'image',
      image: 'https://klamby.id/cdn/shop/files/tops_540x.png?v=1641875715',
    },
    {
      category: 'bottoms',
      subcategory: [],
      id: 'image',
      image:
        'https://klamby.id/cdn/shop/files/pants_20c1ccd8-0f94-4df9-8fbb-29a17d33bd42_540x.png?v=1641875837',
    },
  ];

  const selectedCategory = (subcategory) => {
    globalState?.setCategory(subcategory);
    navigate(`/collections/${subcategory}`);
    return toClose();
  };

  return (
    <Box>
      <VStack
        shadow={'xl'}
        zIndex={100}
        h={'30%'}
        spacing={5}
        bgColor={'white'}
        alignItems='center'
        justifyContent={'center'}
        textAlign={'left'}
      >
        <Center>
          <SimpleGrid
            columns={6}
            spacing={10}
            // justifyContent={"top"}
            // alignItems={'start'}
            // textAlign={'left'}
            w={'90%'}
            p={8}
            fontSize={20}
          >
            {category?.map((x, i) => {
              if (x.id === 'text')
                return (
                  <VStack key={i}>
                    <Text
                      cursor={'pointer'}
                      textAlign={'left'}
                      as={'b'}
                      textTransform={'uppercase'}
                      onClick={() => selectedCategory(x.category, i)}
                    >
                      {x.category.toUpperCase()}
                    </Text>
                    {x?.subcategory?.map((y, j) => (
                      <List textAlign={'left'} key={j}>
                        <ListItem
                          cursor={'pointer'}
                          textTransform={'capitalize'}
                          style={karla}
                          onClick={() => selectedCategory(y, j)}
                        >
                          {y}
                        </ListItem>
                      </List>
                    ))}
                  </VStack>
                );
              else {
                return (
                  <VStack
                    key={i}
                    onClick={() => selectedCategory(x.category)}
                    cursor={'pointer'}
                  >
                    <Stack>
                      <Image src={x.image} alt={x.category} objectFit='cover' />
                      <Text as={'b'}>{x.category.toUpperCase()}</Text>
                    </Stack>
                  </VStack>
                );
              }
            })}
          </SimpleGrid>
          <AiOutlineClose size={20} cursor={'pointer'} onClick={toClose} />
        </Center>
      </VStack>
    </Box>
  );
};

CollectionBar.propTypes = {
  toClose: PropTypes.func,
};

export default CollectionBar;
