/* eslint-disable no-console */
import {
  AbsoluteCenter,
  Box,
  Button,
  HStack,
  Heading,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FcApproval, FcInTransit } from 'react-icons/fc';
import { karla, themeColor } from '../../Theme/theme';
import { FiArrowLeft } from 'react-icons/fi';
import { getSingleDocumentFirebase } from '../../Apis/firebaseApi';
import { UseAuthStore } from '../../Hooks/Zustand/store';
import { useNavigate } from 'react-router-dom';

const ThankyouPages = () => {
  const globalState = UseAuthStore();
  const navigate = useNavigate();
  const [dataConfig, setDataConfig] = useState({});

  const getWebConfig = async () => {
    try {
      const data = await getSingleDocumentFirebase(
        'crm_web_config',
        globalState?.currentProject
      );
      if (data) {
        setDataConfig(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getWebConfig();
  }, []);

  return (
    <Box>
      <AbsoluteCenter>
        <VStack style={karla}>
          <Heading>Thank you for your order!</Heading>
          <Text>Please wait, we will take care of your order</Text>
          <HStack>
            <FcApproval size={150} /> ....
            <FcInTransit size={200} />
          </HStack>
          <Text>
            We also send receipt information to your email, please check your
            email!
          </Text>

          <Text>
            {dataConfig?.thankYouPageMessage || 'Thank you for your order!'}
          </Text>
          <HStack m={5}>
            <Button
              colorScheme='none'
              bgColor={themeColor}
              color={'white'}
              _hover={{ bgColor: 'rgba(0, 0, 0, 0.8)' }}
              onClick={() => navigate('/')}
            >
              <FiArrowLeft />
              <Spacer />
              Continue Shopping
            </Button>
            <Button
              colorScheme='none'
              bgColor={themeColor}
              color={'white'}
              _hover={{ bgColor: 'rgba(0, 0, 0, 0.8)' }}
            >
              Send email to {dataConfig?.contactCustomerService}
            </Button>
          </HStack>
        </VStack>
      </AbsoluteCenter>
    </Box>
  );
};

export default ThankyouPages;
