/* eslint-disable react/prop-types */
import moment from 'moment';
import React, { useEffect, useState } from 'react';

const Countdown2 = ({ timeData }) => {
  const [timeLeft, setTimeLeft] = useState({
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00',
  });

  useEffect(() => {
    // Convert timeData to milliseconds if it's in seconds
    const countDownDate =
      timeData < 1000000000000
        ? moment(timeData * 1000).toDate()
        : moment(timeData).toDate();

    // Update the count down every 1 second
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        clearInterval(interval);
        setTimeLeft({
          days: '00',
          hours: '00',
          minutes: '00',
          seconds: '00',
        });
      } else {
        setTimeLeft({
          days: days.toString().padStart(2, '0'),
          hours: hours.toString().padStart(2, '0'),
          minutes: minutes.toString().padStart(2, '0'),
          seconds: seconds.toString().padStart(2, '0'),
        });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        gap: '20px',
        justifyContent: 'center',
        color: '#8e6d46',
        textAlign: 'center',
        paddingTop: '20px',
        paddingBottom: '30px',
        fontWeight: 'bold',
        backgroundColor: 'white',
        //backgroundImage: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/bKEB41GUP7kHzHmpMXjn/A2BpBV6eEbQKypU5SL6e/020524-WEBSITE-UNDER-CONSTRUCTION.jpg',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div>
        <p id='days' style={{ fontSize: '40px' }}>
          {timeLeft.days}
        </p>
        <p style={{ fontSize: '15px' }}>Days</p>
      </div>
      <div>
        <p style={{ fontSize: '40px' }}>:</p>
      </div>
      <div>
        <p id='hours' style={{ fontSize: '40px' }}>
          {timeLeft.hours}
        </p>
        <p style={{ fontSize: '15px' }}>Hours</p>
      </div>
      <div>
        <p style={{ fontSize: '40px' }}>:</p>
      </div>
      <div>
        <p id='minutes' style={{ fontSize: '40px' }}>
          {timeLeft.minutes}
        </p>
        <p style={{ fontSize: '15px' }}>Minutes</p>
      </div>
      <div>
        <p style={{ fontSize: '40px' }}>:</p>
      </div>
      <div>
        <p id='seconds' style={{ fontSize: '40px' }}>
          {timeLeft.seconds}
        </p>
        <p style={{ fontSize: '15px' }}>Seconds</p>
      </div>
    </div>
  );
};

export default Countdown2;
