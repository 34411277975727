/* eslint-disable no-console */
import {
  Box,
  Button,
  Center,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Heading,
  Image,
  Select,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LuSettings2 } from 'react-icons/lu';
import { PriceTag } from '../../Components/PriceTag/PriceTag';
import { getCollectionFirebase } from '../../Apis/firebaseApi';
import { UseAuthStore } from '../../Hooks/Zustand/store';
import FilterProduct from './Components/FilterProduct';
import { clientTypessense } from '../../Apis/Typesense';
import { karla, ovo } from '../../Theme/theme';
import Paginate from '../../Components/Paginate/Paginate';
import SkeletonImage from '../../Components/Skeletons/SkeletonsImage';
import { checkStockProduct } from '../../Utils/productUtils';
import { formatFrice } from '../../Utils/numberUtil';

//w='388.66px' h='581.98px'

//Data
const selectOption = [
  { value: 'sort', name: 'sort' },
  { value: 'featured', name: 'featured' },
  { value: 'best-selling', name: 'best selling' },
  { value: 'a-z', name: 'alphabetically, a-z' },
  { value: 'z-a', name: 'alphabetically, z-a' },
  { value: 'lowest', name: 'price, low to high' },
  { value: 'highest', name: 'price, high to low' },
  { value: 'newest', name: 'date, old to new' },
  { value: 'oldest', name: 'date, new to old ' },
];

const CollectionsPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const globalState = UseAuthStore();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isLoadingProduct, setIsLoadingProduct] = useState(false);

  //data product state
  const [listProduct, setListProduct] = useState([]);
  const [productStocks, setProductStocks] = useState({});

  //filtering
  const [selectedStock, setSelectedStock] = useState('');
  const [selectedSize, setSelectedSize] = useState('');
  const [selectedPrice, setSelectedPrice] = useState('');

  //search
  const [inputSearch, setInputSearch] = useState([]);
  const [dataSearch, setDataSearch] = useState([]);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  const itemsPerPage = 25;

  //variable newvalue
  let newValue = '';

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleTypesenseSearch = (q, filter) => {
    setIsLoadingProduct(true);
    try {
      const searchParameters = {
        q: q,
        query_by: 'name',
        filter_by: filter ? filter : '',
      };
      if (q) {
        clientTypessense
          .collections('crm_product_variants')
          .documents()
          .search(searchParameters)
          .then((x) => {
            const newData = x.hits.map((y) => {
              return { ...y.document };
            });

            const arrProductId = newData.map((z) => {
              return {
                id: z.id,
                productId: z.productId,
                externalVariantId: z.external_variant_id,
              };
            });

            setInputSearch(q);
            setDataSearch(arrProductId);
          })
          .catch((error) => {
            console.log(error, 'errorrr');
          });
      } else {
        setInputSearch(q);
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setIsLoadingProduct(false);
    }
  };

  const fetchAllProduct = async () => {
    let categoryId = '';
    setIsLoadingProduct(true);

    try {
      const conditions = [
        {
          field: 'companyId',
          operator: '==',
          value: globalState?.currentCompany,
        },
        {
          field: 'projectId',
          operator: '==',
          value: globalState?.currentProject,
        },
      ];
      if (params.type !== 'all') {
        if (params.type === 'scarf') {
          newValue = 'veils';
        } else {
          const res = await getCollectionFirebase('crm_product_categories', [
            {
              field: 'name',
              operator: '==',
              value: params.type.toLowerCase()?.replace(/-/g, ' '),
            },
          ]);

          if (res?.length > 0) categoryId = res[0]?.id;
          newValue = params.type;
        }
        // const capitalValue = capitallizeFirstLetter(newValue);
        conditions.push({
          field: 'categoryId',
          operator: 'array-contains',
          value: categoryId,
        });
      }

      const sortBy = { field: 'lastUpdated', direction: 'desc' };
      const limitValue = 200;
      const result = await getCollectionFirebase(
        'crm_product',
        conditions,
        sortBy,
        limitValue
      );
      if (result) {
        if (dataSearch?.length > 0) {
          const resultSearch = dataSearch.map((x) => {
            if (x?.productId) {
              const resultData = result.find((y) => y.id === x.productId);
              return resultData || null;
            } else {
              const resultData = result.find((y) =>
                y.external_variant_id?.includes(x.external_product_id)
              );
              return resultData || null;
            }
          });
          setListProduct(
            resultSearch.filter((element) => element != undefined)
          );
        } else {
          setListProduct(result);
        }
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setIsLoadingProduct(false);
    }
  };

  const setAllFilter = async () => {
    let filters = '';
    if (selectedStock) {
      filters += selectedStock + ' && ';
    }
    if (selectedPrice) {
      filters += selectedPrice + ' && ';
    }
    if (selectedSize) {
      filters += selectedSize;
    }

    if (selectedPrice || selectedSize || selectedStock) {
      //eslint-disable-next-line
      filters = filters.replace(/ \&\& $/, '');
      // fetchAllProduct(filters);

      console.log(filters, 'filters');
      return handleTypesenseSearch(' ', filters);
    }
    await delay(1000);
  };

  const handleStock = async (item) => {
    let operator = '';
    let stock = '';
    const field = 'stock_int';
    if (item[0] === 'in_stock' && item.length === 1) {
      operator = '>';
    } else if (item[0] === 'out_of_stock' && item.length === 1) {
      operator = '=';
    } else if (item.length > 1) {
      operator = '>=';
    }

    if (operator && item) {
      stock = `${field}: ${operator} 0`;
    }
    setSelectedStock(stock);
    await delay(1000);
    onClose();
  };

  const handlePrice = async (item) => {
    const field = 'price_int';
    let price = '';

    if (item[0] || item[1]) {
      price = `${field}:[${item[0]}..${item[1]}]`;
      setSelectedPrice(price);
    }
    await delay(1000);
    onClose();
  };

  const handleSize = async (item) => {
    const field = 'attribute';
    let size = '';
    if (item.length) {
      size = `${field}:[${item}]`;
    }
    setSelectedSize(size);
    await delay(1000);
    onClose();
  };

  const handleDiscount = (sale_price, reguler_price) => {
    const percentage = ((reguler_price - sale_price) / reguler_price) * 100;
    return Math.ceil(percentage);
  };

  useEffect(() => {
    fetchAllProduct();
  }, [params, inputSearch, dataSearch]);

  useEffect(() => {
    setAllFilter();
  }, [selectedStock, selectedPrice, selectedSize]);

  useEffect(() => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    setCurrentData(listProduct.slice(start, end));
  }, [currentPage, listProduct]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  useEffect(() => {
    const fetchStocks = async () => {
      const stocks = {};
      for (const item of currentData) {
        const stock = await checkStockProduct(item.id);
        stocks[item.id] = stock;
      }
      setProductStocks(stocks);
    };
  
    fetchStocks();
  }, [currentData]);

  return (
    <Stack gap={10} mb={10} pt={10}>
      <Heading textAlign={'center'} textTransform={'capitalize'} style={ovo}>
        {/* eslint-disable-next-line */}
        {params?.type?.replace(/\-/g, ' ')}
      </Heading>

      <Flex justify='space-between'>
        <Button
          leftIcon={<LuSettings2 />}
          variant={'outline'}
          rounded={0}
          colorScheme='none'
          borderColor={'#ededec'}
          onClick={onOpen}
          style={karla}
        >
          Filter
          <Drawer
            isOpen={isOpen}
            onClose={onClose}
            placement='left'
            size={'sm'}
          >
            <DrawerOverlay />
            <DrawerContent>
              <FilterProduct
                stock={handleStock}
                size={handleSize}
                price={handlePrice}
              />
            </DrawerContent>
          </Drawer>
        </Button>
        <Select
          variant={'outline'}
          w='fit-content'
          rounded={0}
          textTransform='capitalize'
          borderColor={'#ededec'}
          defaultValue={'featured'}
          style={karla}
        >
          {selectOption?.map((item, index) => (
            <option key={index} value={item.value}>
              {item.name}
            </option>
          ))}
        </Select>
      </Flex>

      {isLoadingProduct ? (
        <Center>
          <Spinner size={'lg'} />
        </Center>
      ) : (
        <>
          {currentData?.length > 0 ? (
            <SimpleGrid columns={[2, 2, 3, 3, 3, 4]} gap={[2, 2, 2, 10]}>
              {currentData.map((item, index) => (
                <Stack
                  key={index}
                  pos={'relative'}
                  minW={['100%', '100%', 'unset', 'unset', 'unset', 'unset']}
                  onClick={() => {
                    navigate(`/collections/all/product/${item.id}`);
                    globalState?.setProduct(item.id);
                  }}
                  cursor={'pointer'}
                >
                  {productStocks[item?.id] > 0 && item?.discountApplied && item?.sale_price_int > 0 ? (
                    <Flex
                      pos={'absolute'}
                      right={0}
                      bgColor={'#b73737'}
                      top={0}
                    >
                      <Text
                        px={2}
                        py={1}
                        fontSize={'sm'}
                        color={'white'}
                        letterSpacing={'0.2em'}
                        style={karla}
                      >
                        {item?.saleType === 'percentage' ? (
                          'SAVE ' + item?.saleValue + '%'
                        ): (
                          'SALE Rp.' + item?.saleValue
                        )}
                      </Text>
                    </Flex>
                  ) : productStocks[item?.id] > 0 && item?.sale_price_int === 0 ? (
                    <Flex
                      pos={'absolute'}
                      right={0}
                      bgColor={'#8e6d46'}
                      top={0}
                    ></Flex>
                  ) : productStocks[item?.id] === 0 ? (
                    <Flex
                      pos={'absolute'}
                      right={0}
                      bgColor={'#b73737'}
                      top={0}
                    >
                      <Text
                        px={2}
                        py={1}
                        fontSize={'sm'}
                        color={'white'}
                        letterSpacing={'0.2em'}
                        style={karla}
                      >
                        SOLD OUT
                      </Text>
                    </Flex>
                  ) : (
                    <></>
                  )}
                  <SkeletonImage
                    objectFit={'cover'}
                    minW={['70%', '70%', 'unset', 'unset', 'unset', 'unset']}
                    aspectRatio={2 / 3}
                    src={item.images ? item.images[0] : null}
                  />
                  <Box>
                    <Text
                      fontSize={['md', 'md', 'lg', 'lg', 'lg', 'xl']}
                      style={karla}
                    >
                      {item?.name}
                    </Text>

                    {item?.sale_price_int > 0 ? (
                      <Flex gap={2}>
                        <Text
                          as={'del'}
                          fontSize={['xs', 'xs', 'md', 'md']}
                          style={karla}
                        >
                          <PriceTag
                            currency={'IDR'}
                            price={item?.regular_price_int}
                          />
                        </Text>
                        <Text fontSize={['xs', 'xs', 'md', 'md']} style={karla}>
                          <PriceTag
                            currency={'IDR'}
                            price={item?.sale_price_int}
                            color='red'
                          />
                        </Text>
                      </Flex>
                    ) : (
                      <Text fontSize={['xs', 'xs', 'md', 'md']} style={karla}>
                        <PriceTag
                          currency={'IDR'}
                          price={item.regular_price_int || 0}
                        />
                      </Text>
                    )}
                  </Box>
                </Stack>
              ))}
            </SimpleGrid>
          ) : (
            <Center>
              <Text fontSize={22} style={ovo}>
                Product in this sections are temporary unavailable
              </Text>
            </Center>
          )}
        </>
      )}

      {listProduct?.length >= 25 ? (
        <Stack display={'flex'} justifyContent={'end'}>
          <Paginate
            isLoadingProduct={isLoadingProduct}
            data={listProduct}
            total={listProduct?.length}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </Stack>
      ) : (
        <></>
      )}
    </Stack>
  );
};

export default CollectionsPage;
