import {
  Box,
  Flex,
  HStack,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { textMuted, themeColor } from '../../../Theme/theme';
import PropTypes from 'prop-types';
import { PriceTag } from '../../../Components/PriceTag/PriceTag';

const ShippingMethod = ({
  warehouses,
  newAddress,
  setNewAddress,
  dataShipment,
  shippingCost,
  isLoading,
  setIsLoading
}) => {
  const [selectedShipping, setSelectedShipping] = useState({});

  const handleShipping = () => {
    return dataShipment(selectedShipping);
  };


  useEffect(() => {
    if (shippingCost?.costs && shippingCost?.costs?.length > 0) {
      setSelectedShipping({
        provider:
          shippingCost?.code === 'oexpress' ? 'oExpress' : shippingCost?.code, // oExpress || jne ======= required!!!
        service: shippingCost?.costs[0]?.service, // HORE,SERBU,KISS,SATSET,SULTAN,GOCAP (oExpress) || REG, OKE, JTR, YES, ======= required!!!
        description: shippingCost?.costs[0]?.description,
        note: '',
        price: shippingCost?.costs[0]?.cost[0]?.value,
      });

      setIsLoading(false)
    }

    // handleShipping()
  }, [shippingCost, newAddress, warehouses]);

  useEffect(() => {
    handleShipping();
  }, [selectedShipping]);


  return (
    <Stack>
      <Skeleton isLoaded={!isLoading}>
        <RadioGroup
          colorScheme='orange'
          value={selectedShipping?.service}
          onChange={(value) => {
            setSelectedShipping({
              ...selectedShipping,
              service: value,
              provider: shippingCost?.code,
            });
          }}
        >
          {shippingCost?.costs?.map((x, i) => (
            <Box
             
              cursor={'pointer'}
              key={i}
              border={
                selectedShipping?.service === x.service
                  ? `1px solid ${themeColor}`
                  : '1px solid rgba(0,0,0,.045)'
              }
              p={5}
              roundedTop={i === 0 && 5}
              roundedBottom={shippingCost.costs.length === i + 1 && 5}
              bgColor={
                selectedShipping?.service === x.service ? '#FCF5F0' : 'none'
              }
            >
              <Flex justify={'space-between'} align={'center'}>
                <Radio
                  value={x.service}
                  onClick={() =>
                    setSelectedShipping({
                      ...selectedShipping,
                      price: x.cost[0].value,
                    })
                  }                >
                  {shippingCost.code} - {x.service}
                </Radio>
                <PriceTag price={x.cost[0].value} currency={'IDR'} />
              </Flex>
              <Text color={textMuted} fontSize={'sm'} ms={5}>
                {x.description}
              </Text>
              <Text color={textMuted} fontSize={'sm'} ms={5}>
                Estimasi {x?.cost[0]?.etd}
              </Text>
              <HStack ms={5} w={'100%'} color={textMuted} fontSize={'sm'}>
                <Text>Sending from</Text>
                <Text textTransform={'uppercase'}>{'Tangerang'}</Text>
                <Text>to</Text>
                <Text textTransform={'uppercase'}>
                  {newAddress?.address?.subdistrict_name ||
                    newAddress?.subdistrict_name}
                </Text>
              </HStack>
            </Box>
          ))}
        </RadioGroup>

      </Skeleton>
    </Stack>
  );
}

ShippingMethod.propTypes = {
  newAddress: PropTypes.object,
  setNewAddress: PropTypes.object,
  shipment: PropTypes.object,
  dataShipment: PropTypes.func,
  warehouses: PropTypes.array,
  shippingCost: PropTypes.array,
  isLoading: PropTypes.bool,
  setIsLoading: PropTypes.func
};

export default ShippingMethod;
