import { MenuItem } from '@chakra-ui/menu';
import PropTypes from 'prop-types';


const MenuItemSubMenu = ({ children }) => {
  return (
    <MenuItem
      boxShadow='0px 2px 0px 0px transparent'
      as='div'
      p={0}
      _focus={{
        boxShadow: '0px 2px 0px 0px #47bae6',
      }}
    >
      {children}
    </MenuItem>
  );
};

MenuItemSubMenu.propTypes = {
  children: PropTypes.any,
};
export default MenuItemSubMenu;
