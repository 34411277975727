import React from 'react';
import PropTypes from 'prop-types';


const HtmlRenderer = ({ html, onImageFound }) => {
  const formatHtml = (html) => {
    if (!html) return '';

    const imageRegex = /<div class="media-wrap image-wrap"><img class="media-wrap image-wrap" src="(.*?)"\/><\/div>/g;
    let match;
    const images = [];

    // Cari semua elemen gambar dalam HTML
    while ((match = imageRegex.exec(html)) !== null) {
      images.push(match[1]);
    }

    // Kirim gambar-gambar yang ditemukan ke props onImageFound
    if (images.length > 0 && onImageFound) {
      onImageFound(images);
    }

    // Hapus elemen gambar dari HTML
    let formattedHtml = html.replace(imageRegex, '');

    formattedHtml = formattedHtml
      .replace('<ul>', '<ul style="list-style-type: disc; margin-left: 20px;">')
      .replace(/<li>/g, '<li style="margin-bottom: 5px;">')
      .replace(/<p>/g, '<p style="margin-top: 10px;">')
      .replace('</ul>', '</ul>')
      .replace('</li>', '</li>')
      .replace('</p>', '</p>');

    return formattedHtml;
  };

  const formattedHtml = formatHtml(html);

  return (
    <div dangerouslySetInnerHTML={{ __html: formattedHtml }} />
  );
};

HtmlRenderer.propTypes = {
  html: PropTypes.string,
  onImageFound: PropTypes.func,
};

export default HtmlRenderer;
