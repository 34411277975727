import axios from 'axios';

export const addPointsCustomer = async (emailUser, category, type, points) => {
  const config = {
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      'x-api-key': process.env.REACT_APP_API_KEY_POINTS,
    },
  };

  const data_points = {
    email: emailUser,
    companyId: 'ffBBNgi1CODzR9xxU2Ro',
    projectId: '2h3DLK7iKAXRNNZS7PLR',
    category: category || '',
    type: type, //inbound or outbound
    points: points,
  };

  try {
    const response = await axios.post(
      'https://asia-southeast2-deoapp-indonesia.cloudfunctions.net/point-set',
      data_points,
      config
    );
    return response.data;
  } catch (error) {
    throw new Error('Failed to add points:', error);
  }
};

export const getPointsInbound = async (emailUser, category, type) => {
  const config = {
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      'x-api-key': process.env.REACT_APP_API_KEY_README,
    },
  };

  const data_points = {
    email: emailUser,
    companyId: 'ffBBNgi1CODzR9xxU2Ro',
    projectId: '2h3DLK7iKAXRNNZS7PLR',
    category: category || '',
    type: type, //inbound or outbound
  };

  try {
    const response = await axios.post(
      'https://asia-southeast2-deoapp-indonesia.cloudfunctions.net/point-get',
      data_points,
      config
    );
    return response.data;
  } catch (error) {
    throw new Error('Error fetching points:', error);
  }
};
