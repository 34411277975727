import { Input, Stack } from '@chakra-ui/react';
import React, { useState } from 'react';
import { BiSearchAlt2 } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';

function AppSearchInput() {
  const [search, setSearch] = useState('');
  const navigate = useNavigate();


  // const [loading, setLoading] = useState(false);

  // const handleKeyDown = async (event) => {
  //   if (event.key === 'Enter') {
  //     setLoading(true);

  //     if (search === undefined || search === '') {
  //       return toast({
  //         title: 'DapetKomisi',
  //         description: 'Please type in your search again',
  //         status: 'warning',
  //       });
  //     }

  //     navigate(`/search?detail=${search}`, { state: search });
  //     setLoading(false);
  //   }
  // };

  return (
    <Stack
      flexDirection={['row', 'row', 'row']}
      w='80%'
      alignItems='center'
      justifyContent={'center'}
    >
      <BiSearchAlt2 size={30} />
      <Input
        borderRadius='2xl'
        bgColor={'white'}
        size={'md'}
        placeholder='Search our store...'
        onChange={(e) => setSearch(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            navigate(`/search/${e.target.value}`);
          }
        }}
      />
    </Stack>
  );
}

export default AppSearchInput;
