import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Circle,
  HStack,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { FaClock, FaUsers } from 'react-icons/fa';
import { IoIosPricetags, IoLogoWhatsapp } from 'react-icons/io';
import { MdClose } from 'react-icons/md';
import { karla, ovo, themeColor } from '../../Theme/theme';
import { UseAuthStore } from '../../Hooks/Zustand/store';
import MainContent from './FloatingContent/MainContent';
import PopupCountdown from './PopupCountdown';
import PopupDiscount from './PopupDiscount';
import { getSingleDocumentFirebase } from '../../Apis/firebaseApi';

const FloatingContacts = () => {
  const globalState = UseAuthStore();
  const [openPoints, setOpenPoints] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);
  const [webConfig, setWebConfig] = useState({});
  const [modal, setModal] = useState({ label: '', mode: '', item: null });

  const handleWebConfig = async () => {
    try {
      const res = await getSingleDocumentFirebase(
        'crm_web_config',
        globalState?.currentProject
      );
      console.log(res?.countdown, 'countdown');
      if (res) return setWebConfig(res?.countdown) || console.log(res);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleOpenPoints = () => {
    setOpenPoints((prev) => !prev);
  };

  const handleOpenVoucher = () => {
    setToggleModal((prev) => !prev);
    setModal({
      label: 'voucher',
      mode: 'voucher',
      item: null,
    });
  };

  const handleOpenCountdown = () => {
    setToggleModal((prev) => !prev);
    setModal({
      label: 'countdown',
      mode: 'countdown',
      item: null,
    });
  };

  const MotionBox = motion(Box);

  useEffect(() => {
    handleWebConfig();
  }, []);

  return (
    <>
      <Stack position={'relative'}>
        <MotionBox
          top={'8%'}
          ml={5}
          position={'fixed'}
          initial={{ opacity: 0, y: 20 }}
          animate={openPoints ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }}
          transition={{ duration: 1 }}
          transitionTimingFunction={'ease-in-out'}
          w={350}
          h={'80vh'}
          bg='white'
          borderRadius={'md'}
          shadow={'md'}
          overflow={'hidden'}
          display={openPoints ? 'block' : 'none'}
        >
          <MainContent ovo={ovo} karla={karla} />
        </MotionBox>
        <Stack
          position={'fixed'}
          bottom={450}
          right={0}
          px={5}
          justify={'end'}
          align={'flex-end'}
        >
          <Stack
            pos={'relative'}
            right={0}
            align={'center'}
            justify={'center'}
            _hover={{
              transform: 'scale(1.05)',
              shadow: 'xl',
            }}
            transition={'0.2s ease-in-out'}
            cursor={'pointer'}
            onClick={handleOpenVoucher}
          >
            <Circle
              shadow={'dark-lg'}
              bgColor={'whiteAlpha.800'}
              borderWidth={'3px'}
              borderColor={'#957c61'}
              size={65}
            />
            <IconButton
              size={'lg'}
              bgColor={'transparent'}
              variant={'ghost'}
              color={'#8e6d46'}
              _hover={'transparent'}
              icon={<IoIosPricetags size={30} />}
              pos={'absolute'}
              zIndex={50000}
            />
          </Stack>
        </Stack>

        {webConfig?.isActive && (
          <Stack
            position={'fixed'}
            bottom={850}
            left={0}
            px={5}
            justify={'end'}
            align={'flex-end'}
          >
            <Stack
              pos={'relative'}
              left={0}
              align={'center'}
              justify={'center'}
              _hover={{
                transform: 'scale(1.05)',
                shadow: 'xl',
              }}
              transition={'0.2s ease-in-out'}
              cursor={'pointer'}
              onClick={handleOpenCountdown}
            >
              <Circle
                shadow={'dark-lg'}
                bgColor={'whiteAlpha.800'}
                borderWidth={'3px'}
                borderColor={'#957c61'}
                size={65}
              />
              <IconButton
                size={'lg'}
                bgColor={'transparent'}
                variant={'ghost'}
                color={'#8e6d46'}
                _hover={'transparent'}
                icon={<FaClock size={30} />}
                pos={'absolute'}
                zIndex={50000}
              />
            </Stack>
          </Stack>
        )}
        {globalState.uid && (
          <HStack
            justify={'space-between'}
            position={'fixed'}
            bottom={5}
            px={5}
            // w={'100%'}
          >
            <Stack
              pos={'relative'}
              align={'flex-start'}
              justify={'center'}
              cursor='pointer'
              spacing={3}
            >
              {!openPoints ? (
                <HStack
                  bgColor={'#8e6d46 '}
                  px={5}
                  py={1}
                  borderRadius='full'
                  spacing={1}
                  onClick={handleOpenPoints}
                >
                  <IconButton
                    size={'lg'}
                    variant={'none'}
                    color={'white'}
                    icon={<FaUsers size={30} />}
                  />
                  <Text fontSize={'sm'} color='white'>
                    Klamby Family
                  </Text>
                </HStack>
              ) : (
                <Stack onClick={handleOpenPoints}>
                  <Stack bgColor={'#8e6d46'} p={1} borderRadius='full'>
                    <IconButton
                      size={'lg'}
                      variant={'none'}
                      color={'white'}
                      icon={<MdClose size={35} />}
                    />
                  </Stack>
                </Stack>
              )}
            </Stack>
            <Stack
              display={'none'}
              pos={'relative'}
              right={0}
              align={'center'}
              justify={'center'}
              onClick={() =>
                window.open(
                  'https://api.whatsapp.com/send?phone=+6282125197645&text=Hallo%2C',
                  '_blank'
                )
              }
            >
              <Circle bgColor={'white'} size={50} />
              <IconButton
                size={'lg'}
                variant={'ghost'}
                color={'#8e6d46'}
                icon={<IoLogoWhatsapp size={50} />}
                pos={'absolute'}
                zIndex={50000}
              />
            </Stack>
          </HStack>
        )}
      </Stack>

      <Modal isOpen={toggleModal} onClose={() => setToggleModal(false)}>
        <ModalOverlay />
        <ModalContent rounded={'lg'} minW={'60%'}>
          {globalState?.isTrial ? (
            modal.mode === 'voucher' ? (
              <PopupDiscount close={() => setToggleModal(false)} />
            ) : (
              <PopupCountdown close={() => setToggleModal(false)} config = {webConfig}  />
            )
          ) : (
            <Stack w={'full'} fontSize={'sm'}>
              <Alert status='error'>
                <AlertIcon />
                <Stack>
                  <Text>
                    Websites ini dalam tahap pengembangan, silahkan kembali ke
                  </Text>
                  <Link color={'blue.500'} href='https://klamby.id'>
                    klamby.id
                  </Link>
                </Stack>
              </Alert>
            </Stack>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default FloatingContacts;
