/* eslint-disable no-console */
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Divider,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { karla, ovo, transparent } from '../../Theme/theme';
import {
  deleteDocumentFirebase,
  getCollectionFirebase,
} from '../../Apis/firebaseApi';
import AddressForm from './Components/FormAddress';
import { useParams } from 'react-router-dom';
import { FiPlus } from 'react-icons/fi';
const themeColor = '#8e6d46';

const AddressPage = () => {
  const toast = useToast();
  const params = useParams();
  const [user, setUser] = useState([]);
  const [alertToggle, setAlertToggle] = useState(false);
  const [modalToggle, setModalToggle] = useState(false);
  const [isDelete, setIsDelete] = useState({});
  const [form, setForm] = useState({
    label: '',
    type: '',
    item: null,
  });

  const fetchUserInfo = async () => {
    const data = await getCollectionFirebase(`users/${params?.user}/address`);
    if (data) {
      setUser(data);
      console.log(data, 'there are datas in user collection address');
    } else {
      console.log('there are no datas in user collection address');
    }
  };

  const handleEditAddress = (item) => {
    setForm({
      label: 'Edit Address',
      type: 'edit',
      item: item,
    });
    setModalToggle(true);
  };

  const handleFormAddress = () => {
    setForm({
      label: 'Add Address',
      type: 'add',
      item: null,
    });
    setModalToggle(true);
  };

  const handleCancel = () => {
    setForm({
      label: '',
      type: '',
      item: null,
    });
    setModalToggle(!modalToggle);
  };

  const handleDelete = async () => {
    // console.log(isDelete, "jbwheof");
    const deleteAddress = await deleteDocumentFirebase(
      `users/${params?.user}/address`,
      isDelete?.id
    );
    if (deleteAddress) {
      toast({
        title: 'Address deleted',
        description: 'Address has been deleted',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    } else {
      toast({
        title: 'Error',
        description: 'Failed to delete address',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
    setAlertToggle(!alertToggle);
    fetchUserInfo();
  };

  useEffect(() => {
    fetchUserInfo();
  }, [modalToggle]);

  return (
    <Stack gap={10} my={10}>
      <SimpleGrid
        columns={[1, 1, 2, 2]}
        templateColumns={['100%', '100%', '90% 10%', '90% 10%']}
      >
        <Heading textAlign={'center'} style={ovo} pt={3}>
          Addresses
        </Heading>
        <Button
          bgColor={themeColor}
          style={ovo}
          color={'white'}
          rounded={0}
          size={'xl'}
          colorScheme='none'
          _hover={transparent}
          onClick={() => handleFormAddress()}
        >
          {<FiPlus />} {' Address'}
        </Button>
      </SimpleGrid>
      {/* {form.type === "add" ? (
        <AddressForm
          cancel={handleCancel}
          item={form.item}
          label={form.label}
        />
      ) : (
        <></>
      )} */}
      <Divider />
      <Stack align={'left'} justify={'left'} gap={5} textAlign={'left'}>
        {user?.map((item, index) => (
          <Stack key={index}>
            {item.isDefault === true ? (
              <Text as={'bold'} style={ovo} textAlign={'left'} fontSize={'xl'}>
                {item.label.toUpperCase()} (Default)
              </Text>
            ) : (
              <Text style={ovo} textAlign={'left'} fontSize={'xl'}>
                {item?.label?.toUpperCase()}
              </Text>
            )}
            <Box>
              <Text style={karla}>{item?.receiver?.toUpperCase()}</Text>
              <Text style={karla}>{item?.address?.company}</Text>
              <Text style={karla}>{item?.address?.street}</Text>
              <Text style={karla}>{item?.address?.subdistrict_name}</Text>
              <Text style={karla}>{item?.address?.address2}</Text>
              <Text style={karla}>{item?.address?.city}</Text>
              <HStack>
                <Text style={karla}>{item?.address?.state}</Text>
                <Text style={karla}>{item?.address?.postal_code}</Text>
              </HStack>
              <Text style={karla}>{item?.address?.country}</Text>
              <HStack>
                <Button
                  bgColor={themeColor}
                  style={ovo}
                  color={'white'}
                  rounded={0}
                  size={'sm'}
                  w={20}
                  colorScheme='none'
                  _hover={transparent}
                  onClick={() => handleEditAddress(item)}
                >
                  Edit
                </Button>
                <Button
                  bgColor={themeColor}
                  style={ovo}
                  color={'white'}
                  rounded={0}
                  size={'sm'}
                  w={20}
                  colorScheme='none'
                  _hover={transparent}
                  onClick={() => {
                    setAlertToggle(!alertToggle);
                    setIsDelete(item);
                  }}
                >
                  Delete
                </Button>
              </HStack>
            </Box>
          </Stack>
        ))}
      </Stack>
      {/* <Divider/> */}
      {/* {form.type === "edit" ? (
        <AddressForm
          cancel={handleCancel}
          item={form.item}
          label={form.label}
        />
      ) : (
        <></>
      )} */}

      <Modal
        isOpen={modalToggle}
        onClose={() => setModalToggle(!modalToggle)}
        rounded={0}
      >
        <ModalOverlay />
        <ModalContent minW={'60%'}>
          <ModalBody>
            <ModalCloseButton />
            {form.type === 'edit' ? (
              <AddressForm
                cancel={handleCancel}
                item={form.item}
                label={form.label}
                user={params?.user}
              />
            ) : (
              <></>
            )}
            {form.type === 'add' ? (
              <AddressForm
                cancel={handleCancel}
                item={form.item}
                label={form.label}
                user={params?.user}
              />
            ) : (
              <></>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>

      <AlertDialog
        isOpen={alertToggle}
        isCentered
        onClose={() => setAlertToggle(!alertToggle)}
        motionPreset='slideInBottom'
        // leastDestructiveRef={cancelRef}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogCloseButton />
              Delete Address?
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want delete this address?
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                color={themeColor}
                style={{ ...karla, fontWeight: 'bold' }}
                size={'md'}
                minW={'20%'}
                variant={'unstyled'}
                border={`1px solid ${themeColor}`}
                _hover={{ color: 'rgba(142, 109, 70, 0.7)' }}
                rounded={0}
                onClick={() => setAlertToggle(!alertToggle)}
                px={3}
              >
                No
              </Button>
              <Button
                bgColor={themeColor}
                style={{ ...karla, fontWeight: 'bold' }}
                color={'white'}
                rounded={0}
                size={'md'}
                minW={'20%'}
                colorScheme='none'
                _hover={transparent}
                onClick={handleDelete}
              >
                Yes
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Stack>
  );
};

export default AddressPage;
