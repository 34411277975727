import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Circle,
  Flex,
  HStack,
  Heading,
  Image,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { PriceTag } from '../../../Components/PriceTag/PriceTag';
import { karla } from '../../../Theme/theme';
import { UseAuthStore } from '../../../Hooks/Zustand/store';
import { PropTypes } from 'prop-types';
import RedeemPointsComponents from './RedeemPointsComponents';
import VoucherApplyComponent from './VoucherApplyComponent';
import { timeFirebase2 } from '../../../Utils/timeUtil';

const themeColor = '#8e6d46';

const OrderSummaryComponent = ({
  shippingPrice,
  dataCart,
  voucherUsed,
  voucherId,
  pointsRedeem,
}) => {
  const totalPrice = useRef(0);
  const totalOrder = useRef(0);
  const totalAll = useRef(0);
  const globalState = UseAuthStore();
  const [quantity, setQuantity] = useState(0);
  const [price, setPrice] = useState(0);
  const [voucher, setVoucher] = useState(0);
  const [sumTotal, setSumTotal] = useState(0);
  const [isPoints, setIsPoints] = useState({ status: false, value: 0 });

  let statusDiscount = {};

  const getAddToCart = async () => {
    let totalPriceCal = 0;
    let totalOrderCal = 0;
    try {
      const quantityArr = [];
      const priceArr = [];
      if (dataCart) {
        dataCart.map((x) => {
          quantityArr.push(x.quantity);
          priceArr.push(
            x?.productData?.sale_price_int
              ? x?.productData?.sale_price_int * x?.quantity
              : x?.variantData?.regular_price_int * x?.quantity ||
                  parseInt(x?.variantData?.regular_price_int) * x?.quantity
          );
        });
        totalPriceCal = priceArr.reduce((a, b) => a + b, 0);
        totalOrderCal = quantityArr.reduce((a, b) => a + b, 0);
      }
      totalPrice.current = totalPriceCal;
      totalOrder.current = totalOrderCal;
      setPrice(totalPrice.current);
      setQuantity(totalOrderCal);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleDiscount = (item) => {
    if (item?.expired_sale) {
      const expired = timeFirebase2(item?.expired_sale);
      if (expired < new Date()) {
        statusDiscount = { status: 'expired' };
      } else {
        statusDiscount = {
          status: 'active',
          saleValue: item?.saleValue,
          saleType: item?.saleType,
        };
      }
    }
  };

  useEffect(() => {
    getAddToCart();
    globalState?.setIsLoading(true);
    totalAll.current =
      totalPrice.current +
      (shippingPrice || 0) -
      voucher -
      (isPoints.status ? isPoints.value : 0);
    setSumTotal(totalAll.current < 0 ? 0 : totalAll.current);
    globalState?.setIsLoading(false);
  }, [voucher, dataCart, shippingPrice, isPoints]);

  return (
    <>
      <Stack>
        <Accordion
          border={'transparent'}
          allowToggle
          w='100%'
          defaultIndex={[0]}
        >
          <AccordionItem>
            <h2>
              <AccordionButton
                justifyContent={'space-between'}
                paddingInlineStart={0}
                paddingInlineEnd={0}
                style={{ WebkitPaddingStart: 0, WebkitPaddingEnd: 'none' }}
              >
                <Heading fontSize={'2xl'}>Order summary ({quantity})</Heading>
                <Box>
                  <Box color={themeColor} as='span' flex='1' textAlign='left'>
                    Show
                  </Box>
                  <AccordionIcon />
                </Box>
              </AccordionButton>
            </h2>
            <AccordionPanel
              pb={4}
              paddingInlineStart={0}
              paddingInlineEnd={0}
              style={{ WebkitPaddingStart: 0, WebkitPaddingEnd: 'none' }}
            >
              <Stack>
                {dataCart?.map((x, i) => {
                  handleDiscount(x?.productData);

                  return (
                    <Flex gap={5} key={i}>
                      <Box pos={'relative'}>
                        <Circle
                          pos={'absolute'}
                          right={'-2'}
                          top={'-2'}
                          bgColor={'rgba(0,0,0,0.56)'}
                          w={5}
                          color={'white'}
                          fontSize={'sm'}
                        >
                          <Text>{x?.quantity}</Text>
                        </Circle>
                        <Image
                          src={
                            x?.variantData?.image || x?.productData?.image[0]
                          }
                          aspectRatio={43 / 64}
                          w={'86px'}
                        />
                      </Box>
                      <Stack w='full'>
                        <Text>{x?.productData?.name}</Text>
                        <Text fontSize={'sm'}>{x?.variantData?.name}</Text>
                        <Text as={'i'} color='gray'>
                          {x?.quantity} pcs
                        </Text>
                        <HStack
                          display={'flex'}
                          alignItems={'end'}
                          gap={2}
                          justifyContent={'space-between'}
                        >
                          <HStack>
                            {statusDiscount?.status === 'active' ? (
                              <Text as={'s'} color={'gray.500'} style={karla}>
                                <PriceTag
                                  price={
                                    x?.variantData?.price_int * x?.quantity ||
                                    parseInt(x?.variantData?.price) *
                                      x?.quantity
                                  }
                                  currency={'IDR'}
                                  text={karla}
                                />
                              </Text>
                            ) : (
                              <></>
                            )}
                            <Text
                              color={
                                x?.productData?.sale_price_int ? 'red' : 'black'
                              }
                            >
                              <PriceTag
                                price={
                                  x?.productData?.sale_price_int
                                    ? x?.productData?.sale_price_int *
                                      x?.quantity
                                    : x?.variantData?.price_int * x?.quantity ||
                                      parseInt(x?.variantData?.price) *
                                        x?.quantity
                                }
                                currency={'IDR'}
                                text={karla}
                              />
                            </Text>
                            {statusDiscount?.saleType === 'percentage' ? (
                              <Badge colorScheme={'red'}>
                                -{statusDiscount?.saleValue}%
                              </Badge>
                            ) : (
                              <></>
                            )}
                          </HStack>
                        </HStack>
                      </Stack>
                    </Flex>
                  );
                })}
                <Flex justify={'space-between'}>
                  <Text as={'b'}>Customer Note: </Text>
                  <Text as={'i'}>{localStorage.getItem('note') || '...'}</Text>
                </Flex>
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <VoucherApplyComponent
          dataCart={dataCart}
          voucherId={voucherId}
          voucherUsed={(item) => voucherUsed(item)}
          quantity={quantity}
          price={price}
          setPrice={(item) => setPrice(item)}
          setVoucher={(item) => setVoucher(item)}
        />
        {globalState?.isLoggedIn && (
          <Stack>
            <RedeemPointsComponents
              pointsRedeem={(point) => {setIsPoints(point),pointsRedeem(point)}}
            />
          </Stack>
        )}
        <Flex justify={'space-between'} fontSize={'sm'}>
          <Text>Subtotal</Text>
          <PriceTag
            price={totalPrice?.current || 0}
            currency={'IDR'}
            text={karla}
          />
        </Flex>
        <Flex justify={'space-between'} fontSize={'sm'}>
          <Text>Shipping</Text>
          <PriceTag
            price={shippingPrice != undefined ? shippingPrice : 0}
            currency={'IDR'}
            text={karla}
          />
        </Flex>
        {voucher ? (
          <>
            <Flex justify={'space-between'} fontSize={'sm'}>
              <Text>voucher</Text>
              <Text color={'red'}>
                <PriceTag
                  price={voucher ? voucher : 0}
                  currency={'IDR'}
                  text={karla}
                />
              </Text>
            </Flex>
            <Flex justify={'space-between'} fontSize={'sm'}>
              <Text></Text>
              <Text as={'s'} color={'gray.500'}>
                <PriceTag
                  price={totalPrice?.current}
                  currency={'IDR'}
                  text={karla}
                />
              </Text>
            </Flex>
          </>
        ) : (
          <></>
        )}
        <Flex justify={'space-between'}>
          <Text as={'b'}>Total</Text>
          <Skeleton isLoaded={!globalState?.isLoading}>
            <Text as={'b'}>
              {isPoints?.status ? (
                <Text color={'green.400'}>
                  <PriceTag price={sumTotal} currency={'IDR'} text={karla} />
                </Text>
              ) : (
                <PriceTag price={sumTotal} currency={'IDR'} text={karla} />
              )}
            </Text>
          </Skeleton>
        </Flex>
      </Stack>
    </>
  );
};

OrderSummaryComponent.propTypes = {
  data: PropTypes.array,
  shippingPrice: PropTypes.number,
  setTotalPrice: PropTypes.func,
  totalWeight: PropTypes.func,
  user: PropTypes.string,
  voucherUsed: PropTypes.array,
  voucherId: PropTypes.string,
  pointsRedeem: PropTypes.func,
  dataCart: PropTypes.obj,
};

export default OrderSummaryComponent;
