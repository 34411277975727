import { create } from 'zustand';

export const UseAuthStore = create((set, get) => ({
  isLoggedIn: false,
  user: {},
  tokens: {},
  isLoading: false,
  currentCompany: 'ffBBNgi1CODzR9xxU2Ro',
  currentProject: 'bKEB41GUP7kHzHmpMXjn',
  authFirebaseToken: '',
  uid: '',
  product: {},
  contactId: '',
  isVIP: false,
  VIPInformation: { email: '', isVIPPassword: false, phone: '', tags:[]},
  filterStock: [],
  filterSize: [],
  filterPrice: [],
  drawerToggle: false,
  globalColour: [],
  category: '',
  filterData: { asignee: '', category: '', label: '' },
  appliedVoucher: '',
  isTrial: true,
  name: '',
  email: '',
  phone: '',

  setTokens: (data) => {
    set({ tokens: data });
  },

  setUid: (data) => {
    set({ uid: data });
  },

  setAuthFirebaseToken: (data) => {
    set({ authFirebaseToken: data });
  },

  setUser: (data) => {
    set({ user: data });
  },

  setCurrentCompany: (data) => {
    set({ currentCompany: data });
  },

  setCurrentProject: (data) => {
    set({ currentProject: data });
  },

  setIsLoggedIn: (data) => {
    set({ isLoggedIn: data });
  },

  setIsLoading: (data) => {
    set({ isLoading: data });
  },

  setProduct: (data) => {
    set({ product: data });
  },

  setFilterStock: (data) => {
    set({ filterStock: data });
  },

  setFilterSize: (data) => {
    set({ filterSize: data });
  },

  setFilterPrice: (data) => {
    set({ filterPrice: data });
  },

  setDrawer: (data) => {
    set({ drawerToggle: data });
  },


  setGlobalColour: (data) => {
    set({ globalColour: data });
  },

  setCategory: (data) => {
    set({ category: data });
  },

  setAppliedVoucher: (data) => {
    set({ category: data });
  },

  setFilterData: (data) => {
    set({
      filterData: {
        ...get().filterData,
        ...data,
      },
    });
  },

  setIsTrial: (data) => {
    set({ isTrial: data });
  },

  setIsVIP: (data) => {
    set({ isVIP: data });
  },

  setName: (data) => {
    set({ name: data });
  },

  setEmail: (data) => {
    set({ email: data });
  },

  setPhone: (data) => {
    set({ phone: data });
  },

  setContactId: (data) => {
    set({ contactId: data });
  },

  setVIPInformation: (data) => {
    set({ 
      VIPInformation: {
        ...get().VIPInformation,
        ...data} 
    });
  },

  resetFilterData: () => {
    set({
      filterData: {
        asignee: '',
        label: '',
        category: '',
        createdBy: '',
        projectId: '',
      },
    });
  },
}));