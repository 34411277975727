import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HomeRouter from './HomeRouter';
function MainRouter() {
  const allRouter = [...HomeRouter];

  return (
    <Routes>
      {allRouter.map((item, index) => {
        return <Route key={index} path={item.path} element={item.element} />;
      })}
    </Routes>
  );
}

export default MainRouter;
