import { Box, Flex, Heading, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { karla, ovo } from '../../../Theme/theme';
import SkeletonImage from '../../../Components/Skeletons/SkeletonsImage';
import PropTypes from 'prop-types';
import { PriceTag } from '../../../Components/PriceTag/PriceTag';
import { UseAuthStore } from '../../../Hooks/Zustand/store';
import { useNavigate } from 'react-router-dom';

const ContentVIPComponent = ({ data, products }) => {
  const videoKlambyBlog =
    'https://cdn.shopify.com/videos/c/o/v/7a88827ef51643cc9a001db1ff8e141d.mp4';
  const globalState = UseAuthStore();
  const navigate = useNavigate();
  return (
    <>
      <Stack pos={'relative'}>
        <video autoPlay muted loop>
          <source src={videoKlambyBlog} type='video/mp4' />
        </video>
        <Stack
          bgColor={'rgba(1,1,1,0.4)'}
          w={'100%'}
          h='100%'
          mt={[5, 5, 5, 5, 10, 10]}
        >
          <Stack
            bottom={0}
            align={'center'}
            pos={'absolute'}
            w={'100%'}
            h={[100, 100, 200, 200]}
          >
            <Heading
              textTransform='capitalize'
              style={ovo}
              color={'white'}
              fontSize={['4xl', '4xl', '7xl', '7xl']}
            >
              community gathering
            </Heading>
            <Text
              textTransform={'uppercase'}
              style={karla}
              color={'white'}
              letterSpacing={'0.2em'}
            >
              Bandung, 2023
            </Text>
          </Stack>
        </Stack>
      </Stack>

      <Stack gap={[10, 10, 10, 10, 10, 20]} my={[5, 5, 5, 5, 10, 10]}>
        <Box position={'relative'}>
          <Heading
            style={ovo}
            fontSize={'3xl'}
            textAlign={'center'}
            mb={[5, 5, 5, 5, 10, 10]}
          >
            Exclusive Product For You
          </Heading>
        </Box>

        <SimpleGrid
          columns={[
            1,
            1,
            data.length % 2 === 0 ? 2 : 3,
            data.length % 2 === 0 ? 2 : 3,
            data.length % 2 === 0 ? 2 : 3,
          ]}
          gap={5}
        >
          {products?.map((x, i) => (
            <Stack
              key={i}
              pos={'relative'}
              minW={['100%', '100%', 'unset', 'unset', 'unset', 'unset']}
              onClick={() => {
                navigate(`/collections/all/product/${x.id}`);
                globalState?.setProduct(x.id);
              }}
              cursor={'pointer'}
            >
              {x?.inventory && x?.sale_price_int > 0 ? (
                <Flex
                  pos={'absolute'}
                  right={0}
                  bgColor={'#b73737'}
                  top={0}
                ></Flex>
              ) : x?.inventory && x?.sale_price_int === 0 ? (
                <Flex
                  pos={'absolute'}
                  right={0}
                  bgColor={'#8e6d46'}
                  top={0}
                ></Flex>
              ) : !x?.inventory ? (
                <Flex pos={'absolute'} right={0} bgColor={'#b73737'} top={0}>
                  <Text
                    px={2}
                    py={1}
                    fontSize={'sm'}
                    color={'white'}
                    letterSpacing={'0.2em'}
                    style={karla}
                  >
                    SOLD OUT
                  </Text>
                </Flex>
              ) : (
                <></>
              )}
              <SkeletonImage
                objectFit={'cover'}
                minW={['70%', '70%', 'unset', 'unset', 'unset', 'unset']}
                aspectRatio={2 / 3}
                src={x.images ? x.images[0] : null}
              />
              <Box>
                <Text
                  fontSize={['md', 'md', 'lg', 'lg', 'lg', 'xl']}
                  style={karla}
                >
                  {x?.name?.includes('-') || x?.name?.includes('-') ? (
                    <Text
                      fontSize={['md', 'md', 'lg', 'lg', 'lg', 'xl']}
                      style={karla}
                    >
                      {x?.name?.split('-')[1] || x?.name?.split('|')[1]}
                    </Text>
                  ) : (
                    <Text
                      fontSize={['md', 'md', 'lg', 'lg', 'lg', 'xl']}
                      style={karla}
                    >
                      {x?.name}
                    </Text>
                  )}
                </Text>
                {x?.inventory && x?.sale_price_int > 0 ? (
                  <Flex gap={2}>
                    <Text
                      as={'s'}
                      fontSize={['xs', 'xs', 'md', 'md']}
                      style={karla}
                    >
                      <PriceTag currency={'IDR'} price={x?.regular_price_int} />
                    </Text>
                    <Text fontSize={['xs', 'xs', 'md', 'md']} style={karla}>
                      <PriceTag
                        currency={'IDR'}
                        price={x?.sale_price_int}
                        color='red'
                      />
                    </Text>
                  </Flex>
                ) : (
                  <Text fontSize={['xs', 'xs', 'md', 'md']} style={karla}>
                    <PriceTag
                      currency={'IDR'}
                      price={x.regular_price_int || 0}
                    />
                  </Text>
                )}
              </Box>
            </Stack>
          ))}
        </SimpleGrid>
      </Stack>
    </>
  );
};

ContentVIPComponent.propTypes = {
  products: PropTypes.array,
  data: PropTypes.array,
};

export default ContentVIPComponent;
