/* eslint-disable react/prop-types */
import { Stack, Text } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { themeColor } from '../../Theme/theme';
const Countdown = ({ startDate, endDate }) => {
  const [countdownText, setCountdownText] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      if (startDate === undefined) {
        return
      }
      const end = new Date(endDate).getTime();
      const totalSeconds = (end - Date.now()) / 1000;

      if (totalSeconds <= 0) {
        clearInterval(interval);
        setCountdownText('Countdown has ended');
      } else {
        const days = Math.floor(totalSeconds / 86400);
        const hours = Math.floor((totalSeconds % 86400) / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = Math.floor(totalSeconds % 60);

        setCountdownText(
          `${days} ${('Days')} ${hours} ${('Hours')} ${minutes} ${('Minutes')} ${seconds} ${('Seconds')}`
        );
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [startDate, endDate]);

  return (
    <Stack  bgColor={themeColor} borderRadius={'lg'} mt={2} shadow={'xl'} textAlign={'center'} p={2}>
      <Text fontWeight={'bold'} color='white' fontSize={'xs'} noOfLines={1}>
        {countdownText}
      </Text>
    </Stack>
  );
};

export default Countdown;