import { useState } from 'react';
import { Skeleton, Image } from '@chakra-ui/react';
import PropTypes from 'prop-types';


const SkeletonImage = ({ src, alt, ...rest }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <Skeleton isLoaded={imageLoaded} {...rest}>
      <Image
        src={src}
        alt={alt}
        onLoad={() => setImageLoaded(true)}
        {...rest}
      />
    </Skeleton>
  );
};

SkeletonImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default SkeletonImage;
