import React, { useEffect, useState } from 'react';
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md';
import PropTypes from 'prop-types';
import { Stack } from '@chakra-ui/react';
import AppImageSlide from '../../Carousel/AppImageSlide';

const HeroSlider = ({ data }) => {
  const dataDummy = [
    {
      image:
        'https://klamby.id/cdn/shop/files/website_estella-2_1944x.jpg?v=1705984402',
      link: 'https://facebook.com',
    },
    {
      image:
        'https://klamby.id/cdn/shop/files/website_biyya-4_1944x.jpg?v=1705634701',
      link: 'https://instagram.com',
    },
    {
      image:
        'https://klamby.id/cdn/shop/files/BANNER-110124-LASTCALL_1944x.png?v=1704961356',
      link: 'https://klamby.id',
    },
  ];

  const dataSlider = data
  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? dataSlider?.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === dataSlider?.length - 1;

    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000); // 5000 milliseconds = 5 seconds

    return () => clearInterval(interval);
  }, [currentIndex]);

  return (
    <Stack w={'100%'} position='relative' >
      <AppImageSlide images={data && data} />
    </Stack>
    
  );
};

export default HeroSlider;

HeroSlider.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};
