import {
  Button,
  HStack,
  Heading,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import parse from 'html-react-parser';
import { getCollectionFirebase } from '../../Apis/firebaseApi';
import { UseAuthStore } from '../../Hooks/Zustand/store';
import { useNavigate } from 'react-router-dom';
const ovo = {
  fontFamily: 'Ovo',
  fontWeight: 400,
};
const karla = {
  fontFamily: 'Karla',
  fontWeight: 400,
};
const themeColor = '#8e6d46';
const BlogsPage = () => {
  const globalState = UseAuthStore()
  const navigate = useNavigate()
  const [blogData, setBlogData] = useState([])

  const fetchListBlog = async() => {
    const conditions = [
      {field: 'companyId', operator: '==', value: globalState.currentCompany},
      {field: 'projectId', operator: '==', value: globalState.currentProject},
      {field: 'isPublish', operator: '==', value: true},
    ]

    try {
      const res = await getCollectionFirebase('crm_web_blog', conditions)

      if(res.length > 0){
        //pecah berdasarkan tags
        const blog = res.filter((data) => data?.tags?.includes('blogpost'))
        setBlogData(blog)
      }

    } catch (error) {
      throw new Error(error)
    }
  }

  useEffect(() => {
    fetchListBlog()
  }, []);

  return (
    <Stack mb={10}>
      <Heading
        style={ovo}
        fontSize={'3xl'}
        textAlign={'center'}
        mb={[5, 5, 5, 5, 10, 10]}
      >
        Journal
      </Heading>
      <Stack columns={1} gap={10}>
        {blogData &&
          blogData?.map((x, i) => (
            <HStack
              key={i}
              col
              gap={10}
              flexDirection={{
                base: 'column',
                md: i % 2 === 0 ? 'row' : 'row-reverse',
              }}
            >
              <Image
                w={{ base: '100%', md: '50%' }}
                h={{ base: 'none', md: 450 }}
                src={x.thumbnail_image}
                aspectRatio={16 / 9}
                objectFit={'cover'}
              />

              <Stack gap={5}>
                <Stack gap={2}>
                  <Text
                    letterSpacing={'0.2em'}
                    textTransform={'uppercase'}
                    fontSize={'sm'}
                    style={karla}
                  >
                    {moment(x?.createdAt).format('ll')}
                  </Text>
                  <Heading fontSize={'3xl'} style={ovo}>
                    {x?.title}
                  </Heading>
                </Stack>
                <Text noOfLines={5} style={karla} textAlign={'justify'}>
                  {x?.post && parse(x?.post)}
                </Text>
                <Button
                  color={'white'}
                  bgColor={themeColor}
                  rounded={0}
                  colorScheme='none'
                  style={ovo}
                  size={'lg'}
                  w={'fit-content'}
                  onClick={() => navigate(`/journal/${encodeURIComponent(x?.title)?.replace(/%20/g, '-')}/${x.id}`)}
                >
                  Continue reading
                </Button>
              </Stack>
            </HStack>
          ))}
      </Stack>
    </Stack>
  );
};

export default BlogsPage;
