import {
  Box,
  Divider,
  HStack,
  IconButton,
  Image,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { formatFrice } from '../../../Utils/numberUtil';
import { RiHandCoinLine } from 'react-icons/ri';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { CiTimer } from 'react-icons/ci';
import PropTypes from 'prop-types';
import RewardActivity from './RewardActivity';
import { UseAuthStore } from '../../../Hooks/Zustand/store';
import {
  getCollectionFirebase,
  getSingleDocumentFirebase,
} from '../../../Apis/firebaseApi';
import logo_klamby from '../../../Assets/Image/Klamby/white.png';
import {
  getPastFirestoreTimestamp,
} from '../../../Utils/timeUtil';
import { Timestamp } from 'firebase/firestore';

const MainContent = ({ karla }) => {
  const [section, setSection] = useState('');
  const globalState = UseAuthStore();
  const [config, setConfig] = useState({});
  const [earnPoints, setEarnPoints] = useState(0);
  const [redeemPoints, setRedeemPoints] = useState(0);
  const [logPoint, setLogPoint] = useState([]);
  const [order, setOrder] = useState([]);
  const [lastExpired, setLastExpired] = useState(0);
  const [contact, setContact] = useState({});
  const getHistoryOrder = async (config) => {
    try {
      // Fetch user external points
      const userExternalPoints = await getSingleDocumentFirebase('crm_contacts', globalState?.contactId);
      setContact(userExternalPoints);
      
      const lastTime = getPastFirestoreTimestamp(config?.expiredDays);
      const conditions = [
        { field: 'projectId', operator: '==', value: globalState?.currentProject },
        { field: 'contactId', operator: '==', value: globalState?.contactId },
        { field: 'paymentStatus', operator: '==', value: 'PAID' },
        { field: 'createdAt', operator: '>=', value: lastTime }
      ];
      const orders = await getCollectionFirebase('crm_orders', conditions);

      let totalEarn = orders.reduce((acc, order) => acc + (order?.point || 0), 0) / config?.earn;
      console.log(totalEarn, 'totalearn')
      totalEarn += userExternalPoints?.externalPoints / config?.earn >= 0 ? userExternalPoints?.externalPoints / config?.earn : 0;

      setOrder(orders);
      setEarnPoints(totalEarn);
    } catch (error) {
      console.log(error.message);
    }
  };

  const getConfigPoint = async () => {
    try {
      const config = await getSingleDocumentFirebase(
        'crm_points',
        globalState?.currentProject
      );

      if (config && config?.isActive) {
        const clear = {
          earn: parseInt(config?.pointValueEarn),
          expiredDays: parseInt(config?.expiredDays),
          redeem: parseInt(config?.pointValueRedeem),
        };
        setConfig(clear);
        await getHistoryOrder(clear);
        await getLogPoint(clear);
      }
    } catch (error) {
      // eslint-disable-next-line
      console.log(error.message);
    }
  };

  const getLogPoint = async (config) => {
    if (!globalState.contactId) return;
    const lastTime = getPastFirestoreTimestamp(config?.expiredDays);
    try {
      const conditions = [
        { field: 'companyId', operator: '==', value: globalState?.currentCompany },
        { field: 'projectId', operator: '==', value: globalState?.currentProject },
        { field: 'contactId', operator: '==', value: globalState?.contactId },
        { field: 'createdAt', operator: '>=', value: lastTime }
      ];
      const log = await getCollectionFirebase('crm_points_log', conditions);
      if (log?.length > 0) {
        setLogPoint((prevLogPoint) => [...prevLogPoint, ...log]);
        const totalRedeem = log.reduce((acc, logEntry) => acc + (logEntry?.point || 0), 0) / config?.redeem;
        setRedeemPoints(totalRedeem);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (globalState?.isLoggedIn) {
      getConfigPoint();
    }
  }, []);

  return (
    <Box>
      <Box bg={'#8e6d46'} h={'fit-content'} w='full' p={5} style={karla}>
        <Image boxSize='60px' src={logo_klamby} alt='Monogram White' />
        <Stack spacing={0.5} color={'white'} py={2}>
          <Text fontSize={'md'} style={karla}>
            Your Points
          </Text>
          <Text fontSize={'xx-large'} fontWeight={'semibold'} style={karla}>
            {formatFrice(earnPoints - redeemPoints)}
          </Text>
          {contact?.externalVipName && (
            <Text fontSize={'md'} fontWeight={'semibold'} style={karla}>
              {contact?.externalVipName || ''}
            </Text>
          )}
          <Box
            bg={'#58432c'}
            w={'fit-content'}
            borderRadius={'md'}
            px={2}
            py={1}
          >
            <Text fontSize={'xs'}>
              {lastExpired} point will be expired in 7 days
            </Text>
          </Box>
        </Stack>
      </Box>

      {section === '' ? (
        <>
          <Box
            m={4}
            p={5}
            fontSize={'sm'}
            border={'1px'}
            borderRadius={'md'}
            borderColor={'gray.100'}
            boxShadow={'sm'}
          >
            <HStack
              cursor={'pointer'}
              _hover={{ bg: 'gray.100', transition: '0.3s ease-in-out' }}
              onClick={() => setSection('earn')}
            >
              <IconButton
                size={'lg'}
                variant={'none'}
                color={'black'}
                icon={<RiHandCoinLine size={25} />}
              />
              <Text style={karla}>Ways To Earn</Text>
              <Spacer />
              <IconButton
                size={'lg'}
                variant={'none'}
                color={'gray.400'}
                icon={<MdKeyboardArrowRight size={25} />}
              />
            </HStack>
            <Divider />
            <HStack
              cursor={'pointer'}
              _hover={{ bg: 'gray.100', transition: '0.3s ease-in-out' }}
              onClick={() => setSection('redeem')}
            >
              <IconButton
                size={'lg'}
                variant={'none'}
                color={'black'}
                icon={<RiHandCoinLine size={25} />}
              />
              <Text style={karla}>Ways To Redeem</Text>
              <Spacer />
              <IconButton
                size={'lg'}
                variant={'none'}
                color={'gray.400'}
                icon={<MdKeyboardArrowRight size={25} />}
              />
            </HStack>
          </Box>
          <Box
            m={4}
            p={4}
            fontSize={'sm'}
            border={'1px'}
            borderRadius={'md'}
            borderColor={'gray.100'}
            boxShadow={'sm'}
          >
            <HStack
              cursor={'pointer'}
              _hover={{ bg: 'gray.100', transition: '0.3s ease-in-out' }}
              onClick={() => setSection('activity')}
            >
              <IconButton
                size={'lg'}
                variant={'none'}
                color={'black'}
                icon={<CiTimer size={30} />}
              />
              <Text style={karla}>Your activity</Text>
              <Spacer />
              <IconButton
                size={'lg'}
                variant={'none'}
                color={'gray.400'}
                icon={<MdKeyboardArrowRight size={25} />}
              />
            </HStack>
          </Box>
        </>
      ) : section != '' ? (
        <RewardActivity
          section={section}
          setSection={setSection}
          karla={karla}
          logPoint={[...logPoint, ...order]}
          config =  {config}
        />
      ) : (
        <></>
      )}
    </Box>
  );
};

export default MainContent;

MainContent.propTypes = {
  ovo: PropTypes.object,
  karla: PropTypes.object,
};
