import { Box, Button, HStack, IconButton } from '@chakra-ui/react';
import { useState } from 'react';
import { themeColor } from '../../Theme/theme';
import PropTypes from 'prop-types';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

const Paginate = ({ data, total, itemsPerPage, currentPage, onPageChange, isLoadingProduct }) => {
  const totalPages = Math.ceil(total / itemsPerPage);
  const pages = [...Array(totalPages).keys()].map((num) => num + 1);

  return (
    <>
      {isLoadingProduct ? (
        null
      ):(
        <>
          {data?.length > 0 ? (
            <HStack spacing={4} mt={4} justify='center'>
              <IconButton
                onClick={() => onPageChange(currentPage - 1)}
                isDisabled={currentPage === 1}
                // rounded={'full'}
                bgColor={themeColor}
                color={'white'}
                icon={<FiChevronLeft/>}
              >
                  Previous
              </IconButton>

              {pages.map((page) => (
                <Button
                  key={page}
                  onClick={() => onPageChange(page)}
                  bgColor={currentPage === page ? '' : themeColor}
                  color={currentPage === page ? 'black' : 'white'}
                  rounded={'full'}
                >
                  {page}
                </Button>
              ))}

              <IconButton
                onClick={() => onPageChange(currentPage + 1)}
                isDisabled={currentPage === totalPages}
                // rounded={'full'}
                bgColor={themeColor}
                color={'white'}
                icon={<FiChevronRight/>}
              >
          Next
              </IconButton>
            </HStack>
          ):(
            null
          )}
        </>
      )}
    </>
  );
};

Paginate.propTypes = {
  data: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  isLoadingProduct: PropTypes.bool
};

export default Paginate;
