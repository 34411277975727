import {
  Button,
  Container,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Image,
  Input,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { blog } from '../../../Data/Blog';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import {
  FaFacebook,
  FaLongArrowAltLeft,
  FaPinterest,
  FaTwitter,
} from 'react-icons/fa';
import parse from 'html-react-parser';
import { getSingleDocumentFirebase } from '../../../Apis/firebaseApi';
const themeColor = '#8e6d46';

const SingleLookbookPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [dataBlog, setDataBlog] = useState({});

  const fetchSingleDataBlog = async () => {
    try {
      const res = await getSingleDocumentFirebase('crm_web_blog', params.id)

      //modify inline style
      const modify = res?.html?.replace(/<img\s/g, '<img style="width: 100%;"')
      setDataBlog({...res, html: modify})
    } catch (error) {
      throw new Error(error)
    }
  };

  const karla = {
    fontFamily: 'Karla',
    fontWeight: 400,
  };
  const ovo = {
    fontFamily: 'Ovo',
    fontWeight: 400,
  };

  useEffect(() => {
    fetchSingleDataBlog();
  }, []);

  return (
    // <Container w={'full'}>
    <div>
      {/* <Stack gap={10}> */}
      <div dangerouslySetInnerHTML={{ __html: dataBlog?.html }}/>
      {/* </Stack> */}
    </div>
    // </Container>
  );
};

export default SingleLookbookPage;
