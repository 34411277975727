/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Skeleton,
  SlideFade,
  useColorModeValue,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

const AppImageSlide = ({ images }) => {

  const [currentImage, setCurrentImage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) =>
        prevImage === images.length - 1 ? 0 : prevImage + 1
      );
    }, 5000);
    return () => clearInterval(interval);
  }, [images.length]);

  const nextImage = () => {
    setCurrentImage((prevImage) =>
      prevImage === images.length - 1 ? 0 : prevImage + 1
    );
  };

  const prevImage = () => {
    setCurrentImage((prevImage) =>
      prevImage === 0 ? images.length - 1 : prevImage - 1
    );
  };

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const handleRouteDashboard = (link) => {
    if(link){
      window.open(link, '_blank');
    }
  }

  const skeletonColor = useColorModeValue('gray.200', 'gray.700');

  return (
    <Box position="relative">
      <Flex align="center" justify="center">
        <Button
          onClick={prevImage}
          position="absolute"
          top="50%"
          borderRadius={'full'}
          bgColor={'blackAlpha.500'}
          left="10px"
          zIndex={100}
          transform="translateY(-50%)"
          _hover={{ bgColor: 'transparent' }}
          _focus={{ boxShadow: 'none' }}
        >
          <ChevronLeftIcon boxSize={6} color="gray.200" />
        </Button>
        <SlideFade in offsetX="20px" offsetY="0" transition={{ duration: 0.3 }}>
          <Box position="relative" width="100%">
            {isLoading ? (
              <Skeleton
                borderRadius="md"
                width="100%"
                height="400px"
                startColor={skeletonColor}
                endColor={skeletonColor}
              />
            ) : null}
            <Image
              onClick={() => handleRouteDashboard(images[currentImage]?.image || images[currentImage]?.description)}
              src={images[currentImage]?.image}
              alt="Carousel Image"
              objectFit='contain'
              onLoad={handleImageLoad}
              onError={handleImageLoad}
              display={isLoading ? 'none' : 'block'}
              cursor='pointer'
            />
          </Box>
        </SlideFade>
        <Button
          onClick={nextImage}
          position="absolute"
          top="50%"
          zIndex={100}
          right="10px"
          borderRadius={'full'}
          bgColor={'blackAlpha.500'}
          transform="translateY(-50%)"
          _hover={{ bgColor: 'transparent' }}
          _focus={{ boxShadow: 'none' }}
        >
          <ChevronRightIcon boxSize={6} color="gray.200" />
        </Button>
      </Flex>
    
    </Box>
  );
};

export default AppImageSlide;
