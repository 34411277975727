import React, { useEffect, useState } from 'react';
import { UseAuthStore } from '../../../Hooks/Zustand/store';
import {
  Box,
  Flex,
  Grid,
  Image,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import { getCollectionFirebase } from '../../../Apis/firebaseApi';
import { PriceTag } from '../../../Components/PriceTag/PriceTag';
import { karla } from '../../../Theme/theme';
import { useNavigate } from 'react-router-dom';

const ProductSlider = (categories) => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const globalState = UseAuthStore();

  const getData = async () => {
    try {
      const condition = [
        {
          field: 'companyId',
          operator: '==',
          value: globalState?.currentCompany,
        },
        {
          field: 'projectId',
          operator: '==',
          value: globalState?.currentProject,
        },
        {
          field: 'categoryId',
          operator: 'array-contains-any',
          value: categories?.categories,
        },
      ];
      const sortBy = null;
      const limitValue = 10;
      const startAfterData = null;
      const getData = await getCollectionFirebase(
        'crm_product',
        condition,
        sortBy,
        limitValue,
        startAfterData
      );
      if (getData) {
        setProducts(getData);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error.message);
    }
  };

  const handleDiscount = (type, amount) => {
    let value = ''
    if (type === 'percentage') {
      value = amount + '%';
      return value;
    } else if (type === 'value') {
      value = amount;
      return <PriceTag currency='IDR' price={value} />;
    }
  };

  useEffect(() => {
    getData();
  }, [categories, globalState.product]);

  return (
    <>
      <Grid overflow={'auto'} mt={10}>
        {/* <Flex gap={8} overflowX={"auto"}> */}

        <SimpleGrid
          columns={[2, 3, 4, 5, 5]}
          gap={[2, 2, 2, 10]}
          minW={['100%', '100%', 'unset', 'unset', 'unset', 'unset']}
        >
          {products?.map((x, i) => {
            const firebaseTime = new Date(
              x?.launchingDate?.seconds * 1000 +
                x?.launchingDate?.nanoseconds / 1000000
            );
            if (isNaN(firebaseTime.getTime())) {
              return null;
            }

            if (firebaseTime <= new Date()) {
              return (
                <Stack
                  key={i}
                  pos={'relative'}
                  minW={['100%', '100%', 'unset', 'unset', 'unset', 'unset']}
                  onClick={() => {
                    navigate(`/collections/all/product/${x.id}`);
                    globalState.setProduct(x.id);
                  }}
                  cursor={'pointer'}
                >
                  {x?.stocks_int > 0 && x?.is_promo === true ? (
                    <Flex pos={'absolute'} right={0} bgColor={'#b73737'} top={0}>
                      <Text
                        px={2}
                        py={1}
                        fontSize={'sm'}
                        style={karla}
                        color={'white'}
                        letterSpacing={'0.2em'}
                      >
                      SAVE{' '}
                        {handleDiscount(
                          x?.discount_type,
                          x?.discount_amount,
                          x?.currency
                        )}
                      </Text>
                    </Flex>
                  ) : x?.stocks_int && x?.is_promo === false ? (
                    <Flex
                      pos={'absolute'}
                      right={0}
                      bgColor={'#8e6d46'}
                      top={0}
                    ></Flex>
                  ) : !x?.stocks_int ? (
                    <Flex pos={'absolute'} right={0} bgColor={'#8e6d46'} top={0}>
                      <Text
                        px={2}
                        py={1}
                        fontSize={'sm'}
                        style={karla}
                        color={'white'}
                        letterSpacing={'0.2em'}
                      >
                      SOLD OUT
                      </Text>
                    </Flex>
                  ) : (
                    <></>
                  )}
                  <Image
                    objectFit={'cover'}
                    minW={['70%', '70%', 'unset', 'unset', 'unset', 'unset']}
                    aspectRatio={2 / 3}
                    src={x.images ? x.images[0] : null}
                  />
                  <Box>
                    <Text
                      style={karla}
                      fontSize={['md', 'md', 'lg', 'lg', 'lg', 'xl']}
                    >
                      {x.name.split('-')[1] || x.name.split('|')[1]}
                    </Text>
                    {x.saleStatus ? (
                      <Flex gap={2}>
                        <Text
                          as={'del'}
                          style={karla}
                          fontSize={['xs', 'xs', 'md', 'md']}
                        >
                          {<PriceTag currency={x.currency} price={x.price_int} />}
                        </Text>
                        <Text fontSize={['xs', 'xs', 'md', 'md']}>
                          <PriceTag
                            currency={x.currency}
                            price={x.price_int}
                            color='red'
                          />
                        </Text>
                      </Flex>
                    ) : (
                      <Text style={karla} fontSize={['xs', 'xs', 'md', 'md']}>
                        {<PriceTag currency={x.currency} price={x.price_int} />}
                      </Text>
                    )}
                  </Box>
                </Stack>
              );
            } else {
              return null;
            }
          })}
        </SimpleGrid>
        {/* </Flex> */}
      </Grid>
    </>
  );
};

export default ProductSlider;
