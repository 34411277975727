import {
  Box,
  Img,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  Text,
  VStack,
  useBreakpointValue,
} from '@chakra-ui/react';
import React from 'react';
import Countdown2 from '../Templates/Hero/Countdown2';
import PropTypes from 'prop-types';
import { ovo, themeColor } from '../../Theme/theme';
import { timeFirebase } from '../../Utils/timeUtil';

const PopupCountdown = ({config}) => {
  const timestamps = config?.expiredAt?.seconds;
  console.log(timeFirebase(timestamps), timestamps, 'timestamps');
  // const timerSize = useBreakpointValue({ base: '3xl', md: '4xl' });

  return (
    <>
      <ModalHeader color={themeColor} p={2} m={2}>
        <ModalCloseButton/>
        <VStack>
          <Text style={ovo} fontSize='5xl' mb={2}>
            {config?.header || 'Klambys Journal'}
          </Text>
        </VStack>
      </ModalHeader>
      <ModalBody m={0} p={0}>
        <Box
          m={0}
          color={themeColor}
          bgPos='center'
          bgSize='cover'
        >
          <VStack>
            <Text>
              {config?.information || ''}
            </Text>
            <Box>
              <Img src={config?.images[0]} />
            </Box>
            <Box>
              <Countdown2 timeData={timestamps} />
            </Box>
          </VStack>
        </Box>
      </ModalBody>
    </>
  );
};

PopupCountdown.propTypes = {
  header: PropTypes.string,
  content: PropTypes.string,
  launchAt: PropTypes.object,
  config: PropTypes.object,
};

export default PopupCountdown;
