import PropTypes from 'prop-types';
import { Box } from '@chakra-ui/react';
import React from 'react'

function MicrositeBackgroundView({ background }) {
  return (
    <>
      {background?.type === 'solid' && (
        <Box
          h="full"
          w="full"
          bg={background.primaryColor}
        />
      )}
      {background?.type === 'radial' && (
        <Box
          display="flex"
          flexDirection="column"
          h="full"
          overflow="hidden"
          w="full"
          position="relative"
          bg={background.secondaryColor}
        >
          <Box w="full">
            <svg className="w-full" viewBox="0 0 100 100">
              <circle
                cx="50"
                cy="170"
                r="150"
                strokeWidth="10"
                stroke={background.primaryColor}
                fill={background.primaryColor}
              />
            </svg>
          </Box>
          <Box flex="1" w="full" bg={background.primaryColor} />
        </Box>
      )}
      {background?.type === 'linearGradient' && (
        <Box
          h="full"
          w="full"
          background={`linear-gradient(to top, ${background.primaryColor}, ${background.secondaryColor})`}
        />
      )}
      {background?.type === 'radialGradient' && (
        <Box
          h="full"
          w="full"
          background={`radial-gradient(circle at bottom left, ${background.primaryColor}, ${background.secondaryColor})`}
        />
      )}
      {background?.type === 'image' && (
        <Box
          as="img"
          src={background.image}
          className="h-full w-full object-cover"
        />
      )}
    </>
  );
}

MicrositeBackgroundView.propTypes = {
  background: PropTypes.shape({
    type: PropTypes.oneOf([
      'solid',
      'radial',
      'linearGradient',
      'radialGradient',
      'image'
    ]).isRequired,
    primaryColor: PropTypes.string.isRequired,
    secondaryColor: PropTypes.string.isRequired,
    image: PropTypes.string,
  }).isRequired,
};

export default MicrositeBackgroundView;
